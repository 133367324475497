import React, {useState} from 'react';
import {Picto} from "../Picto";
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import {useTranslation} from "../../tools/i18n";
import {TravelPlannerCompositionType} from "./types";
import {imageUrlFor} from "../../tools/sanity";

export interface CompositionProps extends ChatBotComponentProps {
  step: TravelPlannerCompositionType;
}

export const Composition = (props: CompositionProps) => {
  const {t} = useTranslation();
  const {config, step, callback, initialData, back, requirements} = props;
  const options = step.compositionList || [];
  const [data, setData] = useState<string>(initialData || options[0].id)

  const subtitleKey = `travelPlaner.Composition.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  return (
    <div className="composition">
      <FilterLayout
        config={config}
        callback={() => callback(data)}
        title={t(`travelPlaner.Composition.title`)}
        back={back}
        requirements={requirements}
        disabledNext={!data}
        siteConfiguration={props.siteConfiguration}
      >
        {hasSubtitle
          ? <div className="layoutSubtitle">{t(subtitleKey)}</div>
          : null
        }
        <div className="buttonList buttonListComposition">
          {options.map(o => (
            <button
              key={o.id}
              onClick={() => setData(o.id)}
              className={o.id === data ? 'selected' : ''}
            >
              {config.newUI && o.type === 'image'
                ? <img
                    src={imageUrlFor(o.image, requirements).url()}
                    alt={t(`travelPlaner.Composition.${o.id}`)}
                  />
                : o.type === 'picto'
                  ? <Picto iconKey={o.picto}/>
                  : null
              }
              {config.newUI && o.type === "image" && o.selectedImage
                ? <img
                  className="selectedImage"
                  src={imageUrlFor(o.selectedImage, requirements).url()}
                  alt={`${t(`travelPlaner.Composition.${o.id}`)} selected`}
                />
                : null
              }
              <span>{t(`travelPlaner.Composition.${o.id}`)}</span>
            </button>
          ))}
        </div>
      </FilterLayout>
    </div>
  )
}
