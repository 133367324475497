import React from "react";
import {PictoProps} from "./index";

export const EnvelopeNewsletter = (props: PictoProps) => (
  <svg width={props.width || "105"} height={props.height || "105"} viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0912 100.2C12.3617 100.14 11.1132 99.9797 10.228 99.2205L10.8072 98.6814L11.3864 98.1423C11.41 98.2422 12.5132 98.6419 14.123 98.6223C15.7563 98.7027 17.9432 98.5634 20.4915 98.2445C25.5152 97.7266 31.8152 96.6297 37.6832 95.4129C41.9159 94.5353 45.9327 93.5976 49.0601 92.7396C51.8028 91.9614 53.8485 91.223 54.4748 90.8836C54.7696 89.5656 54.4738 86.1702 53.8444 82.2156C53.1444 77.9614 52.0872 73.048 51.0515 68.6539C49.9923 64.1599 49.0017 60.385 48.5289 58.8071C45.0207 58.906 34.1524 60.7406 23.981 62.5355C14.8914 64.2108 6.2827 65.7864 3.78155 66.305C4.24823 69.1411 6.04636 79.7667 8.02357 88.1554C9.20048 93.1488 10.5247 97.483 11.2902 98.1623L10.711 98.7014L10.1318 99.2405C8.86585 97.7223 7.68485 93.5678 6.4844 88.4746C4.17766 78.6877 2.12674 65.7054 2.12674 65.7054C2.03259 65.3059 2.25057 64.9465 2.73156 64.8468C2.82776 64.8268 43.1796 57.0881 49.0416 57.1295C49.3537 57.1696 49.7129 57.4093 49.7835 57.7089C50.1151 58.6876 51.247 63.0618 52.471 68.2548C53.5066 72.6489 54.5874 77.6622 55.2874 81.9164C56.0109 86.2705 56.4264 89.7457 55.8411 91.5431L55.6231 91.9025C54.6826 92.6212 52.6133 93.2598 49.5094 94.2177C46.382 95.0757 42.3652 96.0133 38.0363 96.9109C29.2096 98.8459 19.376 100.361 14.0912 100.2Z" fill="#3B56CA"/>
    <path d="M30.9866 88.1945C28.145 87.3091 21.3196 82.4016 14.9041 77.5144C8.39196 72.6472 2.65034 68.0417 2.65034 68.0417C2.36269 67.6797 2.24456 67.177 2.46338 66.8152C2.8024 66.5338 3.28524 66.4334 3.57289 66.7954C3.57289 66.7954 9.33814 71.5015 15.7301 76.2882C21.785 80.9341 28.5868 85.741 31.0185 86.6062C35.2993 84.1345 39.7467 77.1995 43.1061 70.8071C46.4419 64.3141 48.7134 58.4642 48.7134 58.4642C48.812 58.0219 49.2949 57.9215 49.7284 58.0423C50.0654 58.1831 50.2801 58.6657 50.1814 59.1079C50.0849 59.128 41.3831 82.8695 31.4931 88.1946C31.3965 88.2147 31.3965 88.2147 31.2999 88.2348C31.2034 88.2548 31.1068 88.2749 30.9866 88.1945Z" fill="#3B56CA"/>
    <path d="M13.3707 93.6761C12.9423 93.5572 12.7302 93.0816 12.7322 92.6656C13.4377 89.714 18.2997 79.7701 18.2763 79.6711C18.4925 79.3145 18.9463 79.1165 19.3026 79.3544C19.6588 79.5922 19.871 80.0677 19.6548 80.4242C19.6548 80.4242 18.4509 82.9597 17.1263 85.832C15.8017 88.7042 14.4517 91.8934 14.2314 93.082C14.2061 93.399 13.9432 93.5574 13.6569 93.6168C13.5848 93.7356 13.4894 93.7554 13.3707 93.6761Z" fill="#3B56CA"/>
    <path d="M51.6256 88.3807C50.4011 86.5628 40.7515 76.0306 40.7283 75.9318C40.4454 75.5761 40.4242 75.0625 40.7576 74.786C41.091 74.5095 41.5891 74.5096 41.872 74.8653C41.872 74.8653 51.4034 85.3185 52.9108 87.492C53.1937 87.8477 53.1199 88.3811 52.7633 88.5588C52.6683 88.5785 52.5966 88.697 52.5016 88.7167C52.1217 88.7957 51.7903 88.6573 51.6256 88.3807Z" fill="#3B56CA"/>
    <path d="M100.044 39.1627L79.1723 48.1991" stroke="#3B56CA" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M82.9468 17.4844L69.4586 35.7298" stroke="#3B56CA" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M57.3695 8.04118L55.0944 30.6671" stroke="#3B56CA" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M22.492 16.8358L35.5111 35.51" stroke="#3B56CA" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
  </svg>
)
