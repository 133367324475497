import React from "react";
import {SanityImageSource} from "@sanity/image-url/lib/types/types";
import {ResponsiveImage} from "../ResponsiveImage";
import {RequirementsProps} from "../../tools/context";

interface CustomImageProps {
  requirements: RequirementsProps;
  image: SanityImageSource;
  alt?: string;
  credit?: string;
  className?: string;
}

export default function CustomImage({
  requirements,
  image,
  alt = "Image",
  credit,
  className = "",
}: CustomImageProps) {
  return (
    <div className={`CustomImage ${className}`}>
      <ResponsiveImage
        src={image}
        alt={alt}
        requirements={requirements}
      />
      {credit
        ? <div className="credit">
            <span>{credit}</span>
          </div>
        : null
      }
    </div>
  );
};
