import React, {useState} from 'react';
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import {useTranslation} from "../../tools/i18n";
import {TravelPlannerTimePerDayType} from "./types";

interface TimesProps extends ChatBotComponentProps {
  step: TravelPlannerTimePerDayType;
  siteConfiguration: any;
}

export const Times = (props: TimesProps) => {
  const {t} = useTranslation();
  const {
    config,
    step,
    callback,
    initialData,
    back,
    requirements
  } = props;
  const [data, setData] = useState<string>(initialData || step.options[0])

  const subtitleKey = `travelPlaner.Times.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  return (
    <div className="times">
      <FilterLayout
        config={config}
        callback={() => callback(data)}
        title={t(`travelPlaner.Times.title`)}
        back={back}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
      >
        {hasSubtitle
          ? <div className="layoutSubtitle">{t(subtitleKey)}</div>
          : null
        }
        <div className="buttonList buttonListTimes">
          {step.options.map(o => (
            <button
              key={o}
              onClick={() => setData(o)}
              className={`${o === data ? 'selected' : ''}`}
            >
              <span>{t(`travelPlaner.Times.${o}`)}</span>
            </button>
          ))}
        </div>
      </FilterLayout>
    </div>
  )
}