import React, {useState} from 'react';
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import {useTranslation} from "../../tools/i18n";
import {Picto} from "../Picto";
import {TravelPlannerNewInterestsType} from "./types";
import {imageUrlFor} from "../../tools/sanity";

export interface NewInterestsProps extends ChatBotComponentProps {
  step: TravelPlannerNewInterestsType;
}

export enum InterestLevel {
  dislike = 1,
  neutral = 2,
  like = 3
}

export const NewInterests = (props: NewInterestsProps) => {
  const {t} = useTranslation();
  const {
    config,
    step,
    callback,
    initialData,
    back,
    requirements
  } = props;
  const points = step.list.map(p => p.key);
  const legend: any = t('travelPlaner.Interests.legend');

  const [data, setData] = useState(() => {
    if (initialData)
      return initialData;

    const output = {};
    points.forEach(p => (output[p] = InterestLevel.neutral));
    return output;
  });

  const handleChange = (value: InterestLevel, point: string) => {
    const d = {...data};
    d[point] = value;
    setData(d);
  }

  const toggleChange = (point: string) => {
    const d = {...data};
    d[point] = d[point] === InterestLevel.neutral
      ? InterestLevel.like
      : InterestLevel.neutral;
    setData(d);
  }

  const subtitleKey = `travelPlaner.Interests.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  interface RenderInterestButtonProps {
    pointKey: string;
    label: string;
    level: InterestLevel;
  }

  const RenderInterestButton = ({pointKey, label, level}: RenderInterestButtonProps) => (
    <button
      className={`${data[pointKey] === level ? 'selected' : ''}`}
      onClick={() => handleChange(level, pointKey)}
    >
      <Picto iconKey={level === InterestLevel.like ? "like-circle" : "dislike-circle"} />
      {label}
    </button>
  )

  return (
    <div className="composition">
      <FilterLayout
        config={config}
        callback={() => callback(data)}
        title={t(`travelPlaner.Interests.title`)}
        back={back}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
      >
        {hasSubtitle
          ? <div className="layoutSubtitle">{t(subtitleKey)}</div>
          : null
        }
        <div className={`interestPoints ${step.hideLikeDislike ? 'noLike' : ''}`}>
          {step.list.map(p =>
            <div
              className="point"
              key={encodeURIComponent(p.key)}
              onClick={step.hideLikeDislike
                ? () => toggleChange(p.key)
                : undefined
              }
            >
              <span className="label">
                {t(`travelPlaner.Interests.keys.${p.key}`)}
              </span>
              <div className="image-wrapper">
                <img
                  src={imageUrlFor(p.image, requirements).url()}
                  alt={t(`travelPlaner.Interests.keys.${p.key}`)}
                />
                {step.hideLikeDislike && data[p.key] === InterestLevel.like
                  ? <img className="like" src={imageUrlFor(step.likeImage, requirements).url()} alt="like" />
                  : null
                }
              </div>
              {step.hideLikeDislike
                ? null
                : <div className="buttons">
                    <RenderInterestButton key={p.key} pointKey={p.key} label={legend[0]} level={InterestLevel.dislike} />
                    <RenderInterestButton key={p.key} pointKey={p.key} label={legend[1]} level={InterestLevel.like} />
                  </div>
                }
            </div>
          )}
        </div>
      </FilterLayout>
    </div>
  )
}