import React from "react";
import {PictoProps} from "./index";

export const TravelExpensive = (props: PictoProps) => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.6665 14.6667H27.9998" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.33325 14.6667H6.66659" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.33433 28H14.6653C15.1003 28 15.5079 27.7879 15.7574 27.4316C16.007 27.0753 16.067 26.6198 15.9184 26.211L13.979 20.8777C13.7874 20.3508 13.2866 20 12.726 20H7.27371C6.71304 20 6.21226 20.3508 6.02066 20.8777L4.08126 26.211C3.93262 26.6198 3.9927 27.0753 4.24223 27.4316C4.49176 27.7879 4.89937 28 5.33433 28Z" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.6676 28H27.9986C28.4335 28 28.8412 27.7879 29.0907 27.4316C29.3402 27.0753 29.4003 26.6198 29.2517 26.211L27.3123 20.8777C27.1207 20.3508 26.6199 20 26.0592 20H20.607C20.0463 20 19.5455 20.3508 19.3539 20.8777L17.4145 26.211C17.2659 26.6198 17.326 27.0753 17.5755 27.4316C17.825 27.7879 18.2326 28 18.6676 28Z" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0011 20H21.3321C21.767 20 22.1746 19.7879 22.4242 19.4316C22.6737 19.0753 22.7338 18.6198 22.5852 18.211L20.6458 12.8777C20.4541 12.3508 19.9534 12 19.3927 12H13.9405C13.3798 12 12.879 12.3508 12.6874 12.8777L10.748 18.211C10.5994 18.6198 10.6594 19.0753 10.909 19.4316C11.1585 19.7879 11.5661 20 12.0011 20Z" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.9998 28.0002H13.9998" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.7375 7.59559L24.6803 6.65278" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.59535 7.59559L8.65254 6.65278" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.6664 4.66683V3.3335" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
