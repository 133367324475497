import React from "react";
import { PictoProps } from "./index";

export const Euro = (props: PictoProps) => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.1813 19.7089C16.2682 20.3753 15.8039 20.9983 15.1319 21C14.2672 21.0021 13.4045 20.8694 12.5716 20.6041C11.3512 20.2152 10.2232 19.5507 9.26396 18.6553C8.30475 17.76 7.53675 16.6549 7.01196 15.4147C6.48717 14.1746 6.21784 12.8284 6.22222 11.4673C6.2266 10.1063 6.50458 8.76209 7.03734 7.52583C7.57009 6.28957 8.34519 5.1901 9.31014 4.30185C10.2751 3.41361 11.4074 2.75734 12.6303 2.37748C13.4653 2.11811 14.3292 1.99175 15.1944 2.00042C15.8658 2.00714 16.3254 2.63281 16.2343 3.29806V3.29806C16.1407 3.9815 15.5007 4.44216 14.8117 4.4757C14.2926 4.50097 13.7767 4.59195 13.2749 4.74782C12.3697 5.02898 11.5316 5.51473 10.8174 6.17217C10.1032 6.82962 9.52949 7.64342 9.13516 8.55845C8.74083 9.47349 8.53508 10.4684 8.53184 11.4758C8.5286 12.4832 8.72794 13.4796 9.11638 14.3976C9.50481 15.3155 10.0733 16.1335 10.7832 16.7961C11.4932 17.4588 12.3281 17.9507 13.2315 18.2385C13.7325 18.3982 14.2482 18.493 14.7674 18.522C15.4558 18.5605 16.0922 19.0252 16.1813 19.7089V19.7089Z" fill="black" />
    <rect x="4.59375" y="8.53125" width="8.8125" height="1.1875" rx="0.59375" stroke="black" strokeWidth="1.1875"/>
    <rect x="4.59375" y="13.2812" width="8.8125" height="1.1875" rx="0.59375" stroke="black" strokeWidth="1.1875"/>
  </svg>
);

export default Euro;
