import React from "react";
import {PictoProps} from "./index";

export const TravelEco = (props: PictoProps) => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9666 5.36684C20.7891 7.18929 20.7891 10.1441 18.9666 11.9665C17.1442 13.789 14.1894 13.789 12.367 11.9665C10.5445 10.1441 10.5445 7.18929 12.367 5.36684C14.1894 3.54439 17.1442 3.54439 18.9666 5.36684" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.0434 15.9568C23.0674 15.9807 23.0674 16.0196 23.0434 16.0435C23.0195 16.0675 22.9806 16.0675 22.9567 16.0435C22.9327 16.0196 22.9327 15.9807 22.9567 15.9568C22.9806 15.9328 23.0195 15.9328 23.0434 15.9568" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.3067 9.14533C20.7414 9.03333 21.1587 8.86933 21.5361 8.62667C22.1507 8.23067 22.8801 8 23.6667 8H25.0001V11.4053C25.9574 12.2693 26.7174 13.3467 27.1681 14.58H28.3334C29.0694 14.58 29.6667 15.1773 29.6667 15.9133V20C29.6667 20.736 29.0694 21.3333 28.3334 21.3333H26.5854C25.8854 22.544 24.8747 23.552 23.6667 24.2547V26.6667C23.6667 27.4027 23.0694 28 22.3334 28H19.6667C18.9307 28 18.3334 27.4027 18.3334 26.6667V25.3333H13.6667V26.4907C13.6667 27.2267 13.0694 27.824 12.3334 27.824H9.66675C8.93075 27.824 8.33341 27.2267 8.33341 26.4907V23.284C6.70008 21.82 5.66675 19.7 5.66675 17.3333C5.66675 13.8027 7.95741 10.812 11.1307 9.75067" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.38404 13.3335C2.75871 13.6975 2.33337 14.3682 2.33337 15.1442C2.33337 16.3055 3.27471 17.2468 4.43604 17.2468H5.66671" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.6667 13.3332H19.6667" stroke="#071621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
