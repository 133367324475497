import React from "react";
import {PictoProps} from "./index";

export const List = (props: PictoProps) => (
  <svg width={props.width || "14"} height={props.height || "14"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.333618" width="5.18518" height="5.18518" fill="black"/>
    <rect x="8.48206" width="5.18518" height="5.18518" fill="black"/>
    <rect x="8.48206" y="8.14807" width="5.18518" height="5.18518" fill="black"/>
    <rect x="0.333618" y="8.14807" width="5.18518" height="5.18518" fill="black"/>
  </svg>
)
