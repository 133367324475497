import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface ToggleItem {
  id: string;
  children: ReactNode | string | number;
}

interface ToggleProps {
  items: ToggleItem[];
  selectedId: string;
  onSelect: (id: string) => void;
}

export default function Toggle({ items, selectedId, onSelect }: ToggleProps) {
  const [widths, setWidths] = useState<number[]>([]);
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const updateWidths = () => {
    setWidths(itemRefs.current.map((el) => el?.offsetWidth || 0));
  };

  useEffect(() => {
    updateWidths();
  }, [items]);

  useEffect(() => {
    window.addEventListener("resize", updateWidths);
    return () => window.removeEventListener("resize", updateWidths);
  }, []);

  const translateX = useCallback(
    (selectedIndex: number): number => {
      return widths
        .slice(0, selectedIndex)
        .reduce((acc, width) => acc + width, 0);
    },
    [widths]
  );

  if (!items.length) return null;

  const currentIndex = items.findIndex((item) => item.id === selectedId);

  return (
    <div className="Toggle">
      <div
        className="toggle-inner"
        nonce="__nonce__"
        style={{
          translate: `${translateX(currentIndex)}px`,
          width: widths[currentIndex] + 1,
        }}
      />
      {items.map((item, index) => (
        <button
          key={item.id}
          ref={(el) => (itemRefs.current[index] = el)}
          className={`toggle-item ${item.id === selectedId ? "selected" : ""}`}
          onClick={() => onSelect(item.id)}
        >
          {item.children}
        </button>
      ))}
    </div>
  );
}
