import React from "react";
import {PictoProps} from "./index";

export const TravelFriends = (props: PictoProps) => (
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="4.5" r="3.75" stroke="#071621" strokeWidth="1.5"/>
    <circle cx="19" cy="4.5" r="3.75" stroke="#071621" strokeWidth="1.5"/>
    <path d="M14.5 21C13.9477 21 13.5 20.5523 13.5 20V15C13.5 11.6863 16.1863 9 19.5 9" stroke="#071621" strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M5 10.6379C6.21677 9.61568 7.78648 9 9.5 9H18.5C20.2135 9 21.7832 9.61568 23 10.6379M2.73095 14.21C2.58026 14.7814 2.5 15.3813 2.5 16V20C2.5 20.5523 2.94772 21 3.5 21H24.5C25.0523 21 25.5 20.5523 25.5 20V16C25.5 15.305 25.3987 14.6337 25.2101 14" stroke="#071621" strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M1.5 10H3.5C4.60457 10 5.5 10.8954 5.5 12V12C5.5 13.1046 4.60457 14 3.5 14H1.5V10Z" stroke="#071621" strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M26.5 10H24.5C23.3954 10 22.5 10.8954 22.5 12V12C22.5 13.1046 23.3954 14 24.5 14H26.5V10Z" stroke="#071621" strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M6 16L6 21" stroke="#071621" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M21.5 16L21.5 21" stroke="#071621" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>

)
