import React, {useState} from 'react';
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import {Number} from "./inputs/Number";
import {useTranslation} from "../../tools/i18n";
import {TravelPlannerTripLengthType} from "./types";

interface DayNumberSoloProps {
  number: number;
  setNumber: (newNumber: number) => void;
  maxLength?: number;
}

export const DayNumberSolo = ({number, setNumber, maxLength}: DayNumberSoloProps) => {
  const {t} = useTranslation();

  return (  
    <Number
      data={number}
      setData={setNumber}
      label={t('travelPlaner.common.day', {count: number})}
      min={1}
      max={maxLength ?? 5}
      step={1}
    />
  )
}

export interface DayNumberProps extends ChatBotComponentProps {
  step: TravelPlannerTripLengthType;
}

export const DayNumber = (props: DayNumberProps) => {
  const {
    config,
    step,
    callback,
    initialData,
    stateData,
    back,
    requirements
  } = props;
  const [number, setNumber] = useState<number>(initialData || 1)
  const {t} = useTranslation();

  const maxLength = stateData?.selectedFavorite
    ? step.maxDaysFavorite ?? 7
    : step.maxDays ?? 5;

  return (
    <div>
      <FilterLayout
        config={config}
        callback={() => callback(number)}
        title={t(`travelPlaner.DayNumber.title`)}
        back={back}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
      >
        <DayNumberSolo number={number} setNumber={setNumber} maxLength={maxLength} />
      </FilterLayout>
    </div>
  )
}