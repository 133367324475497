import React, {useState} from 'react';
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import Slider from "rc-slider";
import {useTranslation} from "../../tools/i18n";

export const HourSliders = (props: ChatBotComponentProps) => {
  const {config, callback, stateData, initialData, back} = props;
  const {t} = useTranslation();
  const number = stateData.tripLength || 1;
  const [data, setData] = useState<number[][]>(() => {
    if (!initialData || !initialData.length)
      return new Array(number).fill([9, 21]);
    return initialData;
  });

  const numberToString = (input: number) => {
    if (config.newUI)
      return `${input}h`
    return `${input > 9 ? input : `0${input}`}:00`
  }

  const handleChange = (values: number[], index: number) => {
    const d = [...data];
    d[index] = values;
    setData(d);
  }

  const subtitleKey = `travelPlaner.HoursSliders.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  return (
    <div className="hoursSliders">
      <FilterLayout
        config={config}
        callback={() => callback(data)}
        title={t(`travelPlaner.HoursSliders.title`)}
        back={back}
        requirements={props.requirements}
        siteConfiguration={props.siteConfiguration}
      >
        {hasSubtitle
          ? <div className="layoutSubtitle">{t(subtitleKey)}</div>
          : null
        }
        {new Array(number).fill(' ').map((_p, i) => (
          <div className="slide" key={`hour_${i}`}>
            <div className="day">
              {t(`travelPlaner.HoursSliders.day`)} {i+1}
            </div>
            <div className="hours">
              {data[i] ? `${numberToString(data[i][0])} - ${numberToString(data[i][1])}` : '09:00 - 21:00'}
            </div>
            <Slider
              range
              pushable
              value={data[i]}
              defaultValue={[9, 18]}
              onChange={(p) => handleChange(p as number[], i)}
              min={6}
              max={24}
              step={1}
              handleRender={(p) => (
                <div {...p.props} className="sur-handle" >
                  <div className="handle"/>
                </div>
              )}
            />
          </div>
        ))}
      </FilterLayout>
    </div>
  )
}