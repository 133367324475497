import React, {useState} from "react";
import {Picto} from "../../../../Picto";
import {useTranslation} from "../../../../../tools/i18n";
import {RequirementsProps} from "../../../../../tools/context";

type TripLengthOptionType = {
  value: number;
  label: string;
}

interface TripLengthSelectorProps {
  requirements: RequirementsProps;
  onSelect: (tripLength: number) => void;
  maxDays: number;
}

export default function TripLengthSelector({
  requirements,
  onSelect,
  maxDays = 5
}: TripLengthSelectorProps) {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const options: TripLengthOptionType[] = Array
    .from({length: maxDays}, (_, i) => ({
      value: i + 1,
      label: t('travelPlaner.Hub.ComplexSearch.TripLengthSelector.day', {count: i + 1})
    }));
  options.unshift({
    value: 0,
    label: t('travelPlaner.Hub.ComplexSearch.TripLengthSelector.any')
  });

  const calendarIcons = {
    france: "france-calendar"
  }
  const calendarIcon = calendarIcons[requirements?.config?.key] || "calendar";

  const handleOpen = () => {
    setIsOpen(p => !p);
  }

  const handleSelect = async (tripLength: number) => {
    onSelect(tripLength);
    setValue(tripLength);
    setIsOpen(false);
  }

  return (
    <div className={`TripLengthSelector ${isOpen ? 'open' : ''}`}>
      <button className="selectBlock" onClick={handleOpen}>
        <Picto iconKey={calendarIcon} />
        <span>{options.find(o => o.value === value)?.label}</span>
        <Picto iconKey="chevron-down" />
      </button>
      <div className="options">
        {options.map((o, i) => (
          <button
            key={`option_${i}`}
            onClick={() => handleSelect(o.value)}
            className="option"
          >
            {o.label}
          </button>
        ))}
      </div>
    </div>
  );
};

