import {Picto} from "../Picto";
import * as React from "react";
import {RequirementsProps} from "../../tools/context";
import {useTranslation} from "../../tools/i18n";

export interface SponsoProps {
  coupon?: string
  sponso?: boolean
  giftCardText?: any
  data: any
  device?: string
  seller: any,
  requirements: RequirementsProps,
  disableGiftCardHighlight?: boolean
}

const Sponso = (
  {
    coupon,
    sponso,
    giftCardText,
    data,
    device,
    seller,
    requirements,
    disableGiftCardHighlight,
  }: SponsoProps) => {
    const {t} = useTranslation();

    if (data.seller_type && data.seller_type !== 'store') return null;
    if (!device && disableGiftCardHighlight) return null;

    const using = coupon || sponso;

    const content = (<div className="giftCardBlock">
      <div className="title">
        {using
          ? <span>{giftCardText?.titleCoupon || t('shop.advantages.giftCard.titleCoupon')}</span>
          : <span>{giftCardText?.title || t('shop.advantages.giftCard.title')}</span>}
        <Picto iconKey={"giftCard"} text={giftCardText?.logo}/>
      </div>
      {(!coupon) && <div className="description">
        <span>{giftCardText?.subTitle || t('shop.advantages.giftCard.subtitle')}</span>
        <br/> {giftCardText?.label || t('shop.advantages.giftCard.label')}
      </div>}
      <a className={"Button white"}
         href={coupon
           ? `${requirements.config.clientUrl}portefeuille?coupon=${coupon}&shortcode=${seller.shortcode}`
           : `${requirements.config.clientUrl}connexion-client`
         }>{giftCardText?.button || t('shop.advantages.giftCard.button')}</a>
    </div>);

    if (!device) return content;

    return <div className={`advantages ${device}`}>
      {content}
    </div>
}

export default Sponso;