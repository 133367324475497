const categories = {
  "ou-sejourner": "Où séjourner ?",
  "monuments-et-musees": "Monuments et musées",
  "a-table": "À table !",
  "producteurs-et-artisans": "Producteurs et artisans",
  voyages: "Voyages",
  loisirs: "Loisirs",
  "detours-en-france": "Détours en france",
};

const DROM = ["gp", "mq", "gf", "re", "pm", "yt", "nc", "pf", "mf", "tf"];

const predictionExceptions = [
  { label: "Ain", id: "01", types: ["department"], exception: true },
  { label: "Aisne", id: "02", types: ["department"], exception: true },
  { label: "Allier", id: "03", types: ["department"], exception: true },
  {
    label: "Alpes-de-Haute-Provence",
    id: "04",
    types: ["department"],
    exception: true,
  },
  { label: "Hautes-Alpes", id: "05", types: ["department"], exception: true },
  {
    label: "Alpes-Maritimes",
    id: "06",
    types: ["department"],
    exception: true,
  },
  { label: "Ardèche", id: "07", types: ["department"], exception: true },
  { label: "Ardennes", id: "08", types: ["department"], exception: true },
  { label: "Ariège", id: "09", types: ["department"], exception: true },
  { label: "Aube", id: "10", types: ["department"], exception: true },
  { label: "Aude", id: "11", types: ["department"], exception: true },
  { label: "Aveyron", id: "12", types: ["department"], exception: true },
  {
    label: "Bouches-du-Rhône",
    id: "13",
    types: ["department"],
    exception: true,
  },
  { label: "Calvados", id: "14", types: ["department"], exception: true },
  { label: "Cantal", id: "15", types: ["department"], exception: true },
  { label: "Charente", id: "16", types: ["department"], exception: true },
  {
    label: "Charente-Maritime",
    id: "17",
    types: ["department"],
    exception: true,
  },
  { label: "Cher", id: "18", types: ["department"], exception: true },
  { label: "Corrèze", id: "19", types: ["department"], exception: true },
  { label: "Corse-du-Sud", id: "2A", types: ["department"], exception: true },
  { label: "Haute-Corse", id: "2B", types: ["department"], exception: true },
  { label: "Côte-d'Or", id: "21", types: ["department"], exception: true },
  { label: "Côtes d'Armor", id: "22", types: ["department"], exception: true },
  { label: "Creuse", id: "23", types: ["department"], exception: true },
  { label: "Dordogne", id: "24", types: ["department"], exception: true },
  { label: "Doubs", id: "25", types: ["department"], exception: true },
  { label: "Drôme", id: "26", types: ["department"], exception: true },
  { label: "Eure", id: "27", types: ["department"], exception: true },
  { label: "Eure-et-Loir", id: "28", types: ["department"], exception: true },
  { label: "Finistère", id: "29", types: ["department"], exception: true },
  { label: "Gard", id: "30", types: ["department"], exception: true },
  { label: "Haute-Garonne", id: "31", types: ["department"], exception: true },
  { label: "Gers", id: "32", types: ["department"], exception: true },
  { label: "Gironde", id: "33", types: ["department"], exception: true },
  { label: "Hérault", id: "34", types: ["department"], exception: true },
  {
    label: "Ille-et-Vilaine",
    id: "35",
    types: ["department"],
    exception: true,
  },
  { label: "Indre", id: "36", types: ["department"], exception: true },
  { label: "Indre-et-Loire", id: "37", types: ["department"], exception: true },
  { label: "Isère", id: "38", types: ["department"], exception: true },
  { label: "Jura", id: "39", types: ["department"], exception: true },
  { label: "Landes", id: "40", types: ["department"], exception: true },
  { label: "Loir-et-Cher", id: "41", types: ["department"], exception: true },
  { label: "Loire", id: "42", types: ["department"], exception: true },
  { label: "Haute-Loire", id: "43", types: ["department"], exception: true },
  {
    label: "Loire-Atlantique",
    id: "44",
    types: ["department"],
    exception: true,
  },
  { label: "Loiret", id: "45", types: ["department"], exception: true },
  { label: "Lot", id: "46", types: ["department"], exception: true },
  { label: "Lot-et-Garonne", id: "47", types: ["department"], exception: true },
  { label: "Lozère", id: "48", types: ["department"], exception: true },
  { label: "Maine-et-Loire", id: "49", types: ["department"], exception: true },
  { label: "Manche", id: "50", types: ["department"], exception: true },
  { label: "Marne", id: "51", types: ["department"], exception: true },
  { label: "Haute-Marne", id: "52", types: ["department"], exception: true },
  { label: "Mayenne", id: "53", types: ["department"], exception: true },
  {
    label: "Meurthe-et-Moselle",
    id: "54",
    types: ["department"],
    exception: true,
  },
  { label: "Meuse", id: "55", types: ["department"], exception: true },
  { label: "Morbihan", id: "56", types: ["department"], exception: true },
  { label: "Moselle", id: "57", types: ["department"], exception: true },
  { label: "Nièvre", id: "58", types: ["department"], exception: true },
  { label: "Nord", id: "59", types: ["department"], exception: true },
  { label: "Oise", id: "60", types: ["department"], exception: true },
  { label: "Orne", id: "61", types: ["department"], exception: true },
  { label: "Pas-de-Calais", id: "62", types: ["department"], exception: true },
  { label: "Puy-de-Dôme", id: "63", types: ["department"], exception: true },
  {
    label: "Pyrénées-Atlantiques",
    id: "64",
    types: ["department"],
    exception: true,
  },
  {
    label: "Hautes-Pyrénées",
    id: "65",
    types: ["department"],
    exception: true,
  },
  {
    label: "Pyrénées-Orientales",
    id: "66",
    types: ["department"],
    exception: true,
  },
  { label: "Bas-Rhin", id: "67", types: ["department"], exception: true },
  { label: "Haut-Rhin", id: "68", types: ["department"], exception: true },
  { label: "Rhône", id: "69", types: ["department"], exception: true },
  { label: "Haute-Saône", id: "70", types: ["department"], exception: true },
  { label: "Saône-et-Loire", id: "71", types: ["department"], exception: true },
  { label: "Sarthe", id: "72", types: ["department"], exception: true },
  { label: "Savoie", id: "73", types: ["department"], exception: true },
  { label: "Haute-Savoie", id: "74", types: ["department"], exception: true },
  { label: "Paris", id: "75", types: ["department"], exception: true },
  { label: "Seine-Maritime", id: "76", types: ["department"], exception: true },
  { label: "Seine-et-Marne", id: "77", types: ["department"], exception: true },
  { label: "Yvelines", id: "78", types: ["department"], exception: true },
  { label: "Deux-Sèvres", id: "79", types: ["department"], exception: true },
  { label: "Somme", id: "80", types: ["department"], exception: true },
  { label: "Tarn", id: "81", types: ["department"], exception: true },
  {
    label: "Tarn-et-Garonne",
    id: "82",
    types: ["department"],
    exception: true,
  },
  { label: "Var", id: "83", types: ["department"], exception: true },
  { label: "Vaucluse", id: "84", types: ["department"], exception: true },
  { label: "Vendée", id: "85", types: ["department"], exception: true },
  { label: "Vienne", id: "86", types: ["department"], exception: true },
  { label: "Haute-Vienne", id: "87", types: ["department"], exception: true },
  { label: "Vosges", id: "88", types: ["department"], exception: true },
  { label: "Yonne", id: "89", types: ["department"], exception: true },
  {
    label: "Territoire de Belfort",
    id: "90",
    types: ["department"],
    exception: true,
  },
  { label: "Essonne", id: "91", types: ["department"], exception: true },
  { label: "Hauts-de-Seine", id: "92", types: ["department"], exception: true },
  { label: "Seine-St-Denis", id: "93", types: ["department"], exception: true },
  { label: "Val-de-Marne", id: "94", types: ["department"], exception: true },
  { label: "Val-D'Oise", id: "95", types: ["department"], exception: true },
  { label: "Mayotte", id: "976", types: ["department"], exception: true },
  // {label: 'Guadeloupe', id: '971', types: ['department']},
  // {label: 'Martinique', id: '972', types: ['department']},
  // {label: 'Guyane', id: '973', types: ['department']},
  // {label: 'La Réunion', id: '974', types: ['department']},
];

const Configuration: any = {
  categories: {},
  labels: {},
  CRList: [],
};

const Endpoints = {
  prod: "https://prod.cibler.io",
  qa: "https://qa.cibler.io",
  // qa: "http://localhost:8080",
  local: "http://localhost:8080",
};

class ConfigurationUtils {
  static setContext = (obj) => {
    if (!Object.keys(obj)) return;
    const keyList = Object.keys(obj);

    keyList.forEach((k) => {
      Configuration[k] = obj[k];
    });

    return Configuration;
  };
}

const INTERNATIONAL_AGENT_ID = "cln35b5740008bgpj7qaue1tw";

export {
  categories,
  Configuration,
  ConfigurationUtils,
  DROM,
  predictionExceptions,
  Endpoints,
  INTERNATIONAL_AGENT_ID,
};
