import React, {FC, useEffect, useState} from "react";
import copy from "copy-to-clipboard";
import { useTranslation } from "../../../tools/i18n";
import { useTracking } from "../../../tools/analytics";
import { Picto } from "../../Picto";

interface ShareModalProps {
    open?: boolean;
    onClose?: () => void;
}

const ShareModal: FC<ShareModalProps> = ({ open, onClose }) => {
    const [copyFeedback, setCopyFeedback] = useState<boolean>(false);
    const {trackEvent} = useTracking();
    const {t} = useTranslation();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

    const shareUrl = () => `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    const mailBody = () => `mailto:?body=${
        encodeURIComponent(t('travelPlaner.Travel.mail.body', {link: shareUrl()}))}&subject=${
        encodeURIComponent(t('travelPlaner.Travel.mail.object'))
    }`;

    const handleCopyLink = () => {
        trackEvent('shareLink_click');
        copy(shareUrl());
        setCopyFeedback(true);
        setTimeout(() => setCopyFeedback(false), 1000);
    }

    return open
        ? <div className="sharePopup">
            <div className="sharePopupContainer" onBlur={onClose} tabIndex={0}>
              <div className="sharePopupHeader">
                <div className="container">
                  <Picto iconKey="share" />
                  <span>{t('travelPlaner.Travel.share')}</span>
                </div>
                <button onClick={onClose}>
                  <Picto iconKey="cross" />
                </button>
              </div>
              <div className="methods">
                <a
                  href={mailBody()}
                  onClick={() => trackEvent('shareEmail_click')}
                >
                  E-mail
                </a>
                <button onClick={handleCopyLink}>
                  {copyFeedback
                    ? t('travelPlaner.Travel.copiedLink')
                    : t('travelPlaner.Travel.copyLink')
                  }
                </button>
              </div>
            </div>
          </div>
        : null
}

export default ShareModal;
