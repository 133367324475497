import React from 'react';
import { imageUrlFor } from "../../tools/sanity";
import { RequirementsProps } from "../../tools/context";

export interface FlexContentProps {
  image?: {
    asset: {
      _ref: string;
    };
    hotspot?: {
      x: number;
      y: number;
    };
    alt?: string;
  };
  htmlContent?: string;
  direction?: 'row' | 'column';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  width?: string;
  height?: string;
  borderRadius?: number;
  gap?: number;
  backgroundColor?: {
    hex: string;
  };
  padding?: string;
  isFullWidth?: boolean;
  requirements: RequirementsProps;
}
const FlexContent: React.FC<FlexContentProps> = ({
  image,
  htmlContent,
  direction = 'row',
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  objectFit = 'fill',
  width,
  height,
  borderRadius = 0,
  gap = 0,
  backgroundColor,
  padding = '0',
  isFullWidth,
  requirements,
}) => {
  return (
    <div
      className={`FlexContent ${isFullWidth ? 'full-width' : 'normal-width'}`}
      style={{
        display: 'flex',
        flexDirection: direction,
        alignItems,
        justifyContent,
        width: width,
        height: height || 'auto',
        gap,
        backgroundColor: backgroundColor?.hex || 'transparent',
        padding,
      }}
    >
      {image && image.asset && (
        <img
          className="FlexContent__image"
          src={imageUrlFor(image, requirements).url()}
          alt={image.alt || ''}
          style={{
            objectFit,
            borderRadius: `${borderRadius}px`,
            maxWidth: '100%',
            height: direction === 'row' ? 'auto' : '75px',
          }}
        />
      )}
      {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
    </div>
  );
};

export default FlexContent;
