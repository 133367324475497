import * as React from "react";
import moment from "moment";
import StarNote from "../../StarNote";

export interface ReviewProps {
  review: any;
  hide?: boolean;
}

const Review = (props: ReviewProps) => {
  const { review } = props;

  let rating = parseFloat(review.shopGrade) || 1;
  let review_text = review.review || "";
  let name = review.name || "";
  const date = new Date(review.date || "");
  moment(date).format("DD/MM/YYYY");

  return (
    <div
      className={`flex-review`}
      nonce="__nonce__"
      style={props.hide ? { display: "none" } : {}}
    >
      <div className={`flex-review-header`}>
        <p>{name}</p>
        <span>{moment(date).format("DD/MM/YYYY")}</span>
      </div>
      <StarNote note={rating} />
      <div className="text">{review_text}</div>
    </div>
  );
};

export default Review;
