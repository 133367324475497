export default {
  key: 'siblu',
  canonicalUrl: 'www.passevasionsiblu.fr',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 674,
  icon: 'siblu.ico',
  headerLogo: 'siblu.png',
  footerLogo: 'siblu.png',
  axeptioClientId: "643d0d2a4ef258320870e857",
  axeptioCookiesVersion: "passevasionsiblu-fr",
  clientUrl: "https://client.passevasionsiblu.fr/",
  proUrl: "https://pro.passevasionsiblu.fr/",
  sanity: {
    base: {
      projectId: 'siovyd6x',
      dataset: 'production',
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token: 'skulZbvCB9pBM3yvxWo3Q1emyK8wk34Vltv6urtUzXVekKT865T3LslhEO2dduyAKQ48py8aVwhYSKYEUMZ25a2o7Qbh8DMGroIoXKcAiBDG68dux7k8C9teuA4FsIkIwQPqlfiRxwVA80jA8dY72ljAFiPRUfTtbA9pmXvyh5brlgfPp6L0',
    }
  },
  dev: {
    clientUrl: "http://qa.passevasionsiblu.fr/",
    proUrl: "http://qa.passevasionsiblu.fr/",
  }
}