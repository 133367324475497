import React, {useState} from "react";
import AutoCompleteCity, {CityTypeAutoComplete} from "./AutoCompleteCity";
import TripLengthSelector from "./TripLengthSelector";
import {useTranslation} from "../../../../tools/i18n";
import {RequirementsProps} from "../../../../tools/context";
import {Oval} from "react-loader-spinner";
import {Picto} from "../../../Picto";
import {TravelPlannerSanityProps} from "../../index";

export type SearchDataType = {
  city: CityTypeAutoComplete;
  tripLength: number;
};

export interface TravelPlanerHubComplexSearchProps {
  travelPlanner: TravelPlannerSanityProps;
  requirements: RequirementsProps;
  loading: boolean;
  onSearch: (searchData: SearchDataType) => void;
}

export default function TravelPlanerHubComplexSearch({
  travelPlanner,
  requirements,
  loading,
  onSearch
}: TravelPlanerHubComplexSearchProps) {
  const {t} = useTranslation();
  const [searchData, setSearchData] = useState<SearchDataType>({
    city: null,
    tripLength: 0,
  });

  const handleSelectCity = (city: CityTypeAutoComplete) => {
    setSearchData({...searchData, city: city});
  };

  const handleSelectTripLength = (tripLength: number) => {
    setSearchData({...searchData, tripLength: tripLength});
  }

  return (
    <div className="ComplexSearch">
      <AutoCompleteCity requirements={requirements} onSelect={handleSelectCity}/>
      <TripLengthSelector requirements={requirements} onSelect={handleSelectTripLength} maxDays={5}/>
      <button className="searchButton" onClick={() => onSearch(searchData)}>
        {loading
          ? <Oval color="#FFF" height={20} width={20}/>
          : t('travelPlaner.Hub.ComplexSearch.searchButton')
        }
      </button>
      <a className="goToGuide" href={travelPlanner.path?.current}>
        <Picto iconKey={"chat-icon"} />
        {t('travelPlaner.Hub.ComplexSearch.goToGuide')}
      </a>
    </div>
  );
};
