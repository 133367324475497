import React, { useEffect, useRef, useState } from "react";
import fuzzysort from "fuzzysort";
import OfferSlide, { getDiscountTags } from "../Carousel/OfferSlide";
import { SearchBar } from "./SearchBar";
import { replaceUrlParam } from "../../tools/tools";
import { RequirementsProps } from "../../tools/context";
import Map from "../GridComponents/Map";
import Pagination from "../GridComponents/Pagination";
import { Configuration } from "../../tools/Constants";
import { Picto } from "../Picto";
import { getAllVendors } from "../../tools/ciblerAPI";
import StaticCarousel from "../Carousel/StaticCarousel";
import {
  CategoryPopupData,
  FiltersConfigurationType,
} from "./SearchBar/categoryPopup";

type Sorts = "locationAsc" | "locationDesc" | "advantageDesc";

export interface LightSearchGridProps {
  componentStates: any;
  requirements: RequirementsProps;
  carousels: any[];
  filters?: {
    key?: string;
    value?: string;
    exclusion?: boolean;
    distance: number;
    location?: { lat: number; lng: number };
  }[];
  filtersConfiguration: FiltersConfigurationType;
  sort: Sorts;
}

const Renderer = (props: LightSearchGridProps) => {
  const norm = (it) => it.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const roundGrade = (input) =>
    isNaN(input) ? 0 : Math.round(input * 10) / 10;
  const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;
  const distanceInM = (lat1, lon1, lat2, lon2) => {
    const earthRadiusM = 6371000;
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const rlat1 = degreesToRadians(lat1);
    const rlat2 = degreesToRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(rlat1) *
        Math.cos(rlat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusM * c;
  };

  const preFilter = (c) => {
    if (c.testAccount) return false;
    if (!c.categslug) return false;

    if (!props.filters || !props.filters.length) return true;
    const inclusions = props.filters.filter((c) => !c.exclusion && c.key);
    const exclusions = props.filters.filter((c) => c.exclusion && c.key);

    if (
      exclusions.some((f) => (f.value || "").split(";").includes(`${c[f.key]}`))
    )
      return false;

    if (
      inclusions.some(
        (f) => !(f.value || "").split(";").includes(`${c[f.key]}`)
      )
    )
      return false;

    const perimeter = props.filters.find((f) => f.distance);
    if (!perimeter) {
      return true;
    }
    const distance = distanceInM(
      c.latitude,
      c.longitude,
      perimeter.location.lat,
      perimeter.location.lng
    );

    return distance <= perimeter.distance;
  };

  const defaultScores = (t) => {
    let s = 0;
    if (t.urlimg) s += 1;
    if (t.gifts) s += 2;
    return s;
  };

  const preSort = (t1, t2) => {
    const perimeter = props?.filters?.find((p) => p.distance);
    if (!perimeter) {
      return defaultScores(t2) - defaultScores(t1);
    }

    switch (props.sort) {
      case "locationAsc":
        return (
          distanceInM(
            t1.latitude,
            t1.longitude,
            perimeter.location.lat,
            perimeter.location.lng
          ) -
          distanceInM(
            t2.latitude,
            t2.longitude,
            perimeter.location.lat,
            perimeter.location.lng
          )
        );
      case "locationDesc":
        return (
          distanceInM(
            t2.latitude,
            t2.longitude,
            perimeter.location.lat,
            perimeter.location.lng
          ) -
          distanceInM(
            t1.latitude,
            t1.longitude,
            perimeter.location.lat,
            perimeter.location.lng
          )
        );
      default:
        return defaultScores(t2) - defaultScores(t1);
    }
  };

  const componentStates = useRef<any[]>(
    props.componentStates
      ? props.componentStates
          .filter(preFilter)
          .sort(preSort)
          .map((d) => ({
            ...d,
            norm: norm(`${d.storeName || ""}_${d.city}_${d.categStr}`),
            categslug: d.categslug,
            store_name: d.storeName,
            seller_id: d.id,
            gifts: d.gifts ? JSON.parse(d.gifts) : null,
            note: roundGrade(d.shopGrade),
            _geoloc:
              d.latitude &&
              !isNaN(d.latitude) &&
              d.longitude &&
              !isNaN(d.longitude)
                ? { lat: -(-d.latitude), lng: -(-d.longitude) }
                : null,
          }))
      : []
  );
  const [filters, setFilters] = useState<CategoryPopupData>({
    categ: props.requirements.categ || [],
    labels: props.requirements.labels || [],
    filters: props.requirements.filters || [],
  });
  const [showMap, setShowMap] = useState<boolean>(
    props.requirements.mapOpened === undefined
      ? true
      : props.requirements.mapOpened
  );
  const [state, setState] = useState<any[]>(componentStates.current);
  const [page, setPage] = useState<number>(props.requirements.page || 0);
  const [input, setInput] = useState<string>(null);
  const pageSize = 50;

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      replaceUrlParam("mapOpened", showMap, window.location.href)
    );
  }, [showMap]);

  const renderFilter = (c, checker, list, handler) => (
    <button
      key={c}
      className={`filter ${c}`}
      onClick={() => handler(c, checker)}
    >
      {list[c].name}
      <Picto iconKey={"cross"} />
    </button>
  );

  const renderCurrentFilters = () => {
    const categories = Configuration.categories;
    const labelFilters = Configuration.labels;
    const otherFilters = {
      bonus: { name: "Avec avantage" },
    };
    const handler = (c, checker) => {
      const f = { ...filters };
      const idx = f[checker].findIndex((k) => k === c);
      f[checker].splice(idx, 1);

      let url = window.location.href;
      url = replaceUrlParam("page", null, url);
      url = replaceUrlParam(checker, filters[checker].join("|"), url);
      window.history.replaceState({}, "", url);

      setFilters(f);
    };

    const list = [];

    if (filters.categ.length)
      list.push(
        ...filters.categ.map((c) =>
          renderFilter(c, "categ", categories, handler)
        )
      );
    if (filters.labels.length)
      list.push(
        ...filters.labels.map((c) =>
          renderFilter(c, "labels", labelFilters, handler)
        )
      );
    if (filters.filters.length)
      list.push(
        ...filters.filters.map((c) =>
          renderFilter(c, "filters", otherFilters, handler)
        )
      );

    return list;
  };

  const handleChangePage = async (p) => {
    const requirements: any = { ...props.requirements };
    requirements.page = p;
    setPage(p);
  };

  useEffect(() => {
    setPage(0);
    const newUrl = replaceUrlParam("page", null, window.location.href);
    window.history.pushState({}, "", newUrl);

    let targets = componentStates.current;

    if (filters.categ && filters.categ.length) {
      targets = targets.filter((t) => filters.categ.includes(t.categslug));
    }
    if (filters.labels && filters.labels.length) {
      targets = targets.filter((t) => filters.labels.includes(t.label));
    }
    if (filters.filters && filters.filters.includes("bonus")) {
      targets = targets.filter((t) => t.gifts);
    }
    if (input) {
      const fuz = fuzzysort.go(norm(input), targets, {
        key: ["norm"],
        limit: 300, // don't return more results than you need!
        threshold: -100, // don't return bad results
      });
      targets = fuz.map((f) => f.obj);
    }

    setState(targets);
  }, [input, filters]);

  const couponQuery = props.requirements?.coupon
    ? `?coupon=${props.requirements.coupon}`
    : "";
  const showCarousel =
    componentStates.current.length === state.length &&
    props.carousels &&
    !showMap;

  return (
    <div className="lightSearchGrid">
      <SearchBar
        requirements={props.requirements}
        mapGridCallback={(e) => setShowMap(e)}
        mapOpened={showMap}
        handleInput={(i) => setInput(i)}
        categData={filters}
        filtersConfiguration={props.filtersConfiguration}
        categoryCallback={(f) => setFilters(f)}
      />
      <div className="noResult">
        {state?.length
          ? `${state.length} résultat${state.length > 1 ? "s" : ""}`
          : "Pas de résultat"}
      </div>
      <div className="filters">{renderCurrentFilters()}</div>
      {showMap && <Map results={state} requirements={props.requirements} />}
      {showCarousel &&
        props.carousels.map((c, i) => (
          <StaticCarousel
            key={c._key}
            requirements={props.requirements}
            {...c}
          />
        ))}
      {!!state.length && !showMap && (
        <>
          <div className="grid">
            {showCarousel && <div className="title">Les autres résultats</div>}
            <div className="results">
              {state.slice(pageSize * page, pageSize * (page + 1)).map((r) => (
                <OfferSlide
                  key={r.id}
                  image={r.urlimg}
                  fallbackImage={`https://tourismebyca.twic.pics/static/${props.requirements?.partner}/v2/categSearch/${r.categslug}.jpg`}
                  fallbackImage2={`https://tourismebyca.twic.pics/static/tca/v2/categSearch/${r.categslug}.png`}
                  category={r.categslug}
                  title={r.storeName}
                  note={r.note}
                  place={r.city}
                  discount={r.gifts ? getDiscountTags(r.gifts) : []}
                  url={`/etablissement/${r.id}${couponQuery}`}
                  requirements={props.requirements}
                />
              ))}
            </div>
          </div>
          <Pagination
            nbPages={Math.ceil(state.length / pageSize)}
            currentPage={page}
            requirements={props.requirements}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </div>
  );
};

const preloader = async (data, requirements, clientSide) => {
  return getAllVendors(requirements);
};

const LightSearchGrid = { Renderer, preloader };

export default LightSearchGrid;
export { Renderer, preloader };
