import React from "react";
import {PictoProps} from "./index";

export const Calendar = (props: PictoProps) => (
  <svg width={props.width || "21"} height={props.height || "22"} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.896" y="3" width="18" height="18" rx="2" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.896 1V5" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.896 1V5" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.896 9H19.896" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
