import React from "react";
import { RequirementsProps } from "../../tools/context";

export interface NoticeProps {
  desktopContent: string;
  mobileContent: string;
  background: string;
  hideNotice: boolean;
}

export interface TopPageNoticeProps {
  notice: {
    desktopContent: string;
    mobileContent: string;
    background: string;
    hideNotice: boolean;
  };
  requirements: RequirementsProps;
}

export const TopPageNotice = (props: TopPageNoticeProps) => {
  const { notice } = props;

  if (!notice || notice.hideNotice) return null;

  return (
    <>
      {!!notice.desktopContent && (
        <div
          className={"topPageNotice desktop"}
          nonce="__nonce__"
          style={
            notice.background ? { backgroundColor: notice.background } : null
          }
          dangerouslySetInnerHTML={{
            __html: notice.desktopContent,
          }}
        />
      )}
      {!!notice.mobileContent && (
        <div
          className={"topPageNotice mobile"}
          nonce="__nonce__"
          style={
            notice.background ? { backgroundColor: notice.background } : null
          }
          dangerouslySetInnerHTML={{
            __html: notice.mobileContent,
          }}
        />
      )}
    </>
  );
};
