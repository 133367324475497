export default {
  key: "iledefrance",
  canonicalUrl: "iledefrance.fr",
  datalayerId: "GTM-N7FRS7X",
  customerId: 682,
  icon: "iledefrance.png",
  headerLogo: "iledefrance.png",
  footerLogo: "iledefrance_monochrome.png",
  axeptioClientId: "",
  axeptioCookiesVersion: "iledefrance.fr",
  clientUrl: "https://iledefrance.fr",
  proUrl: "https://iledefrance.fr",
  sanity: {
    base: {
      projectId: "56ttciw7",
      dataset: "production",
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        "skntRbN6LBiBjMMTzLKIUNkMJY8BCNtRRKqfoOzS9ClLiJdbnrcTqH1RyMugXvwBOUdWfUSKpcff2BLNocPJOz88egblf827xoC0VdDyFNmX8nBVSxCH7hjxvcoh06422RMccgAMpnxDOaPYGeSJmrKbWLhhiEgSAIh2uanAQjDFd1SRCbZ1",
    },
  },
  dev: {
    clientUrl: "https://dev.tourismebyca.fr/",
    proUrl: "https://dev.tourismebyca.fr/",
  },
};
