import React from "react";
import {PictoProps} from "./index";

export const TravelCoupleV2 = (props: PictoProps) => (
  <svg width={props.width || "40"} height={props.height || "41"} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon couple">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6666 11.6667C18.6666 13.9679 16.8012 15.8333 14.5 15.8333C12.1988 15.8333 10.3333 13.9679 10.3333 11.6667C10.3333 9.36548 12.1988 7.5 14.5 7.5C16.8012 7.5 18.6666 9.36548 18.6666 11.6667ZM30.3333 11.6667C30.3333 13.9679 28.4679 15.8333 26.1667 15.8333C23.8655 15.8333 22 13.9679 22 11.6667C22 9.36548 23.8655 7.5 26.1667 7.5C28.4679 7.5 30.3333 9.36548 30.3333 11.6667ZM27.8334 17.5H24.5C21.275 17.5 18.6667 20.1083 18.6667 23.3333V31.6667C18.6667 31.8917 18.75 32.1 18.9084 32.2583C19.0667 32.4167 19.275 32.5 19.5 32.5H32.8334C33.0584 32.5 33.2667 32.4167 33.425 32.2583C33.5834 32.1 33.6667 31.8917 33.6667 31.6667V23.3333C33.6667 20.1083 31.0584 17.5 27.8334 17.5ZM19.0083 18.2417C17.7667 19.5833 17 21.3667 17 23.3333V31.6667C17 31.95 17.0583 32.2333 17.15 32.5H7.83333C7.60833 32.5 7.4 32.4167 7.24167 32.2583C7.08333 32.1 7 31.8917 7 31.6667V23.3333C7 20.1083 9.60833 17.5 12.8333 17.5H16.1667C17.2 17.5 18.1667 17.775 19.0083 18.2417Z"
        fill="#000000"
      />
    </g>
  </svg>
)
