import {getOrCreateCiblerId} from "./tools";
import {trackCiblerEvent} from "./ciblerAPI";
import {useContext} from "react";
import {Requirements, RequirementsProps} from "./context";

declare const window: any;

export const useTracking = () => {
  const requirements: RequirementsProps = useContext(Requirements);

  const initTracking = () => {
    if (typeof window.dataLayer === "undefined") return;

    window.dataLayer.push({
      platform: window.location.hostname,
    })
  }

  const trackEvent = (event:string, data?: any) => {
    const localData = {};

    if (typeof window.dataLayer !== "undefined") {
      const localData = data || {};
      window.dataLayer.push({event, ...localData})
    }

    const customerId = requirements?.config?.customerId || 161;
    trackCiblerEvent(event, localData,customerId, getOrCreateCiblerId())
  }

  return {initTracking, trackEvent}
}