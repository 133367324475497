import React from "react";
import {PictoProps} from "./index";

export const TravelMedium = (props: PictoProps) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6653 6.66667C18.6666 5.19333 15.6799 4 11.9999 4C8.31992 4 5.33592 5.19467 5.33325 6.66667C5.33325 8.14 8.31725 9.33333 11.9999 9.33333C15.6826 9.33333 18.6666 8.14 18.6666 6.66667" stroke="#0A94A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.6667 6.6665V17.0132" stroke="#0A94A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.33472 12C5.33472 13.4733 8.31872 14.6667 12.0014 14.6667C15.684 14.6667 18.668 13.4733 18.668 12" stroke="#0A94A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.2423 17.757C28.585 20.0997 28.585 23.8997 26.2423 26.2423C23.8997 28.585 20.0997 28.585 17.757 26.2423C15.4143 23.8997 15.4143 20.0997 17.757 17.757C20.0997 15.4143 23.8997 15.4143 26.2423 17.757" stroke="#0A94A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.6734 19.2335C15.4707 19.7068 13.8214 20.0002 12 20.0002C8.31872 20.0002 5.33472 18.8068 5.33472 17.3335" stroke="#0A94A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5932 24.5918C15.3973 25.0492 13.7853 25.3332 11.9999 25.3332C8.31858 25.3332 5.33459 24.1398 5.33325 22.6665V6.6665" stroke="#0A94A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.9808 20.0939L24.0865 20.6657C23.6731 20.1768 23.2788 19.9116 22.6058 19.9116C21.9231 19.9116 21.3942 20.3011 21.125 20.9227H23.1923V21.6271H20.9423C20.9231 21.7431 20.9231 21.8674 20.9231 21.9917C20.9231 22.1077 20.9231 22.2155 20.9327 22.3232H23.1923V23.0359H21.1154C21.3846 23.6906 21.9327 24.0884 22.6827 24.0884C23.3269 24.0884 23.7115 23.7983 24.1538 23.2928L25 23.8315C24.4519 24.5193 23.7692 25 22.6731 25C21.25 25 20.25 24.2127 19.875 23.0359H19V22.3232H19.7404C19.7308 22.2238 19.7308 22.116 19.7308 22.0083C19.7308 21.884 19.7308 21.7514 19.75 21.6271H19V20.9227H19.9038C20.2885 19.8039 21.2692 19 22.6346 19C23.8077 19 24.4712 19.4641 24.9808 20.0939Z" fill="#0A94A8"/>
  </svg>
)
