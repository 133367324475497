import React, { useState } from 'react';
import {Configuration} from "../../tools/Constants";
import {replaceUrlParam} from "../../tools/tools";
import {RequirementsProps} from "../../tools/context";
import {Picto, pictos} from "../Picto";
import {Button} from "../Button";

export interface CategoryPopupProps {
  requirements: RequirementsProps
  closeCallback: () => void|undefined
}


const CategoryPopup = (props: CategoryPopupProps) => {
  const categories = Configuration.categories;
  const labels = Configuration.labels;
  const [catChecked, setCatChecked] = useState<string[]>(props.requirements.categ || []);
  const [labChecked, setLabChecked] = useState<string[]>(props.requirements.labels || []);
  const [otherChecked, setOtherChecked] = useState<string[]>(props.requirements.filters || []);
  const otherFilters = {
    bonus: {name: 'Avec avantage'},
  }

  const handleValidation = () => {
    let url = window.location.href
    url = replaceUrlParam('page', 0, url);
    url = replaceUrlParam('categ', catChecked.join('|'), url);
    url = replaceUrlParam('labels', labChecked.join('|'), url);
    url = replaceUrlParam('filters', otherChecked.join('|'), url);

    window.location.href = url;
  }

  const handleCheck = (key: string, checker, setChecker) => {
    const temp = [...checker];
    const idx = temp.findIndex(k => k===key);

    if(idx === -1) {
      temp.push(key);
    } else {
      temp.splice(idx, 1);
    }

    setChecker(temp);
  }

  const renderblock = (list, title, checker, setChecker, handler) => (
    list && !!Object.keys(list).length &&
    <div>
      <div className="title">{title}</div>
      {Object.keys(list)
        .filter(ca => !list[ca].disabled)
        .map(c => <button
          key={c} className={`line ${c}`}
          onClick={() => handler(c, checker, setChecker)}
        >
          {pictos[c] && <div className="icon"><Picto iconKey={c} /></div>}
          <span>{list[c].name}</span>
          <div className={`checkbox ${checker.includes(c) ? 'checked' : ''}`}>{
            checker.includes(c) ? <Picto iconKey={"check"} /> : null
          }</div>
        </button>)}
    </div>
  )

  return <div className="categoryPopup">
    <button className="close" onClick={props.closeCallback}>
      <Picto iconKey={"cross"} />
    </button>
    <div className="list">
      {renderblock(categories, 'Filtrer les catégories', catChecked, setCatChecked, handleCheck)}
      {renderblock(labels, 'Filtrer les partenaires', labChecked, setLabChecked, handleCheck)}
      {renderblock(otherFilters, 'Autres filtres', otherChecked, setOtherChecked, handleCheck)}
    </div>
    <Button theme={"transparent"} onClick={handleValidation}>Afficher les résultats</Button>
  </div>
}

export default CategoryPopup;