import React from 'react';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../contexts/ThemeContext';
import ErrorIcon from './ErrorIcon';

const ErrorComponent: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const errorStyles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      backgroundColor: theme.colors.background,
    },
    content: {
      color: theme.colors.text,
    },
    title: {
      color: theme.colors.primary,
    },
    button: {
      backgroundColor: theme.colors.primary,
      color: theme.colors.text,
    },
    secondaryButton: {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.colors.secondary}`,
      color: theme.colors.textSecondary || theme.colors.text
    },
  };

  return (
    <div className="error-container" style={errorStyles.container}>
      <h2 className="error-container__title" style={errorStyles.title}>{t("travelPlaner.InternalError.mainTitle")}</h2>
      <div className="error-container__content">
        <div className="error-container__content__text">
          <h2 className="error-subtitle">{t("travelPlaner.InternalError.subtitle")}</h2>
          <p className="error-message">{t("travelPlaner.InternalError.message")}</p>
          <div className="error-buttons">
            <Button
              onClick={() => window.location.reload()}
              style={errorStyles.button}
              className="error-buttons__button">
              {t("travelPlaner.InternalError.retry")}
            </Button>
            <Button
              onClick={() => window.location.href = '/'}
              style={errorStyles.secondaryButton}
              className="error-buttons__button-secondary">
              {t("travelPlaner.InternalError.backHome")}
            </Button>
          </div>
        </div>
        <div className="error-container__image">
          <ErrorIcon />
      </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
