// export type Profile = "driving-traffic" | "driving" | "walking" | "cycling";
export enum Profile {
  DRIVING_TRAFFIC = "driving-traffic",
  DRIVING = "driving",
  WALKING = "walking",
  CYCLING = "cycling",
}
export type DirectionData = DirectionErrorData | DirectionValidData;

export function isDirectionOk(response: DirectionData): response is DirectionValidData {
  return response.code === "Ok";
}

export type DirectionErrorData = {
  code: ErrorCode;
  message: string;
  routes: [];
};

export type DirectionValidData = {
  routes: Route[];
  waypoints: Waypoint[];
  code: "Ok";
  uuid: string;
};

export type MapboxPoint = [number, number];
export type MapboxLine = MapboxPoint[];

export type Route = {
  weight_name: string;
  weight: number;
  duration: number;
  distance: number;
  legs: Leg[];
  geometry: { coordinates: MapboxLine };
};

export type Leg = {
  via_waypoints: any[];
  admins: Admin[];
  weight: number;
  duration: number;
  steps: any[];
  distance: number;
  summary: string;
};

export type Admin = {
  iso_3166_1_alpha3: string;
  iso_3166_1: string;
};

export type Waypoint = {
  distance: number;
  name: string;
  location: number[];
};

export type ErrorCode =
  | "NoRoute"
  | "NoSegment"
  | "Not Authorized - No Token"
  | "Not Authorized - Invalid Token"
  | "Forbidden"
  | "ProfileNotFound"
  | "InvalidInput";