import React from "react";
import {PictoProps} from "../index";

export const ArmorAlimentaire = (props: PictoProps) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill={props.fill} stroke="none">
<path d="M719 4851 l-29 -29 0 -347 0 -346 -215 -696 c-230 -745 -229 -743
-200 -858 46 -176 178 -314 355 -371 l65 -21 3 -892 2 -891 -205 0 c-205 0
-206 0 -230 -25 -16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 2271 0
2271 0 24 25 c33 32 33 78 0 110 -24 25 -25 25 -225 25 l-200 0 0 893 0 893
46 12 c158 42 297 169 355 323 11 28 24 84 29 123 l9 71 -219 708 -220 707 0
346 0 346 -29 29 -29 29 -1812 0 -1812 0 -29 -29z m3551 -386 l0 -255 -1710 0
-1710 0 0 255 0 255 1710 0 1710 0 0 -255z m-2880 -420 c0 -2 -52 -284 -115
-626 -63 -342 -115 -626 -115 -631 0 -4 -160 -8 -355 -8 -295 0 -355 2 -355
14 0 7 86 291 190 631 105 340 190 619 190 621 0 2 126 4 280 4 154 0 280 -2
280 -5z m720 -32 c0 -21 -16 -303 -35 -628 -19 -324 -35 -593 -35 -597 0 -5
-162 -8 -361 -8 l-361 0 6 28 c3 15 56 295 116 622 61 327 112 601 115 608 3
9 69 12 280 12 l275 0 0 -37z m735 25 c6 -19 82 -1242 78 -1250 -2 -5 -164 -8
-359 -8 l-354 0 0 23 c-1 41 71 1202 76 1225 l4 22 276 0 c211 0 276 -3 279
-12z m834 -605 c62 -340 115 -626 117 -635 5 -17 -17 -18 -354 -18 l-360 0 -6
73 c-6 69 -76 1162 -76 1186 0 8 79 11 283 11 l283 0 113 -617z m611 613 c0
-2 86 -281 190 -621 105 -340 190 -624 190 -631 0 -12 -60 -14 -355 -14 -195
0 -355 4 -355 8 0 5 -52 288 -115 630 -63 342 -115 624 -115 627 0 3 126 5
280 5 154 0 280 -2 280 -4z m-3165 -1453 c-19 -81 -103 -176 -193 -220 -50
-25 -68 -28 -152 -28 -82 0 -103 4 -152 27 -69 32 -138 99 -172 167 -45 88
-71 81 325 81 l351 0 -7 -27z m895 15 c0 -30 -52 -121 -92 -161 -24 -24 -71
-57 -104 -73 -53 -26 -69 -29 -154 -29 -82 0 -103 4 -152 27 -69 32 -138 99
-172 167 -45 88 -71 81 324 81 273 0 350 -3 350 -12z m895 -10 c-15 -50 -61
-121 -103 -159 -116 -108 -303 -130 -432 -51 -71 43 -140 132 -164 210 l-7 22
356 0 356 0 -6 -22z m885 7 c0 -30 -52 -117 -96 -162 -25 -24 -71 -57 -102
-71 -49 -23 -70 -27 -152 -27 -85 0 -101 3 -154 29 -33 16 -80 49 -104 73 -40
40 -92 131 -92 161 0 9 77 12 350 12 308 0 350 -2 350 -15z m890 3 c0 -31 -52
-121 -92 -161 -179 -178 -481 -123 -589 108 -10 22 -19 46 -19 53 0 9 77 12
350 12 273 0 350 -3 350 -12z m-3371 -295 c91 -85 217 -133 351 -133 153 1
257 46 372 160 l77 76 31 -40 c200 -261 620 -261 820 0 l31 40 77 -76 c118
-116 220 -160 372 -160 163 0 319 74 409 193 l33 44 76 -77 c81 -81 144 -119
242 -143 l55 -14 3 -92 3 -91 -1711 0 -1711 0 3 91 3 92 55 14 c98 24 160 62
240 143 l75 76 24 -30 c14 -17 45 -50 70 -73z m621 -923 l0 -450 -545 0 -545
0 0 450 0 450 545 0 545 0 0 -450z m1080 -270 l0 -720 -460 0 -460 0 0 720 0
720 460 0 460 0 0 -720z m1250 270 l0 -450 -545 0 -545 0 0 450 0 450 545 0
545 0 0 -450z m-2330 -800 l0 -190 -545 0 -545 0 0 190 0 190 545 0 545 0 0
-190z m2330 0 l0 -190 -545 0 -545 0 0 190 0 190 545 0 545 0 0 -190z"/>
</g>
</svg>
)
