import React from "react";
import {PictoProps} from "./index";

export const TravelFamily = (props: PictoProps) => (
  <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.65488 14.3809C3.25563 14.3809 0.5 17.1365 0.5 20.5357V25.9406C0.5 26.3548 0.835786 26.6906 1.25 26.6906H12.0598C12.4739 26.6906 12.8097 26.3549 12.8098 25.9407H8.00608V25.1906H2V20.5357C2 17.9649 4.08406 15.8809 6.65488 15.8809C7.96485 15.8809 9.14844 16.422 9.99431 17.2929H11.8872C10.8014 15.5448 8.86408 14.3809 6.65488 14.3809Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1514 17.2929H13.0442C13.8901 16.422 15.0737 15.8809 16.3836 15.8809C18.9545 15.8809 21.0385 17.9649 21.0385 20.5357V25.1906H15.5729V25.9407H10.2288C10.2288 26.3549 10.5646 26.6906 10.9788 26.6906H21.7885C22.2027 26.6906 22.5385 26.3548 22.5385 25.9406V20.5357C22.5385 17.1365 19.7829 14.3809 16.3836 14.3809C14.1744 14.3809 12.2371 15.5448 11.1514 17.2929Z" fill="currentColor"/>
    <path d="M11.4991 2.27393C10.9283 1.70308 10.0026 1.70304 9.43176 2.27393C8.86088 2.84481 9.07334 3.68076 9.43176 4.34129C9.79018 5.00182 10.9197 6.13129 11.7059 6.61538C12.5332 6.13117 13.6627 4.84046 13.98 4.34129C14.2972 3.84211 14.5508 2.84477 13.98 2.27393C13.4091 1.70308 12.4835 1.70304 11.9126 2.27393L11.7059 2.48066L11.4991 2.27393Z" stroke="#071621" strokeWidth="1.5" strokeLinejoin="round"/>
    <circle cx="6.65496" cy="10.8073" r="3.5739" stroke="#071621" strokeWidth="1.5"/>
    <circle cx="11.054" cy="19.5149" r="2.55298" stroke="#071621" strokeWidth="1.5"/>
    <path d="M6.92505 25.9402C6.92505 24.1492 8.37696 22.6973 10.168 22.6973H12.3299C14.1209 22.6973 15.5729 24.1492 15.5729 25.9402V25.9402H6.92505V25.9402Z" stroke="#071621" strokeWidth="1.5" strokeLinejoin="round"/>
    <circle cx="16.3837" cy="10.8073" r="3.5739" stroke="#071621" strokeWidth="1.5"/>
  </svg>

)
