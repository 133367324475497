import React from "react";
import {PictoProps} from "./index";

export const TravelFriendsV2 = (props: PictoProps) => (
  <svg width={props.width || "40"} height={props.height || "41"} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon group">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 15.8333C22.8012 15.8333 24.6667 13.9679 24.6667 11.6667C24.6667 9.36548 22.8012 7.5 20.5 7.5C18.1988 7.5 16.3333 9.36548 16.3333 11.6667C16.3333 13.9679 18.1988 15.8333 20.5 15.8333ZM14.6667 15C14.6667 17.3012 12.8012 19.1666 10.5 19.1666C8.19882 19.1666 6.33334 17.3012 6.33334 15C6.33334 12.6988 8.19882 10.8333 10.5 10.8333C12.8012 10.8333 14.6667 12.6988 14.6667 15ZM34.6667 15C34.6667 17.3012 32.8012 19.1666 30.5 19.1666C28.1988 19.1666 26.3333 17.3012 26.3333 15C26.3333 12.6988 28.1988 10.8333 30.5 10.8333C32.8012 10.8333 34.6667 12.6988 34.6667 15ZM18.8333 17.5H22.1667C25.3917 17.5 28 20.1083 28 23.3333V31.6667C28 31.8917 27.9167 32.1 27.7583 32.2583C27.6 32.4167 27.3917 32.5 27.1667 32.5H13.8333C13.6083 32.5 13.4 32.4167 13.2417 32.2583C13.0833 32.1 13 31.8917 13 31.6667V23.3333C13 20.1083 15.6083 17.5 18.8333 17.5ZM32.1667 20.8333H29.2417C29.5167 21.6166 29.6667 22.4583 29.6667 23.3333V31.6666C29.6667 31.95 29.6167 32.2333 29.525 32.5H37.1667C37.3917 32.5 37.6 32.4166 37.7583 32.2583C37.9167 32.1 38 31.8916 38 31.6666V26.6666C38 23.4416 35.3917 20.8333 32.1667 20.8333ZM8.83333 20.8333H11.7583C11.4833 21.6166 11.3333 22.4583 11.3333 23.3333V31.6666C11.3333 31.95 11.3833 32.2333 11.475 32.5H3.83333C3.60833 32.5 3.4 32.4166 3.24167 32.2583C3.08333 32.1 3 31.8916 3 31.6666V26.6666C3 23.4416 5.60833 20.8333 8.83333 20.8333Z"
        fill="#000000"
      />
    </g>
  </svg>
)
