import React from "react";
import {PictoProps} from "./index";

export const CirclePercent = (props: PictoProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.59863 9.40029L9.3998 6.59912" stroke="#071621" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.5266 9.30246C9.58929 9.36515 9.58929 9.46652 9.5266 9.52855C9.4639 9.59124 9.36253 9.59124 9.3005 9.52855C9.23781 9.46586 9.23781 9.36448 9.3005 9.30246C9.36253 9.23976 9.4639 9.23976 9.5266 9.30246" stroke="#071621" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.69884 6.47128C6.76153 6.53397 6.76153 6.63535 6.69884 6.69737C6.63614 6.76006 6.53477 6.76006 6.47274 6.69737C6.41072 6.63468 6.41005 6.5333 6.47274 6.47128C6.53543 6.40925 6.63681 6.40858 6.69884 6.47128" stroke="#071621" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.32062 13.1626C6.51736 13.1365 6.71611 13.1899 6.87285 13.3106L7.59515 13.8648C7.83391 14.0483 8.16605 14.0483 8.40415 13.8648L9.1538 13.2893C9.29385 13.1819 9.47059 13.1345 9.64533 13.1579L10.5831 13.2813C10.8812 13.3206 11.1686 13.1546 11.284 12.8764L11.6448 12.0041C11.7122 11.8407 11.8416 11.7113 12.005 11.6439L12.8773 11.2831C13.1555 11.1684 13.3215 10.8803 13.2822 10.5821L13.1635 9.67844C13.1375 9.48169 13.1908 9.28294 13.3115 9.12621L13.8658 8.40391C14.0492 8.16514 14.0492 7.833 13.8658 7.5949L13.2902 6.84526C13.1828 6.7052 13.1355 6.52846 13.1588 6.35372L13.2822 5.41599C13.3215 5.11787 13.1555 4.83042 12.8773 4.71504L12.005 4.35422C11.8416 4.28686 11.7122 4.15747 11.6448 3.99407L11.284 3.12171C11.1693 2.84359 10.8812 2.67752 10.5831 2.71687L9.64533 2.84025C9.47059 2.86426 9.29385 2.81691 9.15446 2.7102L8.40482 2.13463C8.16605 1.95122 7.83391 1.95122 7.59581 2.13463L6.84617 2.7102C6.70611 2.81691 6.52937 2.86426 6.35463 2.84159L5.41691 2.7182C5.11878 2.67885 4.83133 2.84492 4.71595 3.12304L4.3558 3.9954C4.28777 4.15814 4.15838 4.28752 3.99565 4.35555L3.12328 4.7157C2.84517 4.83108 2.6791 5.11854 2.71845 5.41666L2.84183 6.35439C2.86451 6.52912 2.81716 6.70586 2.71044 6.84526L2.13487 7.5949C1.95146 7.83367 1.95146 8.16581 2.13487 8.40391L2.71044 9.15355C2.81782 9.29361 2.86518 9.47035 2.84183 9.64509L2.71845 10.5828C2.6791 10.8809 2.84517 11.1684 3.12328 11.2838L3.99565 11.6446C4.15905 11.712 4.28844 11.8413 4.3558 12.0047L4.71661 12.8771C4.83133 13.1552 5.11945 13.3213 5.41757 13.2819L6.32062 13.1626" stroke="#071621" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

)
