import React, { useState } from "react";
import { Button } from "../Button";

export interface BetaProps {
  isInline?: boolean;
  title?: string;
  content: string;
}

export const Beta = (props: BetaProps) => {
  const { isInline, title, content } = props;
  const [showBeta, setShowBeta] = useState<boolean>(false);

  if (!content) return null;

  return isInline ? (
    <div className="beta" dangerouslySetInnerHTML={{ __html: content }} />
  ) : (
    <div
      className="betaOutline"
      onMouseOver={() => setShowBeta(true)}
      onMouseOut={() => setShowBeta(false)}
    >
      <Button
        theme={"pro"}
        onClick={() => setShowBeta((p) => !p)}
        onBlur={() => setShowBeta(true)}
      >
        Beta
      </Button>
      {showBeta ? (
        <div className="popin">
          {title?.length > 1 ? <div className="title">{title}</div> : null}
          {!!content ? (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
