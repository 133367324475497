import PictoButton from "../PictoButton";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Picto} from "../../Picto";
import {TravelPlanerResultsContext} from "../TravelPlanerResultsContext";
import {
  DndContext,
  DragEndEvent, DragOverEvent,
  DragOverlay,
  DragStartEvent, KeyboardSensor, MouseSensor,
  PointerSensor, TouchSensor, UniqueIdentifier,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext} from "@dnd-kit/sortable";
import PopupDayContainer from "./PopupDayContainer";
import {createPortal} from "react-dom";
import PopupItemContainer from "./PopupItemContainer";
import {coordinateGetter} from '../../../tools/sortable-dnd-kit/multipleContainersKeyboardCoordinates';
import {useTranslation} from "../../../tools/i18n";
import lodash from 'lodash';
import {Button} from "../../Button";

interface EditJourneyPopupProps {
}

export type Data = {
  [key: string]: {
    key: string,
    value: any
  }[]
}
export type SelectedActivities = {
  [key: string]: {
    [key: string]: number
  }
}

export type Day = {
  id: UniqueIdentifier,
}
export type Item = {
  id: UniqueIdentifier,
  dayId: string,
  data: any,
}

export default function EditJourney(_: EditJourneyPopupProps) {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    formattedData,
    selectedActivities,
    updateTrip,
    setShowAllSites,
    setShowMobileMap,
  } = useContext(TravelPlanerResultsContext);

  const [dataCopy, setDataCopy] = useState<Data>({});
  const [selectedActivitiesCopy, setSelectedActivitiesCopy] = useState<SelectedActivities>({});
  const [initialDays, setInitialDays] = useState<Day[]>([]);
  const [days, setDays] = useState<Day[]>([]);
  const [initialItems, setInitialItems] = useState<Item[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [activeDay, setActiveDay] = useState<Day>(null);
  const [activeItem, setActiveItem] = useState<Item>(null);
  const [hoveredDay, setHoveredDay] = useState<UniqueIdentifier>(null);

  const daysId = useMemo(() => days.map(day => day.id), [days]);

  useEffect(() => {
    const newData = JSON.parse(JSON.stringify(formattedData));
    const newSA = JSON.parse(JSON.stringify(selectedActivities));
    const dataKeys = Object.keys(newData);
    dataKeys?.forEach((day: any) => {
      newData[day] = newData[day].map((item: any) => {
        let randomKey = Math.random().toString(36).substring(7);
        if (item.key.startsWith('added_'))
          randomKey = 'added_' + randomKey;
        newSA[day][randomKey] = newSA[day][item.key];
        delete newSA[day][item.key];
        return {...item, key: randomKey};
      });
    });

    const initialDays = dataKeys.map((id: string) => ({id}));
    setDays(initialDays);
    setInitialDays(initialDays);

    const initialItems = dataKeys.reduce((acc: Item[], dayId: string) => {
      return acc.concat(newData[dayId].map((item: any) => ({
        id: item.key,
        dayId,
        data: newData[dayId].find((i: any) => i.key === item.key)?.value[newSA[dayId][item.key]]
      })));
    }, []);
    setItems(initialItems);
    setInitialItems(initialItems);

    setSelectedActivitiesCopy(newSA)
    setDataCopy(newData);
  }, [formattedData, selectedActivities]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleDeleteDay = (id: string) => {
    setDays(days.filter(d => d.id !== id));
  }

  const handleDeleteItem = (id: string) => {
    setItems(items.filter(item => item.id !== id));
  }

  const handleApply = () => {
    const newFormattedData = {};
    const newSelectedActivities = {};
    days.forEach(day => {
      const dayId = day.id;
      newFormattedData[dayId] = items
        .filter(item => item.dayId === dayId)
        .map(item => {
          const allItems = Object.keys(dataCopy)
            .reduce((acc, key) => acc.concat(dataCopy[key]), []);
          return allItems.find(i => i.key === item.id);
        });
      newSelectedActivities[dayId] = items
        .filter(item => item.dayId === dayId)
        .reduce((acc, item) => {
          const allItems = Object.keys(selectedActivitiesCopy)
            .reduce((acc, key) => acc.concat(selectedActivitiesCopy[key]), [])
            .reduce((acc, item) => ({...acc, ...item}), {});
          acc[item.id] = allItems[item.id]
          return acc;
        }, {});
    });

    setInitialDays(days);
    setInitialItems(items);
    updateTrip(newFormattedData, newSelectedActivities);
    setIsOpen(false);
  }

  const onDragStart = (e: DragStartEvent) => {
    if (e.active.data.current.type === 'day')
      setActiveDay(e.active.data.current.day);
    if (e.active.data.current.type === 'item')
      setActiveItem(e.active.data.current.item);
  }

  const onDragOver = (e: DragOverEvent) => {
    const {active, over} = e;
    if (!active || !over)
      return;

    const activeType = active.data.current.type;
    const overType = over.data.current.type;

    // Set hovered day
    if (activeType === 'item') {
      setItems(p => {
        const activeIndex = p.findIndex(item => item.id === active.id);
        const overIndex = overType === 'day' ? 0 : p.findIndex(item => item.id === over.id);
        const overDay = overType === 'day' ? over.id : p[overIndex].dayId;

        p[activeIndex].dayId = String(overDay);

        return arrayMove(p, activeIndex, overIndex)
      })

      if (overType === 'day')
        setHoveredDay(over.id);
      else if (overType === 'item')
        setHoveredDay(over.data.current.item.dayId);
    }
  }

  const onDragEnd = (e: DragEndEvent) => {
    setActiveItem(null);
    setActiveDay(null);
    setHoveredDay(null);

    const {active, over} = e;
    if (!active || !over)
      return;

    if (active.data.current.type === 'day') {
      if (!e.over)
        return;
      setDays(p => {
        const activeIndex = p.findIndex(day => day.id === active.id);
        const overIndex = p.findIndex(day => day.id === over.id);

        return arrayMove(p, activeIndex, overIndex)
      });
    }
  }

  const sensorsOptions = {
    activationConstraint: {
      distance: 3,
    }
  }
  const sensors = useSensors(
    useSensor(PointerSensor, sensorsOptions),
    useSensor(MouseSensor, sensorsOptions),
    useSensor(TouchSensor, sensorsOptions),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleReset = () => {
    setDays(initialDays);
    setItems(initialItems);
  }

  const hasEdit = !lodash.isEqual(initialDays, days) || !lodash.isEqual(initialItems, items);
  const {siteConfiguration} = useContext(TravelPlanerResultsContext);

  return (
    <div className="EditJourney">
      <Button
          className="editButton"
          onClick={handleOpen}
          withPicto={"pen"}
          textLabel="travelPlaner.Travel.EditJourney.edit"
          withSVG={siteConfiguration.withSVG}
      >
      </Button>


      {isOpen
        ? <div className={`editJourneyPopup ${activeDay ? "dragging-day" : ""} ${activeItem ? "dragging-item" : ""}`} onClick={() => setIsOpen(false)}>
            <div className="editJourneyPopupContainer" onClick={(e) => e.stopPropagation()}>
              <div className="closeButtonContainer">
                <button className="closeButton" onClick={() => setIsOpen(false)}>
                  <div className="closeButtonContent">
                    <Picto iconKey='cross' width="14" height="14"/>
                  </div>
                </button>
              </div>
              <div className="editJourneyContentContainer">
                <div className="editJourneyPopupHeader">
                  <span>{t('travelPlaner.Travel.EditJourney.title')}</span>
                </div>
                <DndContext
                  sensors={sensors}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDragEnd={onDragEnd}
                >
                  <div className="editJourneyPopupContent">
                    <SortableContext items={daysId}>
                      {days.map((day, index) => (
                        <PopupDayContainer
                          key={day.id}
                          day={day}
                          dayIndex={index}
                          items={items.filter(item => item.dayId === day.id)}
                          isHovered={hoveredDay === day.id}
                          handleDeleteDay={handleDeleteDay}
                          handleDeleteItem={handleDeleteItem}
                        />
                      ))}
                    </SortableContext>
                  </div>
                  {createPortal(
                    <DragOverlay>
                      {activeDay ? (
                        <PopupDayContainer
                          overlay
                          day={activeDay}
                          dayIndex={days.findIndex(day => day.id === activeDay.id)}
                          items={items.filter(item => item.dayId === activeDay.id)}
                          handleDeleteDay={handleDeleteDay}
                          handleDeleteItem={handleDeleteItem}
                        />
                      ) : null}
                      {activeItem ? (
                        <PopupItemContainer
                          overlay
                          item={activeItem}
                          handleDeleteItem={handleDeleteItem}
                        />
                      ) : null}
                    </DragOverlay>, document.querySelector('.editJourneyPopup') || document.body
                  )}
                </DndContext>
              </div>
              <div className="editJourneyPopupFooter">
                <Button
                  className="addStep"
                  onClick={() => {
                    setShowAllSites(true);
                    setShowMobileMap(false);
                  }}
                >
                  <div className="iconWrapper">
                    <Picto iconKey="plus" />
                  </div>
                  {t("travelPlaner.Travel.addStep")}
                </Button>
                {hasEdit
                  ? <button className="resetButton" onClick={handleReset}>
                      <Picto iconKey='rotate-right' width="14" height="14"/>
                    </button>
                  : null
                }
                <button
                  className="applyButton"
                  disabled={!hasEdit}
                  onClick={handleApply}
                >
                  {t('travelPlaner.Travel.EditJourney.apply')}
                </button>
              </div>
            </div>
          </div>
        : null
      }
    </div>
  );
};
