export default {
  key: 'otc',
  canonicalUrl: 'www.tourismebyca.fr',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 679,
  icon: 'fav_otc.png',
  headerLogo: 'otc.png',
  footerLogo: 'otc_monochrome.png',
  axeptioClientId: "",
  axeptioCookiesVersion: "zanmi.ai",
  clientUrl: "https://zanmi.ai/",
  proUrl: "https://zanmi.ai/",
  sanity: {
    base: {
      projectId: '80q9c3qa',
      dataset: 'production',
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token: 'skwHQyM9qoeyoOYA0Mle2j6MjxRqmWIZsdXtaldVn3UyvddpzMNMFVAFsXIhgUXwtVgeN4Wo7XMlG1WvEmZYi3P9iCul1xVemUEPXwLyhL3p1qRnp1IBLhCW894qTT8WTti6CtygSRh6EkJhSff46OeQ3qnwTJYXWMuDk0dtEY9O8iubV7eZ',
    }
  },
  dev: {
    clientUrl: "https://dev.tourismebyca.fr/",
    proUrl: "https://dev.tourismebyca.fr/",
  },
  travelForceLogin: false,
}