
import React from "react";
import {PictoProps} from "./index";

export const AiMention = (props: PictoProps) => {
    const { width } = props;
    const finalWidth = width || 12;
    return (
        <svg width={finalWidth} height={finalWidth} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.00008 9.174C5.00008 7.15028 2.84989 5.00008 0.826172 5.00008C2.84989 5.00008 5.00008 2.84989 5.00008 0.826172C5.00008 2.84989 7.15028 5.00008 9.174 5.00008C7.15028 5.00008 5.00008 7.15028 5.00008 9.174Z" fill="white"/>
        </svg>
    );
}
