import React from "react";
import { PictoProps } from ".";

export const Telecharger = (props: PictoProps) => (
    <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14 7.12445V10.2838C14 11.2806 13.1845 12.0961 12.1877 12.0961H2.81227C1.81552 12.0961 1 11.2806 1 10.2838V7.12445"
            stroke="white"
            strokeMiterlimit="10"
        />
        <path d="M7.53027 8.20882V0.780029" stroke="white" strokeMiterlimit="10" />
        <path
            d="M10.6385 5.09924L7.53045 8.20879L4.4209 5.09924"
            stroke="white"
            strokeMiterlimit="10"
        />
    </svg>
);