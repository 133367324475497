import * as React from "react";
import { Configuration } from "../../tools/Constants";
import { RequirementsProps } from "../../tools/context";
import { ResponsiveImage } from "../ResponsiveImage";
import StarNote from "../StarNote";

export interface OfferSlideProps {
  image?: object | string;
  fallbackImage?: string;
  fallbackImage2?: string;
  category: string;
  title: string;
  note?: number;
  noNote?: boolean;
  place: string;
  discount?: string[];
  url?: string;
  noImage?: boolean;
  small?: boolean;
  requirements: RequirementsProps;
}

export enum DiscountType {
  PERCENT,
  VALUE,
  GIFT,
  PRODUCT,
  FREESHIPPING,
  GWP,
}

export const getDiscountTags = (gifts) => {
  if (!gifts || gifts.length === 0) {
    return [];
  }
  let giftTag, maxgift;

  let sorted = gifts
    .filter(
      (gift) =>
        gift.discount_type == DiscountType.PERCENT ||
        gift.discount_type == DiscountType.VALUE
    )
    .sort((g1, g2) => g2.value - g1.value);
  maxgift = sorted.length ? sorted[0] : null;
  giftTag = gifts.some(
    (gift) =>
      gift.discount_type == DiscountType.GIFT ||
      gift.discount_type == DiscountType.GWP ||
      gift.discount_type == DiscountType.PRODUCT
  );

  let tags = [];
  if (maxgift && maxgift.value > 0) {
    let dtype = maxgift.discount_type == DiscountType.PERCENT ? "%" : "€";
    tags.push("Jusqu'a " + maxgift.value + dtype + " de remise");
  }
  if (giftTag && gifts.length === 1) tags.push(gifts[0].description);
  if (giftTag && gifts.length > 1) tags.push("Avantages offerts");
  return tags;
};

const Pin = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18206 5.09079C9.18206 8.27261 5.09115 10.9999 5.09115 10.9999C5.09115 10.9999 1.00024 8.27261 1.00024 5.09079C1.00024 2.83144 2.83181 0.999878 5.09115 0.999878C7.3505 0.999878 9.18206 2.83144 9.18206 5.09079V5.09079Z"
      stroke="black"
      strokeOpacity="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="5.09118"
      cy="5.09081"
      r="1.36364"
      stroke="black"
      strokeOpacity="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const OfferSlide = (props: OfferSlideProps) => {
  const {
    image,
    fallbackImage,
    fallbackImage2,
    category,
    title,
    note,
    noNote,
    place,
    discount,
    url,
    noImage,
    small,
    requirements,
  } = props;

  const content = (
    <div className={`offerSlide ${small ? "small" : ""}`}>
      {!noImage && (image || fallbackImage) && (
        <ResponsiveImage
          src={image}
          fallback={fallbackImage}
          fallback2={fallbackImage2}
          alt={title}
          height={352}
          width={352}
          mobileHeight={254}
          mobileWidth={254}
          requirements={requirements}
        />
      )}
      <div className={`category ${category}`}>
        {Configuration.categories[category]
          ? Configuration.categories[category].name
          : category}
      </div>
      <div className="title">{title}</div>
      {note !== 0 && !noNote && <StarNote note={note} />}
      <div className="place">
        <Pin /> {place}
      </div>
      {discount && discount.map && (
        <div className="discountBlock">
          {discount.map((d, i) => (
            <div className="discount" key={i}>
              {d}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  if (!url) return content;

  return <a href={url}>{content}</a>;
};

export default OfferSlide;
