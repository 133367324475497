import React from "react";
import {PictoProps} from "./index";

export const TravelCouple = (props: PictoProps) => (
  <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.23041" cy="4.78436" r="4.03436" stroke="#071621" strokeWidth="1.5"/>
    <circle cx="17.9952" cy="4.78436" r="4.03436" stroke="#071621" strokeWidth="1.5"/>
    <path d="M12.4855 12.2263C11.5181 11.259 9.94957 11.2589 8.98215 12.2263C8.01473 13.1938 8.01479 14.7623 8.98215 15.7297L12.8358 19.5834L16.6895 15.7297C17.657 14.7623 17.6569 13.1937 16.6895 12.2263C15.7222 11.259 14.1536 11.2589 13.1862 12.2263L12.8358 12.5767L12.4855 12.2263Z" stroke="#071621" strokeWidth="1.5" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.23046 8.81885C3.51333 8.81885 0.5 11.8322 0.5 15.5493V21.5298C0.5 21.944 0.835786 22.2798 1.25 22.2798H13.2109C13.6251 22.2798 13.9609 21.944 13.9609 21.5298V18.4587L12.8359 19.5837L12.4609 19.2088V20.7798H2V15.5493C2 12.6606 4.34176 10.3188 7.23046 10.3188C8.49123 10.3188 9.64782 10.7649 10.551 11.5079C11.2445 11.4569 11.9553 11.6965 12.4855 12.2267L12.8359 12.577L13.1214 12.2915C11.9737 10.2207 9.76578 8.81885 7.23046 8.81885Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2595 12.0263C12.3379 12.0877 12.4134 12.1545 12.4856 12.2267L12.836 12.577L13.1863 12.2267C13.6004 11.8126 14.1246 11.5758 14.6647 11.5162C15.5694 10.7683 16.7299 10.3188 17.9953 10.3188C20.884 10.3188 23.2258 12.6606 23.2258 15.5493V20.7798H12.7649V19.5127L11.2649 18.0127V21.5298C11.2649 21.944 11.6007 22.2798 12.0149 22.2798H23.9758C24.39 22.2798 24.7258 21.944 24.7258 21.5298V15.5493C24.7258 11.8322 21.7125 8.81885 17.9953 8.81885C15.5698 8.81885 13.444 10.1019 12.2595 12.0263Z" fill="currentColor"/>
  </svg>

)
