import React from 'react';
import {Button} from "../Button";
import {useTranslation} from "../../tools/i18n";

export interface ErrorProps {
  callback: () => void
}

export const Error = ({callback}: ErrorProps) => {
  const {t} = useTranslation();

  return (
    <div className="error">
      {t(`travelPlaner.Error.title`)}
      <Button onClick={callback} theme={"pro"}>{t(`travelPlaner.Error.button`)}</Button>
    </div>
  )
}