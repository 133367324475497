import React from 'react'
import {PictoProps} from "./index";

export const Logo = () => {
  const css = `
        .col0{fill:#028990;}
        .col1{fill:#9CCA40;}
        .col2{fill:#6D6E71;}
  `;
  return (
    <>
      <style type="text/css">{css}</style>
    <svg version="1.1" height="100" width="250" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         viewBox="0 0 720 296.88">
      <g id="Calque_1_xA0_Image_00000103975689416804768220000003701401102387073421_">

	<g>
		<g>
			<g>
				<path className="col1" d="M11.52,229.09c1.25-0.17,2.5-0.32,3.74-0.52c1.3-0.22,1.61-1.22,1.61-2.32c0.01-1.25-0.35-2.56,0.89-3.51
					c0.08-0.06-0.09-0.48-0.18-0.72c-0.45-1.19-0.92-2.39-1.38-3.58c0.09-0.16,0.17-0.31,0.26-0.47c-0.07-0.03-0.19-0.09-0.2-0.07
					c-0.09,0.12-0.15,0.25-0.22,0.37c-0.18-0.75-0.42-1.5-0.53-2.27c-0.12-0.79-0.1-1.61-0.17-2.41c-0.07-0.87,0.14-1.53,0.89-2.13
					c0.77-0.62,1.33-1.51,1.99-2.27c0.15-0.17,0.36-0.48,0.49-0.45c1.46,0.33,1.92-1.19,3.02-1.57c0.84,0.77,0.35,1.56,0.04,2.36
					c-0.25,0.04-0.5,0.06-0.75,0.11c-0.29,0.05-0.58,0.12-0.87,0.17c-0.55,0.75-1.14,1.48-1.63,2.27c-1.25,2.05-1.51,4.62,0.07,6.77
					c0.85,1.16,1.36,2.57,1.98,3.89c0.09,0.19-0.04,0.56-0.18,0.75c-0.71,0.9-1.08,1.84-0.88,3.04c0.08,0.51-0.33,1.1-0.54,1.65
					c-0.15,0.4-0.32,0.78-0.58,1.4c2.27-0.43,4.37,0.77,6.46-0.24c0.03-0.02,0.07-0.08,0.09-0.07c2.54,0.65,2.77-1.58,3.68-2.96
					c0.26-0.39,0.58-0.75,0.96-1.07c0.21-0.52,0.41-1.05,0.62-1.57c0.06,0.01,0.13,0.02,0.19,0.03c0.07,0.71,0.15,1.41,0.25,2.39
					c-0.04,0.05-0.27,0.36-0.51,0.69c-0.19-0.29-0.37-0.56-0.55-0.83c0.14,0.72-1.15,1.68,0.03,2.03c-0.21,0.94-0.42,1.85-0.63,2.77
					c-0.11,0.05-0.23,0.1-0.34,0.14c-0.16-0.35-0.32-0.7-0.49-1.05c-0.12,0.08-0.24,0.15-0.36,0.23c0.09,0.38,0.18,0.77,0.27,1.15
					l0.01-0.01c-0.38-0.01-0.77-0.07-1.13-0.01c-0.19,0.03-0.41,0.27-0.49,0.47c-0.08,0.19,0.01,0.45,0.02,0.67
					c-0.25-0.12-0.53-0.19-0.73-0.36c-0.25-0.22-0.42-0.53-0.63-0.8c-1.59,1.37-3.43-0.39-5.08,0.46c-0.07-0.3-0.12-0.52-0.19-0.8
					c-0.09,0.14-0.18,0.23-0.21,0.33c-0.27,0.84-0.57,1.3-1.62,0.72c-0.69-0.38-1.64-0.26-2.45-0.45c-0.52-0.12-1.04-0.34-1.51-0.6
					c-0.86-0.48-1.69-1.02-2.53-1.53C11.52,229.25,11.52,229.17,11.52,229.09z M25.81,230.7c-0.06,0.21-0.12,0.43-0.18,0.64
					c0.06,0.02,0.12,0.04,0.19,0.05c0.07-0.18,0.14-0.35,0.21-0.53c0.02-0.18,0.05-0.36,0.07-0.53c-0.04-0.01-0.09-0.02-0.13-0.03
					C25.91,230.44,25.86,230.57,25.81,230.7z"/>
        <path className="col1" d="M143.23,10.67c1.06-0.01,2.17-0.16,2.79,1.15c-1.07,0.52,0.08,0.97,0.11,1.52c0,0.07-0.01,0.13-0.04,0.19
					c-0.17-0.12-0.35-0.24-0.52-0.36c-0.06,0.2-0.12,0.4-0.18,0.6c0.08,0.09,0.16,0.17,0.24,0.26c0,0-0.01-0.02-0.01-0.02
					c-0.01,0.71-0.04,1.42-0.03,2.13c0.01,0.9-0.04,1.81,1.39,1.61c0.33-0.05,0.76,0.58,0.9,0.7c0.61-0.32,1.07-0.71,1.59-0.8
					c0.81-0.15,1.66-0.13,2.5-0.15c1.35-0.03,2.3,0.6,2.78,1.85c0.37,0.96,0.72,1.94,0.92,2.95c0.18,0.86,0.61,1.12,1.41,1.04
					c1.25-0.11,2.52-0.3,3.77-0.26c1.22,0.04,1.35,1.15,1.62,2.11c0.97,0,1.96,0.06,2.94-0.01c1.58-0.12,2.86,0.37,3.61,1.79
					c1.14,2.15,2.66,4.16,2.56,6.79c-0.01,0.21,0.33,0.61,0.51,0.61c0.44,0,0.92-0.09,1.3-0.31c0.21-0.12,0.23-0.56,0.34-0.86
					c0.1,0.15,0.21,0.31,0.35,0.51c0.35-1.26,0.66-2.51,2.11-3.12c-0.3-0.19-0.58-0.36-0.86-0.54c0.57-0.17,1.14-0.33,1.72-0.5
					c0.01,0.1,0.02,0.2,0.03,0.3c0.95,0.23,1.9,0.47,2.85,0.7c0,0-0.01-0.01-0.01-0.01c0.02,0.16,0.03,0.32,0.05,0.48
					c-0.05,0.62-0.11,1.25-0.16,1.87c-0.05,0.14-0.11,0.28-0.16,0.42c-0.07,0.67-0.13,1.35-0.2,2.02c0.13,0.11,0.25,0.22,0.38,0.33
					c0.12,0.13,0.24,0.26,0.36,0.38c0.02,0.15,0.04,0.3,0.06,0.46c0.05-0.02,0.1-0.03,0.16-0.05c-0.08-0.13-0.16-0.26-0.24-0.39
					c-0.06-0.17-0.13-0.35-0.19-0.52c-0.08-0.07-0.16-0.15-0.24-0.22c0.19-0.03,0.49-0.15,0.54-0.08c0.24,0.3,0.55,0.65,0.58,1.01
					c0.12,1.37,0.86,1.76,2.13,2.26c1.26,0.5,2.59,1.1,3.75,1.81c0.91,0.55,1.33,1.9,1.82,2.67c1.7-0.27,3.19-0.54,4.7-0.71
					c0.37-0.04,0.83,0.18,1.15,0.41c0.73,0.53,1.34,0.99,2.37,0.45c0.59-0.31,1.45-0.19,2.18-0.14c2.74,0.22,5.53,0.29,7.82,2.11
					c0.41,0.33,0.79,0.86,0.9,1.36c0.24,1.08,0.73,1.34,1.81,1.49c1.31,0.18,2.56,0.75,3.86,1.08c0.94,0.24,1.93,0.58,2.85,0.47
					c2.91-0.33,2.9-0.39,5.41,0.85c0.25,0.12,0.59,0.11,0.88,0.09c1.82-0.16,3.64-0.34,5.61-0.52c-0.28,1.22,0.51,1.3,1.33,1.49
					c1,0.24,2.04,0.48,2.93,0.97c2.24,1.23,2.68,4.14,0.86,5.89c-0.83,0.8-1.18,1.72-1.59,2.72c-0.26,0.64-0.78,1.21-1.28,1.73
					c-1.08,1.12-2.4,2.05-2.68,3.75c-0.11,0.65-0.35,1.29-0.77,1.9c0-0.7-0.11-1.42,0.03-2.09c0.15-0.74,0.46-1.48,0.82-2.15
					c0.34-0.63,0.85-1.18,1.43-1.97c-0.53,0.08-0.73,0.11-1.04,0.16c0.55-1.11,1.13-2.09,1.52-3.15c0.34-0.91,0.38-1.88,1.31-2.56
					c0.64-0.47,0.45-1.23-0.24-1.7c-0.29-0.2-0.65-0.3-0.99-0.41c-0.99-0.32-1.97-0.68-2.99-0.92c-0.42-0.1-0.91,0.08-1.37,0.14
					c0,0-0.08,0.04-0.08,0.04c-0.18-0.24-0.38-0.71-0.54-0.69c-0.93,0.08-1.85,0.27-2.78,0.43c0.02,0.1,0.03,0.19,0.05,0.29
					c0.43-0.02,0.86-0.04,1.28-0.06c0.22,0.25,0.43,0.49,0.65,0.74c-0.04,0.01-0.08,0.01-0.12,0.02c-1.62,0.69-3.02,0.62-4.31-0.82
					c-0.7-0.78-1.69-0.78-2.85-0.46c-1.42,0.4-2.99,0.26-4.49,0.28c-0.2,0-0.41-0.42-0.62-0.63c-0.36-0.36-0.73-0.72-1.3-1.29
					c-0.38,3.25-2.74,1.45-4.27,2.41c0-0.79,0.1-1.28-0.03-1.7c-0.15-0.5-0.42-1.25-0.77-1.33c-1-0.23-1.12-0.96-1.23-1.68
					c-0.15-1.03-0.77-1.4-1.69-1.52c-1.22-0.16-2.43-0.33-3.65-0.65c0.58,0,1.15,0,1.73,0c-1-0.5-1.97-0.78-3.04-0.19
					c-1.11,0.61-4.03-0.08-4.96-0.87c-0.36-0.31-1.07-0.33-1.61-0.28c-0.45,0.04-0.89,0.32-1.31,0.55c-0.93,0.52-1.49,0.29-2.28-0.5
					c-1.19-1.19-2.47-2.49-4.38-2.56l0.01,0.01c-1.3-1.04-2.79-1.63-4.43-1.81c-0.79-0.09-0.95-0.54-0.98-1.2
					c-0.04-0.97-0.12-1.95-0.2-3.09c-1.58,0.49-2.94,0.92-4.3,1.32c-0.33,0.1-0.79,0.26-1.02,0.12c-1.8-1.13-3.79-0.66-5.71-0.73
					c-0.27-0.01-0.54-0.04-1.05-0.08c0.36-1.26,0.7-2.42,1.03-3.59c0.08-0.28,0.23-0.66,0.11-0.86c-0.75-1.2-1.13-2.42-0.25-3.87
					c-0.62,0-1.07,0-1.54,0c-1.16,2.39-2.34,4.82-3.67,7.54c-1.22-3.54-2.34-6.8-3.52-10.22c-0.48-0.07-1.26-0.15-2.02-0.31
					c-1.53-0.32-2.43-1.27-2.68-2.83c-0.13-0.83-0.26-1.66-0.52-2.45c-0.09-0.29-0.51-0.59-0.83-0.65
					c-0.18-0.04-0.54,0.33-0.68,0.59c-0.41,0.75-0.75,1.55-1.17,2.44c-0.46-0.36-0.79-0.63-1.14-0.89
					c-1.17-0.85-2.09-1.99-3.58-2.47c-1.18-0.38-1.71-1.5-1.87-2.72c-0.1-0.83-0.16-1.66-0.25-2.62c-1.22,0.2-2.37,0.43-3.54,0.56
					c-1.78,0.2-3.57,0.3-5.35,0.5c-1.32,0.15-2.07,0.99-2.09,2.31c-0.04,3.05-0.04,6.11-0.03,9.16c0,0.3,0.1,0.62,0.22,0.9
					c0.55,1.21,0.45,1.54-0.83,1.98c-0.71,0.25-0.82,0.58-0.52,1.25c0.55,1.2,0.26,1.63-1.1,1.88c-0.32,0.06-0.79,0.22-0.87,0.44
					c-0.73,2.21-2.77,2.98-4.56,4.18c0.13,0.16,0.25,0.31,0.4,0.5c-1.91,1.3-4.02,1.92-6.29,2.34c-2.37,0.43-4.69,1.16-6.97,1.95
					c-1.22,0.42-1.89,1.45-1.56,2.61c2.78,0.74,5.43,1.45,8.09,2.16c0,0.12-0.01,0.24-0.01,0.36c-2.58,0.96-5.17,1.92-7.75,2.88
					c-0.29,0.11-0.61,0.21-0.82,0.42c-1.83,1.89-4.04,2.58-6.62,2.15c-3.41-0.56-6.83-1.12-10.25-1.63
					c-0.52-0.08-1.11,0.07-1.61,0.25c-1.45,0.54-2.88,1.15-4.56,1.83c-0.39-3.31-0.27-6.74-3.25-9.11c-0.53,0-1.36,0-2.23,0
					c-0.98,1.33-0.99,1.77,0.47,2.68c1.1,0.68,1.53,1.44,1.27,2.71c-0.22,1.04,0.06,2.01,0.65,2.95c1.32,2.1,1.63,4.35,0.89,6.77
					c-0.15,0.49,0.19,1.17,0.39,1.73c0.45,1.29,1.11,2.53,1.39,3.85c0.23,1.05-0.39,1.81-1.57,1.97c-1.81,0.26-3.61,0.73-5.42,0.78
					c-1.12,0.03-2.24-0.58-3.37-0.88c-0.29-0.08-0.6-0.13-0.89-0.11c-0.59,0.04-1.19,0.13-2.02,0.23c0.34-0.3,0.52-0.45,0.44-0.38
					c-0.42-0.83-0.86-1.7-1.3-2.56c-0.19,0.17-0.38,0.34-0.57,0.52c-0.08,0.07-0.24,0.14-0.24,0.21c-0.06,1.25-0.82,1.31-1.88,1.49
					c-0.96,0.16-1.86,0.95-2.67,1.61c-0.64,0.52-1.09,1.26-1.69,1.98c-1.86-1.61-3.84-3.01-4.96-5.28c-0.58-1.16-1.4-2.21-2.15-3.28
					c-0.16-0.23-0.55-0.46-0.79-0.42c-1.27,0.22-2.52,0.19-3.75,1.06c-1.2,0.84-2.92,0.96-4.4,1.42c-0.32,0.1-0.61,0.32-1,0.54
					c-0.22-0.51-0.43-1.13-0.75-1.7c-0.11-0.21-0.46-0.29-0.69-0.43c-0.1,0.23-0.28,0.48-0.27,0.7c0.06,0.83,0.19,1.65,0.34,2.88
					c-1.06-0.33-1.91-0.53-2.7-0.87c-1.5-0.65-3.17,0.05-3.5,1.57c-0.07,0.31,0.11,0.69,0.23,1.03c0.32,0.95,0.66,1.9,0.99,2.84
					c0.14,0.4,0.21,0.82,0.39,1.2c0.56,1.2,0.62,2.4-0.06,3.55c-0.18,0.3-0.64,0.44-0.97,0.65c0.01-0.06,0.01-0.11,0.01-0.17
					c0.09-0.07,0.17-0.15,0.26-0.22c0.98-1.32,1.05-2.34,0.24-3.69c-0.06-0.1-0.12-0.19-0.19-0.29c-0.08-0.08-0.16-0.16-0.24-0.24
					c-0.3-0.11-0.6-0.23-0.9-0.34c-0.02,0.08-0.05,0.17-0.07,0.25c0.28,0.07,0.56,0.15,0.84,0.22c0.07,0.09,0.15,0.17,0.22,0.26
					c0.07,0.1,0.13,0.19,0.2,0.29c0.5,1.17,0.37,2.3-0.25,3.38c-0.07,0.09-0.15,0.18-0.22,0.27c-0.61,0.36-1.21,0.73-1.91,1.15
					c0.14,0.49,0.29,1,0.56,1.93c0.26-0.47,0.37-0.67,0.48-0.87c2.37-0.57,4.28,0.07,5.7,2.12c0.14,0.2,0.53,0.3,0.81,0.3
					c2.18,0.02,4.23,0.6,5.86,2.01c2.69,2.33,6.23,3.09,9.04,5.18c0.15,0.11,0.38,0.11,0.57,0.15c0.11,0.15,0.23,0.3,0.35,0.45
					c-0.24,0.13-0.46,0.25-0.68,0.37c0.04,0.12,0.08,0.24,0.12,0.36c0.74-0.18,1.48-0.35,2.22-0.53c1.26,0.23,2.77,0.05,3.11,1.84
					c0,0.15,0,0.31-0.01,0.46c-0.18,0.96-0.37,1.91-0.57,2.98c0.45-0.13,0.63-0.18,0.82-0.23c0.76,0.26,1.52,0.53,2.48,0.86
					c1.4-0.75,3.83,1.11,3.81,3.26c0,0.42,0.07,0.96,0.32,1.26c1.42,1.69,1.49,3.22,0.12,4.93c-0.44,0.56-0.92,1.09-1.44,1.71
					c1.83,1.45,2.66,3.47,3.61,5.45c0.45,0.93,1.36,1.76,2.25,2.35c2.41,1.61,4.93,3.06,7.39,4.6c1.02,0.64,1.92,1.38,1.97,2.79
					c0.01,0.43,0.49,0.86,0.78,1.27c0.9,1.29,1.82,2.57,2.79,3.95c-0.46,0.22-0.69,0.41-0.94,0.44c-2.59,0.27-3.14,2.32-3.71,4.3
					c-0.09,0.32,0.18,0.89,0.45,1.17c1.11,1.12,2.3,2.16,3.44,3.25c2.22,2.12,2.73,4.85,2.72,7.91c-1.37,0.3-2.74,0.6-4.17,0.91
					c-0.6-1.41-0.34-3.41-2.51-3.51c-0.88,4.42-1.76,8.83-2.63,13.25c0.08,0.03,0.17,0.06,0.25,0.09c0.21-0.26,0.42-0.52,0.71-0.88
					c0.35,0.18,0.75,0.34,1.1,0.57c0.83,0.55,1.51,1.23,1.39,2.33c-0.13,1.18-1.03,1.75-2.02,1.93c-0.97,0.17-1.2,0.67-1.31,1.5
					c-0.4,3.13-0.67,6.29-1.29,9.38c-0.81,4-1.65,8.03-3.76,11.61c-0.72,1.22-1.76,2.26-2.59,3.43c-0.37,0.53-0.57,1.19-0.76,1.59
					c1.34,0.44,2.38,0.78,3.43,1.12c0.05,0.08,0.11,0.17,0.16,0.25c0,0.64,0,1.28,0,1.92c-0.18,0.31-0.37,0.62-0.55,0.93
					c-0.53-0.28-1.06-0.56-1.6-0.84c-0.12,0-0.24,0-0.36-0.01c0.06,0.08,0.11,0.15,0.17,0.23c-0.63-0.04-1.31,0.08-1.87-0.14
					c-1.33-0.51-2.72-1.01-3.87-1.82c-1.31-0.92-0.89-3.01,0.72-3.55c1.75-0.59,2.71-1.96,3.61-3.33c0.53-0.8,0.56-1.93,0.84-2.91
					c0.8-2.79,1.71-5.55,2.39-8.37c0.95-3.96,1.78-7.95,2.57-11.94c0.22-1.12,0.1-2.3,0.22-3.45c0.46-4.37,0.96-8.73,1.42-13.1
					c0.1-0.97,0.07-1.96,0.11-2.93c0.07-1.4,0.58-2.56,2-3.32c-0.46-0.37-0.84-0.68-1.27-1.02c-0.49,0.25-1,0.51-1.55,0.79
					c-0.12-2.32,0.54-4.78-2.12-6.07l0.01,0.02c-0.08-0.17-0.17-0.33-0.25-0.5l0.01,0.02c0-0.45,0-0.9,0-1.35c-0.09,0-0.17,0-0.26,0
					c0,0.45,0,0.9,0,1.34l0.02-0.02c-0.88-1.13-0.58-2.39-0.27-3.58c0.18-0.69-0.03-0.96-0.49-1.37c-0.63-0.56-1.27-1.21-1.62-1.95
					c-0.18-0.37,0.25-1.04,0.4-1.57c0.44,0.24,1.01,0.37,1.28,0.73c0.89,1.17,1.17,1.17,2-0.33c-0.51-0.4-1.04-0.8-1.57-1.22
					c-1.53-1.23-3.15-2.37-4.56-3.72c-0.81-0.77-1.26-1.91-1.91-2.86c-0.69-1.01-1.41-1.98-2.34-2.78c1,2.15,2,4.31,3,6.46
					c-0.08,0.05-0.15,0.1-0.23,0.15c-0.46-0.73-1.03-1.42-1.37-2.2c-0.7-1.64-1.58-3.07-3.01-4.22c-1.5-1.22-1.6-3.76-0.41-5.33
					c0.77-1.03,0.63-1.46-0.61-1.81c-1.29-0.37-1.65-2.33-0.39-3.6c-0.84,0-1.48,0.01-2.12,0c-1.96-0.03-2.65-0.66-2.84-2.61
					c-0.08-0.83-0.14-1.66-0.23-2.68c-1.9,0.43-3.45-0.36-4.8-1.53c-1.56,1.02-1.7,0.92-2.93-0.38c-0.44-0.47-0.74-0.85-0.69-1.45
					c0.07-0.94-0.35-1.56-1.11-2.08c-1.76-1.21-3.33-2.79-5.6-3.02c-0.83-0.08-1.67-0.02-2.5,0c-1.07,0.03-1.86-0.25-2.11-1.45
					c-0.09-0.42-0.43-0.78-0.81-1.41c-0.7,1.9-1.76,2.25-3.23,1.34c-0.1-0.06-0.29,0.02-0.44,0.02c-0.96,0-1.92,0-3.02,0
					c0.11-0.58,0.13-0.8,0.19-1.01c0.49-1.61,0.39-3.15-0.16-4.77c-0.74-2.19-0.49-2.46,1.77-3.02c0.23-0.06,0.46-0.12,0.96-0.24
					c-0.68-0.36-1.19-0.6-1.67-0.91c-0.4-0.25-0.75-0.58-1.12-0.88c0.4-0.48,0.8-0.96,1.2-1.44c0.34-0.02,0.67-0.04,1.15-0.07
					c-0.53-0.56-0.96-1.02-1.4-1.49c0-0.68,0-1.36,0-2.04c0,0,0,0.01,0,0.01c0.08-0.24,0.16-0.48,0.25-0.72l0,0.02
					c0.82-0.28,0.67-0.88,0.54-1.52c-0.42-1.97-0.04-2.38,1.92-2.19c0.21,0.02,0.49,0,0.65-0.12c1.96-1.56,4.02-1.17,6.21-0.39
					c1.24-1.27,2.84-1.03,4.54-0.62c0.03-0.32,0.06-0.58,0.08-0.84c0.05-1.01,0.58-1.46,1.59-1.56c0.86-0.09,1.7-0.45,2.56-0.55
					c1.41-0.17,2.85-0.4,4.25-0.3c1.3,0.09,1.73,1.29,2.28,2.35c0.92,1.77,2.05,3.44,3.25,5.4c0.96-0.82,1.66-1.41,2.36-2.02
					c0.21-0.18,0.4-0.54,0.6-0.54c1.73,0,3.5-0.14,5.17,0.2c0.86,0.17,1.38,0.22,1.98-0.38c0.81-0.81,3.26-0.7,3.97,0.15
					c0.05,0.06,0.12,0.12,0.14,0.19c0.13,0.41,0.2,1.16,0.38,1.18c0.88,0.12,1.79,0.06,3,0.06c-0.35-0.58-0.55-0.93-0.77-1.25
					c-0.81-1.21-1.47-2.4-0.78-3.97c0.7-1.58,0.83-3.22-0.39-4.7c-0.05-0.06-0.1-0.13-0.12-0.21c-0.71-2.29-1.29-4.58-2.79-6.6
					c-0.55-0.74-0.12-2.2-0.14-3.34c0,0-0.02,0.01-0.02,0.01c0.12-0.16,0.24-0.33,0.37-0.49c0.03-0.48,0.06-0.97,0.09-1.45
					c-0.03-0.11-0.07-0.23-0.1-0.34c-0.15-0.61-0.29-1.21-0.44-1.82c0.58-0.06,1.18-0.05,1.73-0.21c0.93-0.27,1.43,0.22,2.06,0.78
					c0.25,0.23,1.11-0.09,1.62-0.31c2.51-1.1,4.9-0.23,7.54,0.3c-0.46,1.18-0.87,2.22-1.24,3.18c0.6,1.23,1.2,2.46,1.68,3.44
					c1.88,0.32,3.65,0.66,5.43,0.92c1.12,0.17,2.27,0.22,3.4,0.32c1.03,0.09,2.1,0.03,3.08,0.3c1.9,0.52,3.54,0.34,4.88-1.27
					c-1.51-0.68-1.22-2.04-1.01-3.28c0.4-2.47,1.94-4.17,4.06-5.32c2.16-1.17,4.38-2.17,6.89-2.55c1.45-0.22,2.92-0.77,4.2-1.49
					c2.55-1.45,4.98-3.12,7.13-4.48c0-2.54-0.02-4.67,0-6.8c0.02-2.31,0.07-4.62,0.15-6.93c0.02-0.43,0.29-0.84,0.45-1.27
					c0.06,0.21,0.12,0.42,0.18,0.63c0.18-0.16,0.35-0.32,0.53-0.48c0.24-0.36,0.49-0.73,0.73-1.09c0,0-0.01,0.01-0.01,0.01
					c0.08-0.08,0.16-0.16,0.25-0.25c0,0-0.01,0.01-0.01,0.01c0.08-0.08,0.16-0.16,0.24-0.24c0,0-0.01,0.01-0.01,0.01
					c0.08-0.08,0.16-0.16,0.24-0.24c0,0-0.01,0.01-0.01,0.01c0.24-0.16,0.48-0.32,0.72-0.48c0,0,0,0,0,0
					c2.3-0.25,4.59-0.51,6.89-0.76c0.52-0.08,1.03-0.15,1.61-0.24c-0.16-0.19-0.25-0.3-0.34-0.41
					C140.54,11.5,141.88,11.09,143.23,10.67z M68.29,99.76c-0.07-0.08-0.15-0.17-0.22-0.25c0.23-0.56,0.47-1.13,0.77-1.85
					c0.03,0.05-0.09-0.13-0.17-0.27c-0.4,0.27-0.78,0.54-1.21,0.83c0.18,0.42,0.37,0.88,0.56,1.34c0.08,0.07,0.17,0.15,0.25,0.22
					c0.09,0.06,0.18,0.12,0.27,0.17c0.01,0,0.05-0.05,0.07-0.08C68.5,99.83,68.39,99.79,68.29,99.76z M229.32,62.06
					c0.1-0.09,0.19-0.18,0.29-0.27c-0.15,0.03-0.3,0.06-0.46,0.09c-0.07,0.07-0.13,0.14-0.2,0.21
					C229.08,62.08,229.2,62.07,229.32,62.06z M80.27,126.56c-0.05-0.03-0.11-0.07-0.16-0.1c0.03,0.06,0.05,0.12,0.08,0.18
					c0.65,0.9,1.3,1.8,1.86,2.56C82.33,127.86,82.27,127.76,80.27,126.56z M203.59,46.12c0.06,0.07,0.12,0.13,0.18,0.2
					c0.04-0.04,0.07-0.07,0.11-0.11C203.78,46.18,203.68,46.15,203.59,46.12c-1.37-0.36-2.73-0.72-4.09-1.08
					c-0.03,0.17-0.06,0.35-0.1,0.52C200.8,45.75,202.19,45.94,203.59,46.12z M62.11,91.17c-1.54-0.35-2.89-0.65-4.32-0.97
					c1.06,1.6,2.08,2.04,3.74,1.61C61.78,91.75,61.94,91.37,62.11,91.17z M77.91,67.2c0,0.12-0.01,0.24-0.01,0.36
					c1.84-0.19,3.67-0.37,5.51-0.56c-0.01-0.12-0.01-0.25-0.02-0.37c-1.77,0-3.54,0-5.3,0c-0.01,0.04-0.02,0.08-0.03,0.12
					c0.66,0.1,1.32,0.21,1.98,0.31c0,0.04-0.01,0.09-0.01,0.13C79.32,67.2,78.62,67.2,77.91,67.2z M102.96,50
					c2.29,0.74,3.84-0.65,5.41-2C106.48,48.42,105.19,50.49,102.96,50z M84.9,123.03c-1.21-1.1-2.26-1.76-3.98-1.31
					C82.45,121.88,83.21,123.34,84.9,123.03z M164.95,26.19c-2.99-0.57-3.02-0.55-3.38,1.7C162.1,26.08,163.5,26.1,164.95,26.19z
					 M81.22,41.16c0.05,0.16,0.09,0.31,0.14,0.47c0.95-0.25,1.91-0.51,2.86-0.76c-0.03-0.13-0.07-0.27-0.1-0.4
					C83.15,40.7,82.18,40.93,81.22,41.16z M38.71,74.8c0.03,0.06,0.05,0.13,0.08,0.19c0.34-0.53,0.79-1.02,0.98-1.6
					c0.1-0.31-0.25-0.76-0.39-1.15C39.12,73.21,38.91,74.01,38.71,74.8z M68.45,106.57c0.07-1.06,0.14-2.2,0.22-3.33
					C67.74,104.45,67.69,105.72,68.45,106.57z M214.34,49.78c0,0.05,0,0.09-0.01,0.14c0.38,0,0.76,0,1.13,0c0-0.05,0-0.09,0-0.14
					C215.09,49.78,214.72,49.78,214.34,49.78z M46.8,62.22c0,0.05,0.01,0.1,0.01,0.15c0.17-0.01,0.34-0.03,0.51-0.04
					c0-0.05-0.01-0.09-0.01-0.14C47.14,62.2,46.97,62.21,46.8,62.22z M38.76,72.09c-0.08-0.03-0.15-0.06-0.21-0.06
					c-0.03,0-0.06,0.04-0.09,0.06c0.05,0.03,0.1,0.09,0.16,0.1C38.65,72.19,38.7,72.13,38.76,72.09z M83.95,66.66l0.05-0.06
					l-0.08-0.01L83.95,66.66z M80.78,40.91c-0.02,0.03-0.03,0.06-0.05,0.08c0.05,0,0.11,0.01,0.16,0.01
					c-0.01-0.03-0.02-0.07-0.04-0.09C80.85,40.9,80.81,40.91,80.78,40.91z"/>
        <path className="col1" d="M170.54,184.79c-0.35-0.11-0.98,0.18-0.86-0.62c0.01-0.1-0.25-0.35-0.36-0.33
					c-0.19,0.03-0.36,0.21-0.54,0.32c-0.48,0.29-0.96,0.58-1.45,0.86c-0.23,0.13-0.5,0.22-0.71,0.38c-0.86,0.65-1.79,1.24-2.53,2
					c-1.51,1.55-3.6,1.94-5.4,2.89c-0.35,0.18-0.97,0.73-1.35-0.16c-0.03-0.07-1.2,0.1-1.26,0.32c-0.42,1.58-1.43,2.42-2.75,2.65
					c0.13,0.92,0.36,1.74,0.36,2.55c-0.01,3.07-0.1,6.14-0.16,9.22c-0.03,1.54-0.26,1.75-1.75,1.99c-0.57,0.09-1.14,0.34-1.65,0.63
					c-0.55,0.31-1.03,0.76-1.53,1.15c-2.66,2.06-4.37,2.04-7.05-0.09c-0.28-0.22-0.57-0.43-0.84-0.67
					c-0.85-0.76-1.53-0.71-2.33,0.18c-1.37,1.53-2.85,1.87-4.37,1.04c-0.42-0.23-0.9-0.81-0.91-1.23c-0.01-1.21-0.63-1.41-1.61-1.28
					c0,0,0.02,0.01,0.02,0.01c-0.23,0-0.46-0.01-0.7-0.01c-0.11,0.01-0.21,0.02-0.32,0.04c-0.15-0.08-0.3-0.17-0.44-0.25
					c-0.28-0.61-0.56-1.22-0.83-1.84c-0.09,0.03-0.19,0.05-0.28,0.08c-0.05,0.17-0.09,0.34-0.14,0.51
					c-0.06-0.06-0.13-0.13-0.19-0.19c-0.32-0.61-0.79-1.61-1.44-1.01c-0.67,0.62-1.25,0.44-1.9,0.52c-0.35-0.48-0.71-0.96-1.06-1.44
					c-0.12-0.08-0.25-0.17-0.37-0.25l0.01,0.01c-0.16-0.16-0.33-0.33-0.49-0.49l0.01,0c-0.32-0.16-0.63-0.35-0.96-0.48
					c-0.94-0.35-1.88-0.67-2.82-1c-0.08-0.08-0.16-0.16-0.24-0.24c-0.08-0.08-0.16-0.16-0.25-0.23c-0.09-0.04-0.17-0.07-0.26-0.11
					c0.04,0.08,0.08,0.16,0.12,0.24c0.08,0.08,0.16,0.16,0.24,0.24c0.08,0.09,0.15,0.17,0.22,0.26c0.89,1.34,2.43,0.96,3.67,1.37
					c-0.07,0.16-0.14,0.32-0.21,0.47c-1.61-0.07-3.17-0.22-4.3-1.63c-0.24-0.3-0.74-0.46-1.15-0.54c-0.93-0.18-1.87-0.27-2.88-0.41
					c-0.02,0.2,0.01,0.49-0.08,0.72c-0.26,0.66-0.4,1.64-0.89,1.89c-0.87,0.44-1.98,0.88-2.91-0.15c-0.17-0.18-0.59-0.16-0.89-0.18
					c-1.87-0.1-3.74-0.18-5.61-0.26c0,0-0.07,0.04-0.07,0.04c-0.32,0-0.63,0-0.95,0c-0.03-0.06-0.07-0.07-0.13-0.04
					c-0.17-0.06-0.34-0.12-0.51-0.18c-0.17-0.08-0.33-0.16-0.5-0.24c0,0.15,0,0.3,0,0.45c-2.62,0.49-4.69-0.3-6.22-2.53
					c-0.4-0.59-1.05-0.7-1.76-0.36c-1.42,0.69-2.77,0.76-4.13-0.33c-0.84-0.68-1.6-0.06-2.24,0.58l0.02,0.02
					c-0.08-0.7-0.17-1.41-0.22-2.11c-0.07-0.9-0.49-1.38-1.43-1.57c-1.14-0.23-2.23-0.72-3.37-1c-0.71-0.17-1.47-0.15-2.21-0.22
					c-0.37-0.63-0.77-1.32-1.04-1.76c-0.58,0.43-1.01,0.98-1.54,1.08c-0.99,0.19-2.4-1.03-2.94-2.27c-0.52-1.19-0.27-2.03,1.14-2.41
					c0.04,0.26,0.08,0.58,0.16,1.09c0.62-0.23,1.15-0.43,1.68-0.64c0.21-0.09,0.42-0.17,0.63-0.26c0.02,0.03,0.04,0.09,0.06,0.1
					c2.43,0.4,4.44,1.84,6.7,2.65c0.7,0.25,1.53,0.11,2.23,0.36c0.72,0.26,1.41,0.7,2.01,1.19c0.65,0.54,1.24,1.2,1.74,1.88
					c0.66,0.9,1.28,0.79,2.13,0.25c1.71-1.1,2.8-0.9,4.18,0.64c0.53,0.59,1.06,1.18,1.61,1.76c0.63,0.67,1.31,0.71,2.14,0.3
					c0.81-0.41,1.68-0.71,2.56-0.93c0.25-0.06,0.77,0.22,0.89,0.47c0.36,0.8,0.93,0.95,1.72,0.92c1.34-0.05,2.69-0.01,3.93-0.01
					c0.44-1.51,0.85-2.9,1.3-4.43c0.93,0.33,1.89,0.48,2.61,0.98c1.31,0.92,2.68,1.44,4.28,1.54c0.56,0.03,1.23,0.3,1.61,0.7
					c1.01,1.06,2.3,1.33,3.62,1.64c0.49,0.11,1.1,0.3,1.36,0.67c0.73,1.05,1.72,1.11,2.82,1.1c0.67,0,1.34,0.15,2.02,0.23
					c-0.63,0.11-1.26,0.23-1.99,0.36c0.77,0.09,1.54,0,2.05,0.31c0.36,0.22,0.37,1.01,0.53,1.54c0.05-0.01,0.1-0.02,0.15-0.02
					c0-0.67,0-1.33,0-2c0.89,0.12,1.78,0.25,2.98,0.41c-0.86,0.49-1.45,0.83-2.31,1.31c1.89,0.83,4.12,1.05,4.07,3.98
					c0.69-0.54,1.13-0.82,1.5-1.18c1.51-1.47,3.01-1.57,4.72-0.36c0.94,0.66,1.87,1.36,2.91,1.82c0.5,0.22,1.32,0.08,1.83-0.2
					c1.56-0.85,3.05-1.82,4.54-2.79c0.2-0.13,0.34-0.51,0.35-0.78c0.03-2.83,0.06-5.67,0.01-8.5c-0.01-0.46-0.32-0.96-0.59-1.38
					c-0.82-1.31,0.13-3.32,1.67-3.31c0.88,0,1.13-0.37,1.43-1.08c0.27-0.65,0.73-1.53,1.29-1.69c1.51-0.43,2.98-0.91,4.46-1.46
					c1.49-0.56,2.64-1.39,3.74-2.42c0.76-0.71,1.4-1.57,2.24-2.15c1.06-0.74,2.23-1.36,3.42-1.85c1.16-0.48,2.24-0.14,2.85,1.02
					c0.43,0.81,0.99,0.84,1.82,0.84c1.94,0.01,3.89,0.18,5.82,0.4c0.48,0.05,0.89,0.6,1.38,0.79c0.26,0.1,0.75,0.04,0.94-0.15
					c1.38-1.34,3.59-1.26,4.52,0.38c0.54,0.94,1.19,1.17,2.12,1.11c0.2-0.01,0.41,0.04,0.6,0c1.6-0.4,2.52,0.18,2.85,1.81
					c0.05,0.24,0.46,0.52,0.74,0.57c0.74,0.13,1.5,0.16,2.25,0.2c1.98,0.11,3.4,1.13,4.59,2.63c0.23,0.29,0.93,0.51,1.24,0.36
					c1.35-0.64,2.65-1.07,4.12-0.34c0.2,0.1,0.62-0.18,0.91-0.34c0.86-0.47,1.69-1.14,2.57-0.45c0.7-0.49,1.3-1.09,2.02-1.37
					c0.75-0.29,1.62-0.27,2.09-0.34c0.17-1.51,0.12-2.9,0.55-4.12c0.2-0.58,1.27-1.06,2.01-1.21c1.22-0.24,2.03-0.59,2.08-2.02
					c0.04-1.1,0.94-1.5,1.92-1.61c0.75-0.08,1.2-0.31,1.42-1.14c0.13-0.49,0.69-0.97,1.18-1.22c1.61-0.83,3.27-1.55,4.86-2.28
					c-0.03-0.3-0.18-0.71-0.11-1.08c0.18-0.88,0.33-1.81,0.75-2.58c0.28-0.52,0.95-0.89,1.52-1.19c0.95-0.5,1.11-1.23,0.74-2.1
					c-0.35-0.84-0.87-1.31-1.96-0.93c-1.52,0.52-3.12,0.82-4.69,1.21c-0.07-0.06-0.13-0.13-0.2-0.19c-0.33-0.42-0.66-0.84-1-1.26
					c-0.44-0.23-0.86-0.58-1.33-0.68c-1.15-0.24-2.54-0.04-2.58-1.93c-0.07,2,0.02,2.06,1.75,2.21c0.69,0.06,1.37,0.33,2.06,0.5
					c-0.18,0.14-0.37,0.41-0.55,0.4c-0.43-0.01-0.87-0.27-1.28-0.22c-1.57,0.17-2.72-0.14-2.82-2.01c-0.01-0.23-0.27-0.56-0.48-0.64
					c-1.5-0.55-1.21-1.71-1.03-2.85c0.04-0.29,0.14-0.66,0.01-0.87c-1.11-1.87-1.11-1.96,0.75-3.16c-0.66-1.47-0.07-2.56,1.26-3.38
					c-0.47-1.01-0.83-1.78-2.19-1.71c-1.56,0.08-2.22-0.92-2.47-3.15c-1.26-0.42-2.17-2.09-1.9-3.48c0.24-1.22,1.46-1.92,2.96-1.86
					c0.5,0.02,1.16-0.23,1.47-0.6c0.61-0.72,1.12-1.43,2.21-1.39c0.21,0.01,0.52-0.33,0.64-0.58c0.5-1.09,0.12-2.67-0.79-3.47
					c-1.71-1.5-1.71-1.5-2.17-3.48c-1.72-0.21-2.76-0.8-2.58-3.72c0.12-0.01,0.23-0.01,0.35-0.02c0.14,0.59,0.27,1.19,0.48,2.07
					c0.13-1.38,0.24-2.45,0.33-3.42c0.92-0.22,1.65-0.39,2.38-0.57c0.08,0.08,0.16,0.15,0.25,0.23c-0.21,0.11-0.41,0.24-0.63,0.32
					c-1.88,0.64-1.91,0.68-1.65,2.75c0.29,0.26,0.57,0.52,0.86,0.78c0.24,0,0.48,0,0.71,0c0.88-0.06,0.75,0.68,0.94,1.17
					c0.02,0.32,0.03,0.64,0.05,0.96c0.03,0.17,0.06,0.34,0.09,0.5c0.12,0.16,0.24,0.33,0.36,0.49l-0.01-0.01
					c0.16,0.12,0.33,0.25,0.49,0.37c0.55,0.6,1.09,1.21,1.64,1.81c0.05,1.29,0.18,2.59,0.11,3.87c-0.04,0.8-0.61,1.16-1.52,1.16
					c-0.49,0-1.13,0.34-1.44,0.74c-0.71,0.91-1.33,1.61-2.67,1.19c-0.73-0.23-1.67,0.01-1.79,0.93c-0.11,0.79-0.15,1.76,1.14,1.98
					c0.32,0.06,0.54,0.89,0.73,1.39c0.25,0.64,0.42,1.3,0.63,1.96c0.92,0.07,1.85,0.13,2.77,0.2c0-0.11,0.01-0.22,0.01-0.33
					c-0.89,0-1.78,0-2.67,0c0.98-0.12,1.97-0.36,2.94-0.31c0.43,0.02,1.02,0.54,1.2,0.98c0.3,0.74,0.34,1.58,0.48,2.38
					c0.12,0.02,0.24,0.04,0.36,0.05c-0.35,0.3-0.67,0.65-1.05,0.88c-0.78,0.49-1.09,1.15-1.17,2.07c-0.04,0.46-0.53,0.9-0.85,1.32
					c-0.22,0.29-0.51,0.52-0.76,0.78c0.35,0.24,0.67,0.51,1.04,0.69c0.19,0.09,0.45,0.02,0.82,0.02c-0.03,0.37,0.07,0.83-0.11,1.11
					c-0.59,0.94,0,1.39,0.5,2.12c0.88,1.3,2.11,1.68,3.45,2.1c0.65,0.2,1.27,0.68,1.75,1.18c0.62,0.65,1.1,0.63,1.66,0.01
					c0,0-0.02,0-0.02,0c0.28-0.08,0.57-0.17,0.85-0.25c0.11-0.04,0.23-0.07,0.34-0.11c0.28-0.12,0.57-0.25,0.85-0.37
					c0.2-0.23,0.4-0.46,0.59-0.7c0.07-0.03,0.15-0.04,0.23-0.03c0.13,0.12,0.27,0.25,0.4,0.37c0.11-0.19,0.22-0.39,0.33-0.58
					c0.39,0,0.79,0,1.18,0c-0.26,0.17-0.53,0.34-0.79,0.51c0.02,0.09,0.05,0.17,0.07,0.26c0.5-0.07,1-0.14,1.5-0.21
					c0.07,0.28,0.15,0.55,0.22,0.83c-0.13,0.1-0.26,0.2-0.38,0.3c0.03,0.03,0.05,0.05,0.08,0.08c0.1-0.13,0.2-0.25,0.3-0.38
					c1.51,1.7,1.23,3.97-0.64,5.08c-1.22,0.72-1.44,1.67-0.84,2.93c0.17,0.35,0.06,0.91-0.09,1.3c-0.12,0.29-0.49,0.53-0.8,0.68
					c-1.72,0.87-3.46,1.69-5.18,2.55c-0.23,0.11-0.57,0.3-0.59,0.48c-0.21,1.85-1.56,1.98-2.97,2.05c-0.08,0-0.15,0.03-0.05,0.01
					c-0.54,1.02-0.82,2.19-1.55,2.76c-0.78,0.6-2,0.64-3.11,0.95c0.08,0.29,0.16,0.58,0.23,0.88l0.01,0
					c-0.03,0.52-0.16,1.07-0.06,1.56c0.14,0.64,0.47,1.24,0.82,2.12c-1.42,0.29-2.67,0.66-3.94,0.76c-0.96,0.08-1.56,0.35-1.86,1.3
					c-0.23,0.74-0.66,1.09-1.39,0.4c-0.27-0.25-0.81-0.6-0.97-0.5c-0.41,0.25-0.7,0.72-0.99,1.14c-0.09,0.14-0.04,0.37-0.05,0.5
					c-0.55,0.19-1.02,0.35-1.49,0.51c-0.3-0.18-0.6-0.37-0.9-0.55c-0.07,0.13-0.14,0.27-0.22,0.4c0.28,0.14,0.57,0.27,0.85,0.41
					c0.51,0.05,1.03,0.1,1.54,0.16c-0.24,0.28-0.47,0.57-0.71,0.85c-0.84-0.14-1.87,0.17-2.23-1.07c-0.04-0.12-0.79-0.04-1.21-0.04
					c-0.9-0.01-1.8,0.06-2.68-0.06c-0.36-0.05-0.78-0.44-0.97-0.79c-1.22-2.25-2.37-2.87-4.89-2.51c-0.67,0.1-1.32,0.32-1.99,0.35
					c-0.47,0.02-1.17-0.02-1.36-0.31c-0.2-0.3,0.07-0.92,0.18-1.4c0.05-0.22,0.19-0.41,0.39-0.8c-1.8,0-3.43,0-5.07,0
					c-0.24-0.17-0.54-0.54-0.7-0.49c-0.45,0.15-0.85,0.47-1.27,0.72c-1.94-0.17-3.87-0.35-5.81-0.52c-0.2-0.55-0.4-1.1-0.61-1.65
					c0,0,0,0,0,0c0.29-0.18,0.57-0.36,0.86-0.55c-0.01-0.12-0.02-0.23-0.03-0.35c-1.79-0.09-3.58-0.25-5.37-0.25
					c-1.08,0-1.8-0.33-2.01-1.41c-0.23-1.22-0.99-1.31-1.8-0.75c-1.91,1.34-3.74,2.78-5.61,4.19c-0.12,0.05-0.24,0.1-0.37,0.15
					c0.01,0.05,0.03,0.1,0.04,0.14c0.16-0.03,0.32-0.07,0.48-0.1c1.34-0.82,2.67-1.68,4.04-2.45
					C169.48,182.78,169.49,182.81,170.54,184.79z M215.8,132.1c-0.01-0.03-0.02-0.06-0.04-0.09c-0.01-0.01-0.04-0.01-0.06-0.02
					c0.04,0.03,0.08,0.06,0.12,0.1c0.06,0.09,0.12,0.19,0.18,0.28C215.92,132.28,215.86,132.19,215.8,132.1z M183.43,183.54
					c-0.23,0.15-0.46,0.3-0.68,0.45c0.07,0.08,0.14,0.16,0.22,0.23c0.17-0.21,0.35-0.42,0.52-0.63c0.23,0,0.45,0.01,0.68,0.01
					c1.41,2.28,3.38,3.18,6.13,2.41c-0.53-0.02-0.96-0.06-1.39-0.06c-1.41,0.01-2.67-0.18-3.41-1.65c-0.19-0.38-0.82-0.54-1.25-0.79
					C183.97,183.53,183.7,183.54,183.43,183.54z M84.37,193.12c-0.08-0.03-0.16-0.05-0.24-0.08c0.06,0.04,0.12,0.09,0.18,0.13
					c1.55,0.57,3.1,1.14,4.69,1.72c-0.31-0.97-1.14-1.31-2.04-1.47C86.11,193.27,85.23,193.22,84.37,193.12z M204.25,191.38
					c-1.82-1.26-2.97,0.76-4.59,0.53c0.03,0.13,0.05,0.25,0.08,0.38C201.24,191.99,202.75,191.69,204.25,191.38z M181.17,186.23
					c0.02,0.16,0.03,0.33,0.05,0.49c0.84-0.09,1.68-0.17,2.52-0.26c-0.01-0.13-0.02-0.27-0.03-0.4
					C182.87,186.12,182.02,186.18,181.17,186.23z M117.93,199.89c0.01-0.07,0.02-0.15,0.03-0.22c-0.45,0-0.9,0-1.35,0
					c0,0.05,0,0.11,0,0.16C117.04,199.85,117.48,199.87,117.93,199.89z M214.41,185.4c-0.08-0.01-0.17-0.01-0.25-0.02
					c0.01,0.16,0.03,0.32,0.04,0.49c0.05,0,0.11,0,0.16,0C214.38,185.71,214.4,185.55,214.41,185.4z M89.23,198.39l0.04-0.07
					l-0.06,0L89.23,198.39z M199.1,191.85l0.08,0.04l0.01-0.07L199.1,191.85z M118.42,199.94c-0.01,0.02-0.04,0.05-0.04,0.06
					c0.01,0.03,0.04,0.05,0.07,0.07c0.01-0.04,0.03-0.08,0.04-0.13C118.47,199.94,118.44,199.94,118.42,199.94z"/>
        <path className="col1" d="M224.29,207.54c0.35,1.28,0.73,2.55,1.03,3.84c0.44,1.96-0.82,3.36-1.68,4.92
					c0.17,0.04,0.36,0.09,0.71,0.19c-1.26,1.02-1,2.12-0.4,3.26c-0.85,0.8-0.47,1.78-0.33,2.74c0.19-0.43,0.38-0.87,0.57-1.3
					c0.54,1.43-0.66,2.36-1.06,3.37c0.37,0.44,0.64,0.77,0.92,1.1c-0.06,0.06-0.13,0.12-0.19,0.18c-0.19-0.12-0.37-0.24-0.35-0.23
					c-0.72,2.09-1.46,4.22-2.19,6.34c-0.17,0.06-0.33,0.12-0.5,0.18c-0.62-0.55-1.2-1.16-1.89-1.62c-0.53-0.35-1.18-0.54-1.8-0.75
					c-0.78-0.26-1.68-0.32-2.35-0.75c-0.76-0.48-1.49-1.17-1.94-1.94c-0.31-0.54-0.12-1.36-0.15-2.13c-0.68-0.13-1.4-0.27-2.28-0.44
					c0.71-1.34,1.35-2.56,1.99-3.77c-0.09-0.04-0.18-0.08-0.26-0.13c-0.1,0.16-0.19,0.31-0.18,0.3c-0.86-0.26-1.67-0.5-2.74-0.81
					c0.4-1.1,0.88-2.44,1.45-4.02c-0.09-0.06-0.41-0.31-0.77-0.5c-0.79-0.41-1.48-0.8-1.73-1.82c-0.22-0.87-0.32-1.49,0.57-2
					c0.65-0.37,0.55-0.88,0.01-1.36c-0.45,0.22-0.9,0.44-1.49,0.72c0.23-1.26-0.01-2.47,1.27-3.15c0.28-0.15,0.42-0.77,0.42-1.17
					c-0.01-1.34,0.34-2.15,1.71-2.91c1.23-0.69,2.43-1.09,3.75-1.47c1.05-0.3,1.57-1.27,1.92-2.29c0.08-0.24,0.46-0.52,0.71-0.53
					c0.67,0,1.34,0.13,2.16,0.23c-0.23-1.68-0.29-3.39-0.74-4.99c-0.49-1.75,0.98-2,1.8-2.62c0.38-0.28,1.3,0.15,1.97,0.25
					c-0.02,0.1-0.03,0.21-0.05,0.31c-0.31,0.11-0.65,0.17-0.92,0.35c-0.67,0.44-1.14,0.84-0.85,1.89c0.34,1.23,0.92,2.5,0.35,3.9
					c-0.16,0.39,0.25,1.01,0.36,1.53c0.09,0.46,0.12,0.93,0.21,1.57c-1.03-0.1-1.92-0.21-2.81-0.24c-0.35-0.01-0.9,0.06-1.04,0.29
					c-0.81,1.32-2.28,1.51-3.45,2.22c-0.47,0.29-0.88,0.69-1.3,1.05c-0.46,0.39-0.91,0.8-1.27,1.12c-0.32,0.92-0.62,1.78-0.98,2.81
					c0.49,0.6,1.12,1.36,1.8,2.2c-0.76,0.64-1.51,1.27-2.32,1.95c1.68,0.93,3.25,1.8,5.04,2.79c-1.53,0.57-1.34,1.58-1.23,2.66
					c0.1,1.01-0.09,2.05-0.03,3.07c0.02,0.33,0.38,0.89,0.64,0.93c1.21,0.18,2.44,0.22,4.1,0.34c-1.38,1.17-2.56,2.17-3.85,3.26
					c0.3,1.19,1.63,1.19,2.64,1.56c1.02,0.37,2.11,0.55,3.2,0.82c0.32-0.69,0.64-1.37,1.05-2.23c-0.73-0.11-1.36-0.2-2.29-0.34
					c0.76-0.55,1.28-0.95,1.82-1.32c1.22-0.82,1.9-1.88,1.42-3.39c-0.99-3.12,0.41-5.74,1.69-8.41c0.22-0.47,0.34-1.06,0.29-1.58
					c-0.13-1.35-0.39-2.69-0.59-4.03C224,207.55,224.14,207.55,224.29,207.54z M222.79,225.06c-0.11,0.15-0.17,0.23-0.23,0.32
					c0.08,0.05,0.17,0.14,0.24,0.12c0.07-0.01,0.16-0.13,0.17-0.21C222.99,225.23,222.88,225.16,222.79,225.06z"/>
        <path className="col1" d="M27.8,195.57c1.25,0.48,2.51,0.96,3.88,1.48c0.02-0.08,0.19-0.57,0.17-0.58c-1.08-0.42-0.56-1.44-0.81-2.15
					c-0.58-1.65-1.48-3.08-2.67-4.39c-0.78-0.85-2.22-1.16-2.29-2.64c-0.02-0.52-0.11-1.04-0.2-1.56c-0.09-0.53-0.23-1.06-0.39-1.76
					c0.7-0.69,1.56-1.3,2.81-1.09c0.17,0.03,0.38-0.22,0.58-0.26c0.35-0.07,0.72-0.07,1.08-0.1c-0.06,0.44-0.02,0.92-0.21,1.3
					c-0.16,0.32-0.57,0.51-0.95,0.82c-0.19-0.05-0.55-0.13-0.91-0.22c0.06-0.08,0.12-0.16,0.18-0.24c-0.13,0-0.26,0-0.38,0.01
					c-0.3,0.15-0.6,0.29-0.99,0.48c2.06,1.28,2.31,1.75,1.51,3.14c0.63-0.24,1.07-0.41,1.75-0.67c-0.25,0.76-0.46,1.39-0.56,1.71
					c0.98,1.23,2.05,2.26,2.73,3.51c0.86,1.59,1.58,3.32,1.51,5.2c-0.01,0.36-0.25,0.78-0.52,1.04c-0.97,0.9-1.99,1.74-3.04,2.64
					c-0.46-0.33-1.12-0.81-1.88-1.36c0.26-0.38,0.5-0.72,0.7-1.02c-0.96-0.51-1.92-1.01-2.89-1.52c-0.29-0.14-0.58-0.27-0.88-0.41
					c-0.04,0.08-0.08,0.16-0.12,0.24c0.26,0.14,0.52,0.28,0.77,0.42c-0.94-0.03-1.87-0.05-2.81-0.08c-0.09-0.12-0.17-0.24-0.26-0.36
					c-0.07,0.08-0.14,0.17-0.21,0.25c0.16,0.03,0.32,0.06,0.48,0.09c-0.54,0.34-1.77,0.26-2.06-0.16c-0.71-1.03-1.52-2.03-1.3-3.42
					l-0.01,0.01c0.41-1.26,1.61-0.79,2.45-1.11c0.29-0.11,0.65-0.06,0.91-0.21c0.18-0.1,0.35-0.45,0.31-0.64
					c-0.04-0.19-0.33-0.43-0.54-0.46c-0.82-0.13-1.64-0.2-2.47-0.29c-0.12-0.01-0.27,0.02-0.35-0.04c-1.52-1.19-3.62-1.85-3.87-4.21
					c-0.03-0.24-0.01-0.51,0.05-0.74c0.27-1.05-0.31-2.15-1.34-2.46c-0.79-0.24-1.38-0.61-1.4-1.54c0-0.12-0.03-0.27-0.1-0.34
					c-1.16-0.98-0.48-2.11-0.11-3.08c0.23-0.6,0.9-1.11,1.49-1.46c1.29-0.77,2.69-1.03,4.52-0.39c-1.75,1.12-3.29,2.1-4.79,3.06
					c0.39,1.32,1.08,2.03,2.36,2.18c0.25,0.03,0.58,0.5,0.64,0.81c0.17,0.93,0.23,1.89,0.34,2.84c0.12,1.05,0.63,1.71,1.69,2.05
					c0.55,0.17,0.96,0.76,1.5,0.98c0.55,0.23,1.21,0.4,1.78,0.31c1.13-0.19,1.91-0.1,2.17,1.23c0.08,0.4,0.48,0.8,0.83,1.08
					c0.58,0.47,1.24,0.84,1.91,1.28c-1.02,0.58-1.93,1.15-2.89,1.61c-0.36,0.17-0.85,0.06-1.27,0.13c-0.52,0.09-1.04,0.23-1.64,0.37
					c0.2,0.29,0.27,0.41,0.28,0.4c1.59-0.71,3.06-0.1,4.55,0.38c0.19,0.06,0.44-0.05,0.66-0.08c0,0-0.01-0.02-0.01-0.02
					c0.17,0,0.33,0,0.5,0L27.8,195.57z"/>
        <path className="col1" d="M27.31,157.46c-0.29,0.1-0.74,0.38-0.84,0.28c-0.7-0.66-2.02-0.04-2.48-1.25
					c-0.06-0.15-0.98-0.06-1.44,0.09c-0.22,0.07-0.38,0.55-0.43,0.86c-0.07,0.5-0.02,1.01-0.02,1.66c0.23-0.16,0.4-0.28,0.82-0.57
					c0.33,2.03,0.64,3.93,0.9,5.49c-1.49,1.16-3.22,1.18-4.12,2.77c-0.42,0.75-1.32,1.12-2.21,0.85c-0.63-1.33-1.08-2.69-1.85-3.84
					c-1.08-1.59-1.56-3.12-0.53-4.81c-0.58-0.99-1.83-1.65-1.02-3.02c0.05-0.09-0.04-0.29-0.11-0.41c-0.97-1.82-0.95-2.55,0.4-4.03
					c0.34-0.37,1-0.46,1.54-0.68c-0.3-0.12-0.65-0.27-1-0.41c1.37,0.16,2.85-0.23,4.04,0.82c-0.14,0.05-0.28,0.1-0.42,0.14
					c0.1,0.08,0.2,0.15,0.29,0.22c0.12,0.02,0.24,0.04,0.36,0.06c-0.08-0.07-0.15-0.14-0.23-0.22c-0.02-0.07-0.03-0.15-0.03-0.23
					c0.85,1.01,1.68,2.04,2.57,3.01c0.2,0.22,0.65,0.31,0.97,0.29c0.14-0.01,0.36-0.47,0.35-0.71c-0.05-0.91,0.4-1.33,1.21-1.61
					c0.7-0.25,1.47-0.6,0.73-1.58c0.1-0.08,0.2-0.17,0.3-0.25c-0.08-0.08-0.15-0.16-0.23-0.23c-0.06,0.12-0.12,0.23-0.18,0.35
					c-0.98-0.51-1.34-2.12-0.7-3.25c0.41-0.73,0.82-1.45,1.24-2.17c0.43-0.75,0.96-0.73,1.35-0.03c0.93,1.65,2.17,3.13,2.33,5.2
					c0.04,0.58,0.55,1.52,1.85,1.52c2.37,0,4.52,0.87,6.09,2.74c-0.02,0.13-0.05,0.26-0.07,0.39c-0.87-0.07-1.78-0.04-2.61-0.26
					c-0.87-0.23-1.68-0.7-2.52-1.06c-0.17-0.07-0.38-0.16-0.55-0.12c-1.44,0.34-2.67,0.12-3.52-1.32c-1.03,0.76-2.06,1.4-2.93,2.22
					c-0.62,0.59-0.06,2.09,0.81,2.35c0.44,0.13,0.93,0.11,1.4,0.16C26.99,157.09,27.15,157.28,27.31,157.46z M18.53,165.71
					c1.35-0.8,2.47-1.46,3.41-2.02c0-1.31,0.41-2.72-0.11-3.53c-0.61-0.95-0.65-1.79-0.69-2.74c-0.01-0.19-0.04-0.53-0.13-0.55
					c-1.16-0.31-1.07-1.42-1.46-2.22c-0.11-0.23-0.29-0.48-0.51-0.59c-0.99-0.52-1.99-0.99-3.07-1.52c-0.07,0.6-0.15,0.97-0.15,1.34
					c0.02,1.03,0.02,2.07,0.13,3.1c0.06,0.62,0.44,1.22,0.43,1.83c-0.03,1.63-0.16,3.21,1.02,4.6
					C17.92,164.01,18.13,164.87,18.53,165.71z M26.25,147.7c-1.06,1.48,0.76,1.57,0.65,2.41c0.13-0.05,0.25-0.11,0.38-0.16
					C26.97,149.28,26.66,148.6,26.25,147.7z"/>
        <path className="col1" d="M35.21,132.1c-0.08,0.41-0.2,0.82-0.23,1.23c-0.03,0.39,0.05,0.78,0.1,1.42c-1.72,0.37-3.52,0.76-5.33,1.15
					c0.02,0.02,0.15,0.17,0.27,0.31c-1.4-0.07-3.03,0.32-4.06-0.3c-1.42-0.86-2.9-1.36-4.4-1.9c-0.29-0.1-0.45-0.52-0.7-0.75
					c-0.31-0.28-0.64-0.66-1.01-0.72c-1.07-0.18-1.92-0.65-2.87-1.18c-1.03-0.57-1.24-1.41-1.83-2.24c0.71-0.4,1.1-0.85,0.46-1.65
					c-0.16-0.2,0.14-0.78,0.25-1.24c-0.18-0.1-0.38-0.3-0.58-0.31c-1.94-0.05-1.73-0.9-1.46-2.36c0.1-0.53,0.13-1.07-0.04-1.64
					c-0.07,0.22-0.15,0.44-0.22,0.66c-0.06,0-0.12,0-0.18-0.01c0-0.52,0-1.04,0-1.56c0.75-0.12,1.41-0.22,2.02-0.31
					c0.14,0.22,0.26,0.43,0.45,0.73c0.13-0.23,0.24-0.33,0.23-0.42c-0.06-0.51-0.2-1.01-0.23-1.52c-0.03-0.64-0.12-1.27,0.98-1.14
					c0.77,0.09,1.59-0.11,2.07-1.02c0.51-0.97,1.38-1.51,2.57-1.33c0.24,0.04,0.51-0.15,0.77-0.18c0.83-0.09,1.56-0.41,2.45,0.29
					c0.63,0.49,1.79,0.3,2.69,0.47c0.42,0.08,0.83,0.29,1.22,0.48c0.38,0.18,0.7,0.49,1.09,0.63c0.89,0.33,1.82,0.54,2.69,0.91
					c0.2,0.09,0.3,0.68,0.24,1c-0.16,0.9-0.28,1.53,0.78,2.19c1.14,0.72,2.17,1.83,2.33,3.49c-1.77,0-2.73-1.19-3.77-2.25
					c-0.26-0.26-0.58-0.48-0.91-0.65c-0.61-0.31-0.82-0.67-0.65-1.42c0.32-1.41-0.03-1.29-1.35-1.74c-1.76-0.62-3.73,0.14-5.38-1.16
					c-0.99-0.77-3.01-0.07-3.77,0.98c-0.14,0.19-0.31,0.36-0.51,0.48c-0.45,0.28-1.23,0.44-1.33,0.8c-0.18,0.64,0.04,1.4,0.09,2.11
					c0.01,0.15,0.03,0.3,0.06,0.55c-0.88,0.08-1.72,0.16-2.77,0.25c0.32,0.41,0.45,0.69,0.68,0.85c1.39,0.93,1.08,2.62,1.74,3.87
					c0.05,0.09,0.03,0.24,0,0.35c-0.37,1.35-0.06,2.13,1.26,2.27c1.69,0.17,2.81,0.67,3.71,2.31c0.44,0.81,2.15,0.94,3.31,1.34
					c0.37,0.13,0.84-0.01,1.17,0.16c1.82,0.9,3.66,0.11,5.49,0.1c0.49,0,0.98-0.4,1.43-0.6c0.11-0.61,0.21-1.2,0.31-1.78
					C34.77,132.11,34.99,132.1,35.21,132.1z M26.08,135.11c-0.05,0.08-0.1,0.16-0.15,0.24c0.24,0.16,0.48,0.31,0.72,0.47
					c0.05-0.08,0.1-0.16,0.15-0.23C26.56,135.42,26.32,135.27,26.08,135.11z M22.4,115.99c-0.01,0.06-0.02,0.12-0.03,0.18
					c0.15,0.02,0.31,0.05,0.46,0.07c0.01-0.06,0.02-0.11,0.03-0.17C22.71,116.05,22.56,116.02,22.4,115.99z"/>
        <path className="col1" d="M215.69,126.32c-0.33-0.73-0.6-1.5-1.01-2.18c-0.44-0.73-1.03-1.38-1.55-2.06
					c-0.12-0.16-0.19-0.37-0.34-0.47c-0.48-0.34-1.27-0.54-1.41-0.97c-0.14-0.42,0.28-1.11,0.58-1.6c0.39-0.63-0.01-0.88-0.3-1.39
					c-0.85-1.5-1.96-0.95-3.13-0.7c-1.05,0.23-2.06,0.68-3.23,0.27c-0.77-0.27-1.2,0.19-1.01,1.15c0.4,1.93-0.14,2.65-2.14,3.02
					c-0.78,0.14-1.57,0.26-2.36,0.31c-0.25,0.01-0.51-0.24-0.77-0.37c1.17-0.01,2.21-0.28,3.22-1c0.63-0.45,1.02-0.76,0.9-1.57
					c-0.13-0.86-0.16-1.73-0.24-2.59c0.88-0.38,1.75-0.71,2.72-0.15c0.25,0.14,0.69-0.02,1.04-0.08c1.52-0.28,3.03-0.59,4.56-0.84
					c0.28-0.05,0.6,0.17,0.9,0.26c-0.35,0.91,0.52,1.19,0.89,1.72c0.64,0.9,0.54,2.03-0.32,3.29c-0.53,0.95,0.3,0.8,0.76,0.94
					c0.55,0.85,1.17,1.66,1.63,2.55c0.4,0.78,0.61,1.65,0.91,2.48C215.89,126.33,215.79,126.33,215.69,126.32z"/>
        <path className="col1" d="M31.9,168.21c0.94,1.26,2.08,0.95,3.25,0.42c0.05,0.11,0.1,0.23,0.14,0.34c-0.65,0.74-1.29,1.47-2.07,2.36
					c-0.34-0.66-0.5-0.99-0.67-1.32c-1.47,0.69-2.25,0.09-2.53-1.85c-0.04-0.3-0.16-0.6-0.27-1c0.4-0.19,0.28-1.37,1.43-1
					c0.18,0.06,0.7-0.66,0.94-1.09c0.49-0.89,1.35-1.06,2.2-0.45c0.89,0.65,1.84,1.22,2.79,1.84c-0.3,0.67-1.44,1.18-2.26,0.84
					C33.39,166.72,33.07,166.81,31.9,168.21z"/>
        <path className="col1" d="M34.72,217.99c-0.36-0.48-0.73-0.97-1.25-1.67c-0.12,0.26-0.2,0.46-0.28,0.63c-0.57-0.38-1.2-0.6-1.51-1.04
					c-1.08-1.56-3.35-1.63-4.3-3.67c1.06,0,1.99,0,2.92,0c0.2,0,0.45-0.14,0.58-0.06c1.51,0.99,3.11,1.89,4.47,3.06
					C36.77,216.46,36.57,217.03,34.72,217.99z"/>
        <path className="col1" d="M206.03,104.8c0.91,1.5,0.58,2.75-0.79,3.77c-1.02,0.92-2.12,1.77-3.05,2.77c-0.5,0.55-0.64,1.42-1.07,2.05
					c-0.6,0.88-0.65,1.58,0.3,2.2c0,0,0,0,0,0c0.28,0.5,0.99,0.79,0.5,1.62c-0.32,0.53-0.16,1.45-0.56,1.81
					c-0.62,0.54-1.54,0.75-2.33,1.1c0.12-0.17,0.23-0.45,0.37-0.48c1.55-0.24,1.49-1.56,1.78-2.63c0.04-0.16,0.01-0.44-0.1-0.54
					c-0.77-0.71-1.29-1.51-1.13-2.61c-0.13,0.02-0.27,0.04-0.4,0.07c-0.05-0.05-0.1-0.1-0.15-0.14c1.14-2.33,2.68-4.3,4.83-5.79
					c0.92-0.64,1.68-1.32,1.09-2.65c-0.14-0.32,0.12-0.83,0.2-1.25C205.68,104.33,205.85,104.56,206.03,104.8z"/>
        <path className="col1" d="M163.3,186.16c1.86-1.4,3.7-2.85,5.61-4.19c0.81-0.57,1.57-0.48,1.8,0.75c0.21,1.07,0.93,1.41,2.01,1.41
					c1.79,0,3.58,0.16,5.37,0.25c0.01,0.12,0.02,0.23,0.03,0.35c-0.29,0.18-0.57,0.36-0.86,0.55c-0.21-0.07-0.42-0.22-0.62-0.2
					c-1,0.12-2,0.28-3,0.42c-1.04-0.24-2.07-0.48-3.11-0.72c0,0,0.02,0.01,0.02,0.01c-1.05-1.98-1.06-2.01-3.05-0.89
					c-1.37,0.77-2.69,1.63-4.04,2.45C163.4,186.29,163.35,186.22,163.3,186.16z"/>
        <path className="col1" d="M20.14,209.82c0.29-0.06,0.58-0.12,0.87-0.17c0.25-0.04,0.5-0.07,0.75-0.11c1.08-0.34,2.15-0.67,3.59-1.12
					c0.42,0.28,1.23,0.83,2.15,1.45c0.23-0.12,0.74-0.39,1.26-0.65c0,0-0.01-0.01-0.01-0.01c-0.57,0.86-1.12,1.75-1.75,2.57
					c-0.1,0.13-0.62,0.08-0.85-0.06c-1.06-0.66-2.17-0.68-3.36-0.47c-0.37,0.07-0.81-0.23-1.22-0.36c0,0,0.01,0,0.01,0
					C21.73,209.68,20.67,210.1,20.14,209.82z"/>
        <path className="col1" d="M44.24,151.88c-0.17,0.46-0.26,0.86-0.44,1.21c-0.12,0.23-0.37,0.6-0.53,0.59c-1.7-0.2-3.03,0.84-4.55,1.29
					c-0.09-1.58,1.02-2.34,2.03-3.24C42,150.61,43.03,151.58,44.24,151.88z"/>
        <path className="col1" d="M199.02,120.12c0.79-0.35,1.71-0.56,2.33-1.1c0.4-0.35,0.24-1.27,0.56-1.81c0.49-0.82-0.23-1.12-0.5-1.62
					c0.56,0.03,1.39-0.64,1.5,0.58c0.08,0.86,0.11,1.73,0.24,2.59c0.12,0.81-0.27,1.12-0.9,1.57c-1.01,0.71-2.05,0.99-3.22,1
					c0,0,0.01,0.01,0.01,0.01C199.03,120.92,199.03,120.52,199.02,120.12C199.03,120.11,199.02,120.12,199.02,120.12z"/>
        <path className="col1" d="M224.54,67.66c0.27,2.64,0.46,4.9-1.44,6.73c-1.03,0.99-0.64,2.33-0.33,3.39c0.6,2.03-0.96,3.54-0.73,5.42
					c-0.17-0.03-0.34-0.07-0.5-0.1c0.28-1.06,0.6-2.11,0.83-3.18c0.1-0.44,0.1-0.95-0.01-1.38c-0.53-2.08-0.36-3.93,1.18-5.64
					c0.53-0.58,0.55-1.66,0.71-2.53C224.4,69.59,224.42,68.8,224.54,67.66z"/>
        <path className="col1" d="M216.5,132.79c-0.18-0.49-0.06-1.23-0.94-1.17c-0.08-0.21-0.17-0.42-0.25-0.63
					c-0.15,0.21-0.31,0.42-0.46,0.63c-0.29-0.26-0.57-0.52-0.86-0.78c0.26-0.24,0.52-0.49,0.61-0.57c0.54,0.13,1.05,0.39,1.54,0.35
					c1.03-0.07,1.47,0.26,1.33,1.33c-0.19,1.41,0.09,2.62,1.31,3.56c0.3,0.23,0.24,0.93,0.34,1.41c-0.55-0.6-1.09-1.21-1.64-1.81
					c-0.16-0.12-0.33-0.25-0.49-0.37c0,0,0.01,0.01,0.01,0.01c-0.12-0.16-0.24-0.33-0.36-0.49c-0.03-0.17-0.06-0.34-0.09-0.5
					C216.53,133.43,216.51,133.11,216.5,132.79z M216.88,131.22c-0.1,0.02-0.2,0.04-0.31,0.07c0.08,0.21,0.16,0.41,0.24,0.62
					c0.06-0.02,0.12-0.04,0.19-0.06C216.96,131.63,216.92,131.43,216.88,131.22z"/>
        <path className="col1" d="M201.41,115.6c-0.95-0.62-0.9-1.33-0.3-2.2c0.43-0.63,0.57-1.5,1.07-2.05c0.92-1.01,2.02-1.86,3.05-2.77
					c-0.45,0.57-0.83,1.22-1.37,1.69c-0.81,0.7-1.45,1.39-1.5,2.56c-0.03,0.59-0.47,1.15-0.69,1.73
					C201.55,114.89,201.49,115.25,201.41,115.6z"/>
        <path className="col1" d="M213.45,121.3c-0.46-0.14-1.29,0.01-0.76-0.94c1.11-0.01,1.91,0.32,2.37,1.5
					c0.29,0.74,1.07,1.26,1.54,1.95c0.98,1.43,0.97,2.62,0.06,3.67c-0.07-0.08-0.14-0.17-0.21-0.25c0.54-0.74,0.65-1.59,0.19-2.33
					c-0.82-1.33-1.78-2.58-2.7-3.84C213.92,121.03,213.62,121.21,213.45,121.3z"/>
        <path className="col1" d="M206.03,104.8c-0.17-0.23-0.34-0.47-0.52-0.7c0.54-1.27,2.1-2.05,3.78-1.81
					C208.58,103.7,207.01,103.89,206.03,104.8z"/>
        <path className="col1" d="M27.31,157.46c-0.16-0.19-0.32-0.37-0.48-0.56c1.46-0.46,2.9-0.97,4.38-1.33
					c0.46-0.11,1.02,0.19,1.44,0.29c-1.41,0.45-2.8,0.9-4.19,1.34C28.08,157.32,27.69,157.38,27.31,157.46z"/>
        <path className="col1" d="M177.86,186.92c1.94,0.17,3.87,0.35,5.81,0.52c-0.14,0.14-0.29,0.41-0.43,0.41
					c-1.14,0-2.27-0.06-3.41-0.07c-0.52-0.01-1.04,0.05-1.56,0.08c-0.59-0.02-1.07-0.17-1.01-0.92
					C177.46,186.93,177.66,186.93,177.86,186.92z"/>
        <path className="col1" d="M35.21,132.1c-0.22,0.01-0.44,0.01-0.65,0.02c0.52-1.58,1.04-3.15,1.56-4.73c0.15,0.05,0.31,0.1,0.46,0.14
					C36.12,129.05,35.66,130.57,35.21,132.1z"/>
        <path className="col1" d="M205.49,193.84c0.24-0.28,0.47-0.57,0.71-0.85c0.81,0.01,1.09-0.38,0.99-1.18
					c-0.03-0.22,0.24-0.47,0.38-0.71c0.17,0.22,0.43,0.41,0.48,0.65c0.1,0.54,0.09,1.1,0.14,1.84
					C207.24,193.68,206.36,193.76,205.49,193.84z"/>
        <path className="col1" d="M178.27,187.86c0.52-0.03,1.04-0.09,1.56-0.08c1.14,0.01,2.27,0.07,3.41,0.07c0.14,0,0.29-0.27,0.43-0.41
					c0.42-0.25,0.82-0.58,1.27-0.72c0.16-0.05,0.46,0.31,0.7,0.49c-1.08,1.03-2.46,1.06-3.81,1.01
					C180.64,188.18,179.46,187.99,178.27,187.86z"/>
        <path className="col1" d="M76.65,42.28c-0.03,0.48-0.06,0.97-0.09,1.45c-0.12,0.16-0.24,0.33-0.37,0.49c0,0,0.02-0.01,0.02-0.01
					c-0.16,0.19-0.32,0.39-0.48,0.58c0.56-1.25-0.14-2.14-0.72-3.11c-0.26-0.44-0.16-1.11-0.23-1.76
					C75.5,40.84,76.08,41.56,76.65,42.28z"/>
        <path className="col1" d="M139.19,11.91c0.09,0.11,0.18,0.22,0.34,0.41c-0.58,0.09-1.1,0.16-1.61,0.24
					c-1.84,0.06-3.68,0.08-5.52,0.2c-0.47,0.03-0.91,0.37-1.37,0.56c0,0,0,0,0,0c-0.09-0.02-0.17-0.04-0.26-0.06
					c0-0.09,0-0.17-0.01-0.26c1.1-0.28,2.19-0.68,3.3-0.81C135.76,12,137.48,12,139.19,11.91z"/>
        <path className="col1" d="M63.28,88.12c-0.74,0.18-1.48,0.35-2.22,0.53c-0.04-0.12-0.08-0.24-0.12-0.36
					c0.22-0.12,0.44-0.24,0.68-0.37c-0.12-0.15-0.23-0.3-0.35-0.45c1.3,0.11,2.6,0.21,3.9,0.32C64.54,87.9,63.91,88.01,63.28,88.12z
					"/>
        <path className="col1" d="M63.28,88.12c0.63-0.11,1.26-0.22,1.89-0.33c0,0-0.01-0.01-0.01-0.01c0.19,0.08,0.37,0.17,0.56,0.25
					c0.29,0.12,0.63,0.19,0.85,0.39c0.23,0.21,0.51,0.57,0.47,0.82c-0.04,0.27-0.42,0.48-0.65,0.72
					C66.05,88.17,64.54,88.35,63.28,88.12z"/>
        <path className="col1" d="M124.21,202.98c0.35,0.48,0.71,0.96,1.06,1.44l0.01-0.01c-0.15,0.07-0.3,0.13-0.44,0.2
					c-0.65-0.61-1.31-1.22-1.96-1.84c0.07-0.16,0.14-0.32,0.21-0.47c0.09-0.02,0.19-0.03,0.28-0.05c0,0-0.01,0-0.01,0
					c0.16,0.16,0.33,0.33,0.49,0.49c0,0-0.01-0.01-0.01-0.01C123.96,202.82,124.08,202.9,124.21,202.98z"/>
        <path className="col1" d="M78.55,189.57c-0.21,0.09-0.42,0.17-0.63,0.26c0.07-0.25,0.14-0.5,0.22-0.75
					c0.18-0.31,0.37-0.62,0.55-0.93c0.6-0.64,0.57-1.28,0-1.92c-0.05-0.08-0.11-0.17-0.16-0.25c0.15-0.03,0.44-0.1,0.44-0.08
					c0.19,0.64,0.53,1.3,0.48,1.93C79.4,188.45,79.68,189.43,78.55,189.57z"/>
        <path className="col1" d="M35.48,68.52c0.44,0.46,0.87,0.93,1.4,1.49c-0.48,0.03-0.82,0.05-1.15,0.07c-0.7-1.42-1.55-2.82-0.34-4.39
					c0.04,0.03,0.07,0.06,0.1,0.09c0,0.24-0.01,0.47-0.01,0.71c0,0,0-0.01,0-0.01C35.48,67.16,35.48,67.84,35.48,68.52z"/>
        <path className="col1" d="M215.69,126.32c0.1,0,0.2,0.01,0.3,0.01c-0.96,0.44-1.91,0.87-3,1.36c-0.04,0.44-0.1,1.1-0.16,1.76
					c-0.12,0.01-0.23,0.01-0.35,0.02c0-0.39-0.01-0.79,0-1.18c0.02-0.99,0.31-1.62,1.52-1.51
					C214.55,126.83,215.12,126.49,215.69,126.32z"/>
        <path className="col1" d="M175.29,30.07c0.28,0.18,0.56,0.35,0.86,0.54c-1.45,0.61-1.76,1.85-2.11,3.12
					c-0.14-0.21-0.24-0.36-0.35-0.51C174.23,32.17,174.76,31.12,175.29,30.07z"/>
        <path className="col1" d="M125.27,204.42c0.65-0.07,1.23,0.1,1.9-0.52c0.65-0.6,1.12,0.4,1.44,1.01c-1.11-0.09-2.22-0.18-3.33-0.27
					C125.28,204.57,125.28,204.5,125.27,204.42L125.27,204.42z"/>
        <path className="col1" d="M103.03,202.22c0.06-0.03,0.1-0.02,0.13,0.04c0.32,0,0.63,0,0.94,0c0,0,0.07-0.04,0.07-0.04
					c0.3,0.17,0.58,0.45,0.9,0.49c1.12,0.13,2.26,0.18,3.39,0.26c-0.01,0.11-0.01,0.23-0.02,0.34c-1.51-0.13-3.02-0.23-4.52-0.42
					C103.59,202.85,103.32,202.45,103.03,202.22z"/>
        <path className="col1" d="M39.31,77.7c-0.11,0.2-0.22,0.4-0.48,0.87c-0.27-0.93-0.42-1.44-0.56-1.93c0.69-0.42,1.3-0.78,1.91-1.15
					c0.04,0.04,0.08,0.07,0.12,0.11c0,0.06-0.01,0.11-0.01,0.17C40.2,76.54,38.94,76.71,39.31,77.7z"/>
        <path className="col1" d="M224.29,207.54c-0.15,0-0.29,0-0.44,0.01c-0.14-0.89-0.27-1.77-0.41-2.66c0.4,0.51,0.52,0.03,0.72-0.2
					C224.2,205.64,224.25,206.59,224.29,207.54z"/>
        <path className="col1" d="M66.63,93.17c-0.19,0.05-0.37,0.1-0.82,0.23c0.2-1.07,0.39-2.02,0.57-2.98c0.67,0.01,0.78,0.33,0.6,0.93
					C66.81,91.94,66.75,92.56,66.63,93.17z"/>
        <path className="col1" d="M223.25,204.38c-0.42-1.07-0.85-2.14-1.27-3.21c0.07-0.05,0.14-0.1,0.2-0.15c0.45,0.72,0.91,1.43,1.36,2.15
					C223.45,203.57,223.35,203.98,223.25,204.38z"/>
        <path className="col1" d="M225.25,53.78c0,0,0.08-0.04,0.08-0.04c0.13,0.2,0.25,0.39,0.56,0.87c-0.76-0.05-1.37-0.09-1.97-0.14
					c-0.22-0.25-0.43-0.49-0.65-0.74C223.93,53.76,224.59,53.77,225.25,53.78z"/>
        <path className="col1" d="M227.3,163.97c-0.2,0.23-0.4,0.46-0.59,0.7c-0.28,0.12-0.57,0.25-0.85,0.37
					c-0.11,0.04-0.23,0.07-0.34,0.11c-0.28,0.08-0.57,0.17-0.85,0.25c0,0,0.02,0,0.02,0c-0.26-0.03-0.52-0.05-0.78-0.08
					c-0.02-0.07-0.04-0.13-0.05-0.2C224.99,164.74,226.15,164.35,227.3,163.97z"/>
        <path className="col1" d="M152.79,16.86c-1.59,0-3.19,0-4.78,0c0-0.07,0-0.15,0-0.22c1.59,0,3.19,0,4.78,0
					C152.79,16.71,152.79,16.78,152.79,16.86z"/>
        <path className="col1" d="M177.86,186.92c-0.2,0.01-0.4,0.01-0.6,0.02c-0.29-0.11-0.77-0.14-0.85-0.33c-0.34-0.78,0.23-1.1,0.85-1.33
					C177.46,185.82,177.66,186.37,177.86,186.92z"/>
        <path className="col1" d="M224.18,221.19c-0.19,0.43-0.38,0.87-0.57,1.3c-0.14-0.96-0.52-1.94,0.33-2.74
					C224.02,220.23,224.1,220.71,224.18,221.19z"/>
        <path className="col1" d="M177.03,29.87c-0.01-0.1-0.02-0.2-0.03-0.3c1.79-0.38,2.79-0.05,3.45,1.14c-0.05,0.04-0.1,0.08-0.15,0.12
					c-0.15-0.09-0.29-0.19-0.44-0.28c0,0,0.01,0.01,0.01,0.01C179.14,29.46,178.01,29.95,177.03,29.87z"/>
        <path className="col1" d="M130.78,201.77c0,0.67,0,1.33,0,2c-0.05,0.01-0.1,0.02-0.15,0.02c-0.16-0.53-0.17-1.32-0.53-1.54
					c-0.51-0.32-1.28-0.22-2.05-0.31c0.73-0.13,1.36-0.25,1.99-0.36C130.29,201.63,130.54,201.7,130.78,201.77z"/>
        <path className="col1" d="M223.25,204.38c0.1-0.4,0.2-0.81,0.3-1.21c1.05,0.17,0.54,0.96,0.61,1.52c-0.19,0.23-0.31,0.71-0.72,0.2
					C223.38,204.72,223.32,204.55,223.25,204.38z"/>
        <path className="col1" d="M145.39,13.78c0.06-0.2,0.12-0.4,0.18-0.6c0.17,0.12,0.35,0.24,0.52,0.36c0.09,0.76,0.17,1.51,0.26,2.27
					c-0.03,0-0.06,0.01-0.09,0.01c-0.21-0.6-0.42-1.2-0.63-1.79c0,0,0.01,0.02,0.01,0.02C145.55,13.96,145.47,13.87,145.39,13.78z"
        />
        <path className="col1" d="M128.8,205.11c0.05-0.17,0.09-0.34,0.14-0.51c0.09-0.03,0.19-0.05,0.28-0.08c0.28,0.61,0.56,1.22,0.83,1.84
					C129.64,205.94,129.22,205.52,128.8,205.11z"/>
        <path className="col1" d="M170.53,184.78c1.04,0.24,2.07,0.48,3.11,0.72c-0.7,0-1.42,0.09-2.1-0.02
					C171.17,185.41,170.86,185.02,170.53,184.78z"/>
        <path className="col1" d="M230.21,164.3c-0.5,0.07-1,0.14-1.5,0.21c-0.02-0.09-0.05-0.17-0.07-0.26c0.26-0.17,0.53-0.34,0.79-0.51
					C229.7,163.92,229.95,164.11,230.21,164.3z"/>
        <path className="col1" d="M130.5,206.6c0.11-0.01,0.21-0.02,0.32-0.04c0.23,0,0.46,0.01,0.7,0.01c0,0-0.02-0.01-0.02-0.01
					c0.33,0.21,0.66,0.43,0.98,0.64c-0.04,0.08-0.08,0.17-0.12,0.25C131.74,207.17,131.12,206.89,130.5,206.6z"/>
        <path className="col1" d="M128.87,15.6c-0.18,0.16-0.35,0.32-0.53,0.48c-0.06-0.21-0.12-0.42-0.18-0.63c0.2-0.2,0.39-0.41,0.59-0.61
					c0.06,0.07,0.13,0.14,0.19,0.21C128.91,15.23,128.89,15.42,128.87,15.6z"/>
        <path className="col1" d="M199.03,120.11c0,0.41,0,0.81,0,1.22c-0.19-0.23-0.46-0.44-0.52-0.7
					C198.48,120.51,198.85,120.29,199.03,120.11z"/>
        <path className="col1" d="M35.38,65.69c0.12-0.36,0.23-0.73,0.35-1.09c0.03,0.01,0.06,0.01,0.09,0.02c-0.03,0.39-0.07,0.78-0.1,1.17
					c0,0,0-0.02,0-0.02c-0.08,0-0.16,0.01-0.24,0.01C35.45,65.75,35.42,65.72,35.38,65.69z"/>
        <path className="col1" d="M228.26,163.73c-0.11,0.19-0.22,0.39-0.33,0.58c-0.13-0.12-0.27-0.25-0.4-0.37
					C227.78,163.87,228.02,163.8,228.26,163.73z"/>
        <path className="col1" d="M179.59,33.33c0.05-0.14,0.11-0.28,0.16-0.42c0.15,0.22,0.29,0.45,0.44,0.67c-0.07,0.05-0.14,0.1-0.22,0.16
					C179.85,33.6,179.72,33.47,179.59,33.33z"/>
        <path className="col1" d="M222.56,166.82c0.07,0.06,0.13,0.13,0.2,0.19c-0.19,0.02-0.37,0.06-0.56,0.06c-0.02,0-0.05-0.19-0.07-0.3
					C222.27,166.79,222.41,166.81,222.56,166.82z"/>
        <path className="col1" d="M79.52,127.3c-0.02,0.11-0.04,0.22-0.05,0.33c-0.06,0.02-0.12,0.04-0.18,0.06
					c-0.08-0.29-0.15-0.58-0.23-0.87c0,0-0.02,0.02-0.02,0.02c0.09,0,0.17,0,0.26,0c0,0-0.01-0.02-0.01-0.02
					c0.08,0.17,0.17,0.33,0.25,0.5L79.52,127.3z"/>
        <path className="col1" d="M102.01,202.25c0-0.15,0-0.3,0-0.45c0.17,0.08,0.33,0.16,0.5,0.24
					C102.35,202.11,102.18,202.18,102.01,202.25z"/>
        <path className="col1" d="M180.45,30.72c0.05,0.28,0.1,0.56,0.16,0.85c-0.05,0.01-0.1,0.02-0.16,0.03c-0.06-0.18-0.11-0.37-0.17-0.55
					c0.02-0.07,0.02-0.14,0.02-0.21C180.35,30.8,180.4,30.76,180.45,30.72z"/>
        <path className="col1" d="M19.66,193.89c-0.04-0.16-0.08-0.31-0.12-0.47c-0.01,0-0.02,0-0.03,0.01c0.05,0.16,0.09,0.32,0.14,0.48
					C19.65,193.91,19.66,193.89,19.66,193.89z"/>
        <path className="col1" d="M75.41,39.36c0-0.07,0-0.13,0.02-0.19c0.01-0.03,0.06-0.04,0.09-0.06c0.03,0.05,0.06,0.1,0.09,0.15
					C75.55,39.29,75.49,39.32,75.41,39.36z"/>
        <path className="col1" d="M180.3,30.84c0.01,0.07,0,0.14-0.02,0.21c-0.12,0-0.25,0-0.37-0.01c-0.02-0.16-0.03-0.32-0.05-0.48
					C180.01,30.65,180.16,30.74,180.3,30.84z"/>
        <path className="col1" d="M216.27,127.77c-0.08-0.08-0.16-0.15-0.25-0.23c0.14-0.1,0.28-0.21,0.42-0.31
					c0.07,0.08,0.14,0.17,0.21,0.25C216.53,127.58,216.4,127.67,216.27,127.77z"/>
        <path className="col1" d="M73.43,115.2c-0.11-0.1-0.22-0.21-0.34-0.31c0.11-0.11,0.21-0.22,0.32-0.33c0.05,0.17,0.1,0.34,0.15,0.51
					C73.51,115.12,73.47,115.16,73.43,115.2z"/>
        <path className="col1" d="M182.76,43.25c-0.06,0.04-0.11,0.07-0.17,0.11c0.03-0.01,0.07-0.03,0.1-0.04c0.03-0.02,0.06-0.04,0.08-0.05
					C182.77,43.26,182.76,43.25,182.76,43.25z"/>
        <path className="col1" d="M125.28,204.42c0,0.08,0,0.15,0,0.23c-0.15-0.01-0.29-0.02-0.44-0.03
					C124.98,204.55,125.13,204.48,125.28,204.42z"/>
        <path className="col1" d="M73.79,115.32c0.07,0.1,0.15,0.2,0.21,0.31c0.01,0.01-0.04,0.06-0.06,0.08c-0.07-0.1-0.14-0.21-0.22-0.31
					C73.73,115.4,73.79,115.32,73.79,115.32z"/>
        <path className="col1" d="M147.51,17.19c-0.03,0.02-0.05,0.05-0.08,0.07c-0.02-0.06-0.05-0.12-0.07-0.18c0-0.02,0.05-0.04,0.08-0.07
					C147.46,17.08,147.48,17.14,147.51,17.19z"/>
        <path className="col1" d="M179.67,35.34c0.08,0.07,0.16,0.15,0.24,0.22c-0.05,0.04-0.09,0.08-0.14,0.12
					c-0.13-0.11-0.25-0.22-0.38-0.33C179.48,35.34,179.58,35.34,179.67,35.34z"/>
        <path className="col1" d="M65.72,88.03c-0.19-0.08-0.37-0.17-0.56-0.25C65.35,87.87,65.53,87.95,65.72,88.03z"/>
        <path className="col1"
              d="M27.33,195.57c0.16,0,0.32,0,0.48,0c0,0,0.01-0.02,0.01-0.02c-0.17,0-0.33,0-0.5,0L27.33,195.57z"/>
        <path className="col1" d="M87.66,199.61c-0.03,0.04-0.05,0.08-0.04,0.06c-0.02,0.01,0.02-0.02,0.06-0.05
					C87.68,199.63,87.66,199.61,87.66,199.61z"/>
        <path className="col1" d="M128.74,14.84c0.08-0.08,0.16-0.16,0.24-0.24c0.05,0.06,0.11,0.13,0.16,0.19
					c-0.07,0.09-0.14,0.17-0.21,0.26C128.87,14.98,128.81,14.91,128.74,14.84z"/>
        <path className="col1" d="M128.99,14.6c0.08-0.08,0.16-0.16,0.24-0.24c0.04,0.07,0.09,0.13,0.15,0.18c-0.08,0.08-0.15,0.17-0.23,0.25
					C129.09,14.73,129.04,14.66,128.99,14.6z"/>
        <path className="col1" d="M129.23,14.36c0.08-0.08,0.16-0.15,0.24-0.23c0.04,0.06,0.09,0.11,0.14,0.16
					c-0.01,0.08-0.02,0.15-0.02,0.23c0,0,0.01-0.01,0.01-0.01c-0.08,0-0.15,0.01-0.22,0.03C129.32,14.49,129.27,14.43,129.23,14.36z
					"/>
        <path className="col1" d="M129.47,14.13c0.08-0.08,0.16-0.15,0.24-0.23c0.04,0.05,0.08,0.1,0.13,0.15c-0.02,0.08-0.02,0.16-0.01,0.24
					c0,0,0.01-0.01,0.01-0.01c-0.08-0.01-0.15-0.01-0.23,0.01C129.55,14.24,129.51,14.19,129.47,14.13z"/>
        <path className="col1" d="M199.54,113.93c-0.07,0.04-0.15,0.08-0.22,0.12c0.02-0.09,0.05-0.17,0.07-0.26
					C199.45,113.83,199.5,113.88,199.54,113.93z"/>
        <polygon className="col1" points="28.76,209.22 28.81,209.17 28.75,209.2 				"/>
        <polygon className="col1" points="21.58,210.89 21.55,210.92 21.58,210.89 				"/>
        <path className="col1" d="M129.7,13.9c0.08-0.08,0.16-0.15,0.24-0.23c0.04,0.05,0.08,0.09,0.13,0.14c0,0.08,0,0.16-0.01,0.24
					c0,0,0.01-0.01,0.01-0.01c-0.08-0.01-0.16-0.01-0.24,0.01C129.79,14,129.74,13.95,129.7,13.9z"/>
        <path className="col1" d="M129.95,13.67c0.08-0.07,0.17-0.14,0.25-0.22c0.04,0.04,0.08,0.07,0.11,0.11c0,0.08,0,0.16,0,0.25
					c0,0,0.01-0.01,0.01-0.01c-0.08,0-0.16,0-0.24,0.01C130.03,13.76,129.98,13.72,129.95,13.67z"/>
        <polygon className="col1" points="214.83,184.79 214.86,184.81 214.84,184.79 				"/>
        <path className="col1" d="M130.19,13.45c0.09-0.07,0.17-0.15,0.26-0.22c0.03,0.03,0.06,0.06,0.09,0.09
					c-0.08,0.08-0.16,0.16-0.24,0.24C130.27,13.52,130.23,13.49,130.19,13.45z"/>
        <path className="col1" d="M73.56,115.08c0.08,0.08,0.15,0.16,0.23,0.25c0,0-0.06,0.08-0.06,0.08c-0.1-0.07-0.2-0.13-0.3-0.2
					C73.47,115.16,73.51,115.12,73.56,115.08z"/>
        <path className="col1" d="M130.77,13.01c0,0.09,0,0.17,0.01,0.26c-0.07,0.04-0.14,0.06-0.22,0.06c-0.03-0.03-0.06-0.06-0.09-0.09
					C130.56,13.16,130.66,13.08,130.77,13.01z"/>
        <path className="col1" d="M28.04,231.24c0.02,0.02,0.05,0.04,0.07,0.05c-0.02-0.02-0.04-0.04-0.06-0.07
					C28.05,231.23,28.04,231.24,28.04,231.24z"/>
        <path className="col1" d="M77.91,67.2c0.71,0,1.41,0,2.12,0c0-0.04,0.01-0.09,0.01-0.13c-0.66-0.1-1.32-0.21-1.98-0.31
					c0.01-0.04,0.02-0.08,0.03-0.12c1.77,0,3.54,0,5.3,0c0.01,0.12,0.01,0.25,0.02,0.37c-1.84,0.19-3.67,0.37-5.51,0.56
					C77.9,67.43,77.91,67.32,77.91,67.2z"/>
        <path className="col1" d="M102.96,50c2.22,0.49,3.52-1.59,5.41-2C106.8,49.36,105.25,50.74,102.96,50z"/>
        <path className="col1" d="M84.9,123.03c-1.69,0.31-2.45-1.15-3.98-1.31C82.64,121.28,83.69,121.94,84.9,123.03z"/>
        <path className="col1" d="M131.03,13.32c0.46-0.2,0.9-0.53,1.37-0.56c1.84-0.12,3.68-0.14,5.52-0.2
					C135.62,12.82,133.32,13.07,131.03,13.32z"/>
        <path className="col1" d="M164.95,26.19c-1.45-0.09-2.84-0.11-3.38,1.7C161.93,25.64,161.96,25.63,164.95,26.19z"/>
        <path className="col1" d="M203.59,46.12c-1.4-0.18-2.79-0.37-4.19-0.55c0.03-0.17,0.06-0.35,0.1-0.52
					C200.86,45.4,202.22,45.76,203.59,46.12L203.59,46.12z"/>
        <path className="col1"
              d="M80.27,126.56c1.99,1.19,2.06,1.3,1.78,2.64c-0.56-0.76-1.21-1.66-1.86-2.56L80.27,126.56z"/>
        <path className="col1" d="M81.22,41.16c0.97-0.23,1.93-0.47,2.9-0.7c0.03,0.13,0.07,0.27,0.1,0.4c-0.95,0.25-1.91,0.51-2.86,0.76
					C81.31,41.47,81.26,41.32,81.22,41.16z"/>
        <path className="col1" d="M225.25,53.78c-0.66-0.01-1.32-0.03-1.99-0.04c-0.43,0.02-0.86,0.04-1.28,0.06
					c-0.02-0.1-0.03-0.19-0.05-0.29c0.93-0.15,1.85-0.35,2.78-0.43C224.87,53.08,225.07,53.54,225.25,53.78z"/>
        <path className="col1" d="M38.71,74.8c0.21-0.8,0.41-1.6,0.66-2.56c0.15,0.39,0.5,0.84,0.39,1.15c-0.19,0.58-0.64,1.07-0.98,1.6
					C38.76,74.93,38.73,74.87,38.71,74.8z"/>
        <path className="col1" d="M40.78,71.7c0.81,1.34,0.74,2.36-0.24,3.69c-0.05-0.05-0.1-0.11-0.15-0.16c0.62-1.09,0.75-2.21,0.25-3.38
					C40.69,71.8,40.74,71.75,40.78,71.7z"/>
        <path className="col1" d="M68.01,99.56c-0.19-0.46-0.38-0.92-0.56-1.34c0.43-0.29,0.81-0.56,1.21-0.83c0.09,0.14,0.2,0.32,0.17,0.27
					c-0.3,0.72-0.53,1.29-0.77,1.85C68.06,99.51,68.01,99.56,68.01,99.56z"/>
        <path className="col1" d="M177.03,29.87c0.99,0.08,2.11-0.41,2.85,0.7C178.93,30.34,177.98,30.1,177.03,29.87z"/>
        <path className="col1" d="M129.37,14.54c0.07-0.02,0.15-0.03,0.22-0.03c-0.24,0.36-0.49,0.73-0.73,1.09
					c0.02-0.18,0.05-0.37,0.07-0.55c0.07-0.09,0.14-0.17,0.21-0.26C129.22,14.71,129.3,14.62,129.37,14.54z"/>
        <path className="col1" d="M40.23,71.3c-0.28-0.07-0.56-0.15-0.84-0.22c0.02-0.08,0.05-0.17,0.07-0.25c0.3,0.11,0.6,0.23,0.89,0.34
					C40.31,71.21,40.27,71.26,40.23,71.3z"/>
        <path className="col1" d="M130.55,13.32c0.08,0,0.15-0.02,0.22-0.06c0.09,0.02,0.17,0.04,0.26,0.06c-0.24,0.16-0.48,0.32-0.72,0.48
					c0-0.08,0-0.16,0-0.25C130.39,13.48,130.47,13.4,130.55,13.32z"/>
        <path className="col1" d="M180.1,36.08c0.08,0.13,0.16,0.26,0.24,0.39c-0.05,0.02-0.1,0.03-0.16,0.05c-0.02-0.15-0.04-0.3-0.06-0.46
					C180.13,36.06,180.1,36.08,180.1,36.08z"/>
        <path className="col1" d="M203.59,46.12c0.1,0.03,0.19,0.06,0.29,0.08c-0.04,0.04-0.07,0.07-0.11,0.11
					C203.71,46.25,203.65,46.19,203.59,46.12C203.59,46.12,203.59,46.12,203.59,46.12z"/>
        <path className="col1" d="M68.29,99.75c0.11,0.04,0.21,0.08,0.32,0.11c-0.02,0.03-0.07,0.09-0.07,0.08
					c-0.09-0.05-0.18-0.11-0.27-0.17L68.29,99.75z"/>
        <path className="col1" d="M179.91,35.56c0.06,0.17,0.13,0.35,0.19,0.52c0,0,0.02-0.02,0.02-0.02c-0.12-0.13-0.24-0.26-0.36-0.38
					C179.81,35.64,179.86,35.6,179.91,35.56z"/>
        <path className="col1" d="M38.76,72.09c-0.06,0.04-0.11,0.1-0.15,0.09c-0.05,0-0.1-0.06-0.16-0.1c0.03-0.02,0.06-0.07,0.09-0.06
					C38.61,72.03,38.68,72.06,38.76,72.09z"/>
        <path className="col1" d="M40.17,75.5c0.07-0.09,0.15-0.18,0.22-0.27c0.05,0.05,0.1,0.1,0.15,0.16c-0.09,0.07-0.17,0.15-0.26,0.22
					C40.25,75.57,40.21,75.53,40.17,75.5z"/>
        <path className="col1" d="M40.6,71.41c0.06,0.1,0.12,0.19,0.19,0.29c-0.05,0.05-0.09,0.1-0.14,0.15c-0.07-0.1-0.13-0.19-0.2-0.29
					C40.5,71.51,40.55,71.46,40.6,71.41z"/>
        <path className="col1" d="M40.45,71.56c-0.07-0.09-0.15-0.17-0.22-0.26c0.04-0.04,0.08-0.09,0.13-0.13
					c0.08,0.08,0.16,0.16,0.24,0.24C40.55,71.46,40.5,71.51,40.45,71.56z"/>
        <path className="col1" d="M68.26,99.78c-0.08-0.07-0.17-0.15-0.25-0.22c0,0,0.05-0.05,0.05-0.05c0.08,0.08,0.15,0.17,0.23,0.25
					C68.29,99.75,68.26,99.78,68.26,99.78z"/>
        <polygon className="col1" points="83.95,66.66 83.92,66.58 83.99,66.59 				"/>
        <path className="col1" d="M129.6,14.29c0.08-0.02,0.15-0.02,0.23-0.01c-0.08,0.08-0.16,0.16-0.25,0.25
					C129.58,14.44,129.59,14.37,129.6,14.29z"/>
        <path className="col1" d="M129.84,14.04c0.08-0.02,0.16-0.02,0.24-0.01c-0.08,0.08-0.16,0.16-0.24,0.24
					C129.82,14.2,129.82,14.12,129.84,14.04z"/>
        <path className="col1" d="M130.07,13.8c0.08,0,0.16,0,0.24-0.01c-0.08,0.08-0.16,0.16-0.24,0.24
					C130.07,13.96,130.07,13.88,130.07,13.8z"/>
        <path className="col1" d="M80.19,126.65c-0.03-0.06-0.05-0.12-0.08-0.18c0.05,0.03,0.11,0.07,0.16,0.1
					C80.27,126.56,80.19,126.65,80.19,126.65z"/>
        <path className="col1" d="M216.44,127.23c-0.14,0.1-0.28,0.21-0.42,0.31c-0.73,0.17-1.46,0.35-2.38,0.57
					c-0.09,0.97-0.2,2.04-0.33,3.42c-0.2-0.88-0.34-1.48-0.48-2.07c0.06-0.66,0.12-1.32,0.16-1.76c1.08-0.49,2.04-0.93,3-1.36
					c-0.3-0.83-0.51-1.7-0.91-2.48c-0.46-0.89-1.08-1.7-1.63-2.55c0.17-0.09,0.47-0.27,0.49-0.25c0.92,1.27,1.87,2.51,2.7,3.84
					C217.1,125.64,216.99,126.49,216.44,127.23z"/>
        <path className="col1" d="M184.24,183.52c0.43,0.26,1.06,0.42,1.25,0.79c0.74,1.47,2,1.66,3.41,1.65c0.43,0,0.86,0.04,1.39,0.06
					c-2.74,0.76-4.72-0.14-6.13-2.41C184.19,183.58,184.22,183.55,184.24,183.52z"/>
        <path className="col1" d="M84.37,193.12c0.86,0.1,1.74,0.15,2.59,0.31c0.9,0.16,1.72,0.5,2.04,1.47c-1.58-0.58-3.13-1.15-4.69-1.72
					L84.37,193.12z"/>
        <path className="col1" d="M204.25,191.38c-1.5,0.3-3.01,0.6-4.51,0.91c-0.03-0.13-0.05-0.25-0.08-0.38
					C201.28,192.14,202.43,190.13,204.25,191.38z"/>
        <path className="col1" d="M221.45,165.66c-0.68-0.17-1.36-0.44-2.06-0.5c-1.72-0.14-1.82-0.21-1.75-2.21
					c0.03,1.88,1.42,1.69,2.58,1.93c0.47,0.1,0.89,0.45,1.33,0.68C221.55,165.63,221.52,165.66,221.45,165.66z"/>
        <path className="col1" d="M123.37,202.26c-0.09,0.02-0.19,0.03-0.28,0.05c-1.24-0.41-2.77-0.04-3.67-1.37
					c0.06-0.05,0.11-0.11,0.16-0.16c0.94,0.33,1.89,0.65,2.82,1C122.74,201.9,123.05,202.09,123.37,202.26z"/>
        <path className="col1" d="M181.17,186.23c0.85-0.06,1.69-0.11,2.54-0.17c0.01,0.13,0.02,0.27,0.03,0.4
					c-0.84,0.09-1.68,0.17-2.52,0.26C181.21,186.56,181.19,186.39,181.17,186.23z"/>
        <path className="col1" d="M117.93,199.89c-0.44-0.02-0.88-0.04-1.33-0.06c0-0.05,0-0.11,0-0.16c0.45,0,0.9,0,1.35,0
					C117.95,199.75,117.94,199.82,117.93,199.89z"/>
        <path className="col1" d="M183.48,183.6c-0.17,0.21-0.35,0.42-0.52,0.63c-0.07-0.08-0.14-0.16-0.22-0.23
					c0.23-0.15,0.46-0.3,0.68-0.45L183.48,183.6z"/>
        <path className="col1" d="M230.43,165.12c-0.1,0.13-0.2,0.25-0.3,0.38c-0.03-0.03-0.05-0.05-0.08-0.08
					C230.18,165.32,230.31,165.23,230.43,165.12C230.43,165.13,230.43,165.12,230.43,165.12z"/>
        <path className="col1"
              d="M123.85,202.74c-0.16-0.16-0.33-0.33-0.49-0.49C123.53,202.42,123.69,202.58,123.85,202.74z"/>
        <path className="col1" d="M183.43,183.54c0.27-0.01,0.54-0.01,0.81-0.02c-0.03,0.03-0.05,0.06-0.08,0.09
					c-0.23,0-0.45-0.01-0.68-0.01C183.48,183.6,183.43,183.54,183.43,183.54z"/>
        <path className="col1" d="M163.45,186.35c-0.16,0.03-0.32,0.07-0.48,0.1c-0.01-0.05-0.03-0.1-0.04-0.14c0.12-0.05,0.24-0.1,0.37-0.15
					C163.35,186.22,163.4,186.29,163.45,186.35z"/>
        <path className="col1"
              d="M215.8,132.1c0.06,0.09,0.13,0.18,0.19,0.27c-0.06-0.09-0.12-0.19-0.18-0.28L215.8,132.1z"/>
        <path className="col1" d="M84.31,193.18c-0.06-0.04-0.12-0.09-0.18-0.13c0.08,0.03,0.16,0.05,0.24,0.08
					C84.37,193.12,84.31,193.18,84.31,193.18z"/>
        <polygon className="col1" points="199.1,191.85 199.19,191.82 199.18,191.89 				"/>
        <path className="col1" d="M118.42,199.94c0.02,0,0.05,0,0.07,0c-0.01,0.04-0.03,0.08-0.04,0.13c-0.02-0.02-0.05-0.04-0.07-0.07
					C118.37,199.99,118.4,199.96,118.42,199.94z"/>
        <path className="col1" d="M118.96,200.44c-0.04-0.08-0.08-0.16-0.12-0.24c0.09,0.04,0.17,0.07,0.26,0.11
					C119.05,200.35,119,200.39,118.96,200.44z"/>
        <path className="col1" d="M119.19,200.68c-0.08-0.08-0.16-0.16-0.24-0.24c0.05-0.04,0.1-0.09,0.14-0.14
					c0.08,0.08,0.16,0.16,0.25,0.23C119.3,200.58,119.25,200.63,119.19,200.68z"/>
        <path className="col1" d="M119.42,200.94c-0.07-0.09-0.15-0.17-0.22-0.26c0.05-0.05,0.1-0.1,0.15-0.15
					c0.08,0.08,0.16,0.16,0.24,0.24C119.53,200.83,119.48,200.88,119.42,200.94z"/>
        <path className="col1" d="M215.81,132.09c-0.04-0.03-0.08-0.06-0.12-0.1c0.02,0.01,0.05,0,0.06,0.02c0.02,0.03,0.03,0.06,0.04,0.09
					C215.8,132.1,215.81,132.09,215.81,132.09z"/>
        <path className="col1"
              d="M124.21,202.98c-0.12-0.08-0.25-0.17-0.37-0.25C123.96,202.82,124.08,202.9,124.21,202.98z"/>
        <path className="col1" d="M26.25,147.7c0.41,0.9,0.72,1.57,1.03,2.24c-0.13,0.05-0.25,0.11-0.38,0.16
					C27,149.28,25.19,149.18,26.25,147.7z"/>
			</g>
		</g>
	</g>
</g>
      <g id="Tourisme_xA0_Image_00000175313651853113987890000005771896466103241100_">
	<g>
		<g>
			<g>
				<path className="col0" d="M197.65,81.6c2.21,0.4,4.45,0.69,6.63,1.22c15.94,3.83,27.58,17.06,29.27,33.35
					c1.33,12.83-2.19,24.09-11.44,33.28c-6.97,6.93-15.56,10.46-25.33,11.15c-8.73,0.61-17.01-0.98-24.5-5.59
					c-11-6.76-17.13-16.72-18.41-29.5c-1.26-12.57,2.42-23.57,11.4-32.59c6.4-6.43,14.31-9.9,23.28-11.05
					c0.25-0.03,0.49-0.17,0.73-0.25C192.07,81.6,194.86,81.6,197.65,81.6z M219.74,121.1c-0.08-1.04-0.15-2.27-0.29-3.49
					c-1.34-12.01-10.29-22.21-22.92-23.46c-9.06-0.9-16.81,1.9-22.59,9.02c-6.08,7.49-7.58,16.19-5.17,25.38
					c5.01,19.13,25.71,24.4,39.23,15.5C216.1,138.72,219.46,130.75,219.74,121.1z"/>
        <path className="col0" d="M471.28,81.6c1.47,0.14,2.94,0.31,4.42,0.43c7.09,0.59,13.58,2.87,19.51,6.78
					c3.11,2.05,4.04,5.83,2.14,8.81c-1.89,2.95-5.77,3.7-8.96,1.96c-2.99-1.63-6.02-3.26-9.21-4.42c-4.4-1.6-9.01-1.97-13.62-0.68
					c-2.2,0.62-4.08,1.74-5.56,3.51c-2.41,2.89-2.58,9.02,1.77,11.79c3.41,2.17,7.23,3.25,11.08,4.25
					c5.46,1.41,10.99,2.61,16.12,5.08c4.98,2.39,9.25,5.59,11.03,11.09c3.21,9.96,0.51,23.45-14.24,28.39
					c-8.76,2.94-17.57,2.32-26.3-0.3c-5.23-1.57-10-4.09-14.38-7.34c-3.06-2.27-3.69-6.13-1.52-9.04c2.1-2.81,6.37-3.36,9.28-1.11
					c4.67,3.61,9.76,6.28,15.64,7.21c4.41,0.7,8.81,0.85,13.05-0.87c4.79-1.95,7.01-6.18,5.8-10.89c-0.46-1.79-1.54-3.11-3.01-4.15
					c-2.95-2.08-6.37-3.01-9.77-4c-5.23-1.51-10.51-2.84-15.65-4.6c-2.55-0.88-4.99-2.32-7.19-3.91c-4.9-3.52-6.69-8.65-6.9-14.5
					c-0.44-11.9,7.53-20.11,18.75-22.52c2.09-0.45,4.26-0.51,6.39-0.77c0.29-0.03,0.56-0.15,0.84-0.23
					C470.96,81.6,471.12,81.6,471.28,81.6z"/>
        <path className="col0" d="M84.28,87.8c0.14-0.32,0.29-0.63,0.43-0.95c1.08-2.51,2.98-3.93,5.72-3.94c17.06-0.03,34.12-0.03,51.18,0
					c3.36,0,6.04,2.88,6.01,6.29c-0.03,3.4-2.74,6.18-6.13,6.19c-5.78,0.03-11.56,0.01-17.34,0.01c-0.43,0-0.86,0-1.4,0
					c0,0.58,0,1.04,0,1.51c0,18.73,0,37.45,0,56.18c0,3.43-2.47,6.35-5.69,6.76c-3.76,0.47-6.95-1.63-7.76-5.15
					c-0.13-0.57-0.14-1.18-0.14-1.77c-0.01-18.65-0.01-37.29-0.01-55.94c0-0.47,0-0.95,0-1.59c-0.47,0-0.89,0-1.31,0
					c-5.3,0-10.61-0.11-15.9,0.04c-3.83,0.11-6.43-1.32-7.66-5.01C84.28,89.55,84.28,88.68,84.28,87.8z"/>
        <path className="col0" d="M578.91,107.55c-0.39,0.55-0.61,0.85-0.82,1.17c-5.96,8.92-11.92,17.84-17.88,26.77
					c-1.14,1.71-2.52,3.06-4.67,3.39c-2.08,0.32-3.88-0.24-5.33-1.75c-0.63-0.66-1.17-1.41-1.68-2.16
					c-5.75-8.59-11.49-17.18-17.24-25.77c-0.24-0.36-0.49-0.72-0.98-1.07c0,0.4,0,0.8,0,1.2c0,14.67,0,29.34,0,44.01
					c0,3.17-2.09,5.76-5.16,6.44c-3.17,0.7-6.29-0.76-7.53-3.63c-0.37-0.85-0.6-1.83-0.6-2.76c-0.03-21.39-0.03-42.78-0.02-64.17
					c0-3.8,2.95-6.52,6.75-6.76c3.89-0.24,6.42,1.35,8.45,4.58c7.28,11.57,14.71,23.04,22.09,34.55c0.11,0.17,0.22,0.33,0.41,0.62
					c0.28-0.4,0.53-0.74,0.75-1.09c7.45-11.6,14.93-23.19,22.31-34.83c2.49-3.93,5.71-4.43,10.1-3.41c2.74,0.64,4.49,3.36,4.49,6.22
					c0.01,21.43,0.01,42.86,0,64.29c0,3.75-2.9,6.5-6.76,6.5c-3.67-0.01-6.66-2.92-6.66-6.53c-0.01-14.75,0-29.5,0-44.25
					C578.91,108.68,578.91,108.26,578.91,107.55z"/>
        <path className="col0" d="M378.38,130.12c3.57,4.5,7.06,8.88,10.54,13.28c1.66,2.09,3.39,4.12,4.92,6.3c2.81,3.99,1,9.03-3.62,10.04
					c-3.15,0.68-5.81-0.32-7.83-2.89c-4.59-5.85-9.18-11.7-13.77-17.55c-1.55-1.97-3.08-3.95-4.66-5.9
					c-0.26-0.32-0.75-0.67-1.13-0.67c-5.26-0.04-10.52-0.03-15.78-0.02c-0.11,0-0.22,0.06-0.49,0.13c0,0.4,0,0.82,0,1.25
					c0,6.28,0,12.56,0,18.84c0,4.11-2.74,6.97-6.68,6.97c-4.04,0-6.81-2.83-6.81-6.97c0-20.99,0-41.98,0-62.97
					c0-4.22,2.8-7.04,7.02-7.04c9.41-0.01,18.81-0.05,28.22,0.02c5.31,0.04,10.45,1.03,15.22,3.47c7.82,3.99,11.6,10.58,12.14,19.14
					c0.29,4.58-0.38,9.04-2.56,13.17c-2.89,5.45-7.54,8.79-13.23,10.88C379.44,129.74,378.99,129.9,378.38,130.12z M346.61,120.71
					c0.29,0.02,0.45,0.05,0.6,0.05c6.45,0,12.9,0.02,19.36-0.01c3.39-0.01,6.62-0.69,9.61-2.4c7.61-4.34,7.2-15.32,2.02-19.58
					c-3.18-2.61-6.93-3.5-10.89-3.56c-6.49-0.09-12.98-0.05-19.47-0.06c-0.39,0-0.77,0-1.22,0
					C346.61,103.73,346.61,112.18,346.61,120.71z"/>
        <path className="col0" d="M625.5,126.82c0,6.92,0,13.63,0,20.49c0.52,0,0.98,0,1.45,0c12.08,0,24.15,0.01,36.23-0.01
					c2.85,0,5.04,1.05,6.25,3.72c1.73,3.83-1.12,8.32-5.33,8.33c-15.23,0.03-30.45,0.03-45.68,0c-3.6-0.01-6.45-2.98-6.46-6.62
					c-0.02-7.35-0.01-14.71-0.01-22.06c0-13.56,0-27.11,0-40.67c0-4.3,2.78-7.1,7.08-7.1c14.67-0.01,29.33-0.01,44,0
					c3.63,0,6.32,2.58,6.32,6.04c0,3.46-2.65,6.01-6.34,6.02c-12,0.01-23.99,0-35.99,0c-0.47,0-0.95,0-1.5,0c0,6.61,0,13.14,0,19.84
					c0.46,0,0.92,0,1.39,0c10.48,0,20.96-0.01,31.45,0c3.04,0,5.52,2.14,5.99,5.11c0.47,2.98-1.16,5.69-4.04,6.6
					c-0.71,0.22-1.48,0.3-2.23,0.3c-10.4,0.02-20.81,0.01-31.21,0.01C626.44,126.82,626.01,126.82,625.5,126.82z"/>
        <path className="col0" d="M248.88,109.3c0-6.68,0-13.36,0-20.03c0-4,2.85-6.91,6.77-6.92c3.89-0.01,6.71,2.91,6.71,6.96
					c0,12.16-0.01,24.33,0,36.49c0,3.79,0.36,7.53,1.77,11.1c2.68,6.79,7.82,10.26,14.97,11.03c3,0.32,5.97,0.16,8.88-0.63
					c7.39-2,11.19-7.18,12.48-14.41c0.39-2.17,0.52-4.42,0.53-6.64c0.05-12.4,0.02-24.8,0.03-37.21c0-3.44,2.35-6.13,5.79-6.66
					c3.28-0.51,6.46,1.46,7.4,4.65c0.21,0.71,0.28,1.49,0.28,2.23c0.02,12.84,0.06,25.68-0.01,38.52c-0.03,4.99-0.85,9.87-2.7,14.55
					c-3.31,8.39-9.32,13.91-17.93,16.51c-7.68,2.32-15.49,2.42-23.23,0.39c-11.22-2.96-18-10.37-20.53-21.55
					c-0.77-3.38-1.06-6.91-1.16-10.38C248.74,121.31,248.88,115.31,248.88,109.3z"/>
        <path className="col0" d="M426.51,121.2c0,10.69,0.01,21.39,0,32.08c0,3.26-2.41,6.11-5.49,6.56c-3.57,0.52-6.69-1.31-7.68-4.58
					c-0.22-0.71-0.3-1.48-0.3-2.22c-0.02-21.27-0.01-42.54-0.01-63.81c0-4,2.87-6.89,6.81-6.88c3.83,0.01,6.67,2.94,6.68,6.89
					C426.52,99.89,426.51,110.55,426.51,121.2z"/>
			</g>
		</g>
	</g>
</g>
      <g id="CA_xA0_Image_00000093887201270244528850000016436765324949197716_">
	<g>
		<g>
			<g>
				<path className="col2" d="M676.03,186.72c-0.01,2.62-0.04,5.24-0.01,7.86c0.01,0.7-0.24,1.06-0.83,1.37
					c-5.43,2.89-10.84,5.81-16.26,8.7c-2.35,1.25-4.11,0.62-5.2-1.82c-0.59-1.34-1.17-2.68-1.73-3.96
					c6.27-2.39,11.91-5.95,17.85-8.92c2.02-1.01,3.97-2.14,5.95-3.22C675.87,186.72,675.95,186.72,676.03,186.72z"/>
        <path className="col2" d="M620.81,201.35c-6.66-0.4-12.54,1.87-18.18,4.92c-5.37,2.91-10.4,6.31-14.38,11.03
					c-1.63,1.93-2.91,4.06-3.41,6.59c-0.55,2.81,0.36,4.88,2.88,6.3c2.82,1.59,5.95,1.92,9.1,2.03c9,0.3,17.63-1.65,25.92-4.93
					c6.33-2.51,12.45-5.57,18.66-8.38c0.54-0.24,0.94-0.44,0.62-1.23c-1.88-4.63-3.7-9.28-5.54-13.93c-0.05-0.13-0.13-0.25-0.3-0.59
					c-0.86,1.84-1.62,3.47-2.38,5.11c-1.59,3.41-3.28,6.79-4.76,10.25c-1.35,3.17-3.68,5.19-6.75,6.51
					c-3.38,1.46-6.96,2.16-10.59,2.67c-0.26,0.04-0.54,0-0.99,0c0.26-0.53,0.45-0.95,0.66-1.36c5.88-11.16,11.78-22.31,17.64-33.48
					c0.36-0.69,0.76-0.95,1.55-0.94c3.94,0.05,7.87,0.05,11.81,0c0.88-0.01,1.23,0.33,1.57,1.06c2.87,6.15,5.77,12.27,8.67,18.41
					c0.17,0.35,0.35,0.7,0.57,1.14c7.59-4.17,15.12-8.31,22.82-12.53c0,3.74,0.02,7.3-0.04,10.85c-0.01,0.34-0.47,0.78-0.83,0.99
					c-5.42,3.17-10.85,6.3-16.28,9.44c-0.38,0.22-0.75,0.43-1.25,0.71c3.03,6.44,6.05,12.84,9.05,19.22
					c-0.11,0.1-0.17,0.19-0.23,0.19c-4.09-0.02-8.19-0.02-12.28-0.08c-1.74-0.02-2.84-0.93-3.48-2.57
					c-1.26-3.21-2.62-6.38-3.93-9.57c-0.15-0.36-0.31-0.71-0.5-1.16c-0.46,0.21-0.85,0.37-1.23,0.55
					c-7.78,3.79-15.51,7.67-23.82,10.22c-6.48,1.99-13.07,3.38-19.9,3.21c-5.03-0.13-9.84-1.18-14.28-3.66
					c-4.33-2.41-6.94-6.17-8.43-10.81c-0.08-0.26-0.12-0.54-0.12-0.82c-0.01-3.65-0.02-7.31,0.01-10.96c0-0.5,0.18-1.01,0.37-1.48
					c2.4-5.94,6.66-10.38,11.68-14.15c6.13-4.61,12.91-7.75,20.59-8.75c0.98-0.13,1.98-0.24,2.97-0.26
					c2.15-0.04,3.74,0.89,4.71,2.83C619.96,196.28,620.44,198.79,620.81,201.35z"/>
        <path className="col2" d="M669.12,247.09c0,2.56,0,5.09,0,7.7c-30.02,0-60.05,0-90.13,0c0-2.56,0-5.09,0-7.7
					C609.02,247.09,639.04,247.09,669.12,247.09z"/>
			</g>
		</g>
	</g>
</g>
      <g id="by_xA0_Image_00000103965133990700865260000001080262582398305947_">
	<g>
		<g>
			<g>
				<path className="col2" d="M557.04,186.48c0.17,0.59,0.46,1.17,0.5,1.77c0.08,1.23,0.02,2.46,0.02,3.94
					c1.29-1.38,2.72-2.24,4.43-1.76c1.02,0.28,2.11,0.86,2.82,1.63c1.89,2.08,1.69,5.96-0.23,7.91c-1.85,1.88-4.26,1.84-6.8-0.07
					c-0.32,0.41-0.55,0.99-0.96,1.16c-0.39,0.16-0.97-0.1-1.46-0.18c0-4.8,0-9.6,0-14.4C555.93,186.48,556.48,186.48,557.04,186.48z
					 M560.65,192.44c-1.78,0-3.12,1.44-3.13,3.38c-0.01,1.94,1.31,3.4,3.09,3.42c1.81,0.02,3.09-1.38,3.11-3.37
					C563.73,193.91,562.42,192.45,560.65,192.44z"/>
        <path className="col2" d="M572.65,198.34c0.89-2.35,1.63-4.32,2.39-6.29c0.13-0.33,0.2-0.78,0.45-0.96c0.4-0.28,1.03-0.61,1.38-0.47
					c0.35,0.14,0.63,0.84,0.65,1.3c0.02,0.48-0.32,0.97-0.52,1.45c-1.22,2.9-2.39,5.83-3.68,8.7c-0.92,2.05-2.48,2.71-4.49,2.13
					c-0.68-0.2-1.36-0.5-1.13-1.39c0.21-0.82,0.88-0.68,1.56-0.68c0.61,0,1.32-0.15,1.81-0.49c0.68-0.47,0.23-1.13-0.03-1.71
					c-1.1-2.43-2.18-4.88-3.28-7.31c-0.35-0.78-0.64-1.59,0.37-2.03c1.06-0.47,1.51,0.3,1.85,1.14
					C570.82,193.81,571.66,195.91,572.65,198.34z"/>
			</g>
		</g>
	</g>
</g>
</svg>
      </>
  )
}