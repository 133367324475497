import React, {useState, useEffect, Suspense} from 'react';
import { RequirementsProps } from '../../tools/context';
import {TravelPlannerResultsConfigType} from "./types";

export interface TravelPlanerMapProps {
  journey: any;
  config: TravelPlannerResultsConfigType;
  inputs: any;
  requirements: RequirementsProps;
  doAction?: (action: string) => void;
}

const TravelPlanerMap = (props: TravelPlanerMapProps) => {
  const [isClient, setIsClient] = useState(false);
  const LeafletMap = React.lazy(() => import('./LeafletMap')); // Moved outside the isClient check

  useEffect(() => {
    setIsClient(true); // Set client state to true when component mounts
  }, []);

  // If not on the client side or data is not available, return null or a placeholder
  if (!isClient)
    return null; // or you can return a placeholder/loading state if preferred

  return (
    <Suspense fallback={<div>Loading map...</div>}>
      <LeafletMap key={Date.now()} {...props} />
    </Suspense>
  );
};

export default TravelPlanerMap;
