import React from "react";
import {PictoProps} from "./index";

export const CircleArrowRight = (props: PictoProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z" stroke="#009989" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6666 7.99984H5.33325" stroke="#009989" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.66675 6L10.6667 8L8.66675 10" stroke="#009989" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
