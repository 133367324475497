import React, { Fragment } from "react";
import { imageUrlFor } from "../../tools/sanity";
import { RequirementsProps } from "../../tools/context";
import { LinkProps, Picto } from "../index";

export interface ContentShowcaseProps {
  requirements: RequirementsProps;
  title: LinkProps;
  description: string;
  links: LinkProps[];
  ctas: LinkProps[];
  images: {
    image: any;
    title: string;
    subtitle: string;
    link: string;
  }[];
  className?: string;
}

const Link: React.FC<LinkProps> = ({ link, picto, text }) => (
  <a href={link} className="link">
    {picto && <Picto iconKey={picto} />}
    {text}
  </a>
);

const Renderer = (props: ContentShowcaseProps) => {
  const { title, description, links, ctas, images } = props;

  return (
    <div className={`ContentShowcase ${props.className}`}>
      <div className="informations">
        <a className="title" href={title.link}>
          {title.picto && <Picto iconKey={title.picto} />}
          <h2 className="title">{title.text}</h2>
        </a>
        {links?.length ? (
          <div className="links">
            {links.map((link, index) => (
              <Link key={index} {...link} />
            ))}
          </div>
        ) : null}
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {ctas?.length ? (
          <div className="ctas">
            {ctas.map((cta, index) => (
              <Link key={index} {...cta} />
            ))}
          </div>
        ) : null}
      </div>
      <div className="images-wrapper">
        <div className="images">
          {images.map((image, index) => {
            const Element = image.link ? "a" : "div";

            return (
              <div className="image-container" key={index}>
                <Element className="image" href={image.link || ""}>
                  <img
                    src={imageUrlFor(image.image, props.requirements).url()}
                    alt={image.title}
                  />
                  {image.title || image.subtitle ? (
                    <Fragment>
                      <div className="overlay" />
                      <div className="text">
                        {image.title ? (
                          <div className="title">{image.title}</div>
                        ) : null}
                        {image.subtitle ? (
                          <div className="subtitle">{image.subtitle}</div>
                        ) : null}
                      </div>
                    </Fragment>
                  ) : null}
                </Element>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const preloader = async (data: any, requirements: RequirementsProps) => {
  return data;
};

const ContentShowcase = { Renderer, preloader };

export default ContentShowcase;
export { Renderer, preloader };
