import * as React from "react";
import {RequirementsProps} from "../../../tools/context";
import {Picto} from "../../Picto";
import {useTranslation} from "../../../tools/i18n";
import {Button} from "../../Button";

export interface ConfirmationPopupProps {
  close: () => void
  requirements: RequirementsProps
  confirmation?: boolean
}

const ConfirmationPopup = ({close, requirements, confirmation}: ConfirmationPopupProps) => {
  const {t} = useTranslation();
  const url = `${requirements.config.clientUrl}promotions`;
  const res:any = (key) => t(`shop.advantages.discount.tracked.confirmation.${key}`);
  const pictos = [
    <Picto iconKey={"howto-advantage1"} />,
    <Picto iconKey={"howto-advantage2"} />,
    <Picto iconKey={"howto-advantage3"} />,
  ]
  return <div className="popup confirmationPopup" onClick={close}>
    <div className="popupBlock" onClick={(e) => e.stopPropagation()}>
      <button className="close" onClick={close}><Picto iconKey={"cross"}/></button>
      {confirmation && <div className="title">
          <div>{res('title')}</div>
          {res('subtitle')}
        </div>
      }
      <div className="content">
        {confirmation && <span>{res('now')}</span>}
        <div className="list">
          {res('steps').filter(r => r.title).map((s, i) => <React.Fragment key={`conf_${i}`}>
            {!!i && <div className="vseparator desktop" />}
          <div>
            <div className="logo">{pictos[i]}</div>
            {!i && confirmation && <Picto iconKey={"circle-check"} />}
            <div className='ltitle'>{s.title}</div>
            <div className="text">{s.text}</div>
          </div>
          </React.Fragment>)}
        </div>
      </div>
      <div className="buttons">
        {confirmation ? <>
            <Button theme={"white"} onClick={close}>{res('continue')}</Button>
            <a href={url} className="Button client">{res('use')}</a>
          </>
          : <Button theme={"client"} onClick={close}>{res('close')}</Button>}
      </div>
    </div>
  </div>
}

export default ConfirmationPopup;
