import React from "react";
import {PictoProps} from "../index";

export const ArmorATable = (props: PictoProps) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill={props.fill} stroke="none">
<path d="M3935 4946 c-37 -17 -871 -854 -912 -916 -97 -145 -133 -262 -140
-460 l-5 -145 -73 -76 c-79 -81 -94 -115 -80 -186 9 -49 69 -109 118 -118 75
-14 105 0 205 98 134 132 145 157 153 367 7 174 17 223 66 310 15 26 202 222
454 475 373 376 428 435 434 468 25 132 -101 237 -220 183z"/>
<path d="M501 4783 c-70 -35 -180 -198 -225 -336 -128 -385 20 -930 371 -1374
34 -43 293 -308 575 -589 507 -505 514 -512 598 -553 140 -68 276 -86 420 -56
101 21 131 11 230 -77 94 -84 106 -111 114 -243 5 -99 28 -175 76 -246 42 -64
966 -1003 1030 -1048 216 -150 495 -126 680 59 98 98 160 243 160 375 0 65
-27 171 -61 243 -30 63 -141 176 -1913 1950 -1035 1035 -1895 1889 -1912 1897
-42 20 -101 19 -143 -2z m1886 -2175 c979 -981 1789 -1799 1799 -1818 11 -22
18 -57 19 -95 0 -121 -89 -210 -210 -210 -35 1 -72 7 -90 17 -27 14 -361 346
-822 818 -89 91 -167 178 -172 194 -6 15 -11 60 -12 99 0 93 -26 189 -69 262
-34 57 -182 202 -257 253 -97 67 -250 91 -391 62 -78 -16 -151 -8 -216 24 -76
37 -1034 1002 -1129 1136 -122 175 -220 387 -263 575 -26 112 -26 316 0 403
10 34 21 62 25 62 3 0 808 -802 1788 -1782z"/>
<path d="M4335 4546 c-16 -8 -222 -207 -457 -442 -373 -375 -427 -434 -433
-466 -11 -61 3 -108 44 -149 41 -41 88 -55 149 -44 32 6 91 60 469 438 310
309 436 441 444 466 22 67 -2 139 -63 184 -34 25 -113 32 -153 13z"/>
<path d="M4740 4149 c-14 -6 -216 -200 -450 -433 -251 -250 -443 -434 -470
-449 -87 -49 -136 -59 -310 -66 -210 -8 -235 -19 -367 -153 -98 -100 -112
-130 -98 -205 9 -49 69 -109 118 -118 71 -14 105 1 186 80 l76 73 145 5 c207
8 327 46 475 152 33 24 250 234 482 467 455 456 445 444 429 533 -17 91 -127
149 -216 114z"/>
<path d="M1218 1987 c-14 -7 -235 -219 -491 -472 -464 -459 -465 -460 -505
-545 -48 -101 -67 -195 -59 -296 20 -227 170 -414 397 -490 76 -26 244 -26
320 0 155 52 136 35 888 796 411 416 404 408 387 497 -9 49 -69 109 -118 118
-92 18 -66 39 -657 -549 -579 -577 -567 -566 -660 -566 -124 0 -240 116 -240
240 0 92 -5 87 486 580 499 502 486 486 470 575 -18 92 -131 151 -218 112z"/>
</g>
</svg>

)
