export default {
  key: "orleans",
  canonicalUrl: "citypass.tourisme-orleansmetropole.com",
  datalayerId: "GTM-N7FRS7X",
  customerId: 683,
  icon: "orleans.ico",
  headerLogo: "orleans.png",
  footerLogo: "orleans_monochrome.png",
  axeptioClientId: "",
  axeptioCookiesVersion: "orleans.fr",
  clientUrl: "https://client.tourisme-orleansmetropole.com/",
  proUrl: "https://partenaire.tourisme-orleansmetropole.com/",
  sanity: {
    base: {
      projectId: "kspdz90e",
      dataset: "production",
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        "skJuqEGpRMLlSWVeohTqFpRHeCENhMP0fIBONEtbKZ61Ioaqwd3fvd5ptw8LtOuuBAsTseRJG4cUkYpsjjo2VwKVaARSaTuv9rapTCwuYqgqqtaYteb2GVSc9ILgzZ1n4V4EMoF8NQX8pf9F3flDoJBd9baxH0LzVc410N6l3iZG0gChPd4r",
    },
  },
  dev: {
    clientUrl: "https://client.tourisme-orleansmetropole.com/connexion-client",
    proUrl: "https://partenaire.tourisme-orleansmetropole.com/connexion",
  },
};
