import {createContext} from "react";
import {Endpoints} from "./Constants";

export interface RequirementsProps {
  path: string
  href: string
  host: string,
  sellerId?: string
  shortcode?:string
  lat?: string
  lon?: string
  location?: string
  department?: string
  departement?: string
  categ?: string[]
  labels?: string[]
  filters?: string[]
  parameters: string[]
  coupon?: string
  hash?: string
  query?: string
  aroundme?: boolean
  mapOpened?: boolean
  page?: number
  partner: string
  environment: string
  endpoint: string
  campaign?:number
  config: any
  sponso?: boolean
  useragent:string
}

export const defReq: RequirementsProps = {
  path: "/",
  href: '',
  host: '',
  sellerId: null,
  shortcode: null,
  lat: null,
  lon: null,
  location: null,
  department: null,
  departement: null,
  categ: null,
  labels: null,
  filters: null,
  query: null,
  parameters: [],
  partner: null,
  environment: 'prod',
  endpoint: Endpoints.prod,
  campaign:null,
  config: {},
  useragent:""
}

export const Requirements:React.Context<RequirementsProps> = createContext(defReq);