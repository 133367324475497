import { Profile, DirectionData } from "./apiTypes/direction";

const MAPBOX_PUBLIC_TOKEN =
  "pk.eyJ1IjoiZmFiaWVuZ3Jvc3lldXgiLCJhIjoiY2s4OHV3NXk1MDAxbTNnbzJpc3NjZnBkdyJ9.aXGFMPZ_7ZxDS3MRZXTGHw";

export async function mapboxDirectionApi(
  profile: Profile,
  coordinates: Coordinates[]
) {
  const coordinatesParam = mapboxCoordinatesParam(coordinates);
  const urlPath = `directions/v5/mapbox/${profile}/${coordinatesParam}`;
  const cacheKey = urlPath;
  const url = mapboxUrl(urlPath);
  const cachedDirection = localStorage.getItem(cacheKey);
  if (cachedDirection) {
    return {
      response: "from-cache",
      direction: JSON.parse(cachedDirection),
    };
  }
  const response = await fetch(`${url}&geometries=geojson`);
  const direction = (await response.json()) as DirectionData;
  localStorage.setItem(cacheKey, JSON.stringify(direction));
  return { response, direction };
}

export type Coordinates = {
  latitude: number;
  longitude: number;
};

function mapboxCoordinatesParam(coordinates: Coordinates[]) {
  return coordinates?.map((c) => `${c.longitude},${c.latitude}`).join(";");
}

function mapboxUrl(endpoint: string) {
  return `https://api.mapbox.com/${endpoint}?access_token=${MAPBOX_PUBLIC_TOKEN}`;
}
