import React from "react";
import {RequirementsProps} from "../../tools/context";
import { useTranslation } from "react-i18next";

export interface LogoProps {
  className?: string
  logoLink?: string
  requirements: RequirementsProps
  location?: "header" | "footer"
  target_blank?: boolean
}

const Logo: React.FC<LogoProps> = ({ requirements, className = "", location = "header", logoLink = "/", target_blank = false }) => {
  const { t } = useTranslation();
  const logo = requirements?.config?.[location === "header" ? "headerLogo" : "footerLogo"];
  const logoClassName = `${className} ${requirements.config.showTagline ? 'with-tagline' : ''}`.trim();

  return (
    <a
      href={logoLink ? logoLink : "/"}
      className={logoClassName}
      target={target_blank ? "_blank" : "_self"}
      rel={target_blank ? "noreferrer" : undefined}
    >
      <img src={`/logo/${logo}`} alt="Logo"/>
      {requirements.config.showTagline && <span>{t('Header.logo.tagline')}</span>}
    </a>
  );
}

export default Logo;
