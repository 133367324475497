import * as React from "react";
import StarNote from "../../StarNote";
import {useState} from "react";
import {Button} from "../../Button";
import {RequirementsProps} from "../../../tools/context";
import {useTranslation} from "../../../tools/i18n";

export interface ReviewFormProps {
  callback?: (p: any) => void,
  requirements:RequirementsProps
}

const ReviewForm = (props: ReviewFormProps) => {
  const {t} = useTranslation();
  const [name, setName] = useState<string>();
  const [note, setNote] = useState<number>(5)
  const [feedback, setFeedback] = useState<string>();

  const handleSendReview = (e) => {
    e.preventDefault();

    const body = {
      gift: null,
      shopGrade: note,
      giftGrade: note,
      review: feedback,
      name,
      date: new Date(),
    }

    props.callback && props.callback(body);

    return false
  }

  return (
    <div className={`reviewForm`}>
      <form onSubmit={handleSendReview}>
        <div className={"name"}>
          <span>{t('shop.reviews.form.name')}</span>
          <input value={name} onChange={e => setName(e.target.value)} />
        </div>
        <div className={"note"}>
          <span>{t('shop.reviews.form.note')}</span>
          <StarNote note={note} callback={setNote}/>
        </div>
        <div className={"feedback"}>
          <textarea
            placeholder={t('shop.reviews.form.review')} required
            value={feedback} onChange={e => setFeedback(e.target.value)}
          />
        </div>
        <Button theme={"client"} >{t('shop.reviews.form.confirm')}</Button>
      </form>
    </div>)
}

export default ReviewForm;
