import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { useTranslation as useTrans } from "react-i18next";

const initI18n = (Detector, page) =>
  i18next
    .use(initReactI18next)
    .use(Detector)
    .use(HttpApi)
    .init({
      initImmediate: false,
      fallbackLng: (code) => {
        if (!code) return "fr";
        return [code.split("_")[0], "fr"];
      },
      ns: ["" + page.requirements.config.customerId, "translation"],
      defaultNS: "" + page.requirements.config.customerId,
      debug: false,
      returnObjects: true,
      interpolation: { escapeValue: false },
      backend: {
        loadPath: (lngs, ns) => {
          const { endpoint } = page.requirements;
          const lang = lngs[0].split("-")[0];
          return `${endpoint}/api/jsonConfiguration/${ns}/${lang}`;
        },
      },
    });

const useTranslation = () => {
  const { t, i18n, ready } = useTrans();

  const replacements = {
    ticket: t("common.ticket") || t("translation:common.ticket"),
    tickets: t("common.tickets") || t("translation:common.tickets"),
    promo: t("common.promo") || t("translation:common.promo"),
    promos: t("common.promos") || t("translation:common.promos"),
    partner: t("common.partner") || t("translation:common.partner"),
    partners: t("common.partners") || t("translation:common.partners"),
    giftcard: t("common.giftcard") || t("translation:common.giftcard"),
    giftcards: t("common.giftcards") || t("translation:common.giftcards"),
    partnerName: t("common.partnerName") || t("translation:common.partnerName"),
  };

  const customT = (key, option = {}) =>
    t([key, ""], { ...replacements, ...option }) ||
    t(key, { ns: ["translation"], ...replacements, ...option });

  return { t: customT, i18n, ready };
};

export { initI18n, useTranslation };
