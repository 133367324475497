import React from 'react';
import {RequirementsProps} from "../../tools/context";
import {GoogleOAuth} from "./Google";

export interface OAuthButtonProps {
  provider: 'google'
  requirements: RequirementsProps
  redirectUrl?: string
  parameters: any
}

export const OAuthButton = (props: OAuthButtonProps) => {
  const {
    provider,
  } = props;

  const PButton = {
    google: <GoogleOAuth {...props} />
  }

  return (
    <div className="oAuthButton">
      {PButton[provider]}
    </div>
  )
}