import React from 'react';
import {Picto} from "../Picto";

export interface FeedbackPopinProps {
  callback: () => void
  url: string
}

export const FeedbackPopin = (props: FeedbackPopinProps) => {
  const {
    callback,
    url,
  } = props;

  return (
    <div className={`feedbackPopin`} onClick={callback}>
      <iframe
        onClick={e => e.stopPropagation()}
        className="feedbackIframe"
        src={url}
      />
      <button className="close" onClick={callback}>
        <Picto iconKey={"cross"}/>
      </button>
    </div>
  )
}