import React from "react";
import Carousel from "./Carousel";

export interface MediaCarouselProps {
  label: string;
  subLabel: string;
  background?: string;
  slides: any[];
  reference?: boolean;
}

const MediaCarousel = (props: MediaCarouselProps) => (
  <div
    className={`mediaCarousel ${props.background ? "withBackground" : ""}`}
    nonce="__nonce__"
    style={props.background ? { background: props.background } : null}
  >
    <Carousel {...props}>
      {props.slides?.map &&
        props.slides.map((s) => (
          <div className="slide" key={s._key}>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: s.content,
              }}
            />
            <div className="label">{s.label}</div>
          </div>
        ))}
    </Carousel>
  </div>
);

export default MediaCarousel;
