import React from "react";
import { Picto } from "../Picto";
import { ResponsiveImage } from "../ResponsiveImage";
import { RequirementsProps } from "../../tools/context";
import { useTranslation } from "../../tools/i18n";

export interface RestaurantType {
  formatted_address: string;
  id: number;
  latitude: number;
  longitude: number;
  opening_hours: string;
  price_level: number;
  review_nb: number;
  shop_grade: number;
  store_name: string;
  urlimg: string;
}

export interface RestaurantProps extends RestaurantType {
  requirements: RequirementsProps;
  noRestaurantMention?: boolean;
}

const Restaurant = (props: RestaurantProps) => {
  const {
    formatted_address,
    id,
    opening_hours,
    price_level,
    store_name,
    urlimg,
    requirements,
    noRestaurantMention,
  } = props;

  return (
    <a
      className="Restaurant"
      id={`restaurant_${id}`}
      href={`/etablissement/${id}`}
    >
      <ResponsiveImage
        src={
          urlimg ||
          `https://cdn.cibler.io/static/tca/v2/travelPlaner/${
            noRestaurantMention ? "hotel" : "restaurant"
          }.jpg`
        }
        fallback={`https://tourismebyca.twic.pics/static/tca/v2/etablissement/${
          noRestaurantMention ? "ou-sejourner" : "a-table"
        }.jpg`}
        alt={store_name}
        requirements={requirements}
      />
      <div className="restaurant-content">
        <span className="name">{store_name}</span>
        <div className="informations">
          <span className="price">
            {Array(price_level).fill("€").join("")}
            {noRestaurantMention ? "" : " - Restaurant"}
          </span>
          <span className="address">{formatted_address}</span>
          <span className="openingHours">{opening_hours}</span>
        </div>
      </div>
    </a>
  );
};

export interface RestaurantsProps {
  requirements: any;
  restaurants: RestaurantType[];
  dayOfTheWeek: number;
  lodgings?: boolean;
}

const Restaurants = (props: RestaurantsProps) => {
  const { requirements, restaurants, dayOfTheWeek, lodgings } = props;
  const { t } = useTranslation();

  if (!restaurants.length) return null;

  return (
    <div className="Restaurants" id={lodgings ? "lodgings" : "restaurants"}>
      <div className="restaurants-header">
        <Picto iconKey={lodgings ? "bed" : "cutlery"} width="28" height="28" />
        <span>
          {t(`travelPlaner.Travel.${lodgings ? "whereToSleep" : "whereToEat"}`)}
        </span>
      </div>
      <div className="restaurants-list">
        {restaurants.map((restaurant) => (
          <Restaurant
            {...restaurant}
            key={`restaurant_${restaurant.id}`}
            price_level={Number(restaurant.price_level) || 1}
            opening_hours={
              JSON.parse(restaurant.opening_hours)?.[dayOfTheWeek - 1]
            }
            requirements={requirements}
            noRestaurantMention={lodgings}
          />
        ))}
      </div>
    </div>
  );
};

export default Restaurants;
