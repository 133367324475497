import React from "react";
import {PictoProps} from "./index";

export const Bin = (props: PictoProps) => (
  <svg width={props.width || "21"} height={props.height || "23"} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.16357 5.3053H3.16357H19.1636" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.1636 5.3053C18.1636 4.75301 17.7159 4.3053 17.1636 4.3053C16.6113 4.3053 16.1636 4.75301 16.1636 5.3053H18.1636ZM4.16357 5.3053C4.16357 4.75301 3.71586 4.3053 3.16357 4.3053C2.61129 4.3053 2.16357 4.75301 2.16357 5.3053H4.16357ZM5.16357 5.3053C5.16357 5.85758 5.61129 6.3053 6.16357 6.3053C6.71586 6.3053 7.16357 5.85758 7.16357 5.3053H5.16357ZM13.1636 5.3053C13.1636 5.85758 13.6113 6.3053 14.1636 6.3053C14.7159 6.3053 15.1636 5.85758 15.1636 5.3053H13.1636ZM16.1636 5.3053V19.3053H18.1636V5.3053H16.1636ZM16.1636 19.3053C16.1636 19.8576 15.7159 20.3053 15.1636 20.3053V22.3053C16.8204 22.3053 18.1636 20.9622 18.1636 19.3053H16.1636ZM15.1636 20.3053H5.16357V22.3053H15.1636V20.3053ZM5.16357 20.3053C4.61129 20.3053 4.16357 19.8576 4.16357 19.3053H2.16357C2.16357 20.9622 3.50672 22.3053 5.16357 22.3053V20.3053ZM4.16357 19.3053V5.3053H2.16357V19.3053H4.16357ZM7.16357 5.3053V3.3053H5.16357V5.3053H7.16357ZM7.16357 3.3053C7.16357 2.75301 7.61129 2.3053 8.16357 2.3053V0.305298C6.50672 0.305298 5.16357 1.64844 5.16357 3.3053H7.16357ZM8.16357 2.3053H12.1636V0.305298H8.16357V2.3053ZM12.1636 2.3053C12.7159 2.3053 13.1636 2.75301 13.1636 3.3053H15.1636C15.1636 1.64844 13.8204 0.305298 12.1636 0.305298V2.3053ZM13.1636 3.3053V5.3053H15.1636V3.3053H13.1636Z"
          fill="#004653"/>
    <path d="M8.16357 10.3053V16.3053" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.1636 10.3053V16.3053" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
