import React from "react";
import { PictoProps } from "../index";

export const Place = (props: PictoProps) => (
  <svg width={props.width || "11"} height={props.height || "14"} viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3660_172)">
      <path
        d="M5.05451 13.5C4.14246 12.722 3.29712 11.8692 2.52726 10.9504C1.37194 9.57048 6.39721e-07 7.51546 6.39721e-07 5.55719C-0.0010247 3.51196 1.23059 1.66767 3.12013 0.884947C5.00967 0.10222 7.1847 0.535348 8.63022 1.98221C9.58082 2.92856 10.1133 4.21586 10.109 5.55719C10.109 7.51546 8.73708 9.57048 7.58177 10.9504C6.81189 11.8692 5.96656 12.722 5.05451 13.5ZM5.05451 3.39097C4.28059 3.39097 3.56547 3.80385 3.17851 4.47408C2.79156 5.14431 2.79156 5.97008 3.17851 6.64031C3.56547 7.31054 4.28059 7.72342 5.05451 7.72342C6.25091 7.72342 7.22073 6.75353 7.22073 5.55719C7.22073 4.36082 6.25091 3.39097 5.05451 3.39097Z"
        fill="#8C7215"/>
    </g>
    <defs>
      <clipPath id="clip0_3660_172">
        <rect width="10.109" height="13" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
);