import React from 'react'
import { PictoProps } from '.'


export const Fav = (props: PictoProps) => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.47676 12.0528C7.45397 12.0204 3.84662 8.46382 2.06355 6.71999C1.65073 6.31641 1.34657 5.84885 1.16637 5.30686C1.00511 4.82197 0.959573 4.32628 1.03636 3.81876C1.13119 3.19221 1.38847 2.63649 1.79252 2.14835C2.15924 1.70549 2.6183 1.37755 3.16136 1.16401C3.45357 1.04909 3.75513 0.967275 4.06715 0.934344C4.25103 0.914962 4.43806 0.921479 4.6237 0.922165C4.88366 0.923195 5.13784 0.968476 5.3878 1.03537C5.76364 1.13588 6.11176 1.29419 6.43132 1.51322C6.90829 1.84013 7.24851 2.27081 7.46202 2.7996C7.47096 2.82173 7.48252 2.843 7.50022 2.88005C7.51758 2.84334 7.52971 2.81984 7.54023 2.79549C7.67415 2.48915 7.83099 2.19637 8.06448 1.94887C8.55355 1.43055 9.15942 1.11598 9.86042 0.976025C10.2082 0.906732 10.5628 0.892322 10.9158 0.931085C11.2759 0.970534 11.6212 1.06881 11.9536 1.21203C12.4398 1.42163 12.8502 1.73002 13.1901 2.12657C13.4185 2.39293 13.5992 2.69035 13.7361 3.01194C13.8799 3.35001 13.9666 3.70145 13.9922 4.06746C14.0232 4.51461 13.9615 4.94855 13.8109 5.37151C13.679 5.74217 13.4907 6.08246 13.2395 6.3881C13.1484 6.49908 13.0504 6.60576 12.9477 6.70661C11.7539 7.87762 9.93028 9.6597 7.47676 12.0528Z"
      stroke="white"
    />
  </svg>
)
