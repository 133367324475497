import moment from "moment";
import * as React from "react";
import { useTranslation } from "../../../tools/i18n";
import { RequirementsProps } from "../../../tools/context";

export interface UntrackedProps {
  requirements: RequirementsProps;
  seller: any;
  coupons: any[];
  device: string;
  advantagesRedirectToWallet: boolean;
}

const Untracked = ({
  requirements,
  seller,
  coupons,
  device,
  advantagesRedirectToWallet,
}: UntrackedProps) => {
  const { t } = useTranslation();
  if (!coupons || !coupons.length) return null;

  const today = new Date();
  const coupons_elements = [];
  coupons.forEach((coup) => {
    const expirationDate = coup.expirationDate
      ? new Date(coup.expirationDate)
      : null;
    if (expirationDate && today > expirationDate) return;

    const condition = coup.giftData.find((d) => d.key === "conditions");
    const content = (
      <div className="advantage" key={coup.id}>
        <p className="advantage_title">{coup.description}</p>
        {condition && (
          <i>
            <div>{condition.value}</div>
          </i>
        )}
        {expirationDate && (
          <div>
            {t("shop.advantages.discount.untracked.until")}{" "}
            {moment(expirationDate).format("DD/MM/YYYY")}
          </div>
        )}
      </div>
    );

    coupons_elements.push(content);
  });

  if (coupons_elements.length === 0) return null;

  const redirectLink = advantagesRedirectToWallet
    ? `${requirements?.config?.clientUrl}/connexion-client`
    : undefined;

  const Comp = redirectLink ? "a" : "div";
  const compProps = {
    className: "advantages_button",
    href: redirectLink,
  };
  const compTraductionKey = redirectLink
    ? "shop.advantages.discount.untracked.goToBurn"
    : "shop.advantages.discount.untracked.button";

  return (
    <div className={`advantages ${device}`}>
      <p className="advantages_title">
        {t("shop.advantages.discount.untracked.title")}
      </p>
      {coupons_elements}
      <Comp {...compProps}>{t(compTraductionKey)}</Comp>
    </div>
  );
};

export default Untracked;
