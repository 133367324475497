import * as React from "react";
import {imageUrlFor} from '../../tools/sanity';
import {RequirementsProps} from '../../tools/context';
import {Picto} from "../Picto";

export interface PortraitSlideProps {
  image: any
  name: string
  shortDescription: string
  quote?: string
  url?: string
  reference?: boolean
  _id?: string
  requirements: RequirementsProps
}

const PortraitSlide = (props: PortraitSlideProps) => {
  const {
    image,
    name,
    shortDescription,
    quote,
    reference,
    requirements,
  } = props;
  // console.log(props)
  const url = reference ? `/portrait/${props._id}` : (props.url || null);

  const content = <div className="portraitSlide">
    <img src={imageUrlFor(image, requirements).url()} alt={name} />
    <div className="overlay">
      <div className="name">{name}</div>
      <div className="description">{shortDescription}</div>
    </div>
    {quote && <div className="quote">
      <Picto iconKey={"quote"} />
      {quote}
    </div>}
  </div>;

  if (!url) return content;

  return <a href={url}>
    {content}
  </a>
}

export default PortraitSlide;