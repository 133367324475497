import React from "react";
import {PictoProps} from "./index";

export const BurgerMenu2 = (props: PictoProps) => (
  <svg width={props.width || "21"} height={props.height || "16"} viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.94 0.541748H1.1141C0.499104 0.541748 0 1.00966 0 1.58623C0 2.16069 0.499104 2.63071 1.1141 2.63071H18.94C19.5527 2.63071 20.0541 2.16069 20.0541 1.58623C20.0541 1.00966 19.5527 0.541748 18.94 0.541748Z" fill="white"/>
    <path d="M18.94 7.22656H1.1141C0.499104 7.22656 0 7.69448 0 8.27104C0 8.84551 0.499104 9.31553 1.1141 9.31553H18.94C19.5527 9.31553 20.0541 8.84551 20.0541 8.27104C20.0541 7.69448 19.5527 7.22656 18.94 7.22656Z" fill="white"/>
    <path d="M19.3113 13.9111H7.42742C7.01743 13.9111 6.68469 14.379 6.68469 14.9556C6.68469 15.5301 7.01743 16.0001 7.42742 16.0001H19.3113C19.7198 16.0001 20.0541 15.5301 20.0541 14.9556C20.0541 14.379 19.7198 13.9111 19.3113 13.9111Z" fill="white"/>
  </svg>
)
