import React from "react";

export type JourneysCategory = {
  key: string;
  label: string;
  selected: boolean;
}

interface TravelPlanerHubCategoriesFiltersProps {
  categories: JourneysCategory[];
  onChangeCategories: (categories: JourneysCategory[]) => void;
}

export default function TravelPlanerHubCategoriesFilters({
  categories,
  onChangeCategories,
}: TravelPlanerHubCategoriesFiltersProps) {

  const handleChangeCategory = (categoryKey: string): void => {
    const newCategories = categories.map(c => ({
      ...c,
      selected: c.key === categoryKey ? !c.selected : c.selected,
    }));
    onChangeCategories(newCategories);
  }

  return (
    <div className="CategoriesFilters">
      {categories?.length
        ? <div className="categories">
            {categories.map((c) => (
              <button
                key={c.key}
                className={`category ${c.selected ? "selected" : ""}`}
                onClick={() => handleChangeCategory(c.key)}
              >
                {c.label}
              </button>
            ))}
          </div>
        : null
      }
    </div>
  );
};
