import React from "react";
import {PictoProps} from "./index";

export const BurgerMenu = (props: PictoProps) => (
  <svg width={props.width || "21"} height={props.height || "14"} viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.44434 7H19.4443" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.44434 1H19.4443" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.44434 13H19.4443" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
