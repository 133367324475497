export default {
  key: 'charentes',
  canonicalUrl: 'www.tourismebyca.fr',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 703,
  icon: 'TCA.png',
  headerLogo: 'charentes_monochrome.png',
  footerLogo: 'charentes_monochrome.png',
  axeptioClientId: "63c15e09e908b5eb5c44476a",
  axeptioCookiesVersion: "tourismebyca-fr",
  clientUrl: "https://client.tourismebyca.fr/",
  proUrl: "https://pro.tourismebyca.fr/",
  sanity: {
    base: {
      projectId: '8xljztwh',
      dataset: 'production',
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token: 'sk8zcdVGgjyK6D1k6vycBFLVKlDTsiChfBmbw6YSbVZ3F4U0dAnuUOMcx09CdeAzJKCU7kCi8ansMaTeOXwmqfShuHLBgzfmunUZNVWIvkYr8G5pzleNHFQkH5lmvosBhQhfcwdWmZA8ZF1EecU3C2JWmg10Fj4PUFOa6icV2KRmqULyuFoZ',
    }
  },
  dev: {
    clientUrl: "https://dev.tourismebyca.fr/",
    proUrl: "https://dev.tourismebyca.fr/",
  },
  travelForceLogin: false,
}