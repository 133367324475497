import PictoButton from "../PictoButton";
import React, {Suspense, useContext, useEffect, useState} from "react";
import {DirectionContext} from "../DirectionContext";
import {Oval} from "react-loader-spinner";
import {RequirementsProps} from "../../../tools/context";
import {TravelPlannerResultsConfigType} from "../types";

interface PrintPDFButtonProps {
  trip: any;
  componentStates: any;
  requirements: RequirementsProps;
  config: TravelPlannerResultsConfigType;
}

export default function PrintPDFButton(props: PrintPDFButtonProps) {
  const {trip, componentStates, requirements, config} = props;
  const {currentProfile: profile} = useContext(DirectionContext);
  const [showDownloadLink, setShowDownloadLink] = useState<boolean>(false);
  const DownloadLink = React.lazy(() => import('./DownloadLink'));
  const [isClient, setIsClient] = useState<boolean>(false);

  useEffect(() => {
    setShowDownloadLink(false);
  }, [trip, componentStates, requirements, profile]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient)
    return null;

  return (
    <div className="PrintPDFButton">
      {showDownloadLink
      ? <Suspense fallback={<Oval color="#000" height={30} width={30} />}>
          <DownloadLink
            trip={trip}
            componentStates={componentStates}
            requirements={requirements}
            config={config}
          />
        </Suspense>
      : <PictoButton
          iconKey="telecharger"
          onClick={() => setShowDownloadLink(true)}
        />
      }
    </div>
  );
};
