import React from "react";
import { RequirementsProps } from "../../tools/context";
import TravelPlanerMap from "./TravelPlanerMap";
import { ResponsiveImage } from "../ResponsiveImage";
import { Picto } from "../Picto";
import { useTranslation } from "../../tools/i18n";
import { TravelPlannerResultsConfigType } from "./types";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export interface TravelCustomMapProps {
  day: string;
  journey: any;
  config: TravelPlannerResultsConfigType;
  requirements: RequirementsProps;
  inputs: any;
  doAction?: (action: string) => void;
}

export interface CustomLinkProps {
  labelKey: string;
  image: SanityImageSource;
  url: string;
  requirements: RequirementsProps;
}

const CustomLink = (props: CustomLinkProps) => {
  const { t } = useTranslation();
  const { labelKey, image, url, requirements } = props;
  const label = t(labelKey);

  return (
    <a
      className="customLink"
      href={url}
      target={"_blank"}
      rel="noopener noreferrer"
    >
      <ResponsiveImage src={image} alt={label} requirements={requirements} />
      <span>{label}</span>
      <div className="arrowRight">
        <Picto iconKey={"caret-right"} />
      </div>
    </a>
  );
};

const TravelCustomMapComponent = (props: TravelCustomMapProps) => {
  const { config, requirements } = props;

  return (
    <div
      className="travelCustomMap"
      nonce="__nonce__"
      style={{
        marginTop: config.customLink ? "48px" : "",
      }}
    >
      <div className="travelMapContainer">
        <TravelPlanerMap {...props} />
        {config.customLink ? (
          <CustomLink {...config.customLink} requirements={requirements} />
        ) : null}
      </div>
    </div>
  );
};

const areEqual = (
  prevProps: TravelCustomMapProps,
  nextProps: TravelCustomMapProps
) => {
  return (
    JSON.stringify(
      prevProps.journey?.travelData?.trip?.[prevProps.day] ===
        nextProps.journey?.travelData?.trip?.[nextProps.day]
    ) &&
    JSON.stringify(prevProps.inputs) === JSON.stringify(nextProps.inputs) &&
    prevProps.config === nextProps.config &&
    prevProps.requirements === nextProps.requirements &&
    JSON.stringify(prevProps.doAction) === JSON.stringify(nextProps.doAction)
  );
};

export const TravelCustomMap = React.memo(TravelCustomMapComponent, areEqual);
