import React, { ButtonHTMLAttributes } from 'react';
import { Picto } from "../Picto";
import CustomSvg from "./CustomSvg";
import {useTranslation} from "../../tools/i18n";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: 'white' | 'pro' | 'yellow' | 'client' | 'transparent' | 'blue';
    className?: string;
    withPicto?: string;
    withSVG?: boolean;
    textLabel?: string;
    disabled?: boolean;
    usePicto?: boolean;
}

export const Button: React.FC<ButtonProps> = ({

                                                  theme = 'blue',
                                                  withPicto,
                                                  withSVG = false,
                                                  textLabel,
                                                  children,
                                                  className,
                                                  disabled,
                                                    usePicto = true,
                                                  ...props
                                              }) => {

    const {t} = useTranslation();
    const baseClass: string = withSVG ? `svgButton ${className || ''} caret-right` : `floatingButton ${className || ''}`;
    const buttonTheme: string = theme || className ? `Button ${className || ''} ${theme || ''}` : '';
    const finalClassName: string = `${buttonTheme} ${baseClass}`;

    return (
        <button {...props} className={finalClassName} disabled={disabled}>
            {withPicto && !withSVG && <Picto iconKey={withPicto} className="pictoIcon"/>}
            {textLabel && t && <span className="labelText">{t(textLabel)}</span>}
            {children}
            {withSVG && (
                <>
                    {usePicto && <Picto iconKey="caret-right" className="caretRight"/>}
                    <CustomSvg/>
                </>
            )}
        </button>
    );
}

