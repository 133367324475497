import React from "react";
import {PictoProps} from "./index";

export const HowtoAdvantage3 = (props: PictoProps) => (
  <svg width="152" height="108" viewBox="0 0 152 108" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.845827 67.3478C1.23411 76.9277 4.09633 86.6553 10.7859 94.454C17.4755 102.253 28.3919 107.855 39.9184 107.799C56.1487 107.716 69.417 97.1674 84.9928 93.3649C93.5683 91.2699 102.643 91.2699 111.563 90.6977C120.471 90.1162 129.679 88.8334 137.023 84.5879C147.74 78.3859 152.488 66.6002 150.901 55.8205C149.315 45.0407 142.204 35.267 133.295 27.3206C123.943 18.9774 112.461 12.3139 99.8363 7.88391C87.5221 3.56463 73.9987 1.37731 60.7083 2.48481C31.1432 4.95824 11.03 23.7305 3.68586 46.7574C1.55583 53.4578 0.568481 60.4166 0.845827 67.3478Z" fill="#E7F8F5"/>
    <path d="M93.2765 18.1834L87.7547 21.4964L73.5387 30.02L71.2095 31.4155L56.3509 40.3307L49.4236 31.797L64.5432 21.9382L66.9226 20.382L81.0081 11.1958L86.2388 7.78235L93.2765 18.1834Z" fill="#F24444"/>
    <path d="M93.2764 18.1834L91.0677 19.5086L89.6722 20.3419L87.7547 21.4964L81.0081 11.1958L82.7047 10.0915L84.2107 9.09753L86.2387 7.78235L93.2764 18.1834Z" fill="#BF0504"/>
    <path d="M91.0678 19.5087L89.6723 20.342L82.7048 10.0916L84.2108 9.09766L91.0678 19.5087Z" fill="#F2B950"/>
    <path d="M73.5387 30.0201L71.2095 31.4156L64.5432 21.9382L66.9226 20.3821L73.5387 30.0201Z" fill="#F2CB56"/>
    <path d="M64.5432 21.9382C64.5432 21.9382 72.2837 19.5588 69.8742 27.7009C67.7258 34.9395 70.1855 37.3088 72.1733 37.7205V35.7025L74.3519 36.1844C74.3519 36.1844 71.6914 33.5339 72.4644 30.6626C73.2375 27.7913 74.8739 18.4846 66.9226 20.372L64.5432 21.9382Z" fill="#F2B950"/>
    <path d="M64.5432 21.9382L66.9226 20.3821C66.9226 20.3821 74.0206 15.6434 75.8679 21.1551C77.7151 26.6669 77.0325 34.9094 86.4295 30.6827L84.271 29.99L85.8271 27.6206C85.8271 27.6206 81.0081 31.1144 79.5323 23.7454C77.9862 15.9848 75.1852 12.24 64.5432 21.9382Z" fill="#F2CB56"/>
    <path d="M64.5433 21.9381C64.1016 16.5468 64.1819 3.87685 69.1917 0.704345C70.1454 0.112009 71.3702 -0.0185053 72.4545 0.242524C74.4624 0.734464 75.4564 2.6721 75.5066 4.5997C75.5668 11.1857 69.4226 18.0527 64.5433 21.9381ZM64.5433 21.9381C67.9066 18.113 71.1594 14.047 72.9866 9.26811C73.7396 7.22004 74.7837 3.09377 72.3039 1.98941C70.537 1.3268 69.1615 2.57171 68.2981 4.1178C66.8625 6.73814 66.24 9.79017 65.6979 12.7318C65.1658 15.7738 64.8244 18.8459 64.5433 21.9381Z" fill="#F2CB56"/>
    <path d="M64.5432 21.9382C59.3628 25.4019 50.9597 29.1768 44.695 27.1187C42.8778 26.4862 41.3317 24.9501 41.4723 22.882C41.5425 21.7676 42.0445 20.6431 42.8979 19.9203C47.4458 16.1052 59.5435 19.8801 64.5432 21.9382ZM64.5432 21.9382C61.5113 21.2656 58.4894 20.6632 55.4173 20.2415C52.4556 19.86 49.3634 19.5287 46.4218 20.101C44.6849 20.4524 43.0786 21.376 43.179 23.2635C43.4802 25.9641 47.7269 26.2251 49.9055 26.1247C55.0157 25.8436 59.8849 23.9763 64.5432 21.9382Z" fill="#F2CB56"/>
    <path d="M59.0228 43.2351L57.8271 43.5004L58.4686 46.3916L59.6643 46.1263L59.0228 43.2351Z" fill="#6267DA"/>
    <path d="M52.6362 48.2018L52.0037 47.1577C52.7667 46.6959 53.5899 46.3345 54.4533 46.0935L54.7846 47.2681C54.0216 47.479 53.2988 47.8002 52.6362 48.2018Z" fill="#6267DA"/>
    <path d="M65.2761 47.4285C64.9247 46.3141 64.3224 45.27 63.5493 44.4066L64.4629 43.5934C65.3564 44.5873 66.0391 45.782 66.4508 47.057L65.2761 47.4285Z" fill="#F3A2D7"/>
    <path d="M73.1972 44.979C73.1169 43.9048 72.7454 42.8607 72.1431 41.9571L73.147 41.2744C73.8699 42.3486 74.3116 43.5936 74.412 44.8786L73.1972 44.979Z" fill="#F24444"/>
    <path d="M73.8801 51.9566L72.8159 51.3542C73.2577 50.5711 73.5488 49.7177 73.6894 48.8242L74.8941 49.015C74.7335 50.049 74.3921 51.043 73.8801 51.9566Z" fill="#F2B950"/>
    <path d="M76.5103 55.2294L75.8376 54.2053C76.5505 53.7335 77.1026 53.0407 77.3837 52.2275L78.5383 52.6291C78.1668 53.7034 77.454 54.617 76.5103 55.2294Z" fill="#F24444"/>
    <path d="M84.7529 59.5764C84.261 58.6126 84.1003 57.4982 84.3011 56.4441L85.5059 56.665C85.3553 57.4581 85.4758 58.3014 85.8472 59.0243L84.7529 59.5764Z" fill="#F2B950"/>
    <path d="M99.4207 70.0178L98.1959 69.9877C98.226 68.793 97.9951 67.5882 97.5032 66.4939L98.6176 66.002C99.1898 67.2569 99.4609 68.6424 99.4207 70.0178Z" fill="#F2B950"/>
    <path d="M103.165 62.026C102.121 61.5642 100.866 61.5542 99.8122 62.006L99.3303 60.8815C100.686 60.2892 102.312 60.2992 103.667 60.9016L103.165 62.026Z" fill="#F3A2D7"/>
    <path d="M105.485 58.0506C104.682 57.0768 103.507 56.384 102.262 56.1531L102.483 54.9484C104.009 55.2295 105.445 56.0728 106.428 57.2775L105.485 58.0506Z" fill="#F24444"/>
    <path d="M113.416 52.1173C112.713 51.7057 111.789 51.6755 111.067 52.057L110.504 50.9728C111.589 50.4105 112.974 50.4507 114.038 51.0732L113.416 52.1173Z" fill="#6267DA"/>
    <path d="M95.8467 66.8551L77.9461 103.921L70.8983 100.518L38.4001 84.846L56.3509 48.7437L88.5579 63.5219L95.8467 66.8551Z" fill="#F24444"/>
    <path d="M95.8466 66.8551L77.946 103.921L70.8982 100.518L88.5578 63.522L95.8466 66.8551Z" fill="#BF0504"/>
    <path d="M91.59 64.9073L73.8801 101.953L76.0186 102.987L93.4372 65.7507L91.59 64.9073Z" fill="#F2B950"/>
    <path d="M70.3159 55.1489L52.4053 91.6027L55.638 93.1588L73.4382 56.5745L70.3159 55.1489Z" fill="#F2CB56"/>
  </svg>
)
