import React from "react";
import {PictoProps} from "./index";

export const ATable = (props: PictoProps) => (
  <svg width={props.width || "36"} height={props.height || "23"} viewBox="0 0 36 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.6355 0.875C18.6496 0.875 13.8096 5.71518 13.8096 11.7009C13.8096 17.6866 18.6498 22.5268 24.6355 22.5268C30.6212 22.5268 35.4614 17.6866 35.4614 11.7009C35.4616 5.71531 30.6214 0.875 24.6361 0.875H24.6355ZM24.6355 19.5709C20.2757 19.5709 16.7654 16.0237 16.7654 11.7009C16.7654 7.37806 20.3126 3.83088 24.6355 3.83088C28.9952 3.83088 32.5055 7.37806 32.5055 11.7009C32.5052 16.0239 28.9952 19.5709 24.6355 19.5709Z" fill="#DA3A5C"/>
    <path d="M6.17156 2.23882C6.13505 2.05398 5.9885 1.90626 5.80543 1.90626C5.58586 1.90626 5.40279 2.0911 5.4393 2.3128L5.73216 6.34114C5.73216 6.56284 5.58586 6.74768 5.32951 6.74768H5.293C5.07343 6.74768 4.92688 6.59997 4.89036 6.378L4.7073 2.27569C4.7073 2.05398 4.52423 1.90601 4.30466 1.90601H4.26815C4.04857 1.90601 3.86551 2.09085 3.86551 2.31255L3.90202 6.37804C3.90202 6.59975 3.71895 6.78459 3.49938 6.78459H3.46287C3.24329 6.78459 3.06023 6.59975 3.06023 6.37804L3.09674 2.31255C3.09674 2.09085 2.91367 1.90601 2.69409 1.90601H2.73086C2.51129 1.90601 2.36473 2.05372 2.32822 2.27569L2.18167 6.34118C2.18167 6.56288 1.9986 6.71086 1.77903 6.71086L1.74252 6.7106C1.52294 6.7106 1.33987 6.52576 1.37639 6.30405L1.63273 2.31261C1.63273 2.09091 1.48643 1.90606 1.2666 1.90606C1.08353 1.90606 0.900468 2.05378 0.900468 2.23863L0.424811 6.41492C0.278512 7.78229 1.01051 9.0019 2.14513 9.55646C2.43798 9.70418 2.65755 10.037 2.62104 10.3695C2.51125 12.3651 2.14513 14.6936 2.14513 17.9089C2.14513 21.7524 2.98692 22.0112 3.49936 22.0112C4.01178 22.0112 4.85359 21.7524 4.85359 17.9089C4.85359 14.6937 4.48746 12.3653 4.37767 10.3695C4.34116 10.037 4.52397 9.70443 4.85359 9.55646C5.9882 8.96508 6.72025 7.70857 6.5739 6.30411L6.17156 2.23882Z" fill="#DA3A5C"/>
    <path d="M11.6547 5.18175C11.4681 3.44971 10.3113 2.01921 8.66916 1.41674C8.29586 1.30381 7.92285 1.56722 7.92285 1.98138L8.14672 8.98431C8.14672 11.281 7.62427 13.9164 7.62427 17.8321C7.62427 21.7476 8.48254 22.0113 9.005 22.0113C9.52745 22.0113 10.3857 21.7476 10.3857 17.8321C10.3857 15.4225 10.1991 13.5025 10.0499 11.8458L10.9082 11.9963C11.692 12.1092 12.3636 11.4692 12.2517 10.6784L11.6547 5.18175Z" fill="#DA3A5C"/>
  </svg>
)
