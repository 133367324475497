import React, {useState} from 'react';
import {Picto} from "../Picto";
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import {useTranslation} from "../../tools/i18n";
import {CompositionType, PaceType, TravelPlannerCompositionAndPaceType} from "./types";
import {imageUrlFor} from "../../tools/sanity";

export interface CompositionAndPaceProps extends ChatBotComponentProps {
  step: TravelPlannerCompositionAndPaceType;
}

export const CompositionAndPace = (props: CompositionAndPaceProps) => {
  const {t} = useTranslation();
  const {config, step, callback, initialData, back, requirements} = props;
  const compositionOptions = step.compositionList || [];
  const paceOptions = step.paceList || [];
  const [compositionAndPaceData, setCompositionAndPaceData] = useState(initialData || {
    composition: compositionOptions[0].id,
    pace: paceOptions[0].id
  })

  const subtitleKey = `travelPlaner.Composition.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  type OptionsType = {
    type: "composition" | "pace";
    className: string;
    options: CompositionType[] | PaceType[];
  }

  const RenderOptions = ({type, className, options = []}: OptionsType) => {
    const translationKey = type.charAt(0).toUpperCase() + type.slice(1);

    return (
      <div className={`buttonList ${className}`}>
        {options.map(o => (
          <button
            key={o.id}
            onClick={() => setCompositionAndPaceData({...compositionAndPaceData, [type]: o.id})}
            className={o.id === compositionAndPaceData[type] ? 'selected' : ''}
          >
            {config.newUI && o.type === 'image'
              ? <img
                  src={imageUrlFor(o.image, requirements).url()}
                  alt={t(`travelPlaner.${translationKey}.${o.id}`)}
                />
              : o.type === 'picto'
                ? <Picto iconKey={o.picto}/>
                : null
            }
            {config.newUI && o.type === "image" && o.selectedImage
              ? <img
                  className="selectedImage"
                  src={imageUrlFor(o.selectedImage, requirements).url()}
                  alt={`${t(`travelPlaner.${translationKey}.${o.id}`)} selected`}
                />
              : null
            }
            <span>{t(`travelPlaner.${translationKey}.${o.id}`)}</span>
          </button>
        ))}
      </div>
    )
  }

  return (
    <div className="composition">
      <FilterLayout
        config={config}
        callback={() => callback(compositionAndPaceData)}
        title={t(`travelPlaner.Composition.title`)}
        back={back}
        requirements={requirements}
        disabledNext={!compositionAndPaceData.composition || !compositionAndPaceData.pace}
        siteConfiguration={props.siteConfiguration}
      >
        {hasSubtitle
          ? <div className="layoutSubtitle">{t(subtitleKey)}</div>
          : null
        }
        <RenderOptions
          type="composition"
          className="buttonListComposition"
          options={compositionOptions}
        />
        <div className="paceTitle">{t("travelPlaner.Pace.title")}</div>
        <RenderOptions
          type="pace"
          className="buttonListComposition"
          options={paceOptions}
        />
      </FilterLayout>
    </div>
  )
}
