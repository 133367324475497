import React, { useState, useEffect } from 'react';
import { imageUrlFor } from '../../tools/sanity';
import { RequirementsProps } from '../../tools/context';

interface Color {
  hex: string;
}

interface ImageAsset {
  _ref: string;
  _type: 'reference';
}

interface ImageObject {
  asset: ImageAsset;
  alt?: string;
  width?: string;
  height?: string;
  justifyContent?: string;
  alignItems?: string;
  padding?: string;
  margin?: string;
}

interface ButtonObject {
  text: string;
  url: string;
  textColor: Color;
  backgroundColor: Color;
  borderWidth: number;
  borderColor: Color;
  borderRadius: number;
  fontSize: string;
  hoverTextColor: Color;
  hoverBackgroundColor: Color;
  hoverBorderColor: Color;
  padding?: string;
  margin?: string;
}

interface BannerElement {
  elementType: 'image' | 'text' | 'button';
  image?: {
    padding: any;
    margin: any;
    asset: ImageObject;
    alt?: string;
    width?: string;
    height?: string;
    justifyContent?: string;
    alignItems?: string;
  };
  text?: string;
  textAlignment?: string;
  button?: ButtonObject & {
    alignment?: string;
  };
  width?: number;
  responsiveWidth?: {
    mobile?: number;
    tablet?: number;
    desktopL?: number;
    desktopXL?: number;
  };
}

interface ResponsiveSettings {
  mobile?: { padding?: string; margin?: string };
  tablet?: { padding?: string; margin?: string };
  desktopL?: { padding?: string; margin?: string };
  desktopXL?: { padding?: string; margin?: string };
}

interface InteractiveBannerProps {
  backgroundColor: Color;
  isFullWidth: boolean;
  padding: string;
  margin?: string;
  justifyContent?: string;
  alignItems?: string;
  customClass?: string;
  elements: BannerElement[];
  requirements: RequirementsProps;
  responsiveSettings?: ResponsiveSettings;
  responsiveWidth?: ResponsiveSettings;
}

const InteractiveBanner: React.FC<InteractiveBannerProps> = ({
  backgroundColor,
  isFullWidth,
  padding,
  margin,
  justifyContent,
  alignItems,
  customClass,
  elements,
  requirements,

}) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('mobile');

  useEffect(() => {
    const mediaQueries = [
      { query: '(min-width: 1440px)', name: 'desktopXL' },
      { query: '(min-width: 1024px) and (max-width: 1439px)', name: 'desktopL' },
      { query: '(min-width: 768px) and (max-width: 1023px)', name: 'tablet' },
      { query: '(max-width: 767px)', name: 'mobile' },
    ];

    const mediaQueryLists = mediaQueries.map(({ query }) => window.matchMedia(query));

    const getCurrentBreakpoint = () => {
      const index = mediaQueryLists.findIndex(mql => mql.matches);
      return mediaQueries[index].name;
    };

    const handleResize = () => {
      setCurrentBreakpoint(getCurrentBreakpoint());
    };

    mediaQueryLists.forEach(mql => mql.addListener(handleResize));
    handleResize();

    return () => {
      mediaQueryLists.forEach(mql => mql.removeListener(handleResize));
    };
  }, []);

  const getResponsiveWidth = (width?: number, responsiveWidth?: ResponsiveSettings) => {
    if (responsiveWidth && responsiveWidth[currentBreakpoint as keyof ResponsiveSettings]) {
      return { width: `${responsiveWidth[currentBreakpoint as keyof ResponsiveSettings]}%` };
    } else if (width) {
      return { width: `${width}%` };
    }
    return { width: 'auto' };
  };

  const bannerStyle: React.CSSProperties = {
    backgroundColor: backgroundColor?.hex || '#ffffff',
    padding: padding,
    margin: isFullWidth ? '0' : margin,
    width: isFullWidth ? '100vw' : 'auto',
    display: 'flex',
    justifyContent: justifyContent || 'space-between',
    alignItems: alignItems || 'center',
    flexWrap: 'wrap',
  };

  const bannerClasses = [
    'InteractiveBanner',
    isFullWidth ? 'full-width' : '',
    customClass || ''
  ].filter(Boolean).join(' ');

  const renderElement = (element: BannerElement, index: number) => {
    const elementStyle: React.CSSProperties = {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      ...getResponsiveWidth(element.width, element.responsiveWidth as ResponsiveSettings),
    };

    if (element.elementType === 'text') {
      elementStyle.textAlign = (element.textAlignment || 'left') as React.CSSProperties['textAlign'];
      return element.text ? (
        <div
          key={index}
          className={`banner-element text-container`}
          dangerouslySetInnerHTML={{ __html: element.text }}
          style={elementStyle}
        />
      ) : null;
    }

    switch (element.elementType) {
      case 'image':
        elementStyle.justifyContent = element.image?.justifyContent || 'center';
        elementStyle.alignItems = element.image?.alignItems || 'center';
        if (element.image?.padding) elementStyle.padding = element.image.padding;
        if (element.image?.margin) elementStyle.margin = element.image.margin;
        break;
      case 'button':
        elementStyle.justifyContent = element.button?.alignment || 'center';
        break;
    }

    const innerContent = (() => {
      switch (element.elementType) {
        case 'image':
          if (!element.image || !element.image.asset) return null;
          const imageUrl = imageUrlFor(element.image.asset, requirements).url();
          return imageUrl ? (
            <img
              src={imageUrl}
              alt={element.image.alt || ''}
              style={{
                width: element.image.width || 'auto',
                height: element.image.height || 'auto',
              }}
            />
          ) : (
            <div>Image not available</div>
          );
        case 'button':
          if (!element.button) return null;
          const buttonStyle = {
            color: element.button.textColor?.hex || '#000000',
            backgroundColor: element.button.backgroundColor?.hex || '#ffffff',
            borderWidth: `${element.button.borderWidth || 0}px`,
            borderColor: element.button.borderColor?.hex || 'transparent',
            borderStyle: 'solid',
            borderRadius: `${element.button.borderRadius || 0}px`,
            fontSize: element.button.fontSize || '1rem',
            textDecoration: 'none',
            display: 'inline-block',
            transition: 'all 0.3s ease',
          };
          return (
            <a
              href={element.button.url}
              className="interactive-banner-button"
              style={buttonStyle}
              onMouseEnter={(e) => {
                e.currentTarget.style.color = element.button.hoverTextColor?.hex || buttonStyle.color;
                e.currentTarget.style.backgroundColor = element.button.hoverBackgroundColor?.hex || buttonStyle.backgroundColor;
                e.currentTarget.style.borderColor = element.button.hoverBorderColor?.hex || buttonStyle.borderColor;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = buttonStyle.color;
                e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
                e.currentTarget.style.borderColor = buttonStyle.borderColor;
              }}
            >
              {element.button.text}
            </a>
          );
        default:
          return null;
      }
    })();

    return (
      <div key={index} className={`banner-element ${element.elementType}-container`} style={elementStyle}>
        {innerContent}
      </div>
    );
  };
  return (
    <div className={bannerClasses} style={{ ...bannerStyle }}>
      {elements.map((element, index) => renderElement(element, index))}
    </div>
  );
};

export default InteractiveBanner;
