import * as React from "react";
import {RequirementsProps} from "../../tools/context";
import {Picto} from "../Picto";
import {useTranslation} from "../../tools/i18n";
import {OverLayParameter} from "../../App";
import {OAuthButton} from "../OAuthButton";
import {useEffect, useState} from "react";
import {useTracking} from "../../tools/analytics";

export interface LoginPopupProps {
  close: () => void
  requirements: RequirementsProps
  coupon: any
  context?: any
  siteConfiguration: any,
  setOverlay: (p: OverLayParameter) => void
}

export const LoginPopup = ({setOverlay, requirements, context, siteConfiguration}: LoginPopupProps) => {
  const {t} = useTranslation();
  const {trackEvent} = useTracking();
  const [optin, setOptin] = useState<boolean>(false);
  useEffect(() => {
    trackEvent('open_login_popin')
  }, [])
  let redirect = new URL(window.location.href);

  if (context && context.redirect) {
    redirect = context.redirect
  }
  if(context && context.queries) {
    Object.keys(context.queries).forEach(k => {
      redirect.searchParams.append(k, context.queries[k])
    });
  }

  const redirectUrl = (success) => {
    const red = new URL(redirect.href);
    if (success) {
      red.searchParams.append(success, 'true');
    }
    return `${red.protocol}//${red.host}${red.pathname}${red.search}`;
  }

  const url = `${requirements.config.clientUrl
  }connexion-client?redirect=${
    encodeURIComponent(redirectUrl('successLoginPhone'))}`;

  const close = () => setOverlay(null);
  return <div className="popup loginPopup" onClick={close}>
    <div className="popupBlock" onClick={(e) => e.stopPropagation()}>
      <button className="close" onClick={close}><Picto iconKey={"cross"}/></button>
      <div className="title">{t('loginPopup.connect')}</div>
      <div className="optin">
        <input
          type="checkbox"
          id="optinBox"
          onChange={() => setOptin(p => !p)}
          checked={optin}
        />
        <label htmlFor="optinBox">{t('loginPopup.optin')}</label>
      </div>
      <a
        href={url}
        className="Button client"
        onClick={() => {trackEvent('login_click')}}
      >{t('common.login')}</a>
      {siteConfiguration.oauthEnabled &&
        <OAuthButton
          redirectUrl={redirectUrl('successLoginGoogle')} requirements={requirements}
          provider={"google"} parameters={{
            customerId: requirements.config.customerId,
            optin: optin.toString()
          }} />
      }
      <div className="subtitle">{t('loginPopup.join')}</div>
      <a
        href={url}
        className="Button white"
        onClick={() => {trackEvent('signup_click')}}
      >{t('common.signup')}</a>
    </div>
  </div>
}
