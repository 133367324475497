import React from "react";
import {PictoProps} from "../index";

export const ArmorProducteur = (props: PictoProps) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill={props.fill} stroke="none">
<path d="M1205 4287 c-112 -30 -229 -114 -282 -205 -38 -65 -63 -156 -63 -232
0 -348 392 -602 1175 -759 l180 -37 3 -417 2 -418 -57 8 c-32 4 -123 8 -203 7
-179 0 -241 -15 -535 -124 -115 -43 -237 -85 -270 -94 -89 -23 -224 -36 -372
-36 -124 0 -132 -1 -138 -20 -14 -44 7 -50 164 -50 233 0 347 23 606 120 77
29 178 66 225 82 233 79 456 77 709 -8 180 -60 480 -182 640 -261 170 -83 231
-132 252 -201 10 -35 9 -45 -7 -78 -25 -50 -57 -72 -124 -84 -90 -17 -176 2
-614 136 -226 68 -422 124 -436 122 -31 -4 -40 -42 -13 -56 51 -28 855 -264
929 -274 160 -20 276 21 320 115 37 81 28 144 -31 222 l-35 45 153 72 c83 40
289 141 457 224 192 96 322 155 351 159 68 10 127 -10 173 -58 37 -38 38 -42
34 -97 -2 -34 -12 -67 -23 -83 -21 -29 -360 -287 -660 -502 -264 -190 -751
-514 -820 -546 -93 -44 -178 -62 -285 -62 -84 1 -158 15 -635 122 l-539 121
-387 0 c-414 0 -420 -1 -404 -50 6 -20 12 -20 391 -20 l384 0 543 -122 c510
-114 548 -121 652 -122 123 0 222 22 323 73 207 105 1416 964 1487 1057 69 90
67 195 -6 278 -74 84 -185 106 -314 62 -38 -14 -70 -24 -70 -23 0 1 -9 19 -19
40 -45 87 -126 106 -263 62 l-86 -28 -17 21 c-9 11 -34 32 -55 46 -52 36 -108
34 -198 -5 -56 -25 -74 -29 -86 -20 -53 39 -108 56 -181 55 -82 0 -131 -19
-552 -207 l-168 -75 -57 17 -58 17 -3 430 -2 430 127 23 c636 116 1066 328
1188 587 123 260 -42 572 -330 625 -248 45 -511 -148 -751 -553 -76 -128 -154
-282 -219 -433 -26 -60 -49 -112 -51 -114 -2 -2 -11 13 -20 35 -94 229 -231
487 -348 659 -74 110 -250 288 -330 334 -115 68 -250 94 -351 68z m215 -84
c201 -67 421 -325 619 -728 67 -135 133 -285 125 -285 -3 0 -178 117 -390 261
-212 143 -392 259 -401 257 -9 -2 -21 -11 -27 -20 -8 -14 -6 -22 9 -37 11 -10
179 -125 373 -256 194 -131 351 -239 350 -241 -2 -1 -62 11 -133 27 -542 121
-874 296 -982 517 -25 50 -28 68 -28 152 0 82 4 103 27 152 35 75 95 138 168
177 98 52 183 59 290 24z m1891 7 c56 -15 155 -86 189 -135 50 -73 72 -141 72
-227 1 -65 -4 -86 -30 -143 -91 -193 -354 -354 -768 -469 -151 -42 -360 -88
-348 -76 5 4 164 112 354 240 190 128 353 240 363 248 24 21 18 56 -9 60 -15
2 -146 -81 -407 -257 -212 -144 -388 -261 -391 -261 -9 0 91 217 166 360 154
296 317 501 478 602 118 74 211 90 331 58z m-81 -1855 l25 -13 -25 -10 c-14
-6 -151 -67 -304 -136 l-280 -126 -68 27 c-38 14 -65 29 -61 33 9 8 419 188
498 218 28 11 62 22 75 25 36 7 111 -3 140 -18z m340 -12 l31 -28 -111 -48
c-61 -26 -225 -102 -365 -169 l-255 -121 -57 25 c-32 14 -58 28 -58 32 0 4
128 64 285 135 157 71 319 144 360 164 93 44 130 46 170 10z m349 -28 c23 -11
51 -57 51 -82 0 -5 -580 -290 -727 -358 l-83 -38 -95 49 c-52 27 -95 51 -95
55 0 13 606 290 770 352 105 40 137 44 179 22z"/>
</g>
</svg>

)
