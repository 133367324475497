import StarNote from "../../StarNote";
import {Button} from "../../Button";
import * as React from "react";
import {ShopProps} from "../index";
import Review from './Review';
import ReviewForm from "./ReviewForm";
import {useState} from "react";
import {getReview, sendReview} from "../../../tools/ciblerAPI";
import {useTranslation} from "../../../tools/i18n";

export interface ReviewsProps extends ShopProps {
  review: any[],
  setReview: (p: any) => void,
}

const computeAvgRating = review => {
  review = review || []

  let count = review?.length || 1;
  let total = review.reduce((res, e) => res + parseFloat(e?.shopGrade), 0.0);
  let mean = total / count;
  return mean.toFixed(1);
}

const Reviews = (props:ReviewsProps) => {
  const {t} = useTranslation();
  const [showReviewForm, setShowReviewForm] = useState<boolean>(false)
  const [showAllReview, setShowAllReview] = useState<boolean>(false)
  const maxReviewShown = 2;
  const {
    requirements,
    review,
    setReview,
    componentStates,
  } = props;
  const {seller, data} = componentStates;

  const handleSendReview = async (body) => {
    body.sellerInfo = {id: seller.id};

    await sendReview(JSON.stringify(body),requirements);

    const data = await getReview(requirements,seller.shortcode)
    setReview(data.sort((a, b) => (b.id - a.id)));
    setShowReviewForm(false);
  }

  let reviews = review.map((element, i) =>
    <Review
      review={element}
      key={`review${i}`}
      hide={i >= maxReviewShown && !showAllReview}
    />
  )

  return (
    <div className="reviews">
      <div className="title">
        <span>{props.reviewTitle || t('shop.reviews.title')}</span>
        <div>
          {(!data.google_grade && !!reviews.length)
            ? <StarNote note={parseFloat(computeAvgRating(review))}/>
            : null
          }
          <Button
            theme="client"
            onClick={() => setShowReviewForm(p => !p)}
          >
            {t('shop.reviews.write')}
          </Button>
        </div>
      </div>
      {showReviewForm
        ? <ReviewForm
            callback={handleSendReview}
            requirements={requirements}
          />
        : null
      }
      {!!reviews.length
        ? reviews
        : <div className={`flex-review`}>
            <div className="text">{t('shop.reviews.noReview')}</div>
          </div>
      }
      {reviews.length > maxReviewShown && !showAllReview
        ? <Button
            theme={"transparent"}
            onClick={() => setShowAllReview(true)}
          >
            {t('shop.reviews.seeAll')}
          </Button>
        : null
      }
    </div>
  );
}

export default Reviews;
export {
  computeAvgRating,
}