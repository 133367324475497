import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { ChatBotComponentProps } from "./index";
import { useTranslation } from "../../tools/i18n";
import { Picto } from "../Picto";
import { FilterLayout } from "./FiltersLayout";
import Carousel from "../Carousel/Carousel";
import { ResponsiveImage } from "../ResponsiveImage";
import { DROM, INTERNATIONAL_AGENT_ID } from "../../tools/Constants";
import { TravelPlannerSearchMapType } from "./types";
import {Button} from "../Button";

import StaticCarousel from "../Carousel/StaticCarousel";
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompleteService = google.maps.places.AutocompleteService;

interface SearchMapProps extends ChatBotComponentProps {
    step: TravelPlannerSearchMapType;
    siteConfiguration: any;
}

export const SearchMap = (props: SearchMapProps) => {
    const withSVG = props.siteConfiguration?.withSVG ?? false;
    const { t } = useTranslation();
    const { config, step, requirements, callback, stateData, setStateData } =
        props;
    const voidRef = useRef<HTMLDivElement>();
    const sessionToken = useRef(null);

    const { desktopMap, mobileMap } = step;
    const mapRef = useRef<HTMLDivElement>(null);
    const mobileMapRef = useRef<HTMLDivElement>(null);
    const [selectedPathId, setSelectedPathId] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>();
    const [predictions, setPredictions] = useState<any[]>([]);
    const autocomplete = useRef<AutocompleteService>();
    const { agentId } = props;

    const populars = useMemo(() => {
        if (!step.populars?.length) return [];
        return step?.populars?.sort(() => Math.random() - 0.5);
    }, [step]);

    const mostLiked = useMemo(() => {
        if (!step.mostLiked?.slides?.length) return [];
        return step?.mostLiked?.slides?.sort(() => Math.random() - 0.5);
    }, [step]);

    const translateRegionNames = useCallback((svgString: string) => {
      const regionNames = [
        'bordeaux', 'paris', 'amazonie', 'martinique', 'saintmartin', 'mayotte', 'guyane_1', 'guyane_2', 'alsace', 'bretagne', 'normandie', 'pays_loire', 'occitanie', 'pyrenees', 'val_loire', 'champagne', 'bourgogne', 'alpes_montblanc', 'auvergne', 'provence', 'cote_azur', 'alpes_sud', 'corse', 'biarritz', 'guadeloupe_1', 'guadeloupe_2', 'guadeloupe_3', 'saintmartin', 'barthelemy_1', 'barthelemy_2', 'reunion_1', 'reunion_2', 'mayotte', 'tahiti_1', 'tahiti_2', 'tahiti_3', 'caledonie_1', 'caledonie_2', 'wallis_et_futuna_1', 'wallis_et_futuna_2', 'wallis_et_futuna_3', 'saint_pierre_miquelon_1', 'saint_pierre_miquelon_2', 'jura', 'haut_france'
      ];

      // Utiliser une expression régulière pour trouver les balises <text> avec leurs attributs
      const textTagRegex = /<text([^>]*)>(.*?)<\/text>/g;

      return svgString.replace(textTagRegex, (match, attributes, textContent) => {
        const translatedContent = regionNames.reduce((acc, region) => {
          const regionKey = `travelPlaner.Search.regions.${region}`;
          const translatedRegion = t(regionKey);
          return acc.replace(region, translatedRegion);
        }, textContent);

        if (translatedContent.trim() === ' ') {
          return ''; // Supprimer la balise <text> si la traduction est vide
        }
        return `<text${attributes}>${translatedContent}</text>`;
      });
    }, [t]);

    const mobileMapTranslated = useMemo(() => translateRegionNames(mobileMap), [mobileMap, translateRegionNames]);
    const desktopMapTranslated = useMemo(() => translateRegionNames(desktopMap), [desktopMap, translateRegionNames]);

    useEffect(() => {
        if (typeof window !== "undefined" && window.google) return;

        const loader = new Loader({
            apiKey: "AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8",
            version: "weekly",
            libraries: ["places"],
        });

        loader.load();
    }, []);

    const handleSelect = useCallback(async (pathId?: string) => {
        const place = config.favorites?.find(
            (f) => f.key === pathId?.replace(/mobile_/g, "")
        );
        if (!place?.label) return;
        const temp = { ...stateData };
        temp.lon = place.lng.toString();
        temp.lat = place.lat.toString();
        temp.cityKey = place.key;
        temp.cityLabel = place.label;
        temp.radius = place.radius;
        temp.selectedFavorite = true;
        setStateData(temp);
        callback(place.label);
    }, [config.favorites, stateData, setStateData, callback]);

    const handleClickPath = useCallback((e: any) => {
        const id = e.target.id || e.target.parentNode.id;
        console.log('Ville cliquée:', id);

        if (id === undefined) return;

        if (!step.navOnClick) {
            const activeMapPath = mapRef.current?.querySelector(".active");
            const mapPaths = mapRef.current?.querySelectorAll(".selectable-path");
            if (activeMapPath?.id === id) {
                activeMapPath.classList.remove("active");
                setSelectedPathId("");
                mapPaths.forEach((p) => p.classList.remove("inactive"));
                return;
            }
            if (activeMapPath) activeMapPath.classList.remove("active");
            mapPaths.forEach(
                (p) => p.id !== e.target.id && p.classList.add("inactive")
            );
            e.target.classList.remove("inactive");
            e.target.classList.add("active");
            setSelectedPathId(id);
        } else {
            handleSelect(id).catch((e) => console.error(e));
        }
    }, [step.navOnClick, handleSelect]);

    useEffect(() => {
        if (!mapRef.current && !mobileMapRef.current) return;
        const mapPaths = [];
        mapPaths.push(
            ...(mapRef.current?.querySelectorAll(
                ".selectable-path"
            ) as unknown as Element[])
        );
        mapPaths.push(
            ...(mobileMapRef.current?.querySelectorAll(
                ".selectable-path"
            ) as unknown as Element[])
        );
        if (!mapPaths) return;
        mapPaths.forEach((p) => {
            p.addEventListener("click", handleClickPath);
        });
        return () => {
            mapPaths.forEach((p) => {
                p.removeEventListener("click", handleClickPath);
            });
        };
    }, [handleClickPath]);

    const subtitleKey = `travelPlaner.Search.subTitle`;
    const hasSubtitle = t(subtitleKey) !== subtitleKey;

    const autoCompleteOption = {
        ...(agentId !== INTERNATIONAL_AGENT_ID
            ? { componentRestrictions: { country: ["fr", ...DROM] } }
            : {}),
        fields: ["address_components", "geometry", "name"],
        types: ["locality", "postal_code", "colloquial_area"],
        sessionToken: sessionToken.current,
    };

    const getPredictions = async (input: string) => {
        let r = await autocomplete.current.getPlacePredictions({
            input,
            ...autoCompleteOption,
        });
        let results: AutocompletePrediction[] = r.predictions || [];

        return {
            results,
            input,
        };
    };

    const handleInput = (e) => {
        if (!e.target.value) {
            setPredictions([]);
            return;
        }

        if (!autocomplete.current) {
            if (typeof window !== "undefined" && window.google) {
                autocomplete.current = new google.maps.places.AutocompleteService();
                sessionToken.current =
                    new google.maps.places.AutocompleteSessionToken();
            } else {
                return;
            }
        }

        const value = e.target.value;
        getPredictions(value).then((r) => {
            if (r.input !== inputRef.current.value) return;

            setPredictions(r.results);
        });
    };

    const handleSelectPrediction = async (p: any) => {
        new google.maps.places.PlacesService(voidRef.current).getDetails(
            {
                placeId: p.place_id,
                fields: autoCompleteOption.fields,
                sessionToken: autoCompleteOption.sessionToken,
            },
            (a) => {
                const temp = { ...stateData };
                temp.lon = a.geometry.location.lng();
                temp.lat = a.geometry.location.lat();
                temp.cityKey = a.name;
                temp.cityLabel = a.name;
                temp.selectedFavorite = false;
                setStateData(temp);
                callback(p.label || p.description);
            }
        );
    };

    const adjustRectToText = useCallback((textElement: SVGTextElement, rectElement: SVGRectElement) => {
        const bbox = textElement.getBBox();
        const padding = 10;

        const currentX = parseFloat(rectElement.getAttribute('x') || '0');
        const currentY = parseFloat(rectElement.getAttribute('y') || '0');

        const newX = bbox.x - padding;
        const newY = bbox.y - padding;

        rectElement.setAttribute('x', newX >= 0 ? newX.toString() : currentX.toString());
        rectElement.setAttribute('y', newY >= 0 ? newY.toString() : currentY.toString());
        rectElement.setAttribute('width', (bbox.width + padding * 2).toString());
        rectElement.setAttribute('height', (bbox.height + padding * 2).toString());
    }, []);

    const alignTextsInGroup = useCallback((group: Element) => {
      const texts = group.querySelectorAll('text');
      if (texts.length <= 1) return;

      // Get x coordinate of first text
      const firstX = texts[0].getAttribute('x');

      // Apply same x coordinate to other texts
      for (let i = 1; i < texts.length; i++) {
        texts[i].setAttribute('x', firstX);
      }
    }, []);

    const adjustAllRectangles = useCallback(() => {
        if (!mapRef.current && !mobileMapRef.current) return;

        // Adjust for desktop map
        if (mapRef.current) {
            const groups = mapRef.current.querySelectorAll('g');
            groups.forEach(group => {
                // Align texts in group first
                alignTextsInGroup(group);

                const texts = group.querySelectorAll('text');
                texts.forEach(text => {
                    const rect = group.querySelector('rect');
                    if (rect) {
                        adjustRectToText(text as SVGTextElement, rect as SVGRectElement);
                    }
                });
            });
        }

        // Adjust for mobile map
        if (mobileMapRef.current) {
            const groups = mobileMapRef.current.querySelectorAll('g');
            groups.forEach(group => {
                // Align texts in group first
                alignTextsInGroup(group);

                const texts = group.querySelectorAll('text');
                texts.forEach(text => {
                    const rect = group.querySelector('rect');
                    if (rect) {
                        adjustRectToText(text as SVGTextElement, rect as SVGRectElement);
                    }
                });
            });
        }
    }, [adjustRectToText, alignTextsInGroup]);

    // Adjust rectangles on initial mount
    useEffect(() => {
        adjustAllRectangles();
    }, [adjustAllRectangles]);

    // Adjust rectangles when language changes
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            adjustAllRectangles();
        }, 50);

        return () => clearTimeout(timeoutId);
    }, [t, adjustAllRectangles]);

    if (!desktopMap || !mobileMap) return null;


    return (
        <div className="searchMap">
            <FilterLayout
                config={config}
                title={t(`travelPlaner.Search.title`)}
                requirements={requirements}
                siteConfiguration={props.siteConfiguration}
            >
                {hasSubtitle ? (
                    <div className="layoutSubtitle">{t(subtitleKey)}</div>
                ) : null}
                {step.showInput ? (
                    <div className="searchContainer">
                        <div className="inputBlock">
                            <Picto
                                iconKey={
                                    step.pictoVersion ? `search-${step.pictoVersion}` : "search"
                                }
                            />
                            <input
                                ref={(ref) => (inputRef.current = ref)}
                                onChange={(e) => handleInput(e)}
                                placeholder={t("travelPlaner.Search.input")}
                            />
                        </div>
                        <div className="predictions">
                            {predictions.map((p, i) => (
                                <button
                                    key={`prediction_${i}`}
                                    onClick={() => handleSelectPrediction(p)}
                                >
                                    <div className="prediction">
                                        <Picto iconKey={"place"} />
                                        {p.label || p.description}
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                ) : undefined}
                <div
                    className="map"
                    dangerouslySetInnerHTML={{ __html: desktopMapTranslated }}
                    ref={mapRef}
                />
                <div
                    className="mobileMap"
                    dangerouslySetInnerHTML={{ __html: mobileMapTranslated }}
                    ref={mobileMapRef}
                />
                {step.hasCarousel && mostLiked.length ? (
                    <StaticCarousel
                        label={t("travelPlaner.MostLiked.title")}
                        subLabel=""
                        slides={mostLiked}
                        className="searchMapCarousel"
                        requirements={requirements}
                        button={{
                            ...step.mostLiked.button,
                            text: t('travelPlaner.MostLiked.button')
                        }}
                    />
                ) : undefined}
                {step.hasCarousel && populars.length ? (
                    <Carousel label={t("travelPlaner.Popular.title")} slides={populars}>
                        {populars.map((p) => (
                            <div
                                key={p.key}
                                className="tiles"
                                onClick={() => handleSelect(p.key)}
                            >
                                <ResponsiveImage
                                    requirements={requirements}
                                    className="carouselImage"
                                    src={p.image}
                                    alt={p.label}
                                />
                                <div className="carouselLabel">{p.label}</div>
                            </div>
                        ))}
                    </Carousel>
                ) : undefined}
                {step.navOnClick ? undefined : (
                    <Button
                        onClick={() => handleSelect(selectedPathId)}
                        withPicto={"caret-right"}
                        textLabel="travelPlaner.Search.start"
                        withSVG={withSVG}
                        disabled={!selectedPathId}
                    >
                    </Button>
                )}
                {
                    step.randomSearch && (
                        <button
                            type="button"
                            onClick={() => handleSelect("all")}
                        >
                            {t(`travelPlaner.Search.random`)}
                            <Picto iconKey={"caret-right"}/>
                        </button>
                    )
                }
                <div
                    nonce="__nonce__"
                    style={{ display: "none" }}
                    ref={(ref) => (voidRef.current = ref)}
                />
            </FilterLayout>
        </div>
    );
};
