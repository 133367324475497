import React, {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { makeAnAlgoliaSearch } from "../../tools/searchAPI";
import {
  ActivityHit,
  AlgoliaResponse,
  SiteHit,
} from "../../tools/algoliaTypes";
import { RequirementsProps } from "../../tools/context";
import { SitesExploratorSite } from "./SitesExplorator";
import { OverLayParameter } from "../../App";
import { TravelPlannerResultsConfigType } from "./types";
import { getItinerary, putItinerary } from "../../tools/ciblerAPI";

const algoliaIndexes = ["tca_stores", "tca_store_replica_reviewscore"];

type ContextType = {
  journey?: any;
  isLoadingJourney?: boolean;
  agentId?: string;
  setOverlay?: (p: OverLayParameter) => void;
  config?: TravelPlannerResultsConfigType;
  inputs?: any;
  requirements?: RequirementsProps;
  trip?: any;
  day?: string;
  setDay?: React.Dispatch<React.SetStateAction<ContextType["day"]>>;
  selectedPart?: "dayPoints" | "restaurants" | "lodgings";
  handleGoTo?: (anchor: ContextType["selectedPart"]) => void;
  showAllSites?: boolean;
  setShowAllSites?: React.Dispatch<
    React.SetStateAction<ContextType["showAllSites"]>
  >;
  showMobileMap?: boolean;
  setShowMobileMap?: React.Dispatch<
    React.SetStateAction<ContextType["showMobileMap"]>
  >;
  focusedSite?: number | string;
  setFocusedSite?: React.Dispatch<
    React.SetStateAction<ContextType["focusedSite"]>
  >;
  isLoadingSites?: boolean;
  searchData?: {
    term?: string;
    index?: typeof algoliaIndexes[number];
    filters?: string[];
    bounds?: number[];
  };
  setSearchData?: React.Dispatch<
    React.SetStateAction<ContextType["searchData"]>
  >;
  formattedData?: any;
  setFormattedData?: React.Dispatch<
    React.SetStateAction<ContextType["formattedData"]>
  >;
  selectedActivities?: { [key: string]: number }[];
  setSelectedActivities?: React.Dispatch<
    React.SetStateAction<ContextType["selectedActivities"]>
  >;
  updateTrip?: (newData: any, newSelectedActivities: any) => void;
  searchSite?: (
    newData?: {
      [key in keyof ContextType["searchData"]]?: ContextType["searchData"][key];
    }
  ) => void;
  searchPaginateSite?: () => Promise<void>;
  sites?: SitesExploratorSite[];
  setSites?: (sites: SitesExploratorSite[]) => void;
  sitesInTrip?: { id: number | string; dayIndex: number }[];
  setSitesInTrip?: React.Dispatch<
    React.SetStateAction<ContextType["sitesInTrip"]>
  >;
  maxSitesPages?: number;
  allSitesPage?: number;
  selectedAlgoliaIndex?: string;
  handleChangeAlgoliaIndex?: (index: string) => void;
  siteConfiguration?: any;
};

function createFormattedTrip(formattedData: any, selectedActivities: any) {
  let trip = {};
  for (const dayId in selectedActivities) {
    if (selectedActivities.hasOwnProperty(dayId)) {
      trip[dayId] = {};
      const activities = selectedActivities[dayId];
      for (const activityId in activities) {
        if (activities.hasOwnProperty(activityId)) {
          const activity = formattedData[dayId].find(
            (item: any) => item.key === activityId
          );
          if (activity) {
            trip[dayId][activityId] = activity.value;
          }
        }
      }
    }
  }
  return trip;
}

const algoliaResponseToSitesList = (
  response?: AlgoliaResponse,
  formattedData?: any,
  selectedActivities?: any
): SitesExploratorSite[] => {
  const uniqueIds = new Set();
  const trip = createFormattedTrip(formattedData, selectedActivities);
  const days = trip ? Object.keys(trip) : [];

  return (
    response?.hits
      .filter((s) => {
        if (uniqueIds.has(s.id)) {
          return false;
        } else {
          uniqueIds.add(s.id);
          return true;
        }
      })
      .map((s) => {
        let result: any = {
          type: s.type || "site",
          id: s.id,
          post_content: s.post_content,
          post_content_en: s.post_content_en,
          post_content_es: s.post_content_es,
          post_content_de: s.post_content_de,
          post_content_it: s.post_content_it,
          post_content_nl: s.post_content_nl,
          urlimg: s.urlimg,
          lat: s._geoloc.lat,
          lng: s._geoloc.lng,
        };

        switch (s.type) {
          case "activity":
            const d = s as ActivityHit;
            result = {
              ...result,
              store_name: d.name,
              _categories: d._categories,
              tarif: d.tarif,
              price_range: d.price_range,
              site: d.site,
              start_date: d.start_date,
              end_date: d.end_date,
            } as SitesExploratorSite;
            break;
          default:
            const d2 = s as SiteHit;
            result = {
              ...result,
              formatted_address: d2.formatted_address,
              modalities: d2.modalities,
              free: d2.free,
              possible_fee: d2.possible_fee,
              store_name: d2.store_name,
              tca_categ: d2.tca_categ,
              shop_grade: d2.shop_grade,
            } as SitesExploratorSite;
            break;
        }

        let found = false;
        const { id } = s;

        days.forEach((d, dayIndex) => {
          if (found) return;
          const points = Object.keys(trip[d]);
          points.forEach((p, pointIndex) => {
            if (found) return;
            const pointsIds = trip[d][p].map((pp: any) => pp.id);
            if (pointsIds.includes(id)) {
              result.dayIndex = dayIndex;
              result.pointIndex = pointIndex;
              found = true;
            }
          });
        });
        return result;
      }) || []
  );
};

export const TravelPlanerResultsContext = createContext<ContextType>({});

export function TravelPlanerResultsProvider({
  children,
  agentId,
  setOverlay,
  initialJourney,
  config,
  requirements,
  initialSites,
  siteConfiguration,
  language = 'fr',
}: PropsWithChildren<{
  agentId?: string;
  setOverlay?: (p: OverLayParameter) => void;
  initialJourney?: any;
  config?: TravelPlannerResultsConfigType;
  requirements?: RequirementsProps;
  initialSites?: any;
  siteConfiguration?: any;
  language?: string;
}>) {
  const [day, setDay] = useState<string>();
  const [selectedPart, setSelectedPart] =
    useState<ContextType["selectedPart"]>("dayPoints");
  const [showAllSites, setShowAllSites] =
    useState<ContextType["showAllSites"]>(false);
  const [showMobileMap, setShowMobileMap] =
    useState<ContextType["showMobileMap"]>(false);
  const [focusedSite, setFocusedSite] =
    useState<ContextType["focusedSite"]>(-1);
  const [isLoadingSites, setIsLoadingSites] =
    useState<ContextType["isLoadingSites"]>(false);
  const [searchData, setSearchData] = useState<ContextType["searchData"]>({});
  const [formattedData, setFormattedData] = useState({});
  const [selectedActivities, setSelectedActivities] = useState<
    ContextType["selectedActivities"]
  >([]);
  const [sites, setSites] = useState<SitesExploratorSite[]>([]);
  const [sitesInTrip, setSitesInTrip] = useState<ContextType["sitesInTrip"]>();
  const [maxSitesPages, setMaxSitesPages] = useState<
    ContextType["maxSitesPages"]
  >(initialSites?.data?.nbPages || 1);
  const [allSitesPage, setAllSitesPage] =
    useState<ContextType["allSitesPage"]>(0);
  const [selectedAlgoliaIndex, setSelectedAlgoliaIndex] =
    useState<string>("tca_stores");
  const [journey, setJourney] = useState<any>(initialJourney);
  const [trip, setTrip] = useState<any>(journey?.travelData?.trip || {});
  const [inputs, setInputs] = useState<any>(journey?.travelData?.inputs || {});
  const [isLoadingJourney, setIsLoadingJourney] = useState<boolean>(
    Object.keys(trip || {})?.length < inputs?.tripLength
  );

  const algoliaFilterString = useMemo(() => {
    if (config && config.algoliaFilters && config.algoliaFilters !== "") {
      const filters = config.algoliaFilters.split(';');
      return `NOT ${filters.join(" AND NOT ")}`;
    }
    return "";
  }, [config]);

  useEffect(() => {
    if (!isLoadingJourney) return;

    let intervalId: NodeJS.Timeout;
    let tmpData = journey;

    const fetchJourney = () => {
      getItinerary(requirements?.parameters?.[0], requirements, language)
        .then((data) => {
          if (
            !data?.travelData ||
            JSON.stringify(data) === JSON.stringify(tmpData)
          )
            return;
          tmpData = data;
          const { travelData: results } = data;
          setJourney(data);
          setTrip(results.trip);
          setInputs(results.inputs);
          setIsLoadingJourney(
            Object.keys(results.trip || {}).length < results.inputs.tripLength
          );
        })
        .catch((e) => {
          console.error("Error loading journey", e);
          clearInterval(intervalId);
        });
    };

    intervalId = setInterval(fetchJourney, 2000);
    return () => clearInterval(intervalId);
  }, [isLoadingJourney, requirements, language, journey]);

  useEffect(() => {
    if (sites?.length || !formattedData) return;
    const newSites = algoliaResponseToSitesList(
      initialSites.data as AlgoliaResponse,
      formattedData,
      selectedActivities
    );
    setSites(newSites);
  }, [formattedData, initialSites, selectedActivities, sites?.length]);

  useEffect(() => {
    setSitesInTrip(
      sites
        .filter(
          (s) =>
            typeof s.dayIndex === "number" && typeof s.pointIndex === "number"
        )
        .map((s) => ({ id: s.id, dayIndex: s.dayIndex }))
    );
  }, [sites]);

  const contextValue = useMemo(() => {
    const updateTrip: ContextType["updateTrip"] = (
      newData,
      newSelectedActivities
    ) => {
      const newTrip = {};
      Object.keys(newData).forEach((dayKey) => {
        const dayData = newData[dayKey];
        const day = {};
        Object.keys(dayData).forEach((pointKey) => {
          const pointData = dayData[pointKey];
          day[pointData.key] = pointData.value;
        });
        newTrip[dayKey] = day;
      });

      const oldDay = day;
      const oldDays = Object.keys(formattedData);
      const oldDayIndex = oldDays.indexOf(oldDay);
      const newDays = Object.keys(newTrip);
      const newDay = newDays[oldDayIndex];

      const tripLength = Object.keys(newTrip).length;

      setDay(newDay);
      setFormattedData(newData);
      setSelectedActivities(newSelectedActivities);

      const { travelData: results } = journey;
      putItinerary(
        requirements.parameters[0],
        {
          ...results,
          inputs: {
            ...results.inputs,
            tripLength,
          },
          trip: newTrip,
        },
        requirements
      );
    };

    const searchSite: ContextType["searchSite"] = async (newData) => {
      let data = { ...searchData };
      if (newData) {
        data = {
          ...data,
          ...newData,
        };
      }
      setSearchData(data);

      setIsLoadingSites(true);
      const customIndex = data.index || "tca_stores";
      const { lat, lon, radius } = inputs || {};

      const response = await makeAnAlgoliaSearch(
        {
          query: data.term,
          radius: radius || 10000,
          index: customIndex,
          insideBoundingBox:
            data.bounds?.length % 4 === 0 ? data.bounds.toString() : undefined,
        },
        requirements,
        [],
        data.filters?.length
          ? `${algoliaFilterString} AND ${data.filters
              ?.map((f) => `_categories:${f}`)
              .join(" OR ")}`
          : algoliaFilterString,
        lat,
        lon
      );
      const newSites = algoliaResponseToSitesList(
        response.data as AlgoliaResponse,
        formattedData,
        selectedActivities
      );
      setMaxSitesPages(response.data.nbPages);
      setAllSitesPage(0);
      setIsLoadingSites(false);
      setSites(newSites);
    };

    const searchPaginateSite = async (): Promise<void> => {
      if (allSitesPage + 1 >= maxSitesPages) return;
      setIsLoadingSites(true);
      const customIndex = searchData.index || "tca_stores";
      const { lat, lon, radius } = journey?.travelData || {};
      makeAnAlgoliaSearch(
        {
          query: searchData.term,
          radius: radius || 10000,
          index: customIndex,
          page: allSitesPage + 1,
        },
        requirements,
        [],
        searchData.filters?.length
          ? `${algoliaFilterString} AND ${searchData.filters
              ?.map((f) => `_categories:${f}`)
              .join(" OR ")}`
          : algoliaFilterString,
        lat,
        lon
      )
        .then(({ data }) => {
          const newSites = algoliaResponseToSitesList(
            data as AlgoliaResponse,
            formattedData,
            selectedActivities
          );
          setMaxSitesPages(data.nbPages);
          setAllSitesPage((p) => p + 1);
          setSites((s) => [...s, ...newSites] as SitesExploratorSite[]);
        })
        .catch((e) => console.error("Error paginating search", e))
        .finally(() => setIsLoadingSites(false));
    };

    const handleGoTo = (anchor: ContextType["selectedPart"]) => {
      setSelectedPart(anchor);
      const elementTop =
        document.getElementById(anchor)?.getBoundingClientRect()?.top +
        window.scrollY;
      window.scrollTo({ top: elementTop - 110, behavior: "smooth" });
    };

    const handleChangeAlgoliaIndex = (index: string) => {
      setSelectedAlgoliaIndex(index);
      searchSite({ index });
    };

    return {
      journey,
      agentId,
      setOverlay,
      config,
      inputs,
      requirements,
      trip,
      day,
      setDay,
      selectedPart,
      handleGoTo,
      showAllSites,
      setShowAllSites,
      showMobileMap,
      setShowMobileMap,
      focusedSite,
      setFocusedSite,
      isLoadingSites,
      searchData,
      setSearchData,
      formattedData,
      setFormattedData,
      selectedActivities,
      setSelectedActivities,
      updateTrip,
      searchSite,
      searchPaginateSite,
      sites,
      setSites,
      sitesInTrip,
      setSitesInTrip,
      maxSitesPages,
      allSitesPage,
      selectedAlgoliaIndex,
      handleChangeAlgoliaIndex,
      siteConfiguration,
    };
  }, [
    journey,
    agentId,
    setOverlay,
    config,
    inputs,
    requirements,
    trip,
    day,
    selectedPart,
    showAllSites,
    showMobileMap,
    focusedSite,
    isLoadingSites,
    searchData,
    formattedData,
    selectedActivities,
    sites,
    sitesInTrip,
    maxSitesPages,
    allSitesPage,
    selectedAlgoliaIndex,
    siteConfiguration,
  ]);

  return (
    <TravelPlanerResultsContext.Provider value={contextValue}>
      {children}
    </TravelPlanerResultsContext.Provider>
  );
}
