import React from "react";

import { RequirementsProps } from "../../../tools/context";
import { ResponsiveImage } from "../../ResponsiveImage";

export interface HeroResultProps {
  background?: any;
  backgroundColor?: string;
  defaultTitle?: string;
  requirements: RequirementsProps;
}

export const HeroResult = (props: HeroResultProps) => {
  const { background, backgroundColor, defaultTitle, requirements } = props;

  return (
    <>
      <div
        className={"heroResult"}
        nonce="__nonce__"
        style={backgroundColor ? { backgroundColor } : {}}
      >
        {background && (
          <ResponsiveImage
            className="background"
            src={background}
            alt="Hero background"
            preload
            requirements={requirements}
          />
        )}
        <div className="container">
          <h2 className="subtitle">
            {requirements.query ? (
              <>
                Recherche "{requirements.query}"{" "}
                {requirements.aroundme && "autour de moi"}
              </>
            ) : (
              requirements.location || defaultTitle
            )}
          </h2>
        </div>
      </div>
    </>
  );
};
