import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { getStoresAsync } from "../../tools/searchAPI";
import OfferSlide, { getDiscountTags } from "../Carousel/OfferSlide";
import { SearchBar } from "../SearchBar";
import { replaceUrlParam } from "../../tools/tools";
import { RequirementsProps } from "../../tools/context";
import Map from "../GridComponents/Map";
import {
  preloader as preloaderCarousel,
  Renderer as SearchCarousel,
} from "../Carousel/SearchCarousel";
import Pagination from "../GridComponents/Pagination";
import { Configuration } from "../../tools/Constants";
import { Picto } from "../Picto";

export interface ResultGridProps {
  componentStates: any;
  requirements: RequirementsProps;
  carousels: any[];
}

const Renderer = (props: ResultGridProps) => {
  const geoloc = useRef<any>(null);
  const [componentStates, setComponentStates] = useState<any[]>(
    props.componentStates
  );
  const [showMap, setShowMap] = useState<boolean>(props.requirements.mapOpened);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const state = componentStates[0];

  const loadState = async () => {
    navigator.geolocation.getCurrentPosition(
      async (r) => {
        geoloc.current = r.coords;
        const requirements = { ...props.requirements };
        requirements.lon = r.coords.longitude.toString();
        requirements.lat = r.coords.latitude.toString();
        const result = await preloader(props, requirements, true);
        setComponentStates(result);
        setShowLoader(false);
      },
      async () => {
        const result = await preloader(props, props.requirements, true);
        setComponentStates(result);
        setShowLoader(false);
      }
    );
  };

  useEffect(() => {
    if (props.requirements.aroundme) {
      setShowLoader(true);
      loadState();
    }
  }, []);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      replaceUrlParam("mapOpened", showMap, window.location.href)
    );
  }, [showMap]);

  const roundGrade = (input) => {
    if (isNaN(input)) return 0;

    return Math.round(input * 10) / 10;
  };

  const renderFilter = (c, checker, list, handler) => (
    <button
      key={c}
      className={`filter ${c}`}
      onClick={() => handler(c, checker)}
    >
      {list[c].name}
      <Picto iconKey={"cross"} />
    </button>
  );

  const renderCurrentFilters = () => {
    const categories = Configuration.categories;
    const labelFilters = Configuration.labels;
    const otherFilters = {
      bonus: { name: "Avec avantage" },
    };

    const filters = {
      categ: props.requirements.categ || [],
      labels: props.requirements.labels || [],
      filters: props.requirements.filters || [],
    };

    const handler = (c, checker) => {
      console.log(c, checker);
      const idx = filters[checker].findIndex((k) => k === c);
      filters[checker].splice(idx, 1);

      let url = window.location.href;
      url = replaceUrlParam("page", 0, url);
      window.location.href = replaceUrlParam(
        checker,
        filters[checker].join("|"),
        url
      );
    };

    const list = [];

    if (filters.categ.length)
      list.push(
        ...filters.categ.map((c) =>
          renderFilter(c, "categ", categories, handler)
        )
      );
    if (filters.labels.length)
      list.push(
        ...filters.labels.map((c) =>
          renderFilter(c, "labels", labelFilters, handler)
        )
      );
    if (filters.filters.length)
      list.push(
        ...filters.filters.map((c) =>
          renderFilter(c, "filters", otherFilters, handler)
        )
      );

    return list;
  };

  const handleChangePage = async (page) => {
    const requirements: any = { ...props.requirements };
    requirements.page = page;
    setShowLoader(true);
    const result = await preloader(props, requirements, true);
    setComponentStates(result);
    setShowLoader(false);
  };

  const showCarousel =
    !props.requirements.query &&
    state.data.page === 0 &&
    props.carousels &&
    !!props.carousels.length;

  const results = state?.data?.hits || [];
  // console.log(results[0])
  // console.log(state.data)
  const couponQuery = props.requirements?.coupon
    ? `?coupon=${props.requirements.coupon}`
    : "";
  return (
    <div className="resultGrid">
      <SearchBar
        requirements={props.requirements}
        mapGridCallback={(e) => setShowMap(e)}
        mapOpened={showMap}
      />
      <div className="noResult">
        {showLoader ? (
          <div className="loader">
            <ThreeDots height={100} width={100} />
          </div>
        ) : state?.data?.nbHits ? (
          `${state.data.nbHits} résultat${state.data.nbHits > 1 ? "s" : ""}`
        ) : (
          "Pas de résultat"
        )}
      </div>
      <div className="filters">{renderCurrentFilters()}</div>
      {!!results.length && showMap && (
        <Map
          results={results}
          requirements={props.requirements}
          geoloc={geoloc.current}
        />
      )}
      {showCarousel &&
        props.carousels.map((c, i) => (
          <SearchCarousel
            key={c._key}
            {...c}
            componentStates={props.componentStates[i + 1]}
            requirements={props.requirements}
          />
        ))}
      {!!results.length && !showMap && (
        <div className="grid">
          {showCarousel && <div className="title">Les autres résultats</div>}
          <div className="results">
            {results.map((r) => (
              <OfferSlide
                key={r.id}
                category={r.categslug}
                title={r.store_name}
                image={r.urlimg || ""}
                fallbackImage={`https://tourismebyca.twic.pics/static/${props.requirements?.partner}/v2/categSearch/${r.categslug}.jpg`}
                fallbackImage2={`https://tourismebyca.twic.pics/static/tca/v2/categSearch/${r.categslug}.png`}
                place={r.city || r.deptname}
                url={`/etablissement/${r.seller_id}${couponQuery}`}
                note={roundGrade(r.shop_grade)}
                discount={couponQuery ? [] : getDiscountTags(r.gifts)}
                requirements={props.requirements}
              />
            ))}
          </div>
        </div>
      )}
      <Pagination
        nbPages={state?.data?.nbPages}
        currentPage={state?.data?.page}
        requirements={props.requirements}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

const preloader = async (data, requirements, clientSide) => {
  if (requirements.aroundme && !clientSide) return [];

  const filters = {
    lat: requirements.lat || data.lat,
    lon: requirements.lon || data.lon,
    page: requirements.page,
    categ: requirements.categ || [],
    labels: requirements.labels || [],
    filters: {},
    department: requirements.department,
    radius: data.searchRadius,
    query: requirements.query,
    index: data.searchIndex,
  };
  if (requirements?.coupon) {
    filters.filters["post_status"] = "accepted";
  }

  if (requirements.filters)
    requirements.filters.forEach((k) => (filters.filters[k] = true));

  const loaders = [getStoresAsync(requirements?.config?.key, filters)];

  if (data.carousels) {
    loaders.push(
      ...data.carousels.map((c) => preloaderCarousel(c, requirements))
    );
  }

  return await Promise.all(loaders);
};

const ResultGrid = { Renderer, preloader };

export default ResultGrid;
export { Renderer, preloader };
