import React from "react";
import {PictoProps} from "./index";

export const Twitter = (props: PictoProps) => (
  <svg width={props.width || "25"} height={props.height || "24"} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_56_2208)">
      <path d="M8.06777 21.4175C16.9837 21.4175 21.8617 14.029 21.8617 7.62357C21.8617 7.41586 21.8571 7.20353 21.8479 6.99582C22.7968 6.30958 23.6157 5.45957 24.2661 4.48574C23.3823 4.87893 22.444 5.13573 21.4832 5.24735C22.4949 4.64094 23.2524 3.68829 23.6153 2.56601C22.6635 3.13005 21.6227 3.52792 20.5374 3.74259C19.8062 2.9656 18.8393 2.45115 17.7864 2.27876C16.7334 2.10637 15.653 2.28565 14.7122 2.78888C13.7713 3.29211 13.0225 4.09127 12.5814 5.06279C12.1403 6.03431 12.0315 7.12408 12.2719 8.16363C10.3447 8.06692 8.45947 7.56631 6.73824 6.69424C5.01701 5.82218 3.49826 4.59813 2.28044 3.10145C1.66149 4.16861 1.47208 5.4314 1.75073 6.63318C2.02938 7.83497 2.75517 8.88557 3.78059 9.57145C3.01076 9.54701 2.2578 9.33975 1.58392 8.96678V9.02679C1.58323 10.1467 1.97039 11.2323 2.67959 12.099C3.38879 12.9657 4.37626 13.5601 5.47413 13.7811C4.76101 13.9762 4.01256 14.0046 3.2867 13.8642C3.5965 14.8273 4.19925 15.6697 5.01085 16.2737C5.82244 16.8778 6.80238 17.2134 7.8139 17.2337C6.09664 18.5827 3.97533 19.3143 1.79163 19.3108C1.40437 19.3103 1.01749 19.2865 0.633057 19.2398C2.85147 20.663 5.43207 21.4189 8.06777 21.4175Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_56_2208">
        <rect width="23.633" height="23.633" fill="white" transform="translate(0.633057)"/>
      </clipPath>
    </defs>
  </svg>
)
