import React from "react";
import {PictoProps} from "./index";

export const ThumbBlack = (props: PictoProps) => (
  <svg version="1.0" id="Layer_1" width="800px" height="800px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
<g>
	<circle fill="#231F20" cx="7" cy="57" r="1"/>
  <g>
		<path fill="#231F20" d="M14,26c0-2.212-1.789-4-4-4H4c-2.211,0-4,1.788-4,4v34c0,2.21,1.789,4,4,4h6c2.211,0,4-1.79,4-4V26z M7,60
			c-1.657,0-3-1.344-3-3c0-1.658,1.343-3,3-3s3,1.342,3,3C10,58.656,8.657,60,7,60z"/>
    <path fill="#231F20" d="M64,28c0-3.314-2.687-6-6-6H41l0,0h-0.016H41l2-18c0.209-2.188-1.287-4-3.498-4h-4.001
			C33,0,31.959,1.75,31,4l-8,18c-2.155,5.169-5,6-7,6v30.218c1.203,0.285,2.714,0.945,4.21,2.479C23.324,63.894,27.043,64,29,64h23
			c3.313,0,6-2.688,6-6c0-1.731-0.737-3.288-1.91-4.383C58.371,52.769,60,50.577,60,48c0-1.731-0.737-3.288-1.91-4.383
			C60.371,42.769,62,40.577,62,38c0-1.731-0.737-3.288-1.91-4.383C62.371,32.769,64,30.577,64,28z"/>
	</g>
</g>
</svg>
)
