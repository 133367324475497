import React from "react";
import { PictoProps } from "../index";

export const Calendar = (props: PictoProps) => (
  <svg {...props} width={props.width || "14"} height={props.height || "16"} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3660_179)">
      <path
        d="M5.45555 10.1448C4.97981 10.1448 4.59415 10.5205 4.59415 10.9841C4.59415 11.4476 4.97981 11.8233 5.45555 11.8233H8.51832C8.99406 11.8233 9.37972 11.4476 9.37972 10.9841C9.37972 10.5205 8.99406 10.1448 8.51832 10.1448H5.45555Z"
        fill="#8C7215"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.0497 0.446533C10.5254 0.446533 10.9111 0.822288 10.9111 1.28581V1.93857H11.5811C12.9026 1.93857 13.9739 2.98233 13.9739 4.26988V13.2221C13.9739 14.5097 12.9026 15.5534 11.5811 15.5534H2.39279C1.07129 15.5534 0 14.5097 0 13.2221V4.26988C0 2.98233 1.07129 1.93857 2.39279 1.93857H3.06285V1.28581C3.06285 0.822288 3.44851 0.446533 3.92425 0.446533C4.39999 0.446533 4.78566 0.822288 4.78566 1.28581V1.93857H9.1883V1.28581C9.1883 0.822288 9.57396 0.446533 10.0497 0.446533ZM12.2511 4.26988V6.41468H1.72281V4.26988C1.72281 3.90937 2.02277 3.61711 2.39279 3.61711H3.06285V4.26988C3.06285 4.7334 3.44851 5.10916 3.92425 5.10916C4.39999 5.10916 4.78566 4.7334 4.78566 4.26988V3.61711H9.1883V4.26988C9.1883 4.7334 9.57396 5.10916 10.0497 5.10916C10.5254 5.10916 10.9111 4.7334 10.9111 4.26988V3.61711H11.5811C11.9511 3.61711 12.2511 3.90937 12.2511 4.26988ZM12.2511 8.09323H1.72281V13.2221C1.72281 13.5826 2.02277 13.8749 2.39279 13.8749H11.5811C11.9511 13.8749 12.2511 13.5826 12.2511 13.2221V8.09323Z"
            fill="#8C7215"/>
    </g>
    <defs>
      <clipPath id="clip0_3660_179">
        <rect width="13.9739" height="15.1069" fill="white" transform="translate(0 0.446533)"/>
      </clipPath>
    </defs>
  </svg>
);