import React from 'react';

import {Picto} from "../../Picto";
import {RequirementsProps} from "../../../tools/context";
import {SearchBar} from "../../SearchBar";
import {ResponsiveImage} from "../../ResponsiveImage";
import {LinkProps} from "../../index";

export interface HeroSearchProps {
  background: any
  title?: string
  subtitle?: string
  content?: string
  bottomLinks?: LinkProps[]
  requirements: RequirementsProps
}

export const HeroSearch = (props: HeroSearchProps) => {
  const {
    background,
    title,
    subtitle,
    content,
    bottomLinks,
    requirements,
  } = props;

  return (<>
    <div className={"HeroSearch"}>
      <ResponsiveImage
        className="background"
        src={background} alt="Hero background"
        preload
        requirements={requirements}
      />
      <div className="container">
        <h1 className="title">{title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
        <div className="search"><SearchBar requirements={requirements} heroSearch /></div>
        <p className="content courant2">{content}</p>
      </div>
      {bottomLinks &&
        <div className="bottom-links">
          {bottomLinks.map((link, index) => (
            <a key={index} href={link.link} className="link courant2">
              <>
                {link.picto && <Picto iconKey={link.picto} />}
                {link.text}
              </>
            </a>
          ))}
        </div>
      }
    </div>
    </>
  )
}