import * as React from "react";
import {Picto} from "../Picto";
import {Button} from "../Button";
import {useState} from "react";
import {subToNewsletter} from "../../tools/ciblerAPI";
import {RequirementsProps} from "../../tools/context";


export interface NewsletterProps {
  requirements:RequirementsProps
  text: string
}

const Newsletter = (props: NewsletterProps) => {
  const [email,setEmail] = useState<string>(null);
  const [sent,setSent] = useState<boolean>(false);

  const sub = () =>{
    subToNewsletter(email,props.requirements);
    setSent(true);
  };

  return <div className="newsletter">
    <div className="label">
      <div className="title">
        Inscrivez-vous <br/>à notre
        <span>newsletter</span>
        <Picto iconKey={"envelopeNewsletter"} />
      </div>
      <div className="description">{props.text}</div>
    </div>
    <form className="form" onSubmit={sub}>
      {sent ? <div>Inscription validée</div> :
        <>
          <input type="email" required onChange={e => setEmail(e.target.value)} placeholder="Entrez votre e-mail"/>
          <Button theme={"transparent"} type="submit">Je m'inscris</Button>
        </>
      }
    </form>
  </div>
}

export default Newsletter;