const getQueryString = (name: string, url?: string) => {
  if (typeof window === "undefined") return null;
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const replaceUrlParam = (paramName, paramValue, href) => {
  let url = href;
  var pattern = new RegExp("\\b(" + paramName + "=).*?(&|#|$)");

  if (paramValue == null || paramValue === "") {
    return url.replace(pattern, "");
  }

  if (url.search(pattern) >= 0) {
    return url.replace(pattern, "$1" + paramValue + "$2");
  }
  url = url.replace(/[?#]$/, "");
  return (
    url + (url.indexOf("?") > 0 ? "&" : "?") + paramName + "=" + paramValue
  );
};

const replaceQueryString = (query, value) => {
  window.history.replaceState(
    {},
    "",
    replaceUrlParam(query, value, window.location.href)
  );
};

const isDebugMode = () => {
  return getQueryString("debug");
};

const getQueryStrings = (url?: string) => {
  if (typeof window === "undefined") return null;
  if (!url) url = window.location.href.replace(window.location.hash, "");

  const urlSplit = url.split("?");
  if (urlSplit.length <= 1) return null;

  const output = {};
  urlSplit[1].split("&").forEach((s) => {
    const ss = s.split("=");
    output[ss[0]] = ss[1];
  });

  return output;
};

const getCookies: () => { [key: string]: any } = () => {
  if (typeof window === "undefined" || !document.cookie) return {};

  return document.cookie
    .split(";")
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      acc[decodeURIComponent((v[0] || "").trim())] = decodeURIComponent(
        (v[1] || "").trim()
      );
      return acc;
    }, {});
};

const getOrCreateCiblerId = () => {
  const cookies = getCookies();

  if (cookies.cibler_id) return cookies.cibler_id;

  const id = `CIB1.${Math.floor(Math.random() * 1e9)}.${Math.floor(
    new Date().getTime() / 1000
  )}`;
  document.cookie = `cibler_id=${id}`;
  return id;
};

const isWebview = (ua) => {
  const rules = [
    // if it says it's a webview, let's go with that
    "WebView",
    // iOS webview will be the same as safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
    // old chrome android webview agent
    "Linux; U; Android",
  ];
  const webviewRegExp = new RegExp("(" + rules.join("|") + ")", "ig");
  return !!ua.match(webviewRegExp);
};

const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export {
  getQueryString,
  getCookies,
  replaceQueryString,
  replaceUrlParam,
  getQueryStrings,
  getOrCreateCiblerId,
  isDebugMode,
  isWebview,
  delay,
};
