import React, {useState} from 'react';

import {Button} from '../Button';
import {Picto} from "../Picto";
import Navigation from "./navigation";
import {RequirementsProps} from "../../tools/context";
import Logo from "../Logo";
import LanguageSelector from "./LanguageSelector";
// @ts-ignore
// import burgerMenu from '../../assets/images/icons/burger-menu-2.svg';
// @ts-ignore
// import UserIcon from '../../assets/images/icons/user.svg';
// @ts-ignore
// import TCALogo from '../../assets/images/logos/logo_tca_monochrome.png';

export interface HeaderProps {
  navigation: any[]
  headerButtons: any[]
  requirements: RequirementsProps
}

export const Header = (props: HeaderProps) => {
  const [showNav, setShowNav] = useState<boolean>(false);
  const {
    navigation,
    headerButtons,
    requirements,
  } = props;

  const agentLinksMatch = [
    {pathInclude: '-vin', linkSuffix: '-vin', links: ['/guide', '/itineraire']},
  ];

  const getLink = (link: string) => {
    let suffix = '';
    agentLinksMatch.forEach((match) => {
      if (!requirements.path.includes(match.pathInclude)) return link;
      match.links.forEach((l) => {
        if (l === link) suffix = match.linkSuffix;
      });
    });
    return link + suffix;
  }

  headerButtons?.forEach((button) => {
    button.link = getLink(button.link);
  });

  navigation?.forEach((nav) => {
    nav.link = getLink(nav.link);
  });

  return (
    <div className={"Header"}>
      <div className="header-content">
        <Logo className={"header-logo"} location={"header"} requirements={requirements} />
        <div className="container">
          <div className="container">
            {headerButtons && headerButtons.map(h =>
              <a
                className={`Button navButton ${h.style} ${h.picto ? 'hasPicto' : ''}`}
                href={h.link}
                key={h._key}
                target={h.external ? '_blank' : null}
              >
                {h.picto && <Picto iconKey={h.picto} />}
                <span>{h.text}</span>
              </a>)}
          </div>
          <Button className={"burger-menu"} theme={"pro"} onClick={() => setShowNav(true)}>
            <Picto iconKey={"burger-menu-2"} />
          </Button>
          {requirements.config.customerId && requirements.endpoint && requirements.config.languageSelector && (
              <LanguageSelector customerID={requirements.config.customerId} endpoint={requirements.endpoint} />
          )}
        </div>
      </div>
      {showNav && <Navigation
        requirements={requirements}
        closeCallback={() => setShowNav(false)}
        navigation={navigation}
      />}
    </div>
  )
}