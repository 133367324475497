import React, {ChangeEvent} from "react";
import {useTranslation} from "../../../../tools/i18n";
import {SortsType} from "../../../TravelPlanerResults/SitesExplorator";
import {Picto} from "../../../Picto";

export type JourneysSortsType = "mostPopular" | "bestGrade";

interface TravelPlanerHubFiltersAndSortProps {
  sortBy: JourneysSortsType;
  onChangeSortBy: (sortBy: JourneysSortsType) => void;
  localGuides: boolean;
  onChangeLocalGuides: (localGuides: boolean) => void;
}

export default function TravelPlanerHubFiltersAndSort({
  sortBy,
  onChangeSortBy,
  localGuides,
  onChangeLocalGuides,
}: TravelPlanerHubFiltersAndSortProps) {
  const {t} = useTranslation();

  const handleChangeSortBy = (e: ChangeEvent<HTMLSelectElement>): void => {
    onChangeSortBy(e.target.value as SortsType);
  }

  const handleChangeLocalGuides = (e: ChangeEvent<HTMLInputElement>): void => {
    onChangeLocalGuides(e.target.checked);
  }

  return (
    <div className="FiltersAndSort">
      <div className="filtersSortContainer">
        <div className="sortContainer">
          <span>{t('travelPlaner.Hub.FiltersAndSort.sortBy')}</span>
          <select onChange={handleChangeSortBy} value={sortBy}>
            <option value="mostPopular">{t('travelPlaner.Hub.FiltersAndSort.sorts.mostPopular')}</option>
            <option value="bestGrade">{t('travelPlaner.Hub.FiltersAndSort.sorts.bestGrade')}</option>
          </select>
        </div>
        <div className="localGuides">
          <input
            id="localGuides"
            name={t('travelPlaner.Hub.FiltersAndSort.localGuides')}
            type="checkbox"
            checked={localGuides}
            onChange={handleChangeLocalGuides}
          />
          <label htmlFor="localGuides">
            <Picto iconKey="star" />
            {t('travelPlaner.Hub.FiltersAndSort.localGuides')}
          </label>
        </div>
      </div>
      <div className="line"/>
    </div>
  );
};

