import React from "react";
import { PictoProps } from "./index";

export const AdvantagePromo = (props: PictoProps) => (
  <svg
    width="27"
    height="20"
    viewBox="0 0 27 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4"
      y="6.20996"
      width="20.2837"
      height="12.4406"
      rx="1.62269"
      transform="rotate(-8 4 6.20996)"
      fill="white"
      stroke="#009989"
      strokeWidth="1.8"
    />
    <rect
      x="1"
      y="4.03271"
      width="20.2837"
      height="12.4406"
      rx="1.62269"
      transform="rotate(-8 1 4.03271)"
      fill="white"
      stroke="#009989"
      strokeWidth="1.8"
    />
    <mask
      id="mask0_1239_95222"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="22"
      height="16"
    >
      <rect
        width="20.6149"
        height="12.0252"
        rx="1.62269"
        transform="matrix(0.987321 -0.155696 0.121753 0.99232 1 4.41943)"
        fill="#0A94A8"
      />
    </mask>
    <g mask="url(#mask0_1239_95222)">
      <path
        d="M12.2951 4.69895C12.2797 4.70188 12.2666 4.71183 12.2597 4.72584L10.3915 8.49111L10.3912 8.49178L8.11852 12.9042C8.10976 12.9212 8.11147 12.9417 8.12292 12.957C8.13437 12.9723 8.15355 12.9798 8.17233 12.9762L9.5475 12.7141C9.56287 12.7112 9.57597 12.7012 9.58293 12.6872L11.4283 8.96848L11.4286 8.96782L13.7241 4.50882C13.7328 4.49182 13.7311 4.47132 13.7197 4.456C13.7082 4.44069 13.689 4.43324 13.6703 4.43682L12.2951 4.69895ZM6.31981 7.78902C6.52245 8.85211 7.45297 9.58744 8.573 9.37395C9.70338 9.15848 10.296 8.12208 10.0934 7.05916L10.0895 7.03878C9.88504 5.96607 8.97531 5.22619 7.83438 5.44367C6.69348 5.66115 6.11144 6.69591 6.31593 7.76865L6.31981 7.78902ZM7.65496 7.50285C7.61276 7.28146 7.63361 7.08289 7.70426 6.93361C7.77435 6.78552 7.89358 6.68485 8.05457 6.65417C8.36128 6.5957 8.66552 6.85884 8.75048 7.30458L8.75437 7.32495C8.83736 7.76037 8.65964 8.10497 8.35282 8.16345C8.05551 8.22012 7.74167 7.95773 7.65884 7.52322L7.65496 7.50285ZM11.7531 10.3743C11.9556 11.4368 12.8775 12.1849 14.0082 11.9694C15.1389 11.7538 15.7292 10.707 15.5267 9.64438L15.5228 9.62401C15.3183 8.55129 14.4086 7.81142 13.2676 8.0289C12.1164 8.24835 11.5447 9.28132 11.7492 10.3539L11.7531 10.3743ZM14.1876 9.91018C14.2706 10.3456 14.0929 10.6902 13.7861 10.7487C13.4888 10.8054 13.1749 10.543 13.0921 10.1085L13.0882 10.0881C13.0459 9.86623 13.0645 9.66813 13.1338 9.5193C13.2025 9.37198 13.3215 9.27111 13.4878 9.23939C13.7945 9.18093 14.0988 9.44407 14.1837 9.88981L14.1876 9.91018Z"
        fill="#009989"
        stroke="#009989"
        strokeWidth="0.1"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
