import React from "react";
import {getSanityClient} from "../../tools/sanity";
import {RequirementsProps} from "../../tools/context";
import Page404 from "../404";
import {Picto} from "../Picto";
import {ResponsiveImage} from "../ResponsiveImage";


export interface PortraitProps {
  componentStates: any
  requirements: RequirementsProps
  navigation: any
  headerButtons: any
}

const Renderer = (props: PortraitProps) => {
  if (!props.componentStates || !props.componentStates.length) return <Page404 {...props} />

  const state = props.componentStates[0];

  return <div className="portraitPage">
    <div className="breadcrumb">
      <span>Portraits de pros</span><Picto iconKey={"arrow-right"} /><span>{state.name}</span>
    </div>
    <div className="title">{state.name}</div>
    <div className="description">{state.description}</div>
    <div className="image">
      <ResponsiveImage
        src={state.image}
        alt={"Portrait"}
        requirements={props.requirements}
      />
      {state.quote && <div className="quote"><Picto iconKey={"quote"} />{state.quote}</div>}
    </div>
    {state.fullDescription && state.fullDescription.length && <div className="qna">
      {state.fullDescription.map((q, i) => <div key={`qna_${i}`}>
        <div className="question"><span>Question {i+1} : </span>{q.question}</div>
        <div className="answer">{q.answer}</div>
      </div>)}
    </div>}
    {state.url && <a href={state.url} className="Button pro">Tout savoir sur ce pro et ses avantages</a>}
  </div>
}


const preloader = async (data, requirements: RequirementsProps) => {
  if (!requirements.parameters.length) return null;

  const client = getSanityClient(requirements);
  const query = `*[_type == "portrait" && (_id=="${requirements.parameters[0]}" || slug.current=="${requirements.parameters[0]}")]`;
  return await client.fetch(query);
}

const Portrait = {Renderer, preloader}

export default Portrait;
export {
  Renderer,
  preloader,
}