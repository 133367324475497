import React from "react";
import {PictoProps} from "./index";

export const Printer = (props: PictoProps) => (
  <svg width={props.width || "23"} height={props.height || "23"} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.88867 8.61584V1.61584H17.8887V8.61584" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.88867 17.6158H3.88867C2.7841 17.6158 1.88867 16.7204 1.88867 15.6158V10.6158C1.88867 9.51128 2.7841 8.61584 3.88867 8.61584H19.8887C20.9932 8.61584 21.8887 9.51128 21.8887 10.6158V15.6158C21.8887 16.7204 20.9932 17.6158 19.8887 17.6158H17.8887" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="5.88867" y="13.6158" width="12" height="8" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
