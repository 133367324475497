const partnerThemes = {
  default: {
    primary: '#007bff',
    secondary: '#6c757d',
    background: '#f8f9fa',
    text: '#333333'
  },
  ccvba: {
    primary: '#589172',
    secondary: 'white',
    background: '#D3B382',
    text: 'white'
  },
  france: {
    primary: '#2A3972',
    secondary: 'white',
    background: '#b9c0d5',
    text: 'white'
  },
  martinique: {
    primary: '#27a19e',
    secondary: '#27a19e',
    background: '#eeb97a',
    text: 'white',
    textSecondary: '#27a19e'
  },
};

export default partnerThemes;
