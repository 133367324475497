import React, {useState} from 'react';

import {RequirementsProps} from "../../../tools/context";
import {ResponsiveImage} from "../../ResponsiveImage";
import ImageCarouselPopup, {ImageSlide} from "../../ImageCarouselPopup";
import {Renderer as Meteo} from '../../Meteo';

export interface TitleImage {
  key: string
  phrase: string
  background: any
}

export interface HeroPersoProps {
  title?: string
  titleImage?: TitleImage[]
  contents?: ImageSlide[]
  requirements: RequirementsProps
  siteConfiguration: any
}

export const HeroPerso = (props: HeroPersoProps) => {
  const {
    title,
    titleImage,
    contents,
    requirements,
    siteConfiguration,
  } = props;

  const [showContents, setShowContents] = useState<number>(0);

  const depart = requirements.departement;

  let TI = titleImage && titleImage.find(ti => ti.key === depart);
  if (!TI) {
    if (titleImage && titleImage.length) {
      TI = titleImage.find(ti => !ti.key) || titleImage[0];
    }
  }

  const meteo = Meteo({requirements, lat: requirements.lat, lon: requirements.lon, siteConfiguration});

  return (
    <div className={`heroPerso ${contents && contents.length ? '' : 'noContent'} ${!!meteo ? 'withMeteo' : ''}`}>
      {TI.background &&
      <ResponsiveImage
        className="background"
        src={TI.background} alt="Hero background"
        preload
        requirements={requirements}
      />
      }
      <div className="container">
        <h1 className="title">{requirements.location || title}</h1>
        {TI.phrase && <h2 className="subtitle">{TI.phrase}</h2>}
        {meteo}
      </div>
      {contents &&
      <div className="content">
        {contents.map((c, i) => (
          <button onClick={c.directLink ? () => window.location.href = c.link.link : () => setShowContents(i + 1)} key={c._id + '' + i}>
            <ResponsiveImage
              src={c.smallImage || c.image}
              alt={`heroImage_${i}`}
              width={145}
              height={145}
              mobileWidth={145}
              mobileHeight={145}
              preload
              requirements={requirements}
            />
            <div>{c.title}</div>
          </button>
        ))}
      </div>
      }
      {!!showContents &&
      <ImageCarouselPopup
        list={contents}
        closeCallback={() => setShowContents(0)}
        initialPage={showContents - 1}
        requirements={requirements}
      />
      }
    </div>
  )
}