import React, {useState} from 'react';
import Calendar from 'react-calendar'
import {ChatBotComponentProps} from "./index";
import {useTranslation} from "../../tools/i18n";
import {FilterLayout} from "./FiltersLayout";
import {LooseValue} from "react-calendar/dist/esm/shared/types";
import {Picto} from "../Picto";


export const StartDate = (props: ChatBotComponentProps) => {
  const {config, callback, initialData, back, requirements} = props;
  const [date, setDate] = useState<LooseValue>(initialData);
  const {t, i18n} = useTranslation();
  const subtitleKey = `travelPlaner.StartDate.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  return (
    <div className="startDate">
      <FilterLayout
        config={config}
        callback={() => callback(date)}
        title={t(`travelPlaner.StartDate.title`)}
        back={back}
        requirements={requirements}
        disabledNext={!date}
        siteConfiguration={props.siteConfiguration}
      >
        {hasSubtitle
          ? <div className="layoutSubtitle">{t(subtitleKey)}</div>
          : null
        }
        <Calendar
          value={date}
          onChange={setDate}
          minDate={new Date()}
          defaultView={config.newUI ? 'year' : 'month'}
          prev2Label={config.newUI ? null : "«"}
          next2Label={config.newUI ? null : "»"}
          prevLabel={config.newUI ? <Picto iconKey="caret-left" /> : "‹"}
          nextLabel={config.newUI ? <Picto iconKey="caret-right" /> : "›"}
          locale={i18n.language.split('_')?.[0]}
        />
      </FilterLayout>
    </div>
  )
}