import React, {useState} from 'react';
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import Slider from "rc-slider";
import {useTranslation} from "../../tools/i18n";
import {TravelPlannerInterestsType} from "./types";
import {InterestLevel} from "./NewInterests";

export interface InterestsProps extends ChatBotComponentProps {
  step: TravelPlannerInterestsType;
}

export const Interests = (props: InterestsProps) => {
  const {t} = useTranslation();
  const {
    config,
    step,
    callback,
    initialData,
    back,
    requirements
  } = props;
  const points = step.list;
  const legend: any = t('travelPlaner.Interests.legend');

  const [data, setData] = useState(() => {
    if (initialData) return initialData;

    const output = {};
    points.forEach(p => (output[p] = InterestLevel.neutral));
    return output;
  });

  const handleChange = (value: InterestLevel, point: string) => {
    const d = {...data};
    d[point] = value;
    setData(d);
  }

  return (
    <div className="interests">
      <FilterLayout
        config={config}
        callback={() => callback(data)}
        title={t(`travelPlaner.Interests.title`)}
        back={back}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
      >
        {points.map((p) => (
          <div className="slide" key={encodeURIComponent(p)}>
            <div className="legend">
              <div>{t(`travelPlaner.Interests.keys.${p}`)}</div>
              <div>{legend[data[p] - 1]}</div>
            </div>
            <Slider
              value={data[p]}
              defaultValue={InterestLevel.neutral}
              onChange={(v) => handleChange(v as InterestLevel, p)}
              min={InterestLevel.dislike}
              max={InterestLevel.like}
              step={1}
              handleRender={(p) => (
                <div {...p.props} className="sur-handle" >
                  <div className="handle"/>
                </div>
              )}
            />
          </div>
        ))}
      </FilterLayout>
    </div>
  )
}