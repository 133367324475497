import React from "react";
import {PictoProps} from "./index";

export const Quote = (props: PictoProps) => (
  <svg width={props.width || "39"} height={props.height || "29"} viewBox="0 0 39 29" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0478 28.551H36.3118V13.287H30.5193C30.3627 9.06005 32.9459 6.55519 38.1904 5.92898L36.938 0.214759C27.2316 0.527867 21.0478 4.75482 21.0478 16.105V28.551ZM0.304379 28.551H15.5684V13.287H9.77589C9.54106 9.06005 12.1242 6.55519 17.3687 5.92898L16.1946 0.214759C6.40998 0.527867 0.304379 4.75482 0.304379 16.105V28.551Z"
      fill="#C84D50"/>
  </svg>
)
