import React from "react";
import { PictoProps } from "./index";

export const Flag = (props: PictoProps) => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0261 1.70435C11.0261 1.37638 10.8622 1.07592 10.5969 0.910788C10.3316 0.745653 10.0038 0.745653 9.73632 0.908494C9.27044 1.1906 7.28397 2.03462 6.06965 0.991062C3.56338 -1.15799 0.552398 0.844275 0.425143 0.931429C0.146907 1.1195 0.00886814 1.44977 0.0347505 1.77545C0.0325936 1.80298 0.026123 1.8282 0.026123 1.85573V15.0826C0.026123 15.5895 0.412201 16 0.888868 16C1.36553 16 1.75161 15.5895 1.75161 15.0826V9.89918C2.42887 9.56891 3.84593 9.08497 4.98475 10.062C5.77416 10.7409 6.6973 10.9726 7.57299 10.9726C8.86279 10.9726 10.0577 10.4703 10.5904 10.1446C10.9032 9.95422 11.0606 9.58955 11.0175 9.23176C11.0197 9.20653 11.024 9.1813 11.024 9.15607V1.70435H11.0261ZM6.0675 8.63544C4.56848 7.34876 2.88612 7.55059 1.75161 7.9382V2.2548C2.43103 1.92224 3.84161 1.4383 4.98475 2.41764C6.33279 3.57359 8.06259 3.43368 9.30063 3.05525V8.76387C8.51769 9.09185 7.04455 9.47258 6.0675 8.63314V8.63544Z" fill="white"/>
  </svg>  
);

export default Flag;
