import { Renderer as Meteo } from "../Meteo";
import { ResponsiveImage } from "../ResponsiveImage";
import { Picto } from "../Picto";
import StarNote from "../StarNote";
import * as React from "react";
import { RequirementsProps } from "../../tools/context";
import { useTranslation } from "../../tools/i18n";

export interface HeaderProps {
  seller: any;
  review: any;
  data: any;
  rating: any;
  noCoupon: boolean;
  requirements: RequirementsProps;
  siteConfiguration: any;
  category: string;
  categoryValue: string;
}

const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

const Header = ({
  seller,
  review,
  data,
  rating,
  category,
  categoryValue,
  noCoupon,
  requirements,
  siteConfiguration,
}: HeaderProps) => {
  const { t } = useTranslation();
  const frontCateg = seller?.frontCateg;

  const meteo = Meteo({
    requirements,
    lat: seller.latitude,
    lon: seller.longitude,
    siteConfiguration,
  });

  return (
    <div
      className={`hero ${noCoupon ? "noCoupon" : ""} ${
        meteo ? "withMeteo" : ""
      }`}
    >
      {frontCateg ? (
        <ResponsiveImage
          src={`https://cdn.cibler.io/static/${requirements.partner}/v2/etablissement/${frontCateg}.jpg`}
          fallback={`https://cdn.cibler.io/static/tca/v2/etablissement/${frontCateg}.jpg`}
          alt={`Image ${frontCateg}`}
          className="background"
          notLazy
          requirements={requirements}
        />
      ) : (
        <div className="background" />
      )}
      <div className="shop_header_info">
        {frontCateg ? (
          <div className={`flex ${frontCateg} catBackground category`}>
            <Picto iconKey={frontCateg} />
            {categoryValue}
          </div>
        ) : null}
        <div className="name">{seller?.name}</div>
        {data.google_grade && data.google_review ? (
          <div className="flex">
            <StarNote note={data.google_grade} show_note={false} />
            <span>
              {parseInt(data.google_review).toLocaleString()}{" "}
              {t("shop.header.review", { count: parseInt(data.google_review) })}
            </span>
          </div>
        ) : (
          !!review.length && (
            <div className="flex">
              <StarNote note={rating} show_note={false} />
              <span>
                {review?.length.toLocaleString()}{" "}
                {t("shop.header.review", { count: review?.length })}
              </span>
            </div>
          )
        )}
        <div className="flex location">
          <Picto iconKey={"location"} />
          {capitalize(data?.city) || data?.zipcode || data?.address}
        </div>
      </div>
      {meteo}
    </div>
  );
};

export default Header;
