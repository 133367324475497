import React from "react";
import { makeAnAlgoliaSearch } from "../../tools/searchAPI";
import Carousel from "./Carousel";
import OfferSlide, { getDiscountTags } from "./OfferSlide";
import { RequirementsProps } from "../../tools/context";

export interface ResultCarouselProps {
  label: string;
  componentStates: any;
  requirements: RequirementsProps;
}

const Renderer = (props: ResultCarouselProps) => {
  const { label, componentStates, requirements } = props;
  // console.log(componentStates)

  const hits = componentStates?.data?.hits || [];

  if (!hits.length) return null;

  const roundGrade = (input) => {
    if (isNaN(input)) return 0;

    return Math.round(input * 10) / 10;
  };

  const couponQuery = props.requirements?.coupon
    ? `?coupon=${props.requirements.coupon}`
    : "";
  if (couponQuery) {
    return <></>;
  }
  return (
    <div className="searchCarousel">
      <Carousel label={label} slides={hits}>
        {hits.map((r) => (
          <OfferSlide
            key={r.id}
            category={r.categslug}
            title={r.store_name}
            image={r.urlimg}
            fallbackImage={`https://tourismebyca.twic.pics/static/${requirements.partner}/v2/categSearch/${r.categslug}.jpg`}
            fallbackImage2={`https://tourismebyca.twic.pics/static/tca/v2/categSearch/${r.categslug}.png`}
            place={r.city || r.deptname}
            url={`/etablissement/${r.seller_id}${couponQuery}`}
            note={roundGrade(r.shop_grade)}
            discount={couponQuery ? [] : getDiscountTags(r.gifts)}
            requirements={props.requirements}
          />
        ))}
      </Carousel>
    </div>
  );
};

const preloader = async (data, requirements) => {
  if (requirements?.coupon) return;

  return await makeAnAlgoliaSearch(data, requirements);
};

const SearchCarousel = { Renderer, preloader };

export default SearchCarousel;
export { Renderer, preloader };
