import React from "react";

interface TravelPlanerHubHeroProps {
  title: string;
  subtitle: string;
}

export default function TravelPlanerHubHero({
  title,
  subtitle
}: TravelPlanerHubHeroProps) {
  return (
    <div className="Hero">
      {title
        ? <h2>{title}</h2>
        : null
      }
      {subtitle
        ? <h3>{subtitle}</h3>
        : null
      }
    </div>
  );
};

