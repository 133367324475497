import { Picto } from "../../Picto";
import React, { useMemo } from "react";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PopupItemContainer from "./PopupItemContainer";
import { Day, Item } from "./index";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useTranslation } from "../../../tools/i18n";

interface Props {
  overlay?: boolean;
  day: Day;
  dayIndex: number;
  items: Item[];
  isHovered?: boolean;
  handleDeleteDay(id: UniqueIdentifier): void;
  handleDeleteItem(id: UniqueIdentifier): void;
}

export default function PopupDayContainer(props: Props) {
  const { t } = useTranslation();
  const {
    overlay,
    day,
    dayIndex,
    items,
    isHovered,
    handleDeleteDay,
    handleDeleteItem,
  } = props;

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: day.id,
    data: {
      type: "day",
      day,
    },
  });

  const itemsId = useMemo(() => items.map((item) => item.id), [items]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={`PopupDay ${isDragging ? "dragging" : ""} ${
        overlay ? "overlay" : ""
      } ${isHovered ? "hovered" : ""}`}
      ref={setNodeRef}
      nonce="__nonce__"
      style={style}
    >
      <div className="popupDayHeader">
        <button
          className="deleteButton"
          onClick={() => handleDeleteDay(day.id)}
        >
          <div className="deleteButtonContent">
            <Picto iconKey="minus" width="6" height="6" />
          </div>
        </button>
        <span>
          {t("travelPlaner.Travel.day")} {dayIndex + 1}
        </span>
        <div {...attributes} {...listeners} className="handle">
          <Picto iconKey="burger-fr" width="14" height="14" />
        </div>
      </div>
      <SortableContext items={itemsId}>
        <div className="popupDayContent">
          {items.map((item) => (
            <PopupItemContainer
              key={item.id}
              item={item}
              handleDeleteItem={handleDeleteItem}
            />
          ))}
        </div>
      </SortableContext>
    </div>
  );
}
