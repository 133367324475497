import React from "react";

import { Picto } from "../../Picto";
import { RequirementsProps } from "../../../tools/context";
import { ResponsiveImage } from "../../ResponsiveImage";
import { LinkProps, ButtonLinkProps } from "../../index";

export interface HeroLandingProps {
  title: string;
  subtitle: string;
  background: object;
  image?: object;
  text?: string;
  link?: ButtonLinkProps;
  links?: ButtonLinkProps[];
  bottomLinks?: LinkProps[];
  requirements: RequirementsProps;
}

export const HeroLanding = (props: HeroLandingProps) => {
  const {
    title,
    subtitle,
    background,
    image,
    text,
    link,
    links,
    bottomLinks,
    requirements,
  } = props;

  const oneLink =
    link && link.link
      ? link
      : links && links.length === 1 && links[0].link
      ? links[0]
      : null;
  const redirectUrl = `https://${props.requirements?.config?.canonicalUrl}/`;

  return (
    <>
      <div className={"HeroLanding"}>
        {background && (
          <ResponsiveImage
            className="background"
            src={background}
            alt="Hero backgrounddd"
            preload
            requirements={requirements}
          />
        )}
        <div className="container">
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
          <div className={`content ${image ? "" : "noImage"}`}>
            {image && (
              <ResponsiveImage
                src={image}
                alt="Image"
                preload
                requirements={requirements}
                redirectUrl={redirectUrl ? redirectUrl : ""}
              />
            )}
            <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          {oneLink && (
            <a
              href={oneLink.link}
              className={`Button ${oneLink.style || "white"}`}
            >
              {oneLink.text}
            </a>
          )}
          {links && links.length > 1 && (
            <div className="linkList">
              {links.map((l) => (
                <a
                  key={l._key}
                  href={l.link}
                  className={`Button ${l.style || "white"}`}
                >
                  {l.text}
                </a>
              ))}
            </div>
          )}
        </div>
        {bottomLinks && !!bottomLinks.length && (
          <div className="bottom-links">
            {bottomLinks.map((link, index) => (
              <a key={index} href={link.link} className="link courant2">
                <>
                  {link.picto && <Picto iconKey={link.picto} />}
                  {link.text}
                </>
              </a>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
