export default {
  key: "armor",
  canonicalUrl: "avantagearmor.fr",
  datalayerId: "GTM-N7FRS7X",
  customerId: 686,
  icon: "armor.ico",
  headerLogo: "armor.png",
  footerLogo: "armor_monochrome.png",
  axeptioClientId: "",
  axeptioCookiesVersion: "armor.fr",
  clientUrl: "https://client.avantagearmor.fr",
  proUrl: "https://pro.avantagearmor.fr",
  sanity: {
    base: {
      projectId: "795ccg4i",
      dataset: "production",
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        "skmXCEeYETg9oXTZCKViOpyrc3zpOb8XiyWJOS0OdQNn0iVAIdB5Tw3uhYLYAZnsPWa95DG5pAZHdmhg2saNehYyRN5txV264qILpfq9rryBRQKD4OI37CZGT5y508Ay9yxFWpb1D3gdWOPMlQg4TIQ9kG4c3jlSi2do9DrsIt0ZoVTGVBDS",
    },
  },
  dev: {
    clientUrl: "https://client.avantagearmor.fr",
    proUrl: "https://pro.avantagearmor.fr",
  },
};
