import React from "react";
import {PictoProps} from "./index";

export const TravelFamilyV2 = (props: PictoProps) => (
  <svg width={props.width || "40"} height={props.height || "41"} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon family">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 15.8333C16.8012 15.8333 18.6667 13.9679 18.6667 11.6667C18.6667 9.36548 16.8012 7.5 14.5 7.5C12.1988 7.5 10.3333 9.36548 10.3333 11.6667C10.3333 13.9679 12.1988 15.8333 14.5 15.8333ZM25.6333 15.8333C27.9345 15.8333 29.8 13.9679 29.8 11.6667C29.8 9.36548 27.9345 7.5 25.6333 7.5C23.3321 7.5 21.4666 9.36548 21.4666 11.6667C21.4666 13.9679 23.3321 15.8333 25.6333 15.8333ZM23.9667 17.5H27.3C30.525 17.5 33.1333 20.1083 33.1333 23.3333V31.6667C33.1333 31.8917 33.05 32.1 32.8917 32.2583C32.7333 32.4167 32.525 32.5 32.3 32.5H25.3167V30.1333C25.3167 27.875 23.4917 26.05 21.2333 26.05H18.9C16.6417 26.05 14.8167 27.875 14.8167 30.1333V32.5H7.83333C7.60833 32.5 7.4 32.4167 7.24167 32.2583C7.08333 32.1 7 31.8917 7 31.6667V23.3333C7 20.1083 9.60833 17.5 12.8333 17.5H16.1667C17.6667 17.5 19.0333 18.0667 20.0667 19C21.1 18.075 22.4667 17.5 23.9667 17.5ZM17.15 21.9667C17.15 23.575 18.4583 24.8833 20.0667 24.8833C21.675 24.8833 22.9833 23.575 22.9833 21.9667C22.9833 20.3583 21.675 19.05 20.0667 19.05C18.4583 19.05 17.15 20.3583 17.15 21.9667Z"
        fill="#000000"
      />
    </g>
  </svg>
)
