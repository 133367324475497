import React, { useContext, useState } from "react";
import PictoButton from "./PictoButton";
import ShareModal from "./Modal/ShareModal";
import FeedbackModal from "./Modal/FeedbackModal";
import { Picto } from "../Picto";
import PrintPDFButton from "./PDF/PrintButton";
import { useTranslation } from "../../tools/i18n";
import { RequirementsProps } from "../../tools/context";
import { TravelPlanerResultsContext } from "./TravelPlanerResultsContext";

interface FooterProps {
  trip: any;
  componentStates: any;
  setShowMobileMap: (arg0: boolean) => void;
  requirements: RequirementsProps;
}

const Footer: React.FC<FooterProps> = ({
  trip,
  componentStates,
  setShowMobileMap,
  requirements,
}) => {
  const { t } = useTranslation();
  const { config } = useContext(TravelPlanerResultsContext);
  const [showShare, setShowShare] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const handleAddBookmark = async () => {
    // const { platform } = navigator;
    const platform = navigator.userAgent;
    if (
      platform.includes("MacIntel") ||
      platform.includes("Win32") ||
      platform.includes("Linux")
    ) {
      alert(t("travelPlaner.Travel.addBookmark"));
      return;
    }
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(window.location.href);
      alert(
        `${t("travelPlaner.Travel.copiedLink")} // ${t(
          "travelPlaner.Travel.addBookmarkMobile"
        )}`
      );
      return;
    }
    alert(t("travelPlaner.Travel.addBookmarkMobile"));
  };

  const tryToShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Share my trip",
          url: window.location.href,
        });
      } catch (err) {
        console.error("Share failed:", err.message);
      }
      return;
    }
    setShowShare(true);
  };

  return (
    <div className="daysFooter">
      <div className="daysFooterButtons">
        <PictoButton
            iconKey="fav"
            onClick={handleAddBookmark}
            hideFavButton={config.hideFavButton || false}
        />
        <PictoButton
          iconKey="like-circle-filled"
          onClick={() => setShowFeedback(true)}
        />
        <PictoButton iconKey="share" onClick={tryToShare} />
        <PrintPDFButton
          trip={trip}
          componentStates={componentStates}
          requirements={requirements}
          config={config}
        />
      </div>
      <ShareModal open={showShare} onClose={() => setShowShare(false)} />
      <FeedbackModal
        requirements={requirements}
        open={showFeedback}
        onClose={() => setShowFeedback(false)}
      />
      <div className="showMap" onClick={() => setShowMobileMap(true)}>
        <Picto iconKey="guide-icon" />
        <span>{t("travelPlaner.Travel.showMap")}</span>
      </div>
    </div>
  );
};

export default Footer;
