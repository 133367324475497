import React from "react";
import {PictoProps} from "./index";

export const MaisonJardin = (props: PictoProps) => (
  <svg width="1200" zoomAndPan="magnify"
       viewBox="0 0 900 899.99999" height="1200" preserveAspectRatio="xMidYMid meet" version="1.0">
    <defs>
      <clipPath id="08fc9efbda">
        <path d="M 172 405 L 714 405 L 714 795.75 L 172 795.75 Z M 172 405 " clipRule="nonzero"/>
      </clipPath>
      <clipPath id="58f585a9ab">
        <path d="M 76.394531 94 L 809.894531 94 L 809.894531 419 L 76.394531 419 Z M 76.394531 94 "
              clipRule="nonzero"/>
      </clipPath>
    </defs>
    <g clipPath="url(#08fc9efbda)">
      <path fill="#231f20"
            d="M 680.359375 744.675781 C 680.359375 754.070312 672.730469 761.554688 663.488281 761.554688 L 544.953125 761.554688 C 535.566406 761.554688 528.082031 753.921875 528.082031 744.675781 L 528.082031 568.863281 C 528.082031 540.835938 505.34375 518.085938 477.324219 518.085938 L 409.550781 518.085938 C 381.53125 518.085938 358.792969 540.835938 358.792969 568.863281 L 358.792969 744.675781 C 358.792969 754.070312 351.164062 761.554688 341.921875 761.554688 L 222.949219 761.554688 C 213.558594 761.554688 206.078125 753.921875 206.078125 744.675781 L 206.078125 405.820312 L 172.191406 405.820312 L 172.191406 744.675781 C 172.191406 772.707031 194.929688 795.453125 222.949219 795.453125 L 341.480469 795.453125 C 369.5 795.453125 392.238281 772.707031 392.238281 744.675781 L 392.238281 568.863281 C 392.238281 559.472656 399.867188 551.988281 409.109375 551.988281 L 476.886719 551.988281 C 486.273438 551.988281 493.757812 559.617188 493.757812 568.863281 L 493.757812 744.675781 C 493.757812 772.707031 516.496094 795.453125 544.515625 795.453125 L 663.046875 795.453125 C 691.066406 795.453125 713.804688 772.707031 713.804688 744.675781 L 713.804688 405.820312 L 679.917969 405.820312 Z M 680.359375 744.675781 "
            fillOpacity="1" fillRule="nonzero"/>
    </g>
    <g clipPath="url(#58f585a9ab)">
      <path fill="#231f20"
            d="M 476.152344 106.730469 C 456.933594 90.296875 429.207031 90.296875 409.992188 106.730469 L 76.394531 392.90625 L 98.398438 418.585938 L 432.140625 132.414062 C 438.597656 126.984375 447.839844 126.984375 454.148438 132.414062 L 787.890625 418.585938 L 809.894531 392.90625 Z M 476.152344 106.730469 "
            fillOpacity="1" fillRule="nonzero"/>
    </g>
  </svg>
)
