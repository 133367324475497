import React from 'react';
import FlexContent, { FlexContentProps } from '../FlexContent';
import { RequirementsProps } from "../../tools/context";

interface FlexContainerProps {
  title?: {
    text: string;
    color?: {
      hex: string;
    };
    fontSize?: string;
    textAlign?: 'left' | 'center' | 'right';
    alignSelf?: 'auto' | 'flex-start' | 'center' | 'flex-end' | 'stretch';
    margin?: string;
    padding?: string;
  };
  isFullWidth?: boolean;
  backgroundColor?: {
    hex: string;
  };
  flexContents: FlexContentProps[];
  direction?: 'row' | 'column';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  requirements: RequirementsProps;
}

const FlexContainer: React.FC<FlexContainerProps> = ({
  title,
  isFullWidth = false,
  backgroundColor,
  flexContents,
  direction = 'row',
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  requirements,
}) => {
  const containerStyle: React.CSSProperties = {
    backgroundColor: backgroundColor?.hex || 'transparent',
    display: 'flex',
    flexDirection: direction,
    alignItems,
    justifyContent,
  };

  const titleStyle: React.CSSProperties = title ? {
    color: title.color?.hex,
    fontSize: title.fontSize,
    textAlign: title.textAlign,
    alignSelf: title.alignSelf,
    margin: title.margin,
    padding: title.padding,
  } : {};

  return (
    <div className={`FlexContainer ${isFullWidth ? 'fullWidth' : 'normalWidth'}`} style={containerStyle}>
      {title && <h2 style={titleStyle}>{title.text}</h2>}
      <div className="FlexContainer__content">
        {flexContents.map((content, index) => (
          <FlexContent key={index} {...content} requirements={requirements} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(FlexContainer);
