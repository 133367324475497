import * as React from "react";
import {Picto} from "../Picto";
import {RequirementsProps} from "../../tools/context";

export interface SharingLinksProps {
  requirements: RequirementsProps
}

const SharingLinks = (props: SharingLinksProps) => {
  const FBLink = (url) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
  const twitterLink = (url) => `https://twitter.com/intent/tweet?text=${encodeURIComponent(url)}`;
  const linkedInLink = (url) => `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;

  const platform = [
    {
      key: 'facebook',
      link: FBLink,
    },
    {
      key: 'twitter',
      link: twitterLink
    }
  ]

  return <>
    {platform.map(p => (
      <a className="sharingLink" key={p.key} target="_blank" href={p.link(props.requirements.href)}>
        <Picto iconKey={p.key}/>
      </a>
    ))}
  </>
}


export default SharingLinks;