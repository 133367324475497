import React from "react";
import {PictoProps} from "../index";

export const ArmorLoisirs = (props: PictoProps) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill={props.fill} stroke="none">
<path d="M1212 4844 c-22 -15 -22 -16 -22 -324 l0 -308 -62 -45 c-148 -105
-258 -263 -310 -444 -21 -70 -23 -100 -23 -288 0 -175 3 -222 19 -280 24 -88
64 -178 110 -248 l36 -54 0 -172 0 -171 -218 0 c-162 0 -223 -3 -236 -13 -14
-10 -36 -123 -112 -562 -52 -302 -94 -574 -94 -605 1 -144 72 -323 166 -418
33 -32 80 -65 121 -85 66 -31 73 -32 218 -35 l151 -4 12 -76 c32 -199 175
-364 367 -425 l70 -22 1155 0 c1093 0 1158 1 1215 18 97 30 156 66 227 137 83
83 132 179 150 292 l12 77 151 3 151 3 76 38 c94 46 153 108 208 221 44 88 70
190 70 276 0 43 -161 1028 -185 1127 -12 51 -20 53 -256 53 l-219 0 0 171 0
172 36 54 c47 70 87 161 110 248 27 101 27 459 0 555 -61 220 -201 400 -398
513 l-48 28 0 280 0 280 -25 24 -24 25 -243 0 c-204 0 -247 -3 -266 -16 -22
-15 -22 -18 -22 -265 l0 -249 -89 0 -88 0 -6 64 c-11 119 -67 205 -165 253
l-57 28 -315 0 -315 0 -57 -28 c-98 -48 -154 -134 -165 -253 l-6 -64 -123 0
-124 0 0 240 0 241 -25 24 -24 25 -243 0 c-204 0 -247 -3 -266 -16z m438 -308
l0 -203 -86 -7 c-75 -6 -196 -31 -246 -51 -17 -7 -18 8 -18 229 l0 236 175 0
175 0 0 -204z m2090 -20 l0 -223 -57 13 c-32 8 -111 17 -175 21 l-118 6 0 204
0 203 175 0 175 0 0 -224z m-895 43 c86 -24 135 -88 135 -179 l0 -50 -420 0
-420 0 0 50 c0 88 49 155 130 178 49 14 525 15 575 1z m835 -365 c186 -43 364
-182 453 -353 70 -133 82 -192 82 -411 0 -219 -11 -276 -83 -415 -54 -106
-191 -238 -302 -292 -142 -69 -172 -73 -547 -73 l-330 0 -7 71 c-11 121 -52
190 -138 233 -51 25 -57 26 -245 26 -151 0 -202 -3 -230 -15 -98 -41 -149
-118 -160 -239 l-6 -76 -330 0 c-375 0 -405 4 -547 73 -69 33 -102 59 -176
132 -79 78 -98 103 -137 185 -65 136 -72 176 -72 390 0 213 8 255 70 385 70
145 177 253 320 325 145 72 82 68 1260 69 918 1 1069 -1 1125 -15z m-921
-1342 c58 -31 71 -69 71 -210 0 -138 -10 -170 -63 -202 -29 -18 -51 -20 -207
-20 -156 0 -178 2 -207 20 -54 32 -63 63 -63 206 0 109 3 131 20 159 35 57 65
65 250 65 141 0 170 -3 199 -18z m-1629 -160 c70 -53 186 -109 278 -134 69
-19 111 -22 425 -26 314 -4 347 -6 347 -20 1 -26 38 -104 63 -130 12 -13 45
-36 72 -50 46 -24 62 -26 210 -30 192 -5 257 6 318 52 44 33 96 116 97 153 l0
21 348 4 c313 4 354 7 423 26 99 27 189 69 269 126 l65 45 3 -982 c1 -672 -1
-998 -9 -1034 -25 -126 -121 -247 -236 -301 l-58 -27 -1185 0 -1185 0 -67 34
c-118 59 -203 171 -228 301 -10 53 -15 2010 -5 2010 2 0 27 -17 55 -38z m-170
-1042 l0 -750 -125 0 c-137 0 -194 14 -262 66 -48 36 -109 134 -134 213 -35
111 -29 180 55 671 42 245 81 469 87 498 l10 52 185 0 184 0 0 -750z m3579
698 c6 -29 45 -253 87 -498 84 -491 90 -559 55 -671 -25 -79 -86 -177 -134
-213 -68 -52 -125 -66 -262 -66 l-125 0 0 750 0 750 184 0 185 0 10 -52z"/>
<path d="M1586 2064 c-14 -14 -16 -71 -16 -483 0 -398 2 -475 16 -522 33 -114
119 -207 229 -248 54 -21 72 -21 745 -21 673 0 691 0 745 21 110 41 196 134
229 248 14 47 16 124 16 522 0 412 -2 469 -16 483 -14 14 -116 16 -974 16
-858 0 -960 -2 -974 -16z m1852 -526 l-3 -433 -28 -57 c-33 -66 -82 -111 -146
-133 -67 -23 -1335 -23 -1402 0 -62 21 -109 63 -144 126 l-30 54 -3 438 -3
437 881 0 881 0 -3 -432z"/>
</g>
</svg>

)
