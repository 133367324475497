import * as React from "react";
import { RequirementsProps } from "../../../tools/context";
import { OverLayParameter } from "../../../App";
import Tracked from "./Tracked";
import Untracked from "./Untracked";

export interface CouponsPromoProps {
  seller: any;
  coupons: any;
  device?: "mobile" | "desktop";
  requirements: RequirementsProps;
  setOverlay: (p: OverLayParameter) => void;
  enablePromoTracking: boolean;
  advantagesRedirectToWallet: boolean;
}

const CouponsPromo = ({
  seller,
  coupons,
  device,
  requirements,
  setOverlay,
  enablePromoTracking,
  advantagesRedirectToWallet,
}: CouponsPromoProps) => {
  if (!coupons || !coupons.length) return null;

  return enablePromoTracking ? (
    <Tracked
      coupons={coupons}
      requirements={requirements}
      setOverlay={setOverlay}
      device={device}
    />
  ) : (
    <Untracked
      requirements={requirements}
      seller={seller}
      coupons={coupons}
      device={device}
      advantagesRedirectToWallet={advantagesRedirectToWallet}
    />
  );
};

export default CouponsPromo;
