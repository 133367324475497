import React from "react";
import PortraitSlide from "./PortraitSlide";
import Carousel from "./Carousel";
import {imageUrlFor} from "../../tools/sanity";
import {RequirementsProps} from "../../tools/context";

export interface PortraitCarouselProps {
  label: string
  subLabel: string
  background?: any
  backgroundMobile?: any
  slides: any[]
  reference?: boolean
  requirements: RequirementsProps
}

const PortraitCarousel = (props: PortraitCarouselProps) => (
  <div className={`portraitCarousel ${props.background ? 'withBackground' : ''}`}>
    {props.background &&
    <img src={imageUrlFor(props.background, props.requirements).url()} className="background lazyloadbackground desktop"
         alt={`${props.label} ${props.subLabel}`}/>}
    {(props.backgroundMobile || props.background) &&
    <img src={imageUrlFor(props.backgroundMobile || props.background, props.requirements).url()}
         className="lazyload background mobile" alt={`${props.label} ${props.subLabel}`}/>}
    <Carousel {...props}>
      {props.slides?.map && props.slides.map(
        s => <PortraitSlide {...s} key={s._key || s._id} reference={props.reference} requirements={props.requirements} />
      )}
    </Carousel>
  </div>
)

export default PortraitCarousel;