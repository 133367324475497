import React from "react";
import {PictoProps} from "./index";

export const Download = (props: PictoProps) => (
  <svg width={props.width || "21"} height={props.height || "21"} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8887 13.6158V17.6158C19.8887 18.7204 18.9932 19.6158 17.8887 19.6158H3.88867C2.7841 19.6158 1.88867 18.7204 1.88867 17.6158V13.6158" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.88867 8.61584L10.8887 13.6158L15.8887 8.61584" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.8887 13.6158V1.61584" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
