import React from 'react';

export interface ProgressionBarProps {
  length: number
}

export const ProgressionBar = (props: ProgressionBarProps) => {
  const {length} = props;

  return (
    <div className="head">
      <div className="progressionBar">
        {Array.from(Array(length).keys()).map((s, i) =>
          <div key={`bar_${encodeURIComponent(s)}`}/>
        )}
      </div>
    </div>
  )
}