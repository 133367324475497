import React from "react";
import PortraitCarousel, {PortraitCarouselProps} from "./PortraitCarousel";
import {getSanityClient} from "../../tools/sanity";

const Renderer = (props: PortraitCarouselProps) => (
  <PortraitCarousel {...props} reference />
)

const cmsLoader = async (data, requirement) => {
  const query = `*[${data.slides.map(s => `_id=="${s._ref}"`).join(' || ')}]`;
  const client = getSanityClient(requirement);
  data.slides = await client.fetch(query);

  return data;
}

const PortraitCarouselRef = {Renderer, cmsLoader};

export default PortraitCarouselRef;
export {
  Renderer,
  cmsLoader,
}