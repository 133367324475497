import StaticCarousel from './StaticCarousel';
import PortraitCarousel from './PortraitCarousel';
import SearchCarousel from './SearchCarousel';
import PortraitCarouselRef from './PortraitCarouselRef';
import MediaCarousel from "./MediaCarousel";

const exp = {
  Static: StaticCarousel,
  Portrait: PortraitCarousel,
  Search: SearchCarousel,
  PortraitRef: PortraitCarouselRef,
  Media: MediaCarousel,
}

export default exp;