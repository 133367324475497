import React, { useEffect, useRef, useState } from "react";
import { DROM } from "../../../../../tools/Constants";
import { Loader } from "@googlemaps/js-api-loader";
import { Picto, pictos } from "../../../../Picto";
import { useTranslation } from "../../../../../tools/i18n";
import { RequirementsProps } from "../../../../../tools/context";

export type CityTypeAutoComplete = {
  lon: number;
  lat: number;
  city: string;
};

interface AutoCompleteCityProps {
  requirements: RequirementsProps;
  onSelect: (city: CityTypeAutoComplete) => void;
}

type AutocompleteService = google.maps.places.AutocompleteService;

export default function AutoCompleteCity({
  requirements,
  onSelect,
}: AutoCompleteCityProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const voidRef = useRef<HTMLDivElement>();
  const autocomplete = useRef<AutocompleteService>();
  const sessionToken = useRef(null);
  const [predictions, setPredictions] = useState<any[]>([]);

  const placeIcons = {
    france: "france-place",
  };
  const placeIcon = placeIcons[requirements?.config?.key] || "place";

  const autoCompleteOption = {
    componentRestrictions: { country: ["fr", ...DROM] },
    fields: ["address_components", "geometry", "name"],
    types: ["locality", "postal_code", "colloquial_area"],
    sessionToken: sessionToken.current,
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.google) {
      return;
    }

    const loader = new Loader({
      apiKey: "AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8",
      version: "weekly",
      libraries: ["places"],
    });

    loader.load();
    inputRef.current.focus();
  }, []);

  const getPredictions = async (input: string) => {
    const r = await autocomplete.current.getPlacePredictions({
      input,
      ...autoCompleteOption,
    });

    let results: any[] = r.predictions || [];
    return { results, input };
  };

  const handleInput = (value: string) => {
    onSelect(null);

    if (!!!value) {
      setPredictions([]);
      return;
    }

    if (!autocomplete.current) {
      if (typeof window !== "undefined" && window.google) {
        autocomplete.current = new google.maps.places.AutocompleteService();
        sessionToken.current =
          new google.maps.places.AutocompleteSessionToken();
      } else {
        return;
      }
    }

    getPredictions(value).then((r) => {
      if (r.input !== inputRef.current.value) return;

      setPredictions(r.results);
    });
  };

  const renderTag = (p: any) => {
    let type: {
      key: string;
      label: string;
      icon: keyof typeof pictos;
    };

    switch (p.types ? p.types[0] : "other") {
      case "locality":
      case "postal_code":
        type = { key: "city", label: "Ville", icon: "city" };
        break;
      case "department":
      case "administrative_area_level_2":
        type = { key: "region", label: "Région", icon: "region" };
        break;
      default:
        type = { key: "other", label: "Autre", icon: placeIcon };
    }

    return (
      <div className={`tag ${type.key}`}>
        <Picto iconKey={type.icon} />
        {type.label}
      </div>
    );
  };

  const handleSelect = async (p: any) => {
    new google.maps.places.PlacesService(voidRef.current).getDetails(
      {
        placeId: p.place_id,
        fields: autoCompleteOption.fields,
        sessionToken: autoCompleteOption.sessionToken,
      },
      (a) => {
        const data: CityTypeAutoComplete = {
          lon: a.geometry.location.lng(),
          lat: a.geometry.location.lat(),
          city: a.name,
        };
        onSelect(data);
        inputRef.current.value = p.label || p.description;
        setPredictions([]);
      }
    );
  };

  return (
    <div className="AutoCompleteCity">
      <div className="inputBlock">
        <Picto iconKey={placeIcon} />
        <input
          ref={(ref) => (inputRef.current = ref)}
          onChange={(e) => handleInput(e?.target?.value)}
          placeholder={t(
            "travelPlaner.Hub.ComplexSearch.AutoCompleteCity.placeholder"
          )}
        />
      </div>
      <div className={`predictions ${predictions.length ? "active" : ""}`}>
        {predictions.map((p, i) => (
          <button key={`prediction_${i}`} onClick={() => handleSelect(p)}>
            {p.label || p.description}
            {renderTag(p)}
          </button>
        ))}
      </div>
      <div
        nonce="__nonce__"
        style={{ display: "none" }}
        ref={(ref) => (voidRef.current = ref)}
      />
    </div>
  );
}
