import React, { useCallback } from "react";
import { RequirementsProps } from "../../tools/context";
import { useWindowSize } from "../../tools/hooks";
import { useTranslation } from "../../tools/i18n";

export interface HTMLVisualContentProps {
  requirements: RequirementsProps;
  mobile: string;
  desktop: string;
}

const Renderer = (props: HTMLVisualContentProps) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  const { t } = useTranslation();

  const translateContent = useCallback(
    (content: string) => {
      const regex = /{{t.(.*?)}}/g;

      return content.replace(regex, (_, p1: string) => {
        return t(p1);
      });
    },
    [t]
  );

  const translatedMobile = translateContent(props.mobile);
  const translatedDesktop = translateContent(props.desktop);

  return (
    <div
      className="HTMLVisualContent"
      dangerouslySetInnerHTML={{
        __html: isMobile ? translatedMobile : translatedDesktop,
      }}
    />
  );
};

const HTMLVisualContent = { Renderer };

export default HTMLVisualContent;
export { Renderer };
