import React, { useState } from "react";
import { Configuration } from "../../../tools/Constants";
import { replaceUrlParam } from "../../../tools/tools";
import { RequirementsProps } from "../../../tools/context";
import { Picto, pictos } from "../../Picto";
import { Button } from "../../Button";

export interface FiltersConfigurationType {
  hideAdvantages?: boolean;
}

export interface CategoryPopupData {
  categ: string[];
  labels: string[];
  filters: string[];
}

export interface CategoryPopupProps {
  requirements: RequirementsProps;
  filtersConfiguration: FiltersConfigurationType;
  data: CategoryPopupData;
  closeCallback: () => void | undefined;
  callback: (p: CategoryPopupData) => void | undefined;
}

const CategoryPopup = (props: CategoryPopupProps) => {
  const categories = Configuration.categories;
  const labels = Configuration.labels;
  const [catChecked, setCatChecked] = useState<string[]>(
    props.data.categ || []
  );
  const [labChecked, setLabChecked] = useState<string[]>(
    props.data.labels || []
  );
  const [otherChecked, setOtherChecked] = useState<string[]>(
    props.data.filters || []
  );
  const otherFilters = props.filtersConfiguration?.hideAdvantages
    ? null
    : { bonus: { name: "Avec avantage" } };

  const handleValidation = () => {
    let url = window.location.href;
    url = replaceUrlParam("page", null, url);
    url = replaceUrlParam("categ", catChecked.join("|"), url);
    url = replaceUrlParam("labels", labChecked.join("|"), url);
    url = replaceUrlParam("filters", otherChecked.join("|"), url);

    window.history.pushState({}, "", url);

    props.callback({
      categ: catChecked,
      labels: labChecked,
      filters: otherChecked,
    });
    props.closeCallback();
  };

  const handleCheck = (key: string, checker, setChecker) => {
    const temp = [...checker];
    const idx = temp.findIndex((k) => k === key);

    if (idx === -1) {
      temp.push(key);
    } else {
      temp.splice(idx, 1);
    }

    setChecker(temp);
  };

  const renderblock = (list, title, checker, setChecker, handler) =>
    list &&
    !!Object.keys(list).length && (
      <div>
        <div className="title">{title}</div>
        {Object.keys(list)
          .filter((ca) => !list[ca].disabled)
          .map((c) => (
            <button
              key={c}
              className={`line ${c}`}
              onClick={() => handler(c, checker, setChecker)}
            >
              {pictos[c] && (
                <div className="icon">
                  <Picto iconKey={!list[c].pictoKey ? c : list[c].pictoKey} />
                </div>
              )}
              <span>{list[c].name}</span>
              <div
                className={`checkbox ${checker.includes(c) ? "checked" : ""}`}
              >
                {checker.includes(c) ? <Picto iconKey={"check"} /> : null}
              </div>
            </button>
          ))}
      </div>
    );

  return (
    <div className="categoryPopup">
      <button className="close" onClick={props.closeCallback}>
        <Picto iconKey={"cross"} />
      </button>
      <div className="list">
        {renderblock(
          categories,
          "Filtrer les catégories",
          catChecked,
          setCatChecked,
          handleCheck
        )}
        {renderblock(
          labels,
          "Filtrer les partenaires",
          labChecked,
          setLabChecked,
          handleCheck
        )}
        {renderblock(
          otherFilters,
          "Autres filtres",
          otherChecked,
          setOtherChecked,
          handleCheck
        )}
      </div>
      <Button theme={"transparent"} onClick={handleValidation}>
        Afficher les résultats
      </Button>
    </div>
  );
};

export default CategoryPopup;
