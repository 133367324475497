import React from "react";
import {getSanityClient} from "../../tools/sanity";
import {RequirementsProps} from "../../tools/context";
import Page404 from "../404";
import {Renderer as EditoPage} from '../EditoPage';


export interface DetoursProps {
  componentStates: any
  requirements: RequirementsProps
  navigation: any
  headerButtons: any
}

const Renderer = (props: DetoursProps) => {
  if (!props.componentStates || !props.componentStates.length) return <Page404 {...props} />

  const state = props.componentStates[0];

  return <EditoPage
    requirements={props.requirements}
    {...state}
  />
}


const preloader = async (data, requirements: RequirementsProps) => {
  if (!requirements.parameters.length) return null;

  const client = getSanityClient(requirements);
  const query = `*[_type == "detours" && (_id=="${requirements.parameters[0]}" || slug.current=="${requirements.parameters[0]}")]`;
  return await client.fetch(query);
}

const Detours = {Renderer, preloader}

export default Detours;
export {
  Renderer,
  preloader,
}