import React from "react";
import {RequirementsProps} from "../../../../tools/context";
import {JourneyType, TravelPlannerResultsSanityProps} from "../../index";
import {Oval} from "react-loader-spinner";
import TravelPlanerHubJourney from "../Journey";
import {useTranslation} from "../../../../tools/i18n";

interface TravelPlanerHubJourneysListProps {
  travelPlannerResults: TravelPlannerResultsSanityProps;
  requirements: RequirementsProps;
  loading: boolean;
  journeys: JourneyType[];
}

export default function TravelPlanerHubJourneysList({
  travelPlannerResults,
  requirements,
  loading,
  journeys
}: TravelPlanerHubJourneysListProps) {
  const {t} = useTranslation();
  const loaderColors = {
    france: "#8C7215",
  }
  const loaderColor = loaderColors[requirements?.config?.key] || "#000000";

  return (
    <div className={`JourneysList ${loading ? "loading" : ""} ${!journeys?.length ? "noResults" : ""}`}>
      {loading
        ? <Oval color={loaderColor} height={50} width={50} />
        : journeys?.length
          ? journeys.map((journey) => (
            <TravelPlanerHubJourney
              key={journey.objectID}
              travelPlannerResults={travelPlannerResults}
              requirements={requirements}
              journey={journey}
            />
          ))
          : <div className="noResults">
              {t('travelPlaner.Hub.JourneysList.noResults')}
            </div>
      }
    </div>
  );
};

