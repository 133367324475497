import fetch from "node-fetch";
import { RequirementsProps } from "./context";

const call = (url, options) => {
  return fetch(url, options);
};

const getStoreInfo = async (sellerId, requirements: RequirementsProps) => {
  let customerId = requirements.config.customerId; //TODO Config customerid platform
  const lendpoint = requirements.endpoint;
  const data = await call(
    `${lendpoint}/api/sellers/${sellerId}/storepage?customer=${customerId}&shortcode=${requirements.shortcode}`,
    {
      method: "GET",
    }
  )
    .then((r) => r.json())
    .catch((e) => console.error(e));

  return data;
};

const getAllVendors = async (requirements: RequirementsProps) => {
  const customerId = requirements.config.customerId;
  // const customerId = 161;
  const lendpoint = requirements.endpoint;
  const campaignQuery = requirements.campaign
    ? "&campaignId=" + requirements.campaign
    : "";
  const data = await call(
    `${lendpoint}/api/sellers/search/?customerId=${customerId}&status=accepted${campaignQuery}`,
    {
      method: "GET",
    }
  )
    .then((r) => r.json())
    .catch((e) => console.error(e));
  return data;
};

const subToNewsletter = async (email, requirements: RequirementsProps) => {
  const data = await call(
    `${requirements.endpoint}/api/users/event/subNewsletter?customerId=${requirements.config.customerId}&email=${email}`,
    {
      method: "GET",
    }
  )
    .then((r) => r.json())
    .catch((e) => console.error(e));

  return data;
};

const trackCiblerEvent = async (eventname, d, customerId, cibId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/json");

  await call(
    `https://prod.winitout.com/tr/event/${eventname}?customer=${customerId}&cibId=${cibId}`,
    {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(d),
    }
  );
};

const sendReview = async (body, requirements: RequirementsProps) => {
  const lendpoint = requirements.endpoint;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/json");

  await call(
    `${lendpoint}/api/seller/offers/review?customerId=${requirements.config.customerId}`,
    {
      method: "POST",
      headers: myHeaders,
      body,
    }
  );
};

const getReview = async (
  requirements: RequirementsProps,
  shortcode: string
) => {
  const lendpoint = requirements.endpoint;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/json");

  return await call(
    `${lendpoint}/api/sellers/${shortcode}/review?customerId=${requirements.config.customerId}`,
    {
      method: "GET",
      headers: myHeaders,
    }
  )
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

const getItinerary = async (
id: number | string, requirements: RequirementsProps, language: string) => {
  let customerId = requirements.config.customerId; //TODO Config customerid platform
  const endpoint = requirements.endpoint;
  const response = await fetch(
    `${endpoint}/api/poi/journey/${id}/details?customer=${customerId}`,
    {
      method: "GET",
    }
  );

  return await response.json();
};

const putItinerary = async (id, trip, requirements: RequirementsProps) => {
  let customerId = requirements.config.customerId; //TODO Config customerid platform
  const lendpoint = requirements.endpoint;
  const data = await call(
    `${lendpoint}/api/poi/journey/${id}?customer=${customerId}`,
    {
      method: "PUT",
      body: JSON.stringify(trip),
    }
  )
    .then((r) => r.json())
    .catch((e) => console.error(e));

  return data;
};

type GetNearbyFn = (
  id: string | number,
  category: string,
  requirements: RequirementsProps,
  lat: number,
  lon: number
) => Promise<any>;

const getNearby: GetNearbyFn = async (
  id,
  category,
  requirements: RequirementsProps,
  lat,
  lon
) => {
  // TODO: Config customerId platform (let customerId = requirements.config.customerId;)
  let params = `?category=${category}`;
  if (lat) params += `&lat=${lat}`;
  if (lon) params += `&lon=${lon}`;

  return await call(
    `${requirements.endpoint}/api/poi/journey/${id}/nearby${params}`,
    {
      method: "GET",
    }
  )
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export {
  getStoreInfo,
  getAllVendors,
  sendReview,
  getReview,
  trackCiblerEvent,
  subToNewsletter,
  getItinerary,
  putItinerary,
  getNearby,
};
