import React, {FC, useState} from 'react';
import {Picto} from "../Picto";
import {getOrCreateCiblerId} from "../../tools/tools";
import {useTracking} from "../../tools/analytics";
import {RequirementsProps} from "../../tools/context";

export type ReviewType = '' | 'down' | 'up';

export interface ReviewElementsProps {
  label: string;
  review: ReviewType;
  onChange: (newValue: ReviewType) => void;
  thanksText?: string;
}

export const ReviewElements: FC<ReviewElementsProps> = ({ label, thanksText, review, onChange }) => (
  <div className="review">
    <div className="label">{label}</div>
    <div className="thumbs">
      <button className={`up ${review === 'up' ? 'active' : ''}`} onClick={() => onChange('up')}>
        <Picto iconKey="like-circle-filled" />
      </button>
      <button className={`down ${review === 'down' ? 'active' : ''}`} onClick={() => onChange('down')}>
        <Picto iconKey="dislike-circle-filled" />
      </button>
    </div>
    {thanksText ? <div className={`thanks ${review ? 'visible' : ''}`}>{thanksText}</div> : undefined}
  </div>
);

export interface ReviewProps {
  requirements: RequirementsProps;
  label: string;
  thanksText: string;
  setShowFeedback: (show: boolean) => void;
}

export const Review = (props: ReviewProps) => {
  const {
    requirements,
    label,
    thanksText,
    setShowFeedback
  } = props;
  const [review, setReview] = useState<ReviewType>('');
  const {trackEvent} = useTracking();

  const handleReview = async (value: ReviewType) => {
    if (review) return;

    trackEvent(`review${value}_click`);
    setReview(value);
    const {endpoint, config, parameters} = requirements;
    const id = parameters[0];
    const headers = new Headers();
    await fetch(
      `${endpoint}/api/poi/journey/${id}/feedback?positive=${
        value === 'up'}&customer=${config.customerId}&ciblerId=${getOrCreateCiblerId()}&info`,
      {
        headers,
        method: 'POST',
      }
    );
    if (value === 'down')
      setShowFeedback(true);
  }

  return <ReviewElements label={label} thanksText={thanksText} review={review} onChange={handleReview} />;
}