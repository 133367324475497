import React, { useState } from 'react';
import {useTracking} from "../../tools/analytics";
import {useTranslation} from "../../tools/i18n";
import {Button} from "../Button";
import {Picto} from "../Picto";
import ShareModal from './Modal/ShareModal';


const Share = () => {
  const {trackEvent} = useTracking();
  const {t} = useTranslation();
  const [showShare, setShowShare] = useState<boolean>(false);

  return (
    <div className="Share">
      <Button
        theme={"pro"}
        className={showShare ? 'open' : ''}
        onClick={() => {
          trackEvent('openShare_click');
          setShowShare(p => !p);
        }}
      >
        <Picto iconKey="share" />
        {t('travelPlaner.Travel.share')}
      </Button>
      <ShareModal open={showShare} onClose={() => setShowShare(false)} />
    </div>
  );
};

export default Share;
