import React, { Fragment } from "react";
import { RequirementsProps } from "../../tools/context";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { imageUrlFor } from "../../tools/sanity";
import { Renderer as HTMLVisualContent } from "../HTMLVisualContent";
import { useWindowSize } from "../../tools/hooks";

interface ImageContentFields {
  title: string;
  image: SanityImageSource;
  content: string;
  imagePosition: "left" | "right" | "top" | "bottom";
  imageWidth?: number;
  imageHeight?: number;
}

export interface ImageContentProps {
  requirements: RequirementsProps;
  title: string;
  desktop: ImageContentFields;
  mobile: ImageContentFields;
}

const Renderer = ({
  requirements,
  title,
  desktop,
  mobile,
}: ImageContentProps) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const config = isMobile ? mobile : desktop;
  const imagePos = config.imagePosition;

  return (
    <div
      className="ImageContent"
      style={{
        flexDirection:
          imagePos === "left"
            ? "row"
            : imagePos === "right"
            ? "row-reverse"
            : imagePos === "top"
            ? "column"
            : "column-reverse",
      }}
    >
      {config.image ? (
        <img
          className="lazyload"
          style={{
            width: config.imageWidth ?? "",
            height: config.imageHeight ?? "",
          }}
          src={imageUrlFor(config.image, requirements).url()}
          alt={title}
        />
      ) : null}
      <HTMLVisualContent
        requirements={requirements}
        mobile={mobile.content}
        desktop={desktop.content}
      />
    </div>
  );
};

const preloader = async (data: any, requirements: RequirementsProps) => {
  return data;
};

const ImageContent = { Renderer, preloader };

export default ImageContent;
export { Renderer, preloader };
