import React from "react";
import {PictoProps} from "./index";

export const Target = (props: PictoProps) => (
  <svg width={props.width || "17"} height={props.height || "17"} viewBox="0 0 17 17" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.49967 5.66671C6.93426 5.66671 5.66634 6.93462 5.66634 8.50004C5.66634 10.0655 6.93426 11.3334 8.49967 11.3334C10.0651 11.3334 11.333 10.0655 11.333 8.50004C11.333 6.93462 10.0651 5.66671 8.49967 5.66671ZM14.8322 7.79171C14.6717 6.35529 14.0276 5.01613 13.0056 3.99412C11.9836 2.9721 10.6444 2.32799 9.20801 2.16754V0.708374H7.79134V2.16754C6.35493 2.32799 5.01576 2.9721 3.99375 3.99412C2.97173 5.01613 2.32762 6.35529 2.16717 7.79171H0.708008V9.20837H2.16717C2.32762 10.6448 2.97173 11.984 3.99375 13.006C5.01576 14.028 6.35493 14.6721 7.79134 14.8325V16.2917H9.20801V14.8325C10.6444 14.6721 11.9836 14.028 13.0056 13.006C14.0276 11.984 14.6717 10.6448 14.8322 9.20837H16.2913V7.79171H14.8322V7.79171ZM8.49967 13.4584C5.75842 13.4584 3.54134 11.2413 3.54134 8.50004C3.54134 5.75879 5.75842 3.54171 8.49967 3.54171C11.2409 3.54171 13.458 5.75879 13.458 8.50004C13.458 11.2413 11.2409 13.4584 8.49967 13.4584Z"
      fill="black"/>
  </svg>
)
