import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "../../../tools/i18n";
import { useTracking } from "../../../tools/analytics";
import { Picto } from "../../Picto";
import { ReviewElements, ReviewType } from "../Review";
import {getOrCreateCiblerId} from "../../../tools/tools";
import {RequirementsProps} from "../../../tools/context";

interface FeedbackModalProps {
  requirements: RequirementsProps;
  open?: boolean;
  onClose?: () => void;
}

const FeedbackModal: FC<FeedbackModalProps> = ({ requirements, open, onClose }) => {
  const [review, setReview] = useState<ReviewType>('');
  const [details, setDetails] = useState("");
  const [hasVoted, setHasVoted] = useState(false);
  const {trackEvent} = useTracking();
  const {t} = useTranslation();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const sendFeedback = async () => {
    if ((!!!details && review !== 'up') || details?.length > 255) return;

    trackEvent(`review${review}_click`);
    const {endpoint, parameters} = requirements;
    const id = parameters[0];
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const body = JSON.stringify({
      ciblerId: getOrCreateCiblerId(),
      feedback: details,
      score: review ?
        review === 'up'
          ? 1
          : -1
        : 0,
    });
    await fetch(
      `${endpoint}/api/poi/journey/${id}/feedback`,
      {
        headers,
        method: 'POST',
        body,
      }
    );
    setHasVoted(true);
    setDetails("");
  }

  return open
    ? <div className="feedbackPopup">
        <div className="feedbackPopupContainer">
          <div className="feedbackPopupHeader">
            <div className="container">
              <span>{t("travelPlaner.Travel.feedback.title")}</span>
            </div>
            <button onClick={onClose}>
              <Picto iconKey="cross" />
            </button>
          </div>
          {hasVoted
            ? <div className="label">
              {
                review === 'up'
                  ? t("travelPlaner.Travel.feedback.answer.up")
                  : t("travelPlaner.Travel.feedback.answer.down")
              }
              </div>
            : <ReviewElements
                label={t("travelPlaner.Travel.feedback.question")}
                review={review}
                onChange={setReview}
              />
          }
          {!hasVoted && review === 'down'
            ? <textarea
                className="feedbackDetails"
                placeholder={t("travelPlaner.Travel.feedback.placeholder")}
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                rows={6}
                maxLength={255}
              />
            : null
          }
          {hasVoted
            ? null
            : <div className="methods">
                <button onClick={sendFeedback}>
                  {t("travelPlaner.Travel.feedback.button")}
                </button>
              </div>
          }
        </div>
      </div>
    : null
}

export default FeedbackModal;
