const WeatherCodeMap = new Map([
  [-9999, 2],
  [-8, 2],
  [-7, 8],
  [-6, 21],
  [-5, 18],
  [-4, 5],
  [-3, 8],
  [-2, 6],
  [-1, 6],
  [0, 1],
  [1, 1],
  [2, 2],
  [3, 2],
  [4, 2],
  [5, 3],
  [6, 3],
  [7, 3],
  [8, 4],
  [9, 4],
  [10, 5],
  [11, 5],
  [12, 5],
  [13, 17],
  [14, 11],
  [15, 11],
  [16, 11],
  [17, 17],
  [18, 22],
  [19, 0],
  [20, 7],
  [21, 6],
  [22, 8],
  [23, 9],
  [24, 18],
  [25, 11],
  [26, 14],
  [27, 15],
  [28, 0],
  [29, 12],
  [30, 0],
  [31, 22],
  [32, 0],
  [33, 0],
  [34, 0],
  [35, 0],
  [36, 8],
  [37, 8],
  [38, 8],
  [39, 8],
  [40, 5],
  [41, 5],
  [42, 5],
  [43, 5],
  [44, 5],
  [45, 5],
  [46, 5],
  [47, 5],
  [48, 5],
  [49, 5],
  [50, 7],
  [51, 7],
  [52, 10],
  [53, 10],
  [54, 10],
  [55, 10],
  [56, 18],
  [57, 18],
  [58, 10],
  [59, 10],
  [60, 6],
  [61, 6],
  [62, 6],
  [63, 6],
  [64, 6],
  [65, 6],
  [66, 18],
  [67, 18],
  [68, 9],
  [69, 9],
  [70, 8],
  [71, 8],
  [72, 8],
  [73, 8],
  [74, 8],
  [75, 8],
  [76, 8],
  [77, 8],
  [78, 8],
  [79, 8],
  [80, 11],
  [81, 11],
  [82, 6],
  [83, 15],
  [84, 15],
  [85, 14],
  [86, 14],
  [87, 19],
  [88, 19],
  [89, 19],
  [90, 19],
  [91, 16],
  [92, 16],
  [93, 13],
  [94, 13],
  [95, 12],
  [96, 20],
  [97, 12],
  [98, 12],
  [99, 20],
]);

export enum WeatherCode {
  ClearSkies = 1,
  PartlyCloudy = 2,
  MainlyCloudy = 3,
  Overcast = 4,
  Mist = 5,
  Rain = 6,
  LightDrizzle = 7,
  Snow = 8,
  RainAndSnow = 9,
  DrizzleAndRain = 10,
  RainShower = 11,
  HeavyThunderstorms = 12,
  SpringShower = 13,
  SnowShower = 14,
  RainAndSnowShower = 15,
  Thunderstorms = 16,
  HeatThunderstorms = 17,
  FreezingRain = 18,
  HailShower = 19,
  ThunderstormsWithHailShower = 20,
  Storm = 21,
  StrongWind = 22,
}

export const WeatherResources = {
  "-8":"Ciel dégagé avec trainée nuageuse",
  "-7":"Tempête de neige",
  "-6":"Tempête",
  "-5":"Pluie verglaçante",
  "-4":"Brouillard épais",
  "-3":"Abondantes chutes de neige",
  "-2":"Forte pluies",
  "-1":"Forte pluies",
  "0":"Ciel clair",
  "1":"Ciel dégagé",
  "2":"Ciel relativement clair",
  "3":"Ciel relativement clair",
  "4":"Ciel partiellement couvert",
  "5":"Ciel nuageux",
  "6":"Ciel assez nuageux",
  "7":"Ciel très nuageux",
  "8":"Ciel couvert",
  "9":"Ciel couvert",
  "10":"Brume",
  "11":"Brouillard au sol",
  "12":"Brouillard au sol",
  "13":"Foudre, sans tonnerre",
  "14":"Pluies éparses",
  "15":"Pluies éparses",
  "16":"Quelques averses de pluie",
  "17":"Probable orage isolé",
  "18":"Fortes rafales de vent",
  "20":"Bruine possible",
  "21":"Pluie possible",
  "22":"Neige possible",
  "23":"Légère pluie et neige",
  "24":"Légère pluie verglaçante",
  "25":"Légères averses de pluie",
  "26":"Légères averses de neige",
  "27":"Légères averses de pluie et neige",
  "29":"Orage probable",
  "31":"Fort vent, visibilité réduite",
  "36":"Légères chutes de neige",
  "37":"Fortes chutes de neige",
  "38":"Neige poudreuse",
  "39":"Poudreuse abondante",
  "40":"Nappes de brouillard",
  "41":"Brouillard épars",
  "42":"Brouillard",
  "43":"Brouillard",
  "44":"Brouillard",
  "45":"Brouillard épais et généralisé",
  "46":"Brouillard épais, visibilité réduite",
  "47":"Brouillard très épais, visibilité réduite",
  "48":"Brouillard avec dépôt de givre",
  "49":"Brouillard épais avec dépôt de givre",
  "50":"Légère bruine par intermittence",
  "51":"Légère bruine, en continu",
  "52":"Bruine modérée par intermittence",
  "53":"Bruine modérée, en continu",
  "54":"Bruine épaisse par intermittence",
  "55":"Bruine épaisse, en continu",
  "56":"Légère bruine verglaçante",
  "57":"Bruine verglaçante",
  "58":"Légère pluie et bruine",
  "59":"Pluie et bruine",
  "60":"Légère pluie",
  "61":"Légère pluie, en continu",
  "62":"Pluie modérée",
  "63":"Pluie modérée, en continu",
  "64":"Pluie forte par intermittence",
  "65":"Pluie forte et continue",
  "66":"Légère pluie verglaçante",
  "67":"Pluie verglaçante",
  "68":"Légère pluie et neige",
  "69":"Forte pluie et neige",
  "70":"Légères chutes de neige",
  "71":"Légères chutes de neige",
  "72":"Chutes de neige",
  "73":"Chutes de neige, en continu",
  "74":"Fortes chutes de neige",
  "75":"Fortes chutes de neige, en continu",
  "76":"Poudreuse",
  "77":"Neige en grains",
  "78":"Quelques flocons de neige",
  "79":"Grésil",
  "80":"Averses de pluie",
  "81":"Fortes averses de pluie",
  "82":"Violentes pluies",
  "83":"Averses de pluie et neige",
  "84":"Fortes averses de pluie et neige",
  "85":"Averses de neige",
  "86":"Fortes averses de neige",
  "87":"Averses de neige roulée",
  "88":"Fortes averses de neige roulée",
  "89":"Averses de grêle",
  "90":"Fortes averses de grêle",
  "91":"Probables orages avec pluie",
  "92":"Probables orage avec fortes pluies",
  "93":"Giboulées",
  "94":"Fortes giboulées",
  "95":"Probables orages",
  "96":"Probables orages avec grêle",
  "97":"Probables violents orages",
  "98":"Probables violents orages",
  "99":"Probables forts orages avec grêle",
  "-9999":"Belles éclaircies à l'horizon"
}

export const mapApiWeatherCodeToWeatherCode = (wc?: number) => {
  if (wc === undefined || wc === null) {
    return WeatherCode.ClearSkies;
  }

  const weatherIconCode: WeatherCode | undefined = WeatherCodeMap.get(wc);
  return weatherIconCode ? weatherIconCode : WeatherCode.ClearSkies;
}

export const getImageSrc = (weatherCode: WeatherCode) => {
  switch (weatherCode) {
    case WeatherCode.ClearSkies:
      return `clear-skies.svg`;
    case WeatherCode.PartlyCloudy:
      return `partly-cloudy.svg`;
    case WeatherCode.MainlyCloudy:
      return `mainly-cloudy.svg`;
    case WeatherCode.Overcast:
      return `overcast.svg`;
    case WeatherCode.Mist:
      return `mist.svg`;
    case WeatherCode.Rain:
      return `rain.svg`;
    case WeatherCode.LightDrizzle:
      return `light-drizzle.svg`;
    case WeatherCode.Snow:
      return `snow.svg`;
    case WeatherCode.RainAndSnow:
      return `rain-and-snow.svg`;
    case WeatherCode.DrizzleAndRain:
      return `drizzle-and-rain.svg`;
    case WeatherCode.RainShower:
      return `rain-shower.svg`;
    case WeatherCode.HeavyThunderstorms:
      return `heavy-thunderstorms.svg`;
    case WeatherCode.SpringShower:
      return `spring-shower.svg`;
    case WeatherCode.SnowShower:
      return `snow-shower.svg`;
    case WeatherCode.RainAndSnowShower:
      return `rain-and-snow-shower.svg`;
    case WeatherCode.Thunderstorms:
      return `thunderstorms.svg`;
    case WeatherCode.HeatThunderstorms:
      return `heat-thunderstorms.svg`;
    case WeatherCode.FreezingRain:
      return `freezing-rain.svg`;
    case WeatherCode.HailShower:
      return `hail-shower.svg`;
    case WeatherCode.ThunderstormsWithHailShower:
      return `thunderstorms-with-hail-shower.svg`;
    case WeatherCode.Storm:
      return `storm.svg`;
    case WeatherCode.StrongWind:
      return `strong-wind.svg`;
    default: {
      throw new Error(`Weather code ${weatherCode} unknow`);
    }
  }
}