import React, { createContext, useContext, ReactNode } from 'react';

interface ThemeContextType {
  partner: string;
  colors: {
    primary: string;
    secondary: string;
    background: string;
    text: string;
    textSecondary?: string;
  };
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider: React.FC<{ children: ReactNode, value: ThemeContextType }> = ({ children, value }) => (
  <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
);
