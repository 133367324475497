import React from "react";
import { PictoProps } from ".";

export const TravelExpensiveMartinique = (props: PictoProps) => (
  <svg
    width="80"
    height="82"
    viewBox="0 0 80 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.0694 11.4348C38.7929 11.4348 44.2433 9.09888 44.2433 6.21739C44.2433 3.33591 38.7929 1 32.0694 1C25.346 1 19.8955 3.33591 19.8955 6.21739C19.8955 9.09888 25.346 11.4348 32.0694 11.4348Z"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.2441 6.21741V12.0261"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.7657 63.6087C47.4892 63.6087 52.9396 61.2728 52.9396 58.3913C52.9396 55.5098 47.4892 53.1739 40.7657 53.1739C34.0422 53.1739 28.5918 55.5098 28.5918 58.3913C28.5918 61.2728 34.0422 63.6087 40.7657 63.6087Z"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M52.9396 58.3913V67.0869C52.9396 69.9739 47.4961 72.3043 40.7657 72.3043C34.0353 72.3043 28.5918 69.9739 28.5918 67.0869V58.3913"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.5919 39.2609V47.9565C68.5919 50.8435 63.1484 53.1739 56.418 53.1739C56.1397 53.1739 55.8788 53.1739 55.6006 53.1739"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.5913 47.9565V56.6522C68.5913 59.3652 63.7392 61.6087 57.5479 61.8522"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.5913 56.6522V65.3478C68.5913 68.0609 63.7392 70.3043 57.5479 70.5478"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.2437 56.6348C23.9654 56.6348 23.6698 56.6348 23.3741 56.6348C16.6437 56.6348 11.2002 54.3044 11.2002 51.4174V42.7217"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3564 20.0087C23.9477 19.487 19.8955 17.4 19.8955 14.9131V6.21741"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.1651 28.6695C23.8433 28.113 19.8955 26.0609 19.8955 23.6087V14.913"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39.4608 34.9652C36.0521 34.0434 33.8086 32.4087 33.8086 30.5652"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.8089 65.3479C23.6698 65.3479 23.5132 65.3479 23.3741 65.3479C16.6437 65.3479 11.2002 63.0174 11.2002 60.1305V51.4348"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.8089 74.0435C23.6698 74.0435 23.5132 74.0435 23.3741 74.0435C16.6437 74.0435 11.2002 71.713 11.2002 68.8261V60.1304"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.5479 42.7391V48.7739"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.5918 67.087V75.7826C28.5918 78.6696 34.0353 81 40.7657 81C47.4961 81 52.9396 78.6696 52.9396 75.7826V67.087"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.8955 23.6087V33.1044"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M56.4181 44.4782C63.1415 44.4782 68.592 42.1423 68.592 39.2608C68.592 36.3794 63.1415 34.0435 56.4181 34.0435C49.6946 34.0435 44.2441 36.3794 44.2441 39.2608C44.2441 42.1423 49.6946 44.4782 56.4181 44.4782Z"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.2441 48.7739V39.2609"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.2002 42.7391C11.2002 45.6261 16.6437 47.9565 23.3741 47.9565C30.1045 47.9565 35.548 45.6261 35.548 42.7391"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.548 42.7391C35.548 39.8522 30.1045 37.5217 23.3741 37.5217C16.6437 37.5217 11.2002 39.8522 11.2002 42.7391"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.5479 48.2348V42.7391"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.8086 21.8696C33.8086 18.9826 39.2521 16.6522 45.9825 16.6522C52.7129 16.6522 58.1564 18.9826 58.1564 21.8696"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.1564 28.6696V21.8696C58.1564 24.7565 52.7129 27.087 45.9825 27.087C39.2521 27.087 33.8086 24.7565 33.8086 21.8696V34.8609"
      stroke="#27A19E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
