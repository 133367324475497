import React from "react";
import {PictoProps} from "./index";

export const CircleI = (props: PictoProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99942 5.33317C7.90742 5.33317 7.83275 5.40784 7.83342 5.49984C7.83342 5.59184 7.90808 5.6665 8.00008 5.6665C8.09208 5.6665 8.16675 5.59184 8.16675 5.49984C8.16675 5.40784 8.09208 5.33317 7.99942 5.33317" stroke="#071621" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z" stroke="#071621" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.00008 8V11.3333" stroke="#071621" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
