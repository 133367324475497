import * as React from "react";
import { imageUrlFor } from "../../tools/sanity";
import { RequirementsProps } from "../../tools/context";

export interface TilesBlockSlideProps {
  _key: string;
  name: string;
  image?: any;
  staticImage?: string;
  link?: string;
  callback?: () => undefined | void;
}

export interface TilesBlockProps {
  label: string;
  subLabel: string;
  list: TilesBlockSlideProps[];
  requirements: RequirementsProps;
}

const TilesBlock = (props: TilesBlockProps) => {
  const { label, subLabel, list, requirements } = props;

  if (!list || !list.map) return null;

  return (
    <div className="tilesBlock">
      <div className="header">
        {label}
        <span>{subLabel}</span>
      </div>
      <div className="scrollBlock">
        {list.map((c) => (
          <a
            className="tile"
            key={c._key}
            href={c.link}
            nonce="__nonce__"
            style={{
              backgroundImage: `url('${
                c.staticImage || typeof c.image === "object"
                  ? imageUrlFor(c.image, requirements)
                      .width(294)
                      .height(294)
                      .url()
                  : ""
              }')`,
            }}
            onClick={() => c.callback && c.callback()}
          >
            <div className="blockText">
              <div>{c.name}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default TilesBlock;
