import React, { useState } from "react";
import { Button } from "../../Button";
import { Picto } from "../../Picto";
import { RequirementsProps } from "../../../tools/context";
import CategoryPopup, {
  CategoryPopupData,
  FiltersConfigurationType,
} from "./categoryPopup";

export interface SearchBarProps {
  requirements?: RequirementsProps;
  heroSearch?: boolean;
  mapOpened?: boolean;
  mapGridCallback?: (p: boolean) => undefined | void;
  handleInput: (p: string) => void;
  categData: CategoryPopupData;
  filtersConfiguration: FiltersConfigurationType;
  categoryCallback: (p: CategoryPopupData) => void | undefined;
}

export const SearchBar = (props: SearchBarProps) => {
  const { requirements, heroSearch, mapGridCallback } = props;
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(props.mapOpened || false);

  const handleMapGridCallback = () => {
    mapGridCallback(!showMap);

    setShowMap((prev) => !prev);
  };

  const handleGO = () => {};

  return (
    <div className={`lightSearchBar`}>
      <div className="inputBlock">
        <input
          onChange={(e) => props.handleInput(e.target.value)}
          placeholder={"Entrez le nom de l’établissement recherché"}
        />
        <Picto iconKey={"target"} />
      </div>
      <Button className="validate" theme={"yellow"} onClick={handleGO}>
        {heroSearch ? "Valider" : "Go"}
      </Button>
      <div className="filters">
        {!heroSearch && (
          <Button
            className="categoryButton"
            theme={"transparent"}
            onClick={() => setShowCategory(true)}
          >
            Filtrer les catégories <Picto iconKey={"cursors"} />
          </Button>
        )}
        {mapGridCallback && (
          <Button
            className="mapButton"
            theme={"transparent"}
            onClick={handleMapGridCallback}
          >
            {showMap ? (
              <>
                Liste
                <Picto iconKey={"list"} />
              </>
            ) : (
              <>
                Carte
                <Picto iconKey={"place"} />
              </>
            )}
          </Button>
        )}
      </div>
      {showCategory && (
        <CategoryPopup
          requirements={requirements}
          closeCallback={() => setShowCategory(false)}
          callback={props.categoryCallback}
          filtersConfiguration={props.filtersConfiguration}
          data={props.categData}
        />
      )}
    </div>
  );
};
