import React from "react";

export interface EditoHTMLProps {
  content?: string;
  background?: string;
}

const EditoHTML = (props: EditoHTMLProps) => {
  const { content, background } = props;

  if (!content) return null;

  return (
    <div
      className="editoHTML"
      dangerouslySetInnerHTML={{ __html: content }}
      nonce="__nonce__"
      style={background ? { background } : null}
    />
  );
};

export default EditoHTML;
