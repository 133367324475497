import React from "react";
import {PictoProps} from "./index";

export const Dots = (props: PictoProps) => (
  <svg width={props.width || "19"} height={props.height || "4"} viewBox="0 0 19 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.44434" cy="2" r="1" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="16.4443" cy="2" r="1" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="2.44434" cy="2" r="1" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
