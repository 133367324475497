import React from "react";
import OfferSlide from "./OfferSlide";
import Carousel from "./Carousel";
import {RequirementsProps} from "../../tools/context";

interface ButtonProps {
  backgroundColor: {
    hex: string;
  };
  hoverTextColor: {
    hex: string;
  };
  borderRadius: string;
  hoverBackgroundColor: {
    hex: string;
  };
  link: string;
  position: string;
  text: string;
  textColor: {
    hex: string;
  };
}

export interface StaticCarouselProps {
  label: string;
  subLabel: string;
  className?: string;
  slides: any[];
  requirements: RequirementsProps;
  button?: ButtonProps;
}

const StaticCarousel = (props: StaticCarouselProps) => {
  return (
    <Carousel {...props}>
      {props.slides?.map((s, index) => (
        <OfferSlide
          {...props}
          {...s}
          key={s._key || `staticCarousel_${index}`} // Ensure a unique key
        />
      ))}
    </Carousel>
  );
};

export default StaticCarousel;
