import React, { useEffect, useState } from "react";
import { imageUrlFor } from "../../tools/sanity";
import { RequirementsProps } from "../../tools/context";

export interface ResponsiveImageProps {
  className?: string;
  callback?: (e: any) => void | any;
  children?: React.ReactNode | string | number;
  src: object | string;
  fallback?: string;
  fallback2?: string;
  alt: string;
  width?: number;
  height?: number;
  mobileWidth?: number;
  mobileHeight?: number;
  notLazy?: boolean;
  preload?: boolean;
  requirements: RequirementsProps;
  retry?: number;
  onError?: (e: any) => void;
  redirectUrl?: string;
}

export const ResponsiveImage = (props: ResponsiveImageProps) => {
  let {
    className,
    callback,
    src,
    fallback,
    fallback2,
    alt,
    width,
    height,
    mobileWidth,
    mobileHeight,
    notLazy,
    preload,
    requirements,
    onError,
  } = props;

  const [retry, setRetry] = useState<number>(props.retry);
  const [imageSrc, setImageSrc] = useState<object | string>(
    src || fallback || fallback2
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const key = Date.now()

  useEffect(() => {
    setImageSrc(src || fallback || fallback2);
  }, [src, fallback, fallback2]);

  const handleImageError = () => {
    if (onError) return onError(imageSrc);
    setShowLoader(true);
    if (retry > 0) {
      setTimeout(() => {
        // console.log("retry " + retry);
        setRetry((p) => p - 1);
      }, 3000);
    } else if (fallback && imageSrc !== fallback) {
      // console.log("first fallback", fallback);
      setImageSrc(fallback);
    } else if (fallback2 && imageSrc !== fallback2) {
      console.log("second fallback", fallback2);
      setImageSrc(fallback2);
    }
  };

  const getImageUrl = (imageSrc: string) => {
    let url = new URL(imageSrc);
    return url.pathname;
  };

  const defaultWidth = 1440,
    defaultMobileWidth = 750;
  width = width || defaultWidth;
  height = height || null;
  mobileHeight = mobileHeight || null;
  mobileWidth = mobileWidth || defaultMobileWidth;
  const imageClassName = className || "";
  const redirectUrl = props.redirectUrl;

  const getSanityImage = (src, height, width) =>
    height
      ? imageUrlFor(src, requirements)
          .width(width)
          .height(height)
          .fit("crop")
          .url()
      : imageUrlFor(src, requirements).width(width).fit("crop").url();

  const handleClick = () => {
    if (callback) {
      callback({});
    } else if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  if (imageSrc && typeof imageSrc !== "string") {
    const cursorStyle = redirectUrl ? { cursor: 'pointer' } : {};
    return (
        <>
          <img
              alt={alt}
              src={getSanityImage(imageSrc, height, width)}
              className={`DesktopImage ${imageClassName}`}
              onClick={handleClick}
              onError={handleImageError}
              onLoad={() => setShowLoader(false)}
              loading={notLazy || preload ? undefined : "lazy"}
              nonce="__nonce__"
              style={{ ...showLoader ? { visibility: "hidden", height: 0, width: 0 } : {}, ...cursorStyle }}
          />
          <img
              alt={alt}
              src={getSanityImage(imageSrc, mobileHeight, mobileWidth)}
              className={`MobileImage ${imageClassName}`}
              onClick={handleClick}
              onError={handleImageError}
              onLoad={() => setShowLoader(false)}
              loading={notLazy || preload ? undefined : "lazy"}
              nonce="__nonce__"
              style={{ ...showLoader ? { visibility: "hidden", height: 0, width: 0 } : {}, ...cursorStyle }}
          />
        </>
    )
  }

  if (!(imageSrc as string)?.startsWith("https://cdn.cibler.io")) {
    let retrySrc = imageSrc as string;
    if (retry !== props.retry) {
      const url = new URL(retrySrc);
      url.searchParams.append("retry", new Date().getTime().toString());
      retrySrc = url.href;
    }
    return (
      <>
        <img
          key={key}
          src={retrySrc}
          className={imageClassName}
          alt={alt}
          onClick={callback}
          onError={handleImageError}
          onLoad={() => setShowLoader(false)}
          loading={notLazy || preload ? undefined : "lazy"}
          nonce="__nonce__"
          style={
            showLoader ? { visibility: "hidden", height: 0, width: 0 } : {}
          }
        />
        {showLoader && (
          <>
            <img
              className={`imagePlaceholder DesktopImage ${imageClassName}`}
            />
            <img className={`imagePlaceholder MobileImage ${imageClassName}`} />
          </>
        )}
      </>
    );
  }

  const getEngineUrl = (src, width, height) => {
    const hasVersion = new URL(src)?.searchParams?.has("v");
    if (hasVersion) return src;

    const url = getImageUrl(src as string);

    return `https://tourismebyca.twic.pics${url}?twic=v1/cover=${width}${
      height ? `x${height}` : ""
    }/focus=auto${
      retry !== props.retry ? `&retry=${new Date().getTime().toString()}` : ""
    }`;
  };

  return (
    <>
      <img
        key={`desktop_${key}`}
        alt={alt}
        src={getEngineUrl(imageSrc, width, height)}
        className={`DesktopImage ${imageClassName}`}
        onClick={callback}
        onError={handleImageError}
        onLoad={() => setShowLoader(false)}
        loading={notLazy || preload ? undefined : "lazy"}
        nonce="__nonce__"
        style={showLoader ? { visibility: "hidden", height: 0, width: 0 } : {}}
      />
      <img
        key={`mobile_${key}`}
        alt={alt}
        src={getEngineUrl(imageSrc, mobileWidth, mobileHeight)}
        className={`MobileImage ${imageClassName}`}
        onClick={callback}
        onError={handleImageError}
        onLoad={() => setShowLoader(false)}
        loading={notLazy || preload ? undefined : "lazy"}
        style={showLoader ? { visibility: "hidden", height: 0, width: 0 } : {}}
      />
      {showLoader && (
        <>
          <img className={`imagePlaceholder DesktopImage ${imageClassName}`} />
          <img className={`imagePlaceholder MobileImage ${imageClassName}`} />
        </>
      )}
    </>
  );
};
