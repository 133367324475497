import React, {useState} from 'react';
import {Picto} from "../Picto";
import {ChatBotComponentProps} from "./index";
import {FilterLayout} from "./FiltersLayout";
import {useTranslation} from "../../tools/i18n";
import {TravelPlannerBudgetType, TravelPlannerConfigType} from "./types";

export interface BudgetProps extends ChatBotComponentProps {
  config: TravelPlannerConfigType;
  step: TravelPlannerBudgetType;
  siteConfiguration: any;
}

export const Budget = (props: BudgetProps) => {
  const {t} = useTranslation();
  const {
    config,
    step,
    callback,
    initialData,
    back,
    requirements,
  } = props;
  const options = step.budgetList.map(b => b);
  const [data, setData] = useState<string>(initialData || options[1].key)

  const subtitleKey = `travelPlaner.Budget.subTitle`;
  const hasSubtitle = t(subtitleKey) !== subtitleKey;

  return (
    <div className="budget">
      <FilterLayout
        config={config}
        callback={() => callback(data)}
        title={t(`travelPlaner.Budget.title`)}
        back={back}
        requirements={requirements}
        siteConfiguration={props.siteConfiguration}
      >
        {hasSubtitle
          ? <div className="layoutSubtitle">{t(subtitleKey)}</div>
          : null
        }
        <div className="buttonList buttonListBudget">
          {step.budgetList.map(o => (
            <button
              key={encodeURIComponent(o.key)}
              onClick={() => setData(o.key)}
              className={`${o.key === data ? 'selected' : ''}`}
            >
              <Picto iconKey={o.picto} />
              <span>{t(`travelPlaner.Budget.${o.key}`)}</span>
            </button>
          ))}
        </div>
      </FilterLayout>
    </div>
  )
}