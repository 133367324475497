import React, { Fragment, useEffect, useMemo, useState } from "react";
import { ChatBotComponentProps } from "./index";
import { useTranslation } from "../../tools/i18n";
import { TravelPlannerLoaderType } from "./types";
import { Picto } from "../Picto";
import { CustomImageSlider } from "./CustomImageSlider";

export interface LoaderProps extends ChatBotComponentProps {
  step: TravelPlannerLoaderType;
}

export const Loader = (props: LoaderProps) => {
  const { step, stateData, requirements } = props;
  const days = stateData.DayNumber || 1;
  const time = days * 20000 + 10000;
  const [percent, setPercent] = useState<number>(0);
  const { t } = useTranslation();

  const images = useMemo(() => {
    if (step.version === "v1" || !step.images?.length) return [];
    return step.images.sort(() => Math.random() - 0.5);
  }, [step, requirements]);

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setPercent((p) => {
        if (p === 100) return p;
        if (p >= 98) clearInterval(interval);
        return p + 1;
      });
    }, time / 100);
  }, [time]);

  if (step.version === "v1") {
    return (
      <div className="loading">
        <Picto iconKey={"loader"} />
        <div>
          {percent}
          <span>%</span>
        </div>
      </div>
    );
  }

  return (
    <div className="Loader">
      {images?.length ? (
        <CustomImageSlider requirements={requirements} images={images} />
      ) : null}
      <div className="loader">
        <span>0%</span>
        <div className="progressBar">
          <div
            className="progress"
            nonce="__nonce__"
            style={{ width: `${percent}%` }}
          />
        </div>
        <span>{percent}%</span>
      </div>
      {step.version === "v2"
        ? Array.from({ length: 2 }).map((_, i) => (
            <div className={`sentences${i}`} key={i}>
              {step.sentences.map((s, j) => (
                <Fragment key={j}>
                  <span>{t(`travelPlaner.Loader.${s}`)}</span>
                  <span className="separator">•</span>
                </Fragment>
              ))}
            </div>
          ))
        : null}
      {step.version === "v3" ? (
        <div className="sentence">
          <span>{t(`travelPlaner.Loader.${step.sentence}`)}</span>
        </div>
      ) : null}
      <div className="propulsedBy">
        Powered by
        <a
          href=" https://www.wearegenial.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://factory.wearegenial.com/genial.svg" alt="genial" />
        </a>
      </div>
    </div>
  );
};
