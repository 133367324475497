import * as React from "react";
import { imageUrlFor } from "../../tools/sanity";
import { RequirementsProps } from "../../tools/context";

export interface MiniCarouselSlideProps {
  _key: string;
  name: string;
  image?: any;
  staticImage?: string;
  link?: string;
  callback?: () => undefined | void;
}

export interface MiniCarouselProps {
  label: string;
  subLabel: string;
  list: MiniCarouselSlideProps[];
  requirements: RequirementsProps;
}

const MiniCarousel = (props: MiniCarouselProps) => {
  const { label, subLabel, list, requirements } = props;

  return (
    <div className="miniCarousel">
      <div className="header">
        {label}
        <span>{subLabel}</span>
      </div>
      <div className="scrollBlock">
        {list.map((c) => (
          <a
            className="block"
            key={c._key}
            href={c.link}
            nonce="__nonce__"
            style={{
              backgroundImage: `url('${
                c.staticImage ||
                imageUrlFor(c.image, requirements).width(159).height(159).url()
              }')`,
            }}
            onClick={() => c.callback && c.callback()}
          >
            <div>{c.name}</div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default MiniCarousel;
