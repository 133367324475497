import React, { useState } from 'react';
import moment from 'moment';
import {Picto} from "../Picto";
import {useTracking} from "../../tools/analytics";
import {useTranslation} from "../../tools/i18n";
import {Button} from "../Button";

export interface FavoritesProps {
  inputs: {
    search?: string
    searchMap?: string
    startDate: string
    tripLength: number
  }
}


const Favorites = (props: FavoritesProps) => {
    const {trackEvent} = useTracking();
    const {t} = useTranslation();

    const [isActive, setIsActive] = useState(false);

    const saveToFavorites = () => {
      trackEvent('save_to_favorites');
      const currentUrl = window.location.href;
      const formattedStartDate = moment(props.inputs.startDate).format('DD/MM');

      const newFavorite = {
        destination: props.inputs.search || props.inputs.searchMap,
        depart: formattedStartDate,
        duration: props.inputs.tripLength,
        url: currentUrl
      };

      // Fetch the current favorites from the cookie
      const currentFavorites = document.cookie.split('; ').find(row => row.startsWith('travelFavorites='));

      let favoritesArray = [];
      if (currentFavorites) {
        favoritesArray = JSON.parse(decodeURIComponent(currentFavorites.split('=')[1]));
      }

      // Check if the favorite already exists
      const existingFavoriteIndex = favoritesArray.findIndex(fav => fav.url === currentUrl);

      if (existingFavoriteIndex === -1) {
        // If the favorite doesn't exist, add it
        favoritesArray.push(newFavorite);
        setIsActive(true);
      } else {
        // If it does exist, remove it
        favoritesArray.splice(existingFavoriteIndex, 1);
        setIsActive(false);
      }

      // Save the updated favorites array to the cookie
      document.cookie = `travelFavorites=${encodeURIComponent(JSON.stringify(favoritesArray))}; path=/;`;
    };


    return (
      <Button
        theme="pro"
        className={`favorites ${isActive ? 'active' : ''}`}
        onClick={saveToFavorites}
      >
        {t('travelPlaner.Travel.favorites')}
        <Picto iconKey='heart' fill={isActive ? 'black' : 'white'} />
      </Button>
    );
};

export default Favorites;
