import React, {useState} from 'react';
import {Picto} from "../../../Picto";

export interface NumberProps {
  data: number
  setData: (p: number | ((p: number) => number)) => void
  label?: string
  min?: number | 0,
  max?: number,
  step?: number | 1,
}

export const Number = (props: NumberProps) => {
  let {
    data,
    setData,
    label,
    min,
    max,
    step,
  } = props;
  step = step || 1;

  return (
    <div className="numberInput">
      <button
        onClick={() => setData(p => p - step)}
        disabled={min !== undefined && data<=min}
      >
        <Picto iconKey={"minus"} />
      </button>
      <span>{label || data}</span>
      <button
        onClick={() => setData(p => p + step)}
        disabled={max !== undefined && data>=max}
      >
        <Picto iconKey={"plus"} />
      </button>
    </div>
  )
}