import React from "react";
import { Picto } from "../Picto";

export interface QuoteProps {
  content: string;
  legend?: string;
  background?: string;
  quoteColor?: string;
  textColor?: string;
  _key?: string;
}

const Quote = (props: QuoteProps) => {
  const { content, legend, background, _key, quoteColor, textColor } = props;

  if (!content) return null;

  return (
    <div
      className={`Quote ${_key || ""}`}
      nonce="__nonce__"
      style={background ? { background } : null}
    >
      <style>
        {quoteColor &&
          `
        .${_key} svg path {
          fill: ${quoteColor};
        }
      `}
      </style>
      <Picto iconKey={"quote"} />
      <div
        nonce="__nonce__"
        style={textColor ? { color: textColor } : null}
        className="content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div
        className="legend"
        nonce="__nonce__"
        style={textColor ? { color: textColor } : null}
      >
        {legend}
      </div>
    </div>
  );
};

export default Quote;
