import React, { useEffect, useRef, useState, useContext } from "react";
import { Picto } from "../Picto";
import {
  getCookies,
  getQueryString,
  replaceQueryString,
} from "../../tools/tools";
import { OverLayParameter } from "../../App";
import { useTracking } from "../../tools/analytics";
import { RequirementsProps } from "../../tools/context";
import { useTranslation } from "../../tools/i18n";
import {Button} from "../Button";

import {TravelPlanerResultsContext} from "../TravelPlanerResults/TravelPlanerResultsContext";

export interface ChatAgentProps {
  className?: string;
  requirements: RequirementsProps;
  hideTimer?: number;
  context?: any;
  agentId?: string;
  setOverlay: (p: OverLayParameter) => void;
  withLabel?: boolean;
  hideHighlight?: boolean;
}

export const ChatAgent = (props: ChatAgentProps) => {

  const {
    className,
    requirements,
    hideTimer,
    context,
    agentId,
    withLabel,
    hideHighlight,
  } = props;
  const [showChat, setShowChat] = useState<boolean>(false);
  const [hideAgent, setHideAgent] = useState<boolean>(true);
  const [highLightMessage, setHighLightMessage] = useState<string>("");
  const [showHighLight, setShowHighLight] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>();
  const { trackEvent } = useTracking();
  const { t, i18n } = useTranslation();
  const lang: Intl.LocalesArgument = i18n.language.split("_")?.[0];

  const { siteConfiguration } = useContext(TravelPlanerResultsContext);

  useEffect(() => {
    const hideScroll = () => {
      const daysFooter = document.querySelector(".daysFooter");

      if (showChat && window.innerWidth < 600) {
        document.body.style.overflow = "hidden";
        daysFooter?.setAttribute("style", "z-index: 94");
      } else {
        document.body.style.overflow = "auto";
        daysFooter?.setAttribute("style", "z-index: 96");
      }
    };

    window.addEventListener("resize", hideScroll);
    hideScroll();

    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("resize", hideScroll);
    };
  }, [showChat]);

  useEffect(() => {
    setTimeout(() => {
      setHideAgent(false);
    }, hideTimer || 0);

    const cookies = getCookies();
    if (
        getQueryString("chatbot") === "open" &&
        (cookies.loginToken || !requirements.config.travelForceLogin)
    ) {
      setShowChat(true);
      replaceQueryString("chatbot", null);
    }

    const handleChatbotReady = (event: MessageEvent) => {
      if (event.data.payload) {
        setHighLightMessage(event.data.payload);
        setTimeout(() => setShowHighLight(!hideHighlight), 100);
        setTimeout(() => setShowHighLight(false), 5000);
        setTimeout(() => setHighLightMessage(""), 5150);
      }
      if (context) {
        iframeRef.current?.contentWindow?.postMessage(
            {
              type: "contextData",
              payload: context,
            },
            "*"
        );
      }
      iframeRef.current?.contentWindow?.postMessage(
          {
            type: "clearConversationId",
            payload: "",
          },
          "*"
      );
      iframeRef.current?.contentWindow?.postMessage(
          {
            type: "clearVisitorId",
            payload: "",
          },
          "*"
      );
    };

    window.addEventListener("message", (event) => {
      if (!event.data) return;
      if (event.data.type === "chatbotReady") handleChatbotReady(event);
    });

    //const payload = 'Bonjour 👋🏼 Vous avez besoin de plus de précisions sur votre itinéraire ? N’hésitez pas à me poser vos questions 😊';
    const payload = t("travelPlaner.Travel.chatPopup");
    setHighLightMessage(payload);
    setTimeout(() => setShowHighLight(!hideHighlight), 100);
    setTimeout(() => setShowHighLight(false), 5000);
    setTimeout(() => setHighLightMessage(""), 5150);
  }, [context, hideHighlight]);

  if (hideAgent) return null;

  const cookies = getCookies();
  const handleButtonClick = () => {
    if (!cookies.loginToken && requirements.config.travelForceLogin) {
      props.setOverlay({
        key: "loginPopup",
        context: { queries: { chatbot: "open" } },
      });
      return;
    }
    setShowChat((p) => {
      if (!p) {
        trackEvent("chatbot_open_click");
      }
      return !p;
    });
    setHighLightMessage("");
    setShowHighLight(false);
  };

  const agentClassName = className || "";
  return (
      <div className={`agent ${agentClassName} ${withLabel ? "withLabel" : ""}`}>
        {highLightMessage && (
            <div className={`highLight ${showHighLight ? "visible" : ""}`}>
              {highLightMessage}
            </div>
        )}

        <Button
            onClick={() => handleButtonClick()}
            withPicto={"chat-icon"}
            textLabel="travelPlaner.Travel.chatLabel"
            withSVG={siteConfiguration.withSVG}
        >
        </Button>

        {(cookies.loginToken || !requirements.config.travelForceLogin) && (
            <div
                className={`agentBlock ${showChat ? "visible" : ""}`}
                onClick={() => setShowChat(false)}
            >
              <iframe
                  ref={(ref) => (iframeRef.current = ref)}
                  onClick={(e) => e.stopPropagation()}
                  className="chatIframe"
                  title="chatbot"
                  src={`https://factory.wearegenial.com/agents/${agentId}/iframe?lang=${lang}`}
              />
              <button className="close" onClick={() => setShowChat(false)}>
                <Picto iconKey={"cross"}/>
              </button>
            </div>
        )}
      </div>
  );
};
