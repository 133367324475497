import * as React from "react";
import { RequirementsProps } from "../../tools/context";
import { Picto } from "../Picto";
import { useEffect, useRef, useState } from "react";
import { ResponsiveImage } from "../ResponsiveImage";

export interface ImageSlide {
  _id?: string;
  image: object | string;
  smallImage?: object | string;
  title?: string;
  description?: string;
  link?: any;
  directLink?: boolean;
}

export interface ImageCarouselPopupProps {
  list: ImageSlide[];
  initialPage?: number;
  closeCallback: () => void;
  requirements: RequirementsProps;
}

const ImageCarouselPopup = (props: ImageCarouselPopupProps) => {
  const { list, requirements } = props;
  const [currentPage, setCurrentPage] = useState<number>(
    props.initialPage || 0
  );

  const scrollRef = useRef<HTMLDivElement>();
  const [showPrev, setShowPrev] = useState<boolean>(false);
  const [showNext, setShowNext] = useState<boolean>(true);

  useEffect(() => {
    if (!props.initialPage) return;

    scrollRef.current.scroll({ left: props.initialPage * window.innerWidth });
  }, []);

  useEffect(() => {
    setShowPrev(!!currentPage);
    setShowNext(currentPage !== list.length - 1);
  }, [currentPage]);

  const handleNextPrev = (e, prev?: boolean) => {
    e.stopPropagation();
    scrollRef.current.scrollBy({
      left: (prev ? -1 : 1) * window.innerWidth,
      behavior: "smooth",
    });
    return false;
  };

  const handleScroll = (e) => {
    const ref = e.target;
    const page = Math.round(ref.scrollLeft / window.innerWidth);
    setCurrentPage(page);
  };

  const goToPage = (e, page) => {
    e.stopPropagation();
    scrollRef.current.scroll({
      left: page * window.innerWidth,
      behavior: "smooth",
    });
    return false;
  };

  return (
    <div className="imageCarouselPopup" onClick={props.closeCallback}>
      <button className="close" onClick={props.closeCallback}>
        <Picto iconKey={"cross"} />
      </button>
      <div className="scrollBlock">
        <div
          className="scroller"
          onScroll={handleScroll}
          ref={(ref) => (scrollRef.current = ref)}
        >
          {list.map((c, i) => (
            <div className="imageSlide" key={`image_${i}`}>
              <ResponsiveImage
                src={c.image}
                alt={`Image ${i + 1}`}
                requirements={requirements}
                // callback={e => e.stopPropagation()}
              />
              {(c.title || c.description) && (
                <div className="blockText">
                  <div className="title">{c.title}</div>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: c.description,
                    }}
                  />
                  {c.link && (
                    <a
                      className="link"
                      href={c.link.link}
                      target={"_blank"}
                      rel={"noopener"}
                    >
                      {c.link.picto && <Picto iconKey={c.link.picto} />}
                      <span>{c.link.text || c.link.link}</span>
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="pagination">
          {list.map((_c, i) => (
            <button
              onClick={(e) => goToPage(e, i)}
              key={`page_${i}`}
              className={i === currentPage ? "current" : null}
            />
          ))}
        </div>
      </div>
      {showPrev && (
        <button
          onClick={(e) => handleNextPrev(e, true)}
          className="pageButton previous"
        >
          <Picto iconKey={"arrow-left"} />
        </button>
      )}
      {showNext && (
        <button onClick={(e) => handleNextPrev(e)} className="pageButton next">
          <Picto iconKey={"arrow-right"} />
        </button>
      )}
    </div>
  );
};

export default ImageCarouselPopup;
