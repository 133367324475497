import React from "react";
import { PictoProps } from "../index";

export const Periods = (props: PictoProps) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0497 0.446564C10.5254 0.446564 10.9111 0.822319 10.9111 1.28584V1.9386H11.5811C12.9026 1.9386 13.9739 2.98236 13.9739 4.26991V13.2221C13.9739 14.5097 12.9026 15.5535 11.5811 15.5535H2.39279C1.07129 15.5535 0 14.5097 0 13.2221V4.26991C0 2.98236 1.07129 1.9386 2.39279 1.9386H3.06285V1.28584C3.06285 0.822319 3.44851 0.446564 3.92425 0.446564C4.39999 0.446564 4.78566 0.822319 4.78566 1.28584V1.9386H9.1883V1.28584C9.1883 0.822319 9.57396 0.446564 10.0497 0.446564ZM12.2511 4.26991V6.41472H1.72281V4.26991C1.72281 3.9094 2.02277 3.61714 2.39279 3.61714H3.06285V4.26991C3.06285 4.73343 3.44851 5.10919 3.92425 5.10919C4.39999 5.10919 4.78566 4.73343 4.78566 4.26991V3.61714H9.1883V4.26991C9.1883 4.73343 9.57396 5.10919 10.0497 5.10919C10.5254 5.10919 10.9111 4.73343 10.9111 4.26991V3.61714H11.5811C11.9511 3.61714 12.2511 3.9094 12.2511 4.26991ZM12.2511 8.09326H1.72281V13.2221C1.72281 13.5827 2.02277 13.8749 2.39279 13.8749H11.5811C11.9511 13.8749 12.2511 13.5827 12.2511 13.2221V8.09326Z"
      fill={props.fill || "#3b56dc"}
    />
  </svg>
);
