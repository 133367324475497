import React from "react";
import {PictoProps} from "./index";

export const CircleCross = (props: PictoProps) => (
  <svg width={props.width || "23"} height={props.height || "23"} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.4443" cy="11.6133" r="10" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.4443 8.61328L8.44434 14.6133" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.44434 8.61328L14.4443 14.6133" stroke="#004653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
